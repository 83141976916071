<template>
  <div>
    <PresetDataTable />
  </div>
</template>

<script>
import PresetDataTable from "./PresetDataTable.vue";
export default {
  components: { PresetDataTable },
  data: function () {
    return {
      isProcessing: false,
    };
  },
  computed: {
    offer() {
      return this.$store.state.offer.offer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {},
};
</script>

<style scoped></style>

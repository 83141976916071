/* eslint-disable no-console */
import { db } from "@/firebase";

const state = {
  permittedEmailDomains: [],
  permittedEmailDomainsLoadingTable: false,
};

const actions = {
  async loadPermittedEmailDomains({ commit, rootGetters }) {
    commit("setPermittedEmailDomainsLoadingTable", true);
    try {
      const programId = rootGetters.programId;

      if (!programId) {
        throw "No program selected";
      }

      const domainsSnapshot = await db
        .collection("programs")
        .doc(programId)
        .collection("permittedAdminDomains")
        .get();

      const domains = [];
      domainsSnapshot.forEach((doc) => {
        domains.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      commit("setPermittedEmailDomains", domains);
    } catch (error) {
      console.error("Error loading permitted email domains:", error);
      throw error;
    } finally {
      commit("setPermittedEmailDomainsLoadingTable", false);
    }
  },

  async addDomain({ commit, rootGetters }, payload) {
    try {
      const programId = rootGetters.programId;
      const userId = rootGetters.user.id;

      if (!programId) {
        throw "No program selected";
      }

      if (!userId) {
        throw "No user found";
      }

      // Check if domain already exists
      const existingDomainsSnapshot = await db
        .collection("programs")
        .doc(programId)
        .collection("permittedAdminDomains")
        .where("domain", "==", payload.domain)
        .get();

      if (!existingDomainsSnapshot.empty) {
        throw "Domain already exists";
      }

      const domainRef = await db
        .collection("programs")
        .doc(programId)
        .collection("permittedAdminDomains")
        .add({
          domain: payload.domain,
          createdAt: new Date(),
          updatedAt: new Date(),
          createdBy: userId,
          updatedBy: userId,
        });

      const newDomain = {
        id: domainRef.id,
        domain: payload.domain,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: userId,
        updatedBy: userId,
      };

      commit("addPermittedEmailDomain", newDomain);
    } catch (error) {
      console.error("Error adding domain:", error);
      throw error;
    }
  },

  async updateDomain({ commit, rootGetters }, payload) {
    try {
      const programId = rootGetters.programId;
      const userId = rootGetters.user.id;

      if (!programId) {
        throw "No program selected";
      }

      if (!userId) {
        throw "No user found";
      }

      // Find the current domain document
      const currentDomainSnapshot = await db
        .collection("programs")
        .doc(programId)
        .collection("permittedAdminDomains")
        .where("domain", "==", payload.oldDomain)
        .get();

      if (currentDomainSnapshot.empty) {
        throw "Domain not found";
      }

      const currentDomainDoc = currentDomainSnapshot.docs[0];

      // Check if new domain already exists (excluding current domain)
      if (payload.domain !== payload.oldDomain) {
        const existingDomainsSnapshot = await db
          .collection("programs")
          .doc(programId)
          .collection("permittedAdminDomains")
          .where("domain", "==", payload.domain)
          .get();

        if (!existingDomainsSnapshot.empty) {
          throw "Domain already exists";
        }
      }

      await db
        .collection("programs")
        .doc(programId)
        .collection("permittedAdminDomains")
        .doc(currentDomainDoc.id)
        .update({
          domain: payload.domain,
          updatedAt: new Date(),
          updatedBy: userId,
        });

      commit("updatePermittedEmailDomain", {
        id: currentDomainDoc.id,
        domain: payload.domain,
        updatedAt: new Date(),
        updatedBy: userId,
      });
    } catch (error) {
      console.error("Error updating domain:", error);
      throw error;
    }
  },

  async deleteDomain({ commit, rootGetters }, domain) {
    try {
      const programId = rootGetters.programId;
      if (!programId) {
        throw "No program selected";
      }

      // Find the domain document
      const domainSnapshot = await db
        .collection("programs")
        .doc(programId)
        .collection("permittedAdminDomains")
        .where("domain", "==", domain)
        .get();

      if (domainSnapshot.empty) {
        throw "Domain not found";
      }

      const domainDoc = domainSnapshot.docs[0];
      await db
        .collection("programs")
        .doc(programId)
        .collection("permittedAdminDomains")
        .doc(domainDoc.id)
        .delete();

      commit("deletePermittedEmailDomain", domainDoc.id);
    } catch (error) {
      console.error("Error deleting domain:", error);
      throw error;
    }
  },
};

const mutations = {
  setPermittedEmailDomains(state, domains) {
    state.permittedEmailDomains = domains;
  },

  setPermittedEmailDomainsLoadingTable(state, loading) {
    state.permittedEmailDomainsLoadingTable = loading;
  },

  addPermittedEmailDomain(state, domain) {
    state.permittedEmailDomains.push(domain);
  },

  updatePermittedEmailDomain(state, updatedDomain) {
    const index = state.permittedEmailDomains.findIndex(
      (d) => d.id === updatedDomain.id
    );
    if (index !== -1) {
      state.permittedEmailDomains.splice(index, 1, {
        ...state.permittedEmailDomains[index],
        ...updatedDomain,
      });
    }
  },

  deletePermittedEmailDomain(state, domainId) {
    state.permittedEmailDomains = state.permittedEmailDomains.filter(
      (d) => d.id !== domainId
    );
  },
};

const getters = {
  permittedEmailDomains(state) {
    return state.permittedEmailDomains;
  },

  permittedEmailDomainsLoadingTable(state) {
    return state.permittedEmailDomainsLoadingTable;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-icon :color="filtered.color" v-on="on">{{ filtered.icon }}</v-icon>
    </template>
    <span class="text-capitalize">{{ filtered.state }}</span>
  </v-tooltip>
</template>

<script>
export default {
  data: () => ({
    statuses: [
      { state: true, icon: "check_circle", color: "green" },
      { state: false, icon: "cancel", color: "red" },
      { state: "Active", icon: "check_circle", color: "green" },
      { state: "Archive", icon: "inventory", color: "red" },
      { state: "Available", icon: "check_circle", color: "green" },
      { state: "Invited", icon: "drafts", color: "blue" },
      { state: "Awaiting Approval", icon: "how_to_reg", color: "amber" },
      { state: "Cancelled", icon: "not_interested", color: "red" },
      { state: "Pending", icon: "pending", color: "amber" },
      { state: "pending", icon: "pending", color: "amber" },
      { state: "inactive", icon: "not_interested", color: "red" },
      { state: "Inactive", icon: "not_interested", color: "red" },
      { state: "Live", icon: "wifi_tethering", color: "green" },
      { state: "Processing", icon: "pending", color: "amber" },
      { state: "Completed", icon: "thumb_up", color: "green" },
      { state: "rejected", icon: "cancel", color: "red" },
      { state: "approved", icon: "check_circle", color: "green" },
      { state: "Approved", icon: "check_circle", color: "green" },
      { state: "Rejected", icon: "cancel", color: "red" },
      { state: "Invoiced", icon: "receipt", color: "green" },
      { state: "Draft", icon: "fas fa-edit", color: "amber" },
      { state: "Voided", icon: "not_interested", color: "red" },
      { state: "Deactivated", icon: "not_interested", color: "red" },
      { state: "Copy", icon: "content_copy", color: "purple" },
      { state: "active", icon: "check_circle", color: "green" },
      { state: "Matched", icon: "check_circle", color: "green" },
      { state: "Unmatched", icon: "cancel", color: "red" },
      { state: "canceled", icon: "cancel", color: "red" },
      { state: "paid", icon: "check_circle", color: "green" },
      { state: "unpaid", icon: "warning", color: "red" },
      { state: "draft", icon: "pending", color: "amber" },
      { state: "open", icon: "lock_open", color: "amber" },
      { state: "Open", icon: "lock_open", color: "amber" },
      { state: "Award", icon: "stars", color: "primary" },
      { state: "Adjust", icon: "iso", color: "green" },
      { state: "Redeem", icon: "shopping_cart", color: "green" },
      { state: "Refund", icon: "update", color: "blue" },
      { state: "Reorder", icon: "restore", color: "green" },
      { state: "void", icon: "block", color: "red" },
      { state: "uncollectible", icon: "report", color: "red" },
      { state: "Closed", icon: "check_circle", color: "green" },
      { state: "Shipping", icon: "local_shipping", color: "blue" },
      { state: "Cancelled", icon: "block", color: "red" },
    ],
  }),

  props: {
    status: {
      required: true,
    },
  },

  computed: {
    filtered() {
      const found = this.statuses.find((item) => {
        return item.state === this.status;
      });
      return found ? found : "";
    },
  },

  methods: {},
};
</script>

<template>
  <div class="preview-page">
    <div
      v-for="(section, sectionIndex) in page.sections"
      :key="`section-${sectionIndex}`"
      class="preview-section mb-4"
    >
      <v-row no-gutters>
        <v-col
          v-for="(column, columnIndex) in section.columns"
          :key="`column-${columnIndex}`"
          class="px-2"
        >
          <div
            v-for="(field, fieldIndex) in column.items"
            :key="`field-${fieldIndex}`"
            class="mb-4"
          >
            <preview-field
              :field="field"
              :isPreview="isPreview"
              :fieldId="getFieldId(sectionIndex, columnIndex, fieldIndex)"
              :value="getFieldValue(sectionIndex, columnIndex, fieldIndex)"
              :validation-error="
                getValidationError(sectionIndex, columnIndex, fieldIndex)
              "
              @update:value="
                updateValue($event, sectionIndex, columnIndex, fieldIndex)
              "
              @table-edit-state-change="handleTableEditStateChange"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PreviewField from "./PreviewField.vue";

export default {
  components: {
    PreviewField,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    pageIndex: {
      type: Number,
      required: true,
    },
    formValues: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Object,
      default: () => ({}),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFieldId(sectionIndex, columnIndex, fieldIndex) {
      return `field_${this.pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}`;
    },

    getFieldValue(sectionIndex, columnIndex, fieldIndex) {
      const fieldId = this.getFieldId(sectionIndex, columnIndex, fieldIndex);
      return this.formValues[fieldId];
    },

    getValidationError(sectionIndex, columnIndex, fieldIndex) {
      const fieldId = this.getFieldId(sectionIndex, columnIndex, fieldIndex);
      return this.validationErrors && this.validationErrors[fieldId]
        ? this.validationErrors[fieldId]
        : null;
    },

    updateValue(value, sectionIndex, columnIndex, fieldIndex) {
      const fieldId = this.getFieldId(sectionIndex, columnIndex, fieldIndex);
      const field =
        this.page.sections[sectionIndex].columns[columnIndex].items[fieldIndex];

      // For radio fields, ensure allOptions is updated
      if (field.type === "radio" && field.allOptions) {
        field.allOptions.forEach((opt) => {
          opt.isSelected = opt.value === value;
        });
      }

      // Emit the value update
      this.$emit("update:form-value", fieldId, value);
    },

    handleTableEditStateChange(event) {
      console.log(
        "[PreviewPage] Received and forwarding table-edit-state-change",
        event
      );
      this.$emit("table-edit-state-change", event);
    },
  },
};
</script>

<style scoped>
.preview-page {
  padding: 0;
}

.page-title {
  margin-bottom: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.preview-section {
  border-radius: 4px;
  padding: 10px;
}

.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
</style>

/**
 * FormDataProcessor - Utility class for processing form data
 *
 * This utility helps transform form data to use idx property as keys
 * with comprehensive field information and all available options for auditing and reporting.
 */
export default class FormDataProcessor {
  /**
   * Transforms form data to use idx property as keys with extended field information
   * @param {Object} formData - The original form data with field_x_y_z keys
   * @param {Object} formStructure - The form structure containing field definitions
   * @returns {Object} - Transformed data with idx-based object structure and extended metadata
   */
  static transformToStructuredData(formData, formStructure) {
    // Build a mapping of original keys to field information including idx
    const fieldMappings = this._buildFieldMappings(formStructure);

    // Create the structured result object
    const result = {};

    // Add form metadata
    if (formStructure.formId || formStructure.formTitle) {
      result._meta = {
        submittedAt: new Date().toISOString(),
        formId: formStructure.formId || null,
        formTitle: formStructure.formTitle || null,
        formVersion: formStructure.formVersion || "1.0",
      };
    }

    // Process each form value and add it to the result with proper structure
    Object.keys(formData).forEach((key) => {
      const fieldInfo = fieldMappings[key];

      // Skip if we don't have field info for this key
      if (!fieldInfo || !fieldInfo.idx) return;

      // Build a complete options array including selected and non-selected values
      const allOptions = this._getFullOptionsArray(fieldInfo, formData[key]);

      // Process component fields for table-type fields
      let processedComponentFields = null;
      if (
        fieldInfo.type === "table" &&
        Array.isArray(fieldInfo.componentFields)
      ) {
        processedComponentFields = fieldInfo.componentFields.map(
          (compField) => {
            // Ensure each component field has a unique idx
            return {
              ...compField,
              idx: compField.idx || `table-field-${this._generateRandomId()}`,
            };
          }
        );
      }

      // Use the idx property as the key in the result object
      // Include extended field metadata for auditing and reporting
      result[fieldInfo.idx] = {
        // Basic field info
        label: fieldInfo.label || "",
        type: fieldInfo.type || "text",
        value: formData[key],

        // Extended metadata for auditing
        required: fieldInfo.required || false,
        fieldId: fieldInfo.fieldId || key,
        position: fieldInfo.position || {},

        // Field validation and constraints
        validation: fieldInfo.validation || null,
        entryLimits: fieldInfo.entryLimits || null,

        // Complete options information
        presets: fieldInfo.presets || null,
        allOptions: allOptions,

        // Component fields for complex types with guaranteed unique idx
        componentFields:
          processedComponentFields || fieldInfo.componentFields || null,

        // Field appearance properties
        styles: fieldInfo.styles || null,
        placeholder: fieldInfo.placeholder || "",

        // Field-specific properties
        min: fieldInfo.min,
        max: fieldInfo.max,
        multiple: fieldInfo.multiple,

        // Timestamps for auditing
        submittedAt: new Date().toISOString(),
      };
    });

    return result;
  }

  /**
   * Gets a complete options array including both selected and non-selected options
   * @param {Object} fieldInfo - The field information
   * @param {any} fieldValue - The current field value
   * @returns {Array|null} - Complete options array or null if not applicable
   * @private
   */
  static _getFullOptionsArray(fieldInfo, fieldValue) {
    // Only process fields that can have options
    if (
      !["dropdown", "radio", "checkboxes", "autocomplete"].includes(
        fieldInfo.type
      )
    ) {
      return null;
    }

    // Handle case where presets information is missing
    if (!fieldInfo.presets) {
      return null;
    }

    // For static presets, return the complete options array with selection state
    if (
      fieldInfo.presets.presetsType === "static" &&
      Array.isArray(fieldInfo.presets.staticOptions)
    ) {
      return fieldInfo.presets.staticOptions.map((option) => {
        const optionValue =
          typeof option === "object"
            ? option.value || option.identifier
            : option;

        // Determine if this option is selected
        let isSelected = false;
        if (Array.isArray(fieldValue)) {
          // For multi-select fields (checkboxes)
          isSelected = fieldValue.includes(optionValue);
        } else {
          // For single-select fields (radio, dropdown)
          isSelected = fieldValue === optionValue;
        }

        // Return enhanced option object with selection state
        return {
          ...option,
          isSelected,
        };
      });
    }

    // For dynamic presets, if we have the full options array in the field info
    if (
      fieldInfo.presets.presetsType === "dynamic" &&
      Array.isArray(fieldInfo.dynamicOptions)
    ) {
      return fieldInfo.dynamicOptions.map((option) => {
        const optionId = option.identifier || option.id;

        // Determine if this option is selected
        let isSelected = false;
        if (Array.isArray(fieldValue)) {
          // For array values, check if the value is in the array
          if (typeof fieldValue[0] === "object") {
            // Array of objects - compare by identifier
            isSelected = fieldValue.some(
              (item) => (item.identifier || item.id) === optionId
            );
          } else {
            // Array of primitives
            isSelected = fieldValue.includes(optionId);
          }
        } else if (typeof fieldValue === "object") {
          // For object values, compare identifiers
          isSelected = (fieldValue.identifier || fieldValue.id) === optionId;
        } else {
          // For primitive values
          isSelected = fieldValue === optionId;
        }

        // Return enhanced option object with selection state
        return {
          ...option,
          isSelected,
        };
      });
    }

    // If we can't determine options, return null
    return null;
  }

  /**
   * Builds field mappings from original field IDs to extended field metadata
   * @param {Object} formStructure - The form structure with fields
   * @returns {Object} - Mapping from original keys to field metadata
   * @private
   */
  static _buildFieldMappings(formStructure) {
    const mappings = {};

    if (!formStructure || !formStructure.fields) {
      return mappings;
    }

    // Process each field to create a mapping with extended properties
    formStructure.fields.forEach((field) => {
      if (field.fieldId) {
        // Ensure field has an idx
        const fieldIdx = field.idx || field.fieldId;

        // Process component fields for tables to ensure each has a unique idx
        let componentFields = null;
        if (field.type === "table" && Array.isArray(field.componentFields)) {
          componentFields = field.componentFields.map((compField) => {
            return {
              ...compField,
              idx: compField.idx || `table-field-${this._generateRandomId()}`,
            };
          });
        } else {
          componentFields = field.componentFields;
        }

        mappings[field.fieldId] = {
          // Basic field identification
          idx: fieldIdx,
          fieldId: field.fieldId,

          // Field display properties
          label: field.label || "",
          type: field.type || "text",
          placeholder: field.placeholder || "",

          // Field constraints
          required: field.required || false,
          clearable: field.clearable || false,

          // Field validation
          validation: field.validation || null,
          entryLimits: field.entryLimits || null,

          // Field positioning information for reporting
          position: field.position || {},

          // Field options and presets
          presets: field.presets || null,
          dynamicOptions: field.dynamicOptions || null, // Store cached dynamic options

          // Additional properties for specific field types
          min: field.min,
          max: field.max,
          multiple: field.multiple,
          prependIcon: field.prependIcon,
          thumbLabel: field.thumbLabel,

          // Component fields for table-type fields with guaranteed unique idx
          componentFields: componentFields,

          // Styles for heading-type fields
          styles: field.styles || null,
        };
      }
    });

    return mappings;
  }

  /**
   * Generate a random ID for fields that need one
   * @returns {String} - Random ID
   * @private
   */
  static _generateRandomId() {
    return Math.random().toString(36).substring(2, 10);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-table"},[_c('v-sheet',{staticClass:"mb-0 pa-0",attrs:{"outlined":"","rounded":""}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},_vm._l((_vm.tableFields),function(field){return _c('v-col',{key:field.idx,staticClass:"pl-2",attrs:{"cols":field.cols || Math.floor(12 / _vm.tableFields.length)}},[_c('component-renderer',{attrs:{"field":_vm.getClonedField(field),"value":_vm.newRowFormValues[field.idx || `field-${field.label}`],"show-validation":_vm.showValidation,"validation-error":_vm.newRowValidationErrors[field.idx || `field-${field.label}`]},on:{"input":function($event){return _vm.updateNewRowFormValue(
                  field.idx || `field-${field.label}`,
                  $event
                )},"clear-validation":function($event){return _vm.clearNewRowValidationError(
                  field.idx || `field-${field.label}`
                )}}})],1)}),1),(_vm.pointsFields.length > 0)?_c('v-row',{staticClass:"px-2 mt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"points-summary"},_vm._l((_vm.pointsFields),function(field){return _c('div',{key:field.idx,staticClass:"points-item d-inline-block"},[(_vm.hasDropdownValue(field))?_c('v-chip',{staticClass:"mt-n3 mb-2",attrs:{"color":"primary","small":"","icon":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("stars")]),_vm._v(" "+_vm._s(_vm.getPointsValue(_vm.newRowFormValues, field))+" points ")],1):_vm._e()],1)}),0)])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-2 mt-n8",attrs:{"text":"","color":"grey darken-1"},on:{"click":_vm.resetNewRowForm}},[_vm._v(" Clear ")]),_c('v-btn',{staticClass:"mt-n8",attrs:{"color":"green","disabled":!_vm.isNewRowFormValid ||
            Object.keys(_vm.newRowValidationErrors).length > 0,"elevation":"0","dark":_vm.isNewRowFormValid &&
            Object.keys(_vm.newRowValidationErrors).length === 0,"loading":_vm.showValidation},on:{"click":_vm.addNewRow}},[_vm._v("Add ")])],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableRows,"items-per-page":_vm.tableOptions.itemsPerPage,"page":_vm.tableOptions.page,"footer-props":{
        'items-per-page-options': [5, 10, 15, -1],
        'items-per-page-text': 'Rows per page:',
        'show-first-last-page': false,
        'show-current-page': true,
      },"mobile-breakpoint":0},on:{"update:page":function($event){return _vm.$set(_vm.tableOptions, "page", $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [(_vm.editingIndex !== index)?_c('tr',[_vm._l((_vm.displayHeaders),function(header){return _c('td',{key:header.value},[_vm._v(" "+_vm._s(header.value.endsWith("_points") ? _vm.getPointsValue(item, _vm.getFieldFromPointsHeader(header)) : _vm.formatFieldValue(item, _vm.getFieldFromHeader(header)))+" ")])}),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":"","color":"amber"},on:{"click":function($event){return _vm.editRow(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)],1)],2):_c('tr',[_vm._l((_vm.displayHeaders),function(header){return _c('td',{key:header.value},[(!header.value.endsWith('_points'))?[_c('component-renderer',{attrs:{"field":_vm.getClonedField(_vm.getFieldFromHeader(header)),"value":_vm.editRowFormValues[header.value],"show-validation":_vm.showValidation,"validation-error":_vm.editRowValidationErrors[header.value]},on:{"input":function($event){return _vm.updateEditRowFormValue(header.value, $event)},"clear-validation":function($event){return _vm.clearEditRowValidationError(header.value)}}})]:[_vm._v(" "+_vm._s(_vm.getPointsValue( _vm.editRowFormValues, _vm.getFieldFromPointsHeader(header) ))+" ")]],2)}),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":"","color":"success","disabled":!_vm.isEditRowFormValid ||
                Object.keys(_vm.editRowValidationErrors).length > 0,"loading":_vm.showValidation,"x-small":""},on:{"click":_vm.saveEditedRow}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("check")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","small":"","color":"grey"},on:{"click":_vm.cancelEdit}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)],1)],2)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center py-2"},[_vm._v(" No data available. Use the form above to add data. ")])]},proxy:true}])}),(_vm.hasPointsColumns)?_c('v-chip',{staticClass:"mt-n16 ml-6 mb-0",attrs:{"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("stars")]),_vm._v(" Total Points: "+_vm._s(_vm.calculateTotalPoints())+" ")],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
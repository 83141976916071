/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  presetRecords: [],
  loadingPresetRecord: false,
  loadingPresetRecords: false,
};

const actions = {
  async loadPresetRecords({ commit, getters }, dataSetId) {
    commit("setLoadingPresetRecords", true);

    let querySnapshot;
    const dbRef = db.collection("programs").doc(getters.programId);
    try {
      querySnapshot = await dbRef
        .collection("presetDataSets")
        .doc(dataSetId)
        .collection("data")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const presetRecords = [];
    querySnapshot.forEach(function (doc) {
      presetRecords.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    commit("setPresetRecords", presetRecords);
    commit("setLoadingPresetRecords", false);
  },

  async getPresetRecords({ commit, getters }, dataSetId) {
    // console.log(
    // "Store action getPresetRecords called with dataSetId:",
    //  dataSetId
    // );
    commit("setLoadingPresetRecords", true);

    let querySnapshot;
    const dbRef = db.collection("programs").doc(getters.programId);
    try {
      querySnapshot = await dbRef
        .collection("presetDataSets")
        .doc(dataSetId)
        .collection("data")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const presetRecords = [];
    querySnapshot.forEach(function (doc) {
      presetRecords.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    commit("setLoadingPresetRecords", false);
    return presetRecords;
  },

  async createPresetRecord({ dispatch, commit, getters }, payload) {
    commit("setLoadingPresetRecord", true);
    const recordRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("presetDataSets")
      .doc(payload.dataSetId)
      .collection("data");

    let idenifierDupSnapshot;
    try {
      idenifierDupSnapshot = await recordRef
        .where("identifierUppercase", "==", payload.identifierUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking if the record has been used";
    }

    if (idenifierDupSnapshot.size > 0) {
      commit("setLoadingPresetRecord", false);
      throw "Identifier is already registered.";
    }

    const { id, ...presetRecordInput } = payload; // eslint-disable-line no-unused-vars
    const identifier = {
      ...presetRecordInput,
    };

    let newRecordRef;
    try {
      newRecordRef = await recordRef.add(identifier);
    } catch (e) {
      throw "Error occured when creating a new record.";
    }

    commit("createPresetRecord", {
      ...presetRecordInput,
      id: newRecordRef.id,
    });
    commit("setLoadingPresetRecord", false);
    dispatch("setSnackbar", "Record Created");
  },

  async updatePresetRecord({ dispatch, commit, getters }, payload) {
    commit("setLoadingPresetRecord", true);
    const presetRecordRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("presetDataSets")
      .doc(payload.dataSetId)
      .collection("data");

    let storedPresetRecord;
    try {
      const presetRecordDoc = await presetRecordRef.doc(payload.id).get();
      storedPresetRecord = presetRecordDoc.data();
    } catch (e) {
      storedPresetRecord = null;
    }

    if (!storedPresetRecord) {
      throw "Error occured when fetching the data record";
    }

    let presetRecordDupSnapshot;
    try {
      presetRecordDupSnapshot = await presetRecordRef
        .where("identifierUppercase", "==", payload.identifierUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking if the record exists";
    }

    if (presetRecordDupSnapshot.size > 0) {
      let duplicated = false;
      presetRecordDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        commit("setLoadingPresetRecord", false);
        throw "Identifier is already registered";
      }
    }

    const { id, ...presetRecordInput } = payload;
    const presetRecord = {
      ...presetRecordInput,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await presetRecordRef.doc(id).update(presetRecord);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a record";
    }

    const presetDataSetBeforeUpdate = getters.presetRecords.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempPresetDataSet = {
      ...presetDataSetBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updatePresetRecord", tempPresetDataSet);
    commit("setLoadingPresetRecord", false);
    dispatch("setSnackbar", "Record Updated");
  },

  async deletePresetRecord({ dispatch, commit, getters }, payload) {
    commit("setLoading", true);
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("presetDataSets")
        .doc(payload.dataSetId)
        .collection("data")
        .doc(payload.id)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a record";
    }
    commit("deletePresetRecord", payload.id);
    commit("setLoading", false);
    dispatch("setSnackbar", "Record Deleted");
  },

  dataSetHasRecords({ getters }, dataSetId) {
    try {
      const dataRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("presetDataSets")
        .doc(dataSetId)
        .collection("data");

      const snapshot = dataRef.limit(1).get();
      if (snapshot.empty) {
        return 0;
      } else {
        return 1;
      }
    } catch (e) {
      console.error("Error checking for records:", e);
      return false;
    }
  },

  setLoadingPresetRecord({ commit }, payload) {
    commit("setLoadingPresetRecord", payload);
  },

  setLoadingPresetRecords({ commit }, payload) {
    commit("setLoadingPresetRecords", payload);
  },

  resetPresetRecords({ commit }) {
    commit("setPresetRecords", []);
  },
};

const mutations = {
  setPresetRecords(state, payload) {
    state.presetRecords = payload;
  },

  setLoadingPresetRecord(state, payload) {
    state.loadingPresetRecord = payload;
  },

  setLoadingPresetRecords(state, payload) {
    state.loadingPresetRecords = payload;
  },

  createPresetRecord(state, payload) {
    state.presetRecords = [...state.presetRecords, payload];
  },

  updatePresetRecord(state, payload) {
    state.presetRecords = state.presetRecords.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deletePresetRecord(state, payload) {
    const index = state.presetRecords.findIndex((item) => item.id === payload);
    if (index !== -1) {
      state.presetRecords.splice(index, 1);
    }
  },
};

const getters = {
  presetRecords(state) {
    return state.presetRecords;
  },
  loadingPresetRecord(state) {
    return state.loadingPresetRecord;
  },
  loadingPresetRecords(state) {
    return state.loadingPresetRecords;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('draggable',{staticClass:"w-100",attrs:{"group":{
      name: 'sections-only', // Different group name
      pull: true,
      put: function (to, from) {
        return from.options?.group?.name === 'sections-only';
      },
    },"ghost-class":"ghost-section","move":_vm.handleMove},model:{value:(_vm.localSections),callback:function ($$v) {_vm.localSections=$$v},expression:"localSections"}},[_vm._l((_vm.localSections),function(section,index){return _c('v-row',{key:index,attrs:{"no-gutters":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-sheet',{staticClass:"section w-100 drag mb-0",class:{ 'on-hover': hover },attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('Columns',{attrs:{"columns":section.columns,"pageIdx":_vm.pageIdx,"sectionIdx":index,"isPreview":_vm.isPreview}}),(hover)?_c('v-sheet',{staticClass:"hover-actions"},[_c('v-sheet',{staticClass:"action-sheet"},[_c('v-icon',{staticClass:"action-icon"},[_vm._v("drag_indicator")]),_c('v-icon',{staticClass:"action-icon",on:{"click":function($event){return _vm.deleteSection(index)}}},[_vm._v("delete_forever")])],1)],1):_vm._e()],1)],1)]}}],null,true)})],1)}),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(_vm.showBack)?_c('v-btn',{attrs:{"elevation":"0","color":"secondary"},on:{"click":_vm.backPage}},[_vm._v("back")]):_vm._e(),(!_vm.showSubmit)?_c('v-btn',{attrs:{"elevation":"0","color":"secondary"},on:{"click":_vm.nextPage}},[_vm._v("next")]):_vm._e(),(_vm.showSubmit)?_c('v-btn',{attrs:{"elevation":"0","color":"secondary"}},[_vm._v("submit")]):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="admin">
        <v-card-title class="page-heading">
          {{ editingAdmin.email ? "Edit " : "Add New " }}
          {{ formTitle }}
        </v-card-title>

        <v-card-text class="px-16">
          <v-row dense>
            <v-text-field
              v-if="!!editingAdmin.status"
              v-model="status"
              label="Status"
              disabled
            ></v-text-field>

            <v-text-field
              v-validate="'required|email'"
              :error-messages="errors.collect('admin.email')"
              data-vv-name="email"
              v-model="email"
              label="Email"
              required
              :disabled="!!editingAdmin.email"
            ></v-text-field>

            <v-select
              v-validate="'required'"
              :error-messages="errors.collect('admin.role')"
              data-vv-name="role"
              v-model="role"
              :items="roles"
              label="Role"
              append-icon="lock"
              required
              :disabled="!allowDeleteAdmin"
            ></v-select>
          </v-row>

          <v-row dense v-if="!allowDeleteAdmin">
            <v-alert type="warning" :value="true">
              This Administrator's role cannot be changed because you need at
              least one ACTIVE member in the ADMIN role
            </v-alert>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert type="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel </v-btn>
          <v-btn
            @click="handleSave('admin')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            elevation="0"
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ADMIN_ROLES } from "@/constants/roles";

export default {
  $_veeValidate: {
    validator: "new",
  },
  created() {
    const { email, role, status } = this.editingAdmin;
    this.email = email;
    this.role = role;
    this.status = status;
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    editingAdmin: {
      type: Object,
      required: true,
    },
    activeCount: {
      type: Number,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dictionary: {
      custom: {
        email: {
          required: () => "Email is required.",
          email: () => "Email should be valid.",
        },
        role: {
          required: () => "Role is Required.",
        },
      },
    },
    formTitle: "Administrator",
    formIcon: "admin_panel_settings",
    roles: ADMIN_ROLES,
    errorMessage: "",
  }),

  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    allowDeleteAdmin() {
      if (this.editingAdmin.status === "Invited") {
        return true;
      } else if (!this.editingAdmin.email) {
        return true;
      } else if (this.editingAdmin.role === "Editor") {
        return true;
      } else if (this.editingAdmin.role === "Observer") {
        return true;
      } else {
        if (this.activeCount <= 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    permittedEmailDomains() {
      return this.$store.getters.permittedEmailDomains || [];
    },
    isEmailDomainPermitted() {
      // If no permitted domains exist, all domains are allowed
      if (this.permittedEmailDomains.length === 0) {
        return true;
      }

      // @incentable.com is always permitted
      if (this.email && this.email.toLowerCase().endsWith("@incentable.com")) {
        return true;
      }

      // Check if email domain matches any permitted domain
      if (this.email) {
        const domain = this.email.split("@")[1];
        return this.permittedEmailDomains.some(
          (item) => item.domain.toLowerCase() === domain.toLowerCase()
        );
      }

      return false;
    },
  },

  methods: {
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.errorMessage = "";
      this.$validator.reset();
      this.$store.dispatch("setLoading", false);
    },
    handleSave(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        // Check if email domain is permitted
        if (!this.isEmailDomainPermitted) {
          this.errorMessage =
            "This email domain is not permitted for admin accounts.";
          return;
        }

        this.$store.dispatch("setLoading", true);

        const payload = {
          adminEmail: this.email,
          adminRole: this.role,
        };

        const action = this.editingAdmin.email ? "updateAdmin" : "addAdmin";

        this.$store
          .dispatch(action, payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            this.handleClose();
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch("setLoading", false);
          });
      });
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="open" max-width="1000px" persistent>
    <v-card>
      <form data-vv-scope="article">
        <v-row no-gutters>
          <v-card-title class="page-heading">
            {{ articleId ? "Edit " : "Add New " }}
            {{ formTitle }}
          </v-card-title>
        </v-row>
        <v-card-text class="px-16">
          <v-row dense>
            <v-col lg="9" class="pr-16">
              <v-text-field
                name="title"
                v-model="title"
                label="Article Title"
                v-validate="'required'"
                :error-messages="errors.collect('article.title')"
              ></v-text-field>

              <v-textarea
                v-model="body"
                label="Article Text"
                name="body"
                type="text"
                filled
              ></v-textarea>

              <v-row no-gutters class="incentable-form-heading">
                Featured Image
              </v-row>
              <div class="media-upload">
                <media-upload
                  class="mt-3 mb-0"
                  :id="articleId"
                  :currentImageOnFile="image"
                  :externalImageUrl="externalImageUrl"
                  :showMetaData="true"
                  @onImageSaved="handleImageSaved"
                  @onImageProcessing="handleImageProcessing"
                  :radius="radius"
                  :height="300"
                />
              </div>

              <v-row no-gutters class="incentable-form-heading">
                <v-col> Video </v-col>
              </v-row>

              <v-row class="incentable-form-subheading" no-gutters>
                A play button will appear on the article image when a video code
                is added below
              </v-row>

              <v-row class="incentable-form-subheading mt-n2" no-gutters>
                Only enter the video code, not the full web address
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      size="20"
                      >help</v-icon
                    >
                  </template>
                  <span>
                    Do not enter the full web address of the YouTube or Vimeo
                    video you wish to display
                    <br />https://www.youtube.com/watch?v=hJdbCCYxxAA
                    <br />https://vimeo.com/712888388 <br /><br />Only enter the
                    video id, which is last part of the address
                    <br /><br />hJdbCCYxxAA or 712888388 from the above
                    addresses <br /><br />Depending on your video origin
                    (YouTube or Vimeo)
                  </span>
                </v-tooltip>
              </v-row>
              <v-row class="mb-n4">
                <v-col lg="6">
                  <v-select
                    v-model="videoOrigin"
                    label="Video Origin"
                    item-text="text"
                    item-value="value"
                    :items="videoOrigins"
                  ></v-select>
                </v-col>
                <v-col lg="6">
                  <v-text-field
                    v-model="videoUrl"
                    label="Video ID"
                    type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <v-col lg="6">
                  <v-text-field
                    v-model="watchPoints"
                    label="Watch Points"
                    type="number"
                    hint="Award points to members who watch the video"
                  ></v-text-field>
                </v-col>
                <v-col lg="6">
                  <v-switch
                    v-model="videoOnlyPost"
                    label="Video Only Post"
                    type="boolean"
                    hint="Bypass the article body and display the video only"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row dense v-if="videoUrl && videoOrigin === 'youtube'">
                <v-col>
                  <vue-plyr :options="options">
                    <div
                      data-plyr-provider="youtube"
                      :data-plyr-embed-id="videoUrl"
                    ></div>
                  </vue-plyr>
                </v-col>
              </v-row>
              <v-row dense v-else-if="videoUrl && videoOrigin === 'vimeo'">
                <v-col>
                  <div class="vimeo-container">
                    <iframe
                      :src="`https://player.vimeo.com/video/${videoUrl}?h=00000000&badge=0&autopause=0&player_id=0&app_id=58479&byline=0&portrait=0&title=0&transparent=1&dnt=1`"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      title="Vimeo Video"
                    ></iframe>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-else-if="videoUrl && videoOrigin === 'incentable'">
                <v-col>
                  <div class="video-container">
                    <video
                      class="video-player"
                      controls
                      preload="metadata"
                      :src="incentableVideoUrl"
                      title="Incentable Video"
                      @ended="handleVideoComplete"
                      @timeupdate="handleTimeUpdate"
                      @loadedmetadata="handleVideoLoaded"
                    >
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </v-col>
              </v-row>
              <v-row dense v-else>
                <v-col>
                  <v-card
                    height="333px"
                    flat
                    class="mb-5 pt-0 mt-0 grey lighten-3"
                  >
                    <v-row
                      justify="center"
                      align="stretch"
                      style="height: 333px"
                    >
                      <v-icon color="primary" size="80">
                        play_circle_filled
                      </v-icon>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col lg="3">
              <v-select
                name="status"
                label="Status"
                v-validate="'required'"
                :error-messages="errors.collect('article.status')"
                v-model="selectedStatus"
                :items="statuses"
                item-text="text"
                item-value="value"
                append-icon="power_settings_new"
              >
              </v-select>

              <v-select
                name="blog"
                :items="blogs"
                label="Blog"
                v-validate="'required'"
                :error-messages="errors.collect('article.blog')"
                v-model="selectedBlog"
                item-text="title"
                item-value="id"
              ></v-select>

              <v-select
                name="categorys"
                v-model="selectedCategorys"
                :items="categorys"
                item-text="title"
                item-value="id"
                label="Category"
                v-validate="'required'"
                :error-messages="errors.collect('article.categorys')"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="category"
              ></v-select>

              <v-row no-gutters class="incentable-form-heading">
                <v-col> Content Filters </v-col>
              </v-row>
              <v-row no-gutters class="incentable-form-subheading">
                <v-col> Filter this article to specific members </v-col>
              </v-row>

              <v-select
                v-model="selectedCompanyTags"
                :items="companyTags"
                item-text="tag"
                item-value="id"
                label="Company Tags"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="local_offer"
              >
              </v-select>

              <v-select
                v-model="selectedMemberTags"
                :items="memberTags"
                item-text="tag"
                item-value="id"
                label="Member Tags"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="local_offer"
              >
              </v-select>

              <div v-if="articleId">
                <v-row no-gutters class="incentable-form-heading">
                  <v-col> More </v-col>
                </v-row>
                <v-row no-gutters class="incentable-form-subheading">
                  <v-col> Additional information </v-col>
                </v-row>

                <v-text-field :value="created | date" label="Created" readonly>
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(createdBy)"
                  label="Created By"
                  readonly
                >
                </v-text-field>

                <v-text-field :value="updated | date" label="Updated" readonly>
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(updatedBy)"
                  label="Updated By"
                  readonly
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-alert v-model="showAlert" color="primary" border="left" text>
                That Article name is already registered
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4 pr-4">
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()" :disabled="loading">
            {{ articleId ? "Close" : "Cancel" }}
          </v-btn>
          <v-btn
            @click="saveArticle('article')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            :disabled="loading || demo"
            elevation="0"
          >
            {{ articleId ? "Save Article" : "Create Article" }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import MediaUpload from "../Shared/MediaUpload.vue";
import VuePlyr from "vue-plyr";
import "plyr/dist/plyr.css";

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    MediaUpload,
    VuePlyr,
  },
  props: {
    articleId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => "Article title is required",
        },
        blog: {
          required: () => "Please assign this article to a Blog",
        },
        status: {
          required: () => "Status is required",
        },
        categorys: {
          required: () => "Category is required",
        },
      },
    },
    videoDuration: 0,
    videoWatched: false,
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    videoOrigins: [
      { text: "YouTube", value: "youtube" },
      { text: "Vimeo", value: "vimeo" },
      { text: "Incentable", value: "incentable" },
    ],

    formTitle: "Article",
    formIcon: "insert_drive_file",
    showAlert: false,
    selectedStatus: "Active",
    selectedCategorys: [],
    selectedBlog: "",
    selectedCompanyTags: [],
    selectedMemberTags: [],
    title: "",
    created: "",
    updated: "",
    createdBy: "",
    updatedBy: "",
    body: "",
    videoUrl: "",
    watchPoints: "",
    videoOrigin: "",
    videoOnlyPost: false,
    // image uploader
    radius: false,
    externalImageUrl: "",
    image: {
      name: "",
      updated: "",
      smallUrl: "",
      mediumUrl: "",
      largeUrl: "",
      smallStoragePath: "",
      mediumStoragePath: "",
      largeStoragePath: "",
    },
    options: {
      autopause: true,
      resetOnEnd: true,
      hideYouTubeDOMError: true,
      youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        controls: 0,
      },
      vimeo: {
        byline: false,
        portrait: false,
        title: false,
        speed: true,
        transparent: true,
        dnt: true,
      },
    },
  }),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    articles() {
      return this.$store.state.article.articles;
    },
    blogs() {
      return this.$store.state.blog.blogs;
    },
    categorys() {
      return this.$store.state.category.categorys;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    videoCode() {
      return "https://www.youtube-nocookie.com/embed/" + this.videoUrl;
    },
    incentableVideoUrl() {
      if (this.videoOrigin === "incentable" && this.videoUrl) {
        // If the URL is already a full URL, return it as is
        if (this.videoUrl.startsWith("http")) {
          return this.videoUrl;
        }
        // Otherwise, assume it's a Firebase Storage path and construct the URL
        return `https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/uploads/${
          this.programId
        }/${encodeURIComponent(this.videoUrl)}?alt=media`;
      }
      return "";
    },
    blogAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
  },
  methods: {
    handleVideoLoaded(event) {
      this.videoDuration = event.target.duration;
      this.videoWatched = false;
    },
    handleTimeUpdate(event) {
      if (!this.videoWatched && this.videoDuration > 0) {
        const currentTime = event.target.currentTime;
        const percentageWatched = (currentTime / this.videoDuration) * 100;

        if (percentageWatched >= 95) {
          this.videoWatched = true;
          this.handleVideoComplete();
        }
      }
    },
    handleVideoComplete() {
      if (this.videoOrigin === "incentable" && this.videoWatched) {
        // Show completion message
        this.$store.dispatch(
          "setSnackbar",
          "Thank you for watching the video!"
        );

        // Show points message if points are set
        if (this.watchPoints && parseInt(this.watchPoints) > 0) {
          this.$store.dispatch(
            "setSnackbar",
            `You've earned ${this.watchPoints} points for watching this video!`
          );
        }
      }
    },
    handleImageSaved(image) {
      (this.image = {
        updated: image.updated,
        name: image.name || "",
        smallUrl: image.smallUrl || "",
        mediumUrl: image.mediumUrl || "",
        largeUrl: image.largeUrl || "",
        smallStoragePath: image.smallStoragePath || "",
        mediumStoragePath: image.mediumStoragePath || "",
        largeStoragePath: image.largeStoragePath || "",
      }),
        this.saveArticle("article", false);
    },
    handleImageProcessing(val) {
      this.$store.dispatch("setLoading", val);
    },
    clear() {
      this.title = "";
      this.created = "";
      this.updated = "";
      this.createdBy = "";
      this.updatedBy = "";
      this.body = "";
      this.selectedBlog = "";
      this.selectedStatus = "Active";
      this.selectedCategorys = [];
      this.selectedCompanyTags = [];
      this.selectedMemberTags = [];
      this.showAlert = false;
      this.videoUrl = "";
      this.watchPoints = "";
      this.videoOrigin = "";
      this.videoOnlyPost = false;
      (this.image = {
        // new uploader
        name: "",
        updated: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: "",
      }),
        this.$validator.reset();
      this.$store.dispatch("setLoading", false);
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    getAuthorName(adminId) {
      return this.blogAuthorMap[adminId];
    },
    saveArticle(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        this.$store.dispatch("setLoading", true);

        const payload = {
          // Add database fields here
          status: this.selectedStatus,
          title: this.title,
          titleUppercase: this.title.toUpperCase(),
          body: this.body,
          blog: this.selectedBlog,
          categorys: this.selectedCategorys,
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
          videoUrl: this.videoUrl || "",
          watchPoints: this.watchPoints || "",
          videoOrigin: this.videoOrigin || "",
          videoOnlyPost: this.videoOnlyPost || "",
          image: this.image || {
            name: "",
            updated: "",
            smallUrl: "",
            mediumUrl: "",
            largeUrl: "",
            smallStoragePath: "",
            mediumStoragePath: "",
            largeStoragePath: "",
          },
        };
        if (this.articleId) {
          // Update Article
          payload.id = this.articleId;
          this.$store
            .dispatch("updateArticle", payload)
            .then((createResult) => {
              this.$store.dispatch("setLoading", false);
              if (createResult === "ok") {
                this.handleClose();
              } else if (createResult === "duplication") {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch("setLoading", false);
            });
        } else {
          // Create Article
          this.$store
            .dispatch("createArticle", payload)
            .then((createResult) => {
              this.$store.dispatch("setLoading", false);
              if (createResult === "ok") {
                if (close) {
                  this.handleClose();
                  return;
                }
              } else if (createResult === "duplication") {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch("setLoading", false);
            });
        }
      });
    },
  },
  watch: {
    bannerUploadTask: function () {
      if (!this.bannerUploadTask) {
        return;
      }
      this.bannerUploadTask.on(
        "state_changed",
        (sp) => {
          if (sp.totalBytes <= 0) {
            return;
          }
          this.bannerUploadingProgress = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
          this.bannerSize = Math.round(sp.bytesTransferred / 1000);
        },
        () => {
          this.bannerUploading = false;
        },
        () => {
          this.bannerUploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              this.bannerUrl = downloadURL;
              this.bannerUploading = false;
            });
        }
      );
    },
    articleId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedArticle = this.articles.find(
            (item) => item.id === newVal
          );
          const {
            status,
            categorys,
            blog,
            companyTags,
            memberTags,
            title,
            created,
            updated,
            body,
            image,
            videoUrl,
            createdBy,
            updatedBy,
            watchPoints,
            videoOrigin,
            videoOnlyPost,
          } = selectedArticle;
          this.selectedStatus = status;
          this.selectedCategorys = categorys;
          this.selectedBlog = blog;
          this.selectedCompanyTags = companyTags;
          this.selectedMemberTags = memberTags;
          this.title = title;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.body = body;
          this.image = image || {
            name: "",
            updated: "",
            smallUrl: "",
            mediumUrl: "",
            largeUrl: "",
            smallStoragePath: "",
            mediumStoragePath: "",
            largeStoragePath: "",
          };
          this.videoUrl = videoUrl;
          this.watchPoints = watchPoints;
          this.videoOrigin = videoOrigin;
          this.videoOnlyPost = videoOnlyPost;
        }
      },
    },
  },
};
</script>
<style>
.media-upload {
  height: 333px;
  display: flex;
  flex-direction: column;
}

.media-upload > * {
  flex: 1;
  height: 100%;
}

.vimeo-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 20px;
}

.vimeo-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 20px;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<template>
  <v-container fluid fill-height class="gradient-background">
    <v-row justify="center">
      <v-col xs="12" sm="8" md="4" lg="3" align="center">
        <v-card class="el">
          <v-form @submit.prevent="onSignup">
            <v-row justify="center" class="pb-8 pt-14">
              <v-img
                :src="require('@/assets/stacked-no-background-50.png')"
                contain
                max-height="150px"
                max-width="150px"
              />
            </v-row>

            <v-card-text class="px-16">
              <v-text-field
                v-validate="'required|max:100'"
                :error-messages="errors.collect('name')"
                data-vv-name="name"
                v-model="name"
                id="name"
                label="Name"
                type="text"
                required
                outlined
              ></v-text-field>
              <v-text-field
                v-validate="'required|email'"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                v-model="email"
                id="email"
                label="Email"
                type="email"
                required
                outlined
              ></v-text-field>
              <v-text-field
                v-validate="'required|min:8|verify_password'"
                :error-messages="errors.collect('password')"
                v-model="password"
                name="password"
                label="Password"
                ref="password"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                outlined
              ></v-text-field>
              <v-row no-gutters class="px-3 mb-4">
                <div class="caption text-left">
                  Password must contain at least 8 characters, including:
                  <ul class="pl-4 mt-1">
                    <li>One uppercase letter</li>
                    <li>One lowercase letter</li>
                    <li>One number</li>
                    <li>One special character</li>
                  </ul>
                </div>
              </v-row>
              <v-row row wrap align-center justify-center v-if="error">
                <app-alert
                  @dismissed="onDismissed"
                  :text="error.message"
                ></app-alert>
              </v-row>
              <v-btn
                type="submit"
                color="primary"
                block
                large
                :loading="loading"
                class="button-signin ml-0 mt-n1"
                elevation="0"
              >
                Sign Up
              </v-btn>
            </v-card-text>
            <v-row no-gutters justify="center">
              <v-btn to="/" text color="primary"
                >Already registered? Sign in here</v-btn
              >
            </v-row>
            <legal-links />
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LegalLinks from "../Shared/LegalLinks.vue";
export default {
  components: { LegalLinks },
  $_veeValidate: {
    validator: "new",
  },
  created() {
    this.$store.dispatch("clearError");
    this.$store.dispatch("setLoading", false);
  },
  data: () => {
    return {
      name: "",
      email: "",
      password: "",
      show1: false,
      show2: false,
      termsDialog: false,
      terms: true,
      dictionary: {
        custom: {
          name: {
            required: () => "Name is required",
            max: "The name field may not be greater than 100 characters",
          },
          email: {
            required: () => "Email is required",
          },
          password: {
            required: () => "Password is required",
            min: "Password must be at least 8 characters",
            verify_password: "Password must meet all complexity requirements",
          },
          terms: {
            required: () => "Must accept terms and conditions",
          },
        },
      },
    };
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
    this.$validator.extend("verify_password", {
      validate: (value) => {
        // At least one uppercase letter
        const hasUpperCase = /[A-Z]/.test(value);
        // At least one lowercase letter
        const hasLowerCase = /[a-z]/.test(value);
        // At least one number
        const hasNumber = /[0-9]/.test(value);
        // At least one special character
        const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);

        return hasUpperCase && hasLowerCase && hasNumber && hasSpecial;
      },
    });
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    onSignup() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          console.log("Not Valid");
        }
        if (result) {
          console.log("Is Valid");
          const userData = {
            email: this.email,
            password: this.password,
            name: this.name,
            terms: this.terms,
          };
          this.$store.dispatch("signUserUp", userData);
        }
      });
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
  props: {
    source: String,
  },
};
</script>

<style scoped>
.button-signin {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
}
</style>

/* eslint-disable no-console */
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  orderBy,
  addDoc,
  writeBatch,
  setDoc,
} from "firebase/firestore";
import Vue from "vue";
import { DateUtils } from "@/utils/DateUtils"; // Import the new DateUtils

const state = {
  offers: [],
  offerId: "",
  offerIndex: "",
  currentOffer: null,
  loadingCurrentOffer: false,
  pendingOfferUpdates: {},
  deletedEntries: [],
  processingOffer: false,
  pendingPointDeductions: [],
  deletedHistoryEntries: [],
};

const actions = {
  async updateOffer({ commit, getters, state, dispatch }) {
    console.log("[Store] updateOffer action called");
    const { currentOffer, deletedEntries } = state;
    let databucketIdList = [];

    commit("setLoading", true);
    commit("setOfferExists", false);

    try {
      const id = currentOffer?.id;
      if (!id) {
        console.error("[Store] No offer ID provided");
        throw new Error("No offer ID provided");
      }

      console.log("[Store] Updating offer with ID:", id);
      console.log("[Store] Current offer state:", currentOffer);
      console.log("[Store] Pending updates:", state.pendingOfferUpdates);

      const db = getFirestore();
      // Get the offer reference
      const offerRef = doc(db, "programs", getters.programId, "offers", id);

      // Get current offer document
      const docSnap = await getDoc(offerRef);
      if (!docSnap.exists()) {
        console.error("[Store] Offer document does not exist");
        throw new Error("Offer document does not exist");
      }

      // Store entries for databucket processing before we process them
      const entriesForDatabucket = state.pendingOfferUpdates.entries || [];

      // Always process entries using subcollections, regardless of migration status
      console.log("[Store] Processing entries using subcollection");
      const entriesRef = collection(offerRef, "entries");

      // Handle deleted entries first
      if (deletedEntries.length > 0) {
        console.log(
          `[Store] Processing ${deletedEntries.length} deleted entries`
        );
        for (const entry of deletedEntries) {
          console.log(`[Store] Deleting entry: ${entry.id}`);
          await deleteDoc(doc(entriesRef, entry.id));
        }
      }

      // Only process entries that have actually changed
      if (state.pendingOfferUpdates.entries) {
        // Get current entries from subcollection for comparison
        const currentEntriesSnapshot = await getDocs(entriesRef);
        const currentEntries = new Map();
        currentEntriesSnapshot.forEach((doc) => {
          currentEntries.set(doc.id, doc.data());
        });

        // Process each entry in pendingOfferUpdates
        const entries = state.pendingOfferUpdates.entries || [];
        console.log(
          `[Store] Processing ${entries.length} entries for subcollection`
        );

        for (const entry of entries) {
          // Skip entries that don't have an ID (shouldn't happen but just in case)
          if (!entry.id) {
            console.error(
              "[Store] Entry without ID detected, skipping:",
              entry
            );
            continue;
          }

          const currentEntry = currentEntries.get(entry.id);
          const opportunity = currentOffer.opportunities?.find(
            (opp) => opp.id === entry.opportunity
          );

          if (!currentEntry) {
            // New entry - use setDoc instead of updateDoc for new documents
            const entryRef = doc(entriesRef, entry.id);

            // Process all dates into JS Date objects before saving
            const processedEntry = DateUtils.prepareForFirestore(entry);
            console.log(
              `[Store] Creating new entry in subcollection: ${entry.id}`
            );

            await setDoc(entryRef, processedEntry);

            // Handle points for new entry if it has approved history
            if (entry.history?.length > 0) {
              // Process all unprocessed history entries
              for (const historyEntry of entry.history) {
                if (
                  !historyEntry.processed &&
                  historyEntry.outcome === "approved"
                ) {
                  // Handle redeemable points if the opportunity is redeemable
                  if (opportunity?.redeemable) {
                    await dispatch("handleRedeemablePoints", {
                      type: "add",
                      opportunity,
                      transaction: {
                        points: historyEntry.total,
                        member: entry.member,
                      },
                      previousTransaction: null,
                    });
                  }
                  historyEntry.processed = true;
                }
              }
              // Update the entry with processed history
              await updateDoc(entryRef, { history: entry.history });
            }
            continue;
          }

          // For existing entries, compare fields and only update what changed
          const updates = {};
          for (const key of Object.keys(entry)) {
            // Skip metadata fields
            if (key === "__ob__") continue;

            // Special handling for date fields - use DateUtils
            if (
              [
                "submitted",
                "reviewed",
                "formSubmittedAt",
                "createdAt",
                "lastModifiedAt",
              ].includes(key)
            ) {
              const currentDate = DateUtils.toJsDate(currentEntry[key]);
              const newDate = DateUtils.toJsDate(entry[key]);

              if (!currentDate && !newDate) continue;

              // Only include in updates if dates are different
              if (
                !currentDate ||
                !newDate ||
                currentDate.getTime() !== newDate.getTime()
              ) {
                updates[key] = newDate;
              }
              continue;
            }

            // Special handling for formSubmissionData
            if (key === "formSubmissionData") {
              const sanitizedFormData = entry[key];
              if (
                JSON.stringify(currentEntry[key]) !==
                JSON.stringify(sanitizedFormData)
              ) {
                updates[key] = sanitizedFormData;

                // Update formPoints if present in formSubmissionData
                if (sanitizedFormData?.formPoints !== undefined) {
                  updates.formPoints = sanitizedFormData.formPoints;
                }
              }
              continue;
            }

            // Special handling for history and points
            if (key === "history") {
              const currentValue = currentEntry[key];
              const newValue = entry[key];

              if (JSON.stringify(currentValue) !== JSON.stringify(newValue)) {
                // Process all unprocessed history entries
                for (const historyEntry of newValue) {
                  if (
                    !historyEntry.processed &&
                    historyEntry.outcome === "approved"
                  ) {
                    // Handle redeemable points if the opportunity is redeemable
                    if (opportunity?.redeemable) {
                      await dispatch("handleRedeemablePoints", {
                        type: "add",
                        opportunity,
                        transaction: {
                          points: historyEntry.total,
                          member: entry.member,
                        },
                        previousTransaction: null,
                      });
                    }
                    historyEntry.processed = true;
                  }
                }

                // Process dates in history records using DateUtils
                const processedHistory = newValue.map((h) => {
                  // Process each history item's dates
                  return {
                    ...h,
                    created: DateUtils.toJsDate(h.created) || new Date(),
                    submitted: h.submitted
                      ? DateUtils.toJsDate(h.submitted)
                      : null,
                    reviewed: h.reviewed
                      ? DateUtils.toJsDate(h.reviewed)
                      : null,
                  };
                });

                updates[key] = processedHistory;

                // Calculate total points as sum of all approved history entries plus formPoints
                const historyPoints = newValue.reduce((sum, h) => {
                  if (
                    h.outcome === "approved" ||
                    (h.total > 0 && h.outcome !== "rejected")
                  ) {
                    return sum + (parseFloat(h.total) || 0);
                  }
                  return sum;
                }, 0);

                updates.points = historyPoints;
                updates.totalPoints = historyPoints;
              }
              continue;
            }

            // For all other fields, only update if changed
            const currentValue = currentEntry[key];
            const newValue = entry[key];

            if (JSON.stringify(currentValue) !== JSON.stringify(newValue)) {
              updates[key] = newValue;
            }
          }

          // Only update if there are actual changes
          if (Object.keys(updates).length > 0) {
            updates.updatedAt = new Date(); // Add updatedAt only when there are changes
            console.log(
              `[Store] Updating existing entry in subcollection: ${entry.id}`
            );
            await updateDoc(doc(entriesRef, entry.id), updates);
          }
        }
      }

      // Remove entries from main document update since they're stored in subcollection
      delete state.pendingOfferUpdates.entries;

      // Update the main offer document if there are any other updates
      const offerUpdates = {};
      Object.keys(state.pendingOfferUpdates).forEach((key) => {
        // Skip __ob__ or other Vue-specific props
        if (key === "__ob__" || key.startsWith("_")) {
          console.log(`[Store] Skipping Vue-specific property: ${key}`);
          return;
        }

        // Process dates if applicable
        if (
          ["created", "updated", "createdAt", "lastModifiedAt"].includes(key)
        ) {
          offerUpdates[key] =
            DateUtils.toJsDate(state.pendingOfferUpdates[key]) || new Date();
        } else if (Array.isArray(state.pendingOfferUpdates[key])) {
          // For arrays, convert any dates to regular Date objects
          offerUpdates[key] = state.pendingOfferUpdates[key].map((item) => {
            if (item && typeof item.toDate === "function") {
              return item.toDate();
            }
            return item;
          });
        } else {
          offerUpdates[key] = state.pendingOfferUpdates[key];
        }
      });

      // Log what we're about to save
      console.log("[Store] Main document updates:", Object.keys(offerUpdates));

      if (Object.keys(offerUpdates).length > 0) {
        offerUpdates.updated = serverTimestamp();

        // If this is a non-migrated offer, update it to be migrated
        if (!currentOffer.migrationVersion) {
          console.log("[Store] Updating offer to be migrated (version 1)");
          offerUpdates.migrationVersion = 1;
        }

        console.log("[Store] Saving updates to main document");
        await updateDoc(offerRef, offerUpdates);
        console.log("[Store] Main document updated successfully");
      } else {
        console.log("[Store] No updates to main document needed");
      }

      // Update databuckets if needed
      if (entriesForDatabucket.length > 0) {
        console.log(
          `[Store] Processing ${entriesForDatabucket.length} entries for databuckets`
        );

        const entriesToProcess = entriesForDatabucket.filter((entry) => {
          const opportunity = currentOffer.opportunities?.find(
            (opp) => opp.id === entry.opportunity
          );
          // Only process entries where addToDatabucket is true (or undefined for backward compatibility)
          return opportunity?.addToDatabucket !== false;
        });

        if (entriesToProcess.length > 0) {
          // Process each entry for databucket updates
          for (const entry of entriesToProcess) {
            const opportunity = currentOffer.opportunities?.find(
              (opp) => opp.id === entry.opportunity
            );

            if (opportunity?.databucket) {
              const databucketRef = doc(
                db,
                "programs",
                getters.programId,
                "databuckets",
                opportunity.databucket
              );
              const databucketDoc = await getDoc(databucketRef);

              if (databucketDoc.exists()) {
                const currentOffers = databucketDoc.data().offers || [];

                // Prepare the entry payload with all required fields
                const payload = {
                  targetCode: opportunity.targetCode,
                  points: entry.totalPoints,
                  member: entry.member,
                  company: entry.company || "",
                  entryId: entry.id,
                };

                // Find if this entry already exists in the databucket
                const index = currentOffers.findIndex(
                  (el) => el.entryId === entry.id
                );

                if (index === -1) {
                  currentOffers.push(payload);
                } else {
                  currentOffers[index] = payload;
                }

                await updateDoc(databucketRef, { offers: currentOffers });
                databucketIdList.push(opportunity.databucket);
              }
            }
          }
        }
      }

      // Process deleted entries for databuckets
      if (deletedEntries.length > 0) {
        console.log(
          `[Store] Processing ${deletedEntries.length} deleted entries for databuckets`
        );

        for (const entry of deletedEntries) {
          const opportunity = currentOffer.opportunities?.find(
            (opp) => opp.id === entry.opportunity
          );

          if (opportunity?.databucket) {
            // Skip if addToDatabucket is explicitly set to false
            if (opportunity.addToDatabucket === false) {
              continue;
            }

            const databucketRef = doc(
              db,
              "programs",
              getters.programId,
              "databuckets",
              opportunity.databucket
            );
            const databucketDoc = await getDoc(databucketRef);

            if (databucketDoc.exists()) {
              const currentOffers = databucketDoc.data().offers || [];

              // Filter out the deleted entry
              const offers = currentOffers.filter(
                (el) => el.entryId !== entry.id
              );

              await updateDoc(databucketRef, { offers });
              databucketIdList.push(opportunity.databucket);
            }
          }
        }
      }

      // Clear pending updates and reload data
      commit("clearPendingOfferUpdates");
      commit("clearDeletedEntries");
      commit("setLoading", false);

      console.log("[Store] Successfully completed updateOffer");
      return databucketIdList;
    } catch (error) {
      console.error("[Store] Error in updateOffer:", error);
      commit("setLoading", false);
      throw error;
    }
  },

  handleRedeemablePoints({ dispatch, state }, payload) {
    // Skip if not redeemable or if points value is 0
    if (!payload.opportunity.redeemable || payload.transaction.points === 0) {
      return;
    }

    // Skip if this transaction is already being handled as a pending deduction
    const isPendingDeduction = state.pendingPointDeductions.some(
      (deduction) =>
        deduction.memberId === payload.transaction.member &&
        deduction.points === -payload.transaction.points &&
        deduction.notes === payload.opportunity.title
    );
    if (isPendingDeduction) {
      return;
    }

    if (payload.type === "add") {
      const pointsTransaction = {
        points: payload.transaction.points,
        type: "Award",
        description: "Claim",
        notes: payload.opportunity.title,
        memberId: payload.transaction.member,
      };
      dispatch("addMemberPoints", pointsTransaction);
      return;
    }

    if (
      payload.type === "update" &&
      payload.previousTransaction.points === payload.transaction.points
    ) {
      return;
    }

    if (
      payload.type === "update" &&
      payload.previousTransaction.points !== payload.transaction.points
    ) {
      const change =
        payload.transaction.points - payload.previousTransaction.points;
      // Skip if the change results in zero points
      if (change === 0) {
        return;
      }

      if (change > 0) {
        const pointsTransaction = {
          points: change,
          type: "Award",
          description: "Claim",
          notes: payload.opportunity.title,
          memberId: payload.transaction.member,
        };
        dispatch("addMemberPoints", pointsTransaction);
      } else if (change < 0) {
        const pointsTransaction = {
          points: change,
          type: "Award",
          description: "Claim",
          notes: payload.opportunity.title,
          memberId: payload.transaction.member,
        };
        dispatch("subtractMemberPoints", pointsTransaction);
      }
    }
    return;
  },

  loadOffers({ commit, getters }) {
    commit("setLoadingCards", true);
    const db = getFirestore();
    const offersRef = collection(db, "programs", getters.programId, "offers");
    const q = query(offersRef, orderBy("titleUppercase"));
    getDocs(q)
      .then((querySnapshot) => {
        const offers = [];
        querySnapshot.forEach((doc) => {
          // Use DateUtils to ensure dates are properly handled
          const data = doc.data();
          offers.push({
            id: doc.id,
            title: data.title,
            order: data.order,
            displayTitle: data.displayTitle,
            titleUppercase: data.titleUppercase,
            status: data.status,
            created: DateUtils.toJsDate(data.created),
            updated: DateUtils.toJsDate(data.updated),
            companyTags: data.companyTags,
            memberTags: data.memberTags,
          });
        });
        commit("setOffers", offers);
        commit("setLoadingCards", false);
      })
      .catch((error) => {
        console.error("Error loading offers:", error);
        commit("setLoadingCards", false);
      });
  },

  deleteOffer({ commit, getters, state }) {
    commit("setLoadingOffers", true);
    const db = getFirestore();
    deleteDoc(
      doc(db, "programs", getters.programId, "offers", state.currentOffer.id)
    );
    commit("setLoadingOffers", false);
    commit("setSnackbar", "Offer deleted");
  },

  addOffer({ commit, getters }, payload) {
    commit("setLoading", true);
    commit("setOfferExists", false);
    const titleCheck = payload.titleUppercase;
    const db = getFirestore();
    const offersRef = collection(db, "programs", getters.programId, "offers");
    const q = query(offersRef, where("titleUppercase", "==", titleCheck));
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        // If offer is already registered...
        commit("setOfferExists", true);
        commit("setLoading", false);
      } else {
        // If offer is not yet registered...
        const offer = {
          status: payload.status,
          title: payload.title,
          order: payload.order,
          displayTitle: payload.title,
          titleUppercase: payload.titleUppercase,
          created: payload.created,
          updated: payload.updated,
          companyTags: payload.companyTags,
          memberTags: payload.memberTags,
          createdBy: payload.createdBy,
          entries: [],
          opportunities: [],
          formFields: [],
        };
        addDoc(offersRef, offer)
          .then((docRef) => {
            const key = docRef.id;
            commit("addOffer", { ...offer, id: key });
            commit("setDialogNewOffer", false);
            commit("setLoading", false);
            commit("setOfferExists", false);
            commit("setSnackbar", "Offer added");
          })
          .catch((error) => {
            console.error("Error adding offer:", error);
            commit("setLoading", false);
          });
      }
    });
  },

  async loadCurrentOffer({ commit, getters, dispatch }, offerId) {
    commit("setLoadingCurrentOffer", true);
    commit("setCurrentOffer", null);
    commit("clearPendingOfferUpdates");

    console.log("[Store] Loading current offer:", offerId);

    const db = getFirestore();
    const offerRef = doc(db, "programs", getters.programId, "offers", offerId);

    let offerSnapshot;

    try {
      offerSnapshot = await getDoc(offerRef);
    } catch (e) {
      console.error("Error fetching offer:", e);
      commit("setLoadingCurrentOffer", false);
      throw "Error occurred when fetching an offer.";
    }

    const offerData = offerSnapshot.data();

    if (!offerData) {
      commit("setLoadingCurrentOffer", false);
      throw "No such Offer";
    }

    // Ensure opportunities array exists
    offerData.opportunities = offerData.opportunities || [];

    // Process all date fields to ensure consistency
    offerData.created = DateUtils.toJsDate(offerData.created);
    offerData.updated = DateUtils.toJsDate(offerData.updated);

    // Process date fields in opportunities array if they exist
    if (offerData.opportunities && Array.isArray(offerData.opportunities)) {
      offerData.opportunities = offerData.opportunities.map((opp) => {
        if (opp.deadline) {
          return {
            ...opp,
            deadline: DateUtils.toJsDate(opp.deadline),
          };
        }
        return opp;
      });
    }

    // Always try to load entries from subcollection first
    try {
      console.log(
        "[Store] Loading entries from subcollection for offer:",
        offerId
      );
      const entries = await dispatch("loadOfferEntries", offerId);
      console.log(
        "[Store] Loaded",
        entries.length,
        "entries from subcollection"
      );
      offerData.entries = entries;

      // If we have entries but the offer isn't marked as migrated, mark it
      if (entries.length > 0 && offerData.migrationVersion !== 1) {
        console.log(
          "[Store] Marking offer as migrated since it has entries in subcollection"
        );
        // Update the migrationVersion in the database
        await updateDoc(offerRef, {
          migrationVersion: 1,
          updated: serverTimestamp(),
        });
        // Update the migrationVersion in the local data
        offerData.migrationVersion = 1;
      }
    } catch (e) {
      console.error("Error loading entries from subcollection:", e);

      // If we failed to load from subcollection but the offer has entries array
      if (
        offerData.entries &&
        Array.isArray(offerData.entries) &&
        offerData.entries.length > 0
      ) {
        console.log(
          "[Store] Using entries from main document and migrating to subcollection"
        );

        // Process dates in entries from the main document
        offerData.entries = offerData.entries.map((entry) => {
          // Process date fields in each entry
          const processedEntry = { ...entry };
          processedEntry.submitted = DateUtils.toJsDate(entry.submitted);
          processedEntry.reviewed = DateUtils.toJsDate(entry.reviewed);
          processedEntry.formSubmittedAt = DateUtils.toJsDate(
            entry.formSubmittedAt
          );
          processedEntry.createdAt = DateUtils.toJsDate(entry.createdAt);
          processedEntry.lastModifiedAt = DateUtils.toJsDate(
            entry.lastModifiedAt
          );

          // Process dates in history array if it exists
          if (entry.history && Array.isArray(entry.history)) {
            processedEntry.history = entry.history.map((h) => ({
              ...h,
              created: DateUtils.toJsDate(h.created),
              submitted: DateUtils.toJsDate(h.submitted),
              reviewed: DateUtils.toJsDate(h.reviewed),
            }));
          }

          return processedEntry;
        });

        // Try to automatically migrate to subcollection
        try {
          await dispatch("migrateEntriesToSubcollection");
          console.log("[Store] Successfully migrated entries to subcollection");
        } catch (migrationError) {
          console.error(
            "[Store] Error migrating entries to subcollection:",
            migrationError
          );
          // Continue with entries from main document if migration fails
        }
      } else {
        // Initialize with empty entries array if none found
        console.log(
          "[Store] No entries found in subcollection or main document"
        );
        offerData.entries = [];
      }
    }

    commit("setCurrentOffer", { id: offerId, ...offerData });
    commit("setBreadCrumbDetail", offerData.title);
    commit("setLoadingCurrentOffer", false);

    // Ensure we start with no pending updates
    commit("clearPendingOfferUpdates");
    return offerData;
  },

  selectOffer({ commit }, payload) {
    commit("setOfferId", payload);
  },

  setOfferExists({ commit }, payload) {
    commit("setOfferExists", payload);
  },

  setDialogNewOffer({ commit }, payload) {
    commit("setDialogNewOffer", payload);
  },

  setDialogEditOffer({ commit }, payload) {
    commit("setDialogEditOffer", payload);
  },

  patchCurrentOffer({ state }, payload) {
    console.log(
      "[Store] patchCurrentOffer action called with payload:",
      payload
    );
    // Create a new object for currentOffer to ensure reactivity
    const updatedOffer = { ...state.currentOffer };

    // Initialize pendingOfferUpdates if needed
    if (!state.pendingOfferUpdates) {
      console.log("[Store] Initializing pendingOfferUpdates");
      Vue.set(state, "pendingOfferUpdates", {});
    }

    // Process each field in the payload
    Object.keys(payload).forEach((key) => {
      const currentValue = state.currentOffer?.[key];
      const newValue = payload[key];

      // Special handling for resources array
      if (key === "resources") {
        console.log("[Store] Processing resources update");
        // Ensure each resource is reactive
        const reactiveResources = newValue.map((resource) => ({ ...resource }));

        // Use Vue.set to ensure reactivity
        Vue.set(updatedOffer, "resources", reactiveResources);

        // Track resources update in pendingOfferUpdates
        Vue.set(state.pendingOfferUpdates, "resources", [...reactiveResources]);
        return;
      }

      // Special handling for entries array
      if (key === "entries") {
        console.log(
          "[Store] Processing entries update, count:",
          newValue.length
        );
        // Ensure each entry is reactive
        const reactiveEntries = newValue.map((entry) => ({ ...entry }));

        // Use Vue.set to ensure reactivity
        Vue.set(updatedOffer, "entries", reactiveEntries);

        // For migrated offers (using subcollection), track each entry separately
        if (state.currentOffer?.migrationVersion === 1) {
          console.log(
            "[Store] Migrated offer, tracking entries in subcollection"
          );
          // Track entries that need to be updated in the subcollection
          Vue.set(state.pendingOfferUpdates, "entries", [...reactiveEntries]);
        } else {
          console.log(
            "[Store] Non-migrated offer, tracking entries in main document"
          );
          // For non-migrated offers, track in the main document
          Vue.set(state.pendingOfferUpdates, "entries", [...reactiveEntries]);
        }

        console.log(
          "[Store] Updated pendingOfferUpdates.entries:",
          state.pendingOfferUpdates.entries
        );
        return;
      }

      // Special handling for date fields
      if (["created", "updated", "createdAt", "lastModifiedAt"].includes(key)) {
        const dateValue = DateUtils.toJsDate(newValue) || new Date();
        Vue.set(updatedOffer, key, dateValue);
        Vue.set(state.pendingOfferUpdates, key, dateValue);
        return;
      }

      // For other fields, only track if changed
      const areEqual =
        currentValue instanceof Date && newValue instanceof Date
          ? currentValue.getTime() === newValue.getTime()
          : JSON.stringify(currentValue) === JSON.stringify(newValue);

      if (!areEqual) {
        Vue.set(updatedOffer, key, newValue);
        Vue.set(state.pendingOfferUpdates, key, newValue);
      } else {
        Vue.set(updatedOffer, key, currentValue);
      }
    });

    // Update the current offer state with Vue.set to ensure reactivity
    Vue.set(state, "currentOffer", updatedOffer);
    console.log("[Store] Updated currentOffer:", state.currentOffer);
    console.log(
      "[Store] Updated pendingOfferUpdates:",
      state.pendingOfferUpdates
    );
    console.log(
      "[Store] pendingOfferUpdates.entries exists:",
      !!state.pendingOfferUpdates.entries
    );
  },

  setProcessingOffer({ commit }, payload) {
    commit("setProcessingOffer", payload);
  },

  addPendingPointDeduction({ commit }, deduction) {
    commit("ADD_PENDING_POINT_DEDUCTION", deduction);
  },

  clearPendingPointDeductions({ commit }) {
    commit("CLEAR_PENDING_POINT_DEDUCTIONS");
  },

  async saveFormSubmission(
    { getters, state },
    { entryId, formData, opportunity, member }
  ) {
    if (!getters.programId || !state.currentOffer?.id) {
      throw new Error("Missing program ID or offer ID");
    }

    const db = getFirestore();
    const formSubmission = {
      entryId,
      formData,
      opportunity: {
        id: opportunity.id,
        description: opportunity.description,
        points: opportunity.points,
        redeemable: opportunity.redeemable,
      },
      member: {
        id: member.id,
        fullname: member.fullname,
        email: member.email,
      },
      submittedAt: serverTimestamp(),
      createdAt: serverTimestamp(),
    };

    await addDoc(
      collection(
        db,
        "programs",
        getters.programId,
        "offers",
        state.currentOffer.id,
        "formSubmissions"
      ),
      formSubmission
    );

    return true;
  },

  async migrateEntriesToSubcollection({ getters, state, commit }) {
    try {
      const db = getFirestore();
      const currentOffer = state.currentOffer;
      const programId = getters.programId;

      if (!currentOffer) {
        throw new Error("No current offer to migrate");
      }

      // Get entries from either the current offer document or the store state
      const entries = currentOffer.entries || [];

      if (entries.length === 0) {
        return;
      }

      // Create a batch for the entries
      const entriesRef = collection(
        doc(db, "programs", programId, "offers", currentOffer.id, "entries")
      );
      const entriesBatch = writeBatch(db);

      // Process each entry
      for (const entry of entries) {
        const entryRef = doc(entriesRef, entry.id);

        // Create a clean entry object with proper date handling
        const processedEntry = DateUtils.prepareForFirestore({
          ...Object.fromEntries(
            Object.entries(entry).filter(([key]) => key !== "resources")
          ),
        });

        entriesBatch.set(entryRef, processedEntry);
      }

      // Commit the batch
      await entriesBatch.commit();

      // Verify the entries were written
      const verificationSnapshot = await getDocs(entriesRef);

      // Only update the offer document if we successfully wrote the entries
      if (verificationSnapshot.size > 0) {
        // Update the offer document to remove entries and add migration version
        const offerRef = doc(
          collection(db, "programs", programId, "offers"),
          currentOffer.id
        );

        await updateDoc(offerRef, {
          entries: [],
          migrationVersion: 1,
          lastMigratedAt: serverTimestamp(),
        });

        // Update the store state
        commit("patchCurrentOffer", {
          entries: [],
          migrationVersion: 1,
          lastMigratedAt: new Date(),
        });
      } else {
        throw new Error("No entries were written to the subcollection");
      }

      return true;
    } catch (error) {
      console.error("Error migrating entries:", error);
      if (error.code) {
        console.error("Error code:", error.code);
      }
      if (error.message) {
        console.error("Error message:", error.message);
      }
      if (error.stack) {
        console.error("Error stack:", error.stack);
      }
      throw error;
    }
  },

  async loadOfferEntries({ getters }, offerId) {
    try {
      const db = getFirestore();
      const entriesRef = collection(
        db,
        "programs",
        getters.programId,
        "offers",
        offerId,
        "entries"
      );

      const entriesSnapshot = await getDocs(entriesRef);
      const entries = [];

      entriesSnapshot.forEach((doc) => {
        const entryData = doc.data();

        // Process all dates using DateUtils for consistency
        const entry = {
          id: doc.id,
          ...entryData,
          submitted: DateUtils.toJsDate(entryData.submitted),
          reviewed: DateUtils.toJsDate(entryData.reviewed),
          formSubmittedAt: DateUtils.toJsDate(entryData.formSubmittedAt),
          createdAt: DateUtils.toJsDate(entryData.createdAt),
          lastModifiedAt: DateUtils.toJsDate(entryData.lastModifiedAt),
        };

        // Process dates in history array if it exists
        if (entry.history && Array.isArray(entry.history)) {
          entry.history = entry.history.map((h) => ({
            ...h,
            created: DateUtils.toJsDate(h.created),
            submitted: DateUtils.toJsDate(h.submitted),
            reviewed: DateUtils.toJsDate(h.reviewed),
          }));
        }

        entries.push(entry);
      });

      return entries;
    } catch (error) {
      console.error("Error loading offer entries:", error);
      throw error;
    }
  },

  async verifyMigrationStatus({ getters, commit }) {
    try {
      const programId = getters.programId;
      const db = getFirestore();
      const offersRef = collection(db, "programs", programId, "offers");
      const offersSnapshot = await getDocs(offersRef);

      const migrationStatus = {
        totalOffers: offersSnapshot.size,
        migratedOffers: 0,
        offersWithArrayEntries: 0,
        offersWithSubcollectionEntries: 0,
        offersNeedingMigration: [],
        details: [],
      };

      for (const offerDoc of offersSnapshot.docs) {
        const offer = offerDoc.data();
        const offerId = offerDoc.id;
        const entriesRef = collection(offerDoc.ref, "entries");
        const entriesSnapshot = await getDocs(entriesRef);

        const hasArrayEntries =
          offer.entries &&
          Array.isArray(offer.entries) &&
          offer.entries.length > 0;
        const hasSubcollectionEntries = entriesSnapshot.size > 0;
        const isMigrated = offer.migrationVersion === 1;

        const offerStatus = {
          id: offerId,
          title: offer.title || "Untitled Offer",
          migrationVersion: offer.migrationVersion || "Not migrated",
          arrayEntriesCount: hasArrayEntries ? offer.entries.length : 0,
          subcollectionEntriesCount: entriesSnapshot.size,
          needsMigration: hasArrayEntries && !hasSubcollectionEntries,
        };

        migrationStatus.details.push(offerStatus);

        if (isMigrated) {
          migrationStatus.migratedOffers++;
        }

        if (hasArrayEntries) {
          migrationStatus.offersWithArrayEntries++;
        }

        if (hasSubcollectionEntries) {
          migrationStatus.offersWithSubcollectionEntries++;
        }

        if (hasArrayEntries && !hasSubcollectionEntries) {
          migrationStatus.offersNeedingMigration.push({
            id: offerId,
            title: offer.title || "Untitled Offer",
            entriesCount: offer.entries.length,
          });
        }
      }

      console.log("[Migration Status]", migrationStatus);

      commit("setSnackbar", {
        text: `Migration Status - Total: ${migrationStatus.totalOffers}, Migrated: ${migrationStatus.migratedOffers}, Needing Migration: ${migrationStatus.offersNeedingMigration.length}`,
        color: "info",
        timeout: 6000,
      });

      return migrationStatus;
    } catch (error) {
      console.error("Error verifying migration status:", error);
      commit("setSnackbar", {
        text: "Error verifying migration status: " + error.message,
        color: "error",
      });
      throw error;
    }
  },

  async migrateAllOffers({ getters }) {
    try {
      const programId = getters.programId;
      const db = getFirestore();
      const offersRef = collection(db, "programs", programId, "offers");
      const offersSnapshot = await getDocs(offersRef);

      let migratedCount = 0;
      let errorCount = 0;
      let skippedCount = 0;

      for (const docSnapshot of offersSnapshot.docs) {
        const offer = docSnapshot.data();
        const offerId = docSnapshot.id;

        // Check if the offer has entries in the subcollection
        const entriesRef = collection(
          db,
          "programs",
          programId,
          "offers",
          offerId,
          "entries"
        );
        const entriesSnapshot = await getDocs(entriesRef);

        // Skip if already migrated and has entries in subcollection
        if (offer.migrationVersion === 1 && entriesSnapshot.size > 0) {
          console.log(`[Store] Skipping already migrated offer: ${offerId}`);
          skippedCount++;
          continue;
        }

        try {
          // Migrate entries to subcollection if they exist
          if (
            offer.entries &&
            Array.isArray(offer.entries) &&
            offer.entries.length > 0
          ) {
            console.log(
              `[Store] Migrating ${offer.entries.length} entries for offer: ${offerId}`
            );

            const entriesBatch = writeBatch(db);
            for (const entry of offer.entries) {
              // Filter out resources property and process dates
              const cleanEntry = DateUtils.prepareForFirestore(
                Object.fromEntries(
                  Object.entries(entry).filter(([key]) => key !== "resources")
                )
              );
              entriesBatch.set(doc(entriesRef, entry.id), cleanEntry);
            }

            await entriesBatch.commit();
            migratedCount++;

            // Update offer document to mark as migrated
            const offerRef = doc(db, "programs", programId, "offers", offerId);
            await updateDoc(offerRef, {
              migrationVersion: 1,
              entries: [],
              lastMigratedAt: serverTimestamp(),
            });

            console.log(`[Store] Successfully migrated offer: ${offerId}`);
          } else {
            console.log(`[Store] No entries to migrate for offer: ${offerId}`);
            skippedCount++;
          }
        } catch (migrationError) {
          console.error(
            `[Store] Error migrating offer ${offerId}:`,
            migrationError
          );
          errorCount++;
        }
      }

      console.log(
        `[Store] Migration completed: ${migratedCount} migrated, ${skippedCount} skipped, ${errorCount} errors`
      );
      return { migratedCount, skippedCount, errorCount };
    } catch (error) {
      console.error("[Store] Error in migration process:", error);
      throw error;
    }
  },

  async updateDatabuckets(
    { dispatch, getters, state },
    { entries, deletedEntries }
  ) {
    const databucketIdList = new Set();
    const db = getFirestore();

    // Process entries
    for (const entry of entries) {
      // Get the opportunity from the current offer
      const opportunity = state.currentOffer.opportunities.find(
        (opp) => opp.id === entry.opportunity
      );

      if (opportunity?.databucket) {
        // Skip if addToDatabucket is explicitly set to false
        if (opportunity.addToDatabucket === false) {
          continue;
        }

        const databucketRef = doc(
          db,
          "programs",
          getters.programId,
          "databuckets",
          opportunity.databucket
        );
        const databucketDoc = await getDoc(databucketRef);
        if (databucketDoc.exists()) {
          const currentOffers = databucketDoc.data().offers || [];

          const offerIndex = currentOffers.findIndex(
            (o) => o.id === state.currentOffer.id
          );

          // Calculate total points from entry history
          const totalPoints =
            entry.history?.reduce((sum, h) => {
              if (
                h.outcome === "approved" ||
                (h.total > 0 && h.outcome !== "rejected")
              ) {
                return sum + (h.total || 0);
              }
              return sum;
            }, 0) || 0;

          const updatedOffer = {
            id: state.currentOffer.id,
            title: state.currentOffer.title,
            points: totalPoints,
          };

          if (offerIndex === -1) {
            currentOffers.push(updatedOffer);
          } else {
            currentOffers[offerIndex] = updatedOffer;
          }

          await updateDoc(databucketRef, { offers: currentOffers });
          databucketIdList.add(opportunity.databucket);
        }
      }
    }

    // Process deleted entries
    for (const entry of deletedEntries) {
      // Get the opportunity from the current offer
      const opportunity = state.currentOffer.opportunities.find(
        (opp) => opp.id === entry.opportunity
      );

      if (opportunity?.databucket) {
        // Skip if addToDatabucket is explicitly set to false
        if (opportunity.addToDatabucket === false) {
          continue;
        }

        const databucketRef = doc(
          db,
          "programs",
          getters.programId,
          "databuckets",
          opportunity.databucket
        );
        const databucketDoc = await getDoc(databucketRef);
        if (databucketDoc.exists()) {
          const currentOffers = databucketDoc.data().offers || [];

          const offerIndex = currentOffers.findIndex(
            (o) => o.id === state.currentOffer.id
          );
          if (offerIndex !== -1) {
            currentOffers.splice(offerIndex, 1);
            await updateDoc(databucketRef, { offers: currentOffers });
            databucketIdList.add(opportunity.databucket);
          }
        }
      }
    }

    // Reload databuckets to get latest state
    await dispatch("loadDatabuckets");

    return Array.from(databucketIdList);
  },

  // New action specifically for resources that doesn't affect save button state
  async updateOfferResources({ state, getters }, resources) {
    if (!getters.programId) {
      throw new Error("Missing program ID");
    }

    // Create a new object for currentOffer to ensure reactivity
    const updatedOffer = { ...state.currentOffer };

    // Ensure each resource is reactive
    const reactiveResources = resources.map((resource) => ({ ...resource }));

    // Use Vue.set to ensure reactivity
    Vue.set(updatedOffer, "resources", reactiveResources);

    // Update the current offer state with Vue.set to ensure reactivity
    // But do NOT add to pendingOfferUpdates
    Vue.set(state, "currentOffer", updatedOffer);

    // Save resources changes directly to Firestore
    try {
      const db = getFirestore();
      const offerRef = doc(
        db,
        "programs",
        getters.programId,
        "offers",
        state.currentOffer.id
      );

      // Only update the resources field
      await updateDoc(offerRef, {
        resources: reactiveResources,
        updated: new Date(),
      });

      return true;
    } catch (error) {
      console.error("[Offers] Error updating resources:", error);
      throw error;
    }
  },
};

const mutations = {
  setOffers(state, payload) {
    state.offers = payload;
  },
  setOfferId(state, payload) {
    state.offerId = payload;
  },
  setCurrentOffer(state, payload) {
    state.currentOffer = payload;
  },
  setLoadingCurrentOffer(state, payload) {
    state.loadingCurrentOffer = payload;
  },
  setOfferExists(state, payload) {
    state.offerExists = payload;
  },
  setDialogNewOffer(state, payload) {
    state.dialogNewOffer = payload;
  },
  setDialogEditOffer(state, payload) {
    state.dialogEditOffer = payload;
  },
  addOffer(state, payload) {
    state.offers.push(payload);
  },
  setProcessingOffer(state, payload) {
    state.processingOffer = payload;
  },
  clearPendingOfferUpdates(state) {
    Vue.set(state, "pendingOfferUpdates", {});
  },
  clearDeletedEntries(state) {
    Vue.set(state, "deletedEntries", []);
  },
  addDeletedEntry(state, entry) {
    if (!state.deletedEntries) {
      Vue.set(state, "deletedEntries", []);
    }
    state.deletedEntries.push(entry);
  },
  ADD_PENDING_POINT_DEDUCTION(state, deduction) {
    state.pendingPointDeductions.push(deduction);
  },
  CLEAR_PENDING_POINT_DEDUCTIONS(state) {
    state.pendingPointDeductions = [];
  },
  addDeletedHistoryEntry(state, payload) {
    if (!state.deletedHistoryEntries) {
      Vue.set(state, "deletedHistoryEntries", []);
    }
    state.deletedHistoryEntries.push(payload);
  },
  clearDeletedHistoryEntries(state) {
    Vue.set(state, "deletedHistoryEntries", []);
  },
  setPendingOfferUpdates(state, payload) {
    Vue.set(state, "pendingOfferUpdates", payload);
  },
};

const getters = {
  currentOffer: (state) => state.currentOffer,
  currentOfferOpportunities: (state) => state.currentOffer?.opportunities || [],
  hasOfferPendingUpdates: (state) => {
    // Get all keys in pendingOfferUpdates
    const pendingKeys = Object.keys(state.pendingOfferUpdates);

    // Filter out 'resources' if it's the only key - don't count resources as pending updates
    if (pendingKeys.length === 1 && pendingKeys[0] === "resources") {
      return false;
    }

    // For all other changes, maintain the original behavior
    return pendingKeys.length > 0;
  },
  processingOffer: (state) => state.processingOffer,
  offers: (state) => state.offers,
};

export default {
  state,
  actions,
  mutations,
  getters,
};

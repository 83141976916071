<template>
  <div class="page">
    <v-tabs>
      <v-tab class="tab"> Admins </v-tab>
      <v-tab class="tab"> Titles </v-tab>
      <v-tab class="tab"> Website </v-tab>
      <v-tab class="tab"> Terms </v-tab>
      <v-tab class="tab"> Privacy </v-tab>
      <v-tab class="tab"> App </v-tab>
      <v-tab class="tab"> Rewards </v-tab>
      <v-tab class="tab"> Subscription </v-tab>
      <v-tab class="tab"> Other </v-tab>
      <v-tab class="tab" v-if="isIncentableAdmin"> Incentable Config </v-tab>
      <v-tab class="tab" v-if="isIncentableAdmin"> Incentable Sandbox </v-tab>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <admins-list />
        <permitted-email-domains-list />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <program-title />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <website />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <terms />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <privacy />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <app />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <rewards />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <subscription />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <other />
      </v-tab-item>
      <v-tab-item v-if="isIncentableAdmin">
        <incentable-config></incentable-config>
      </v-tab-item>

      <v-tab-item v-if="isIncentableAdmin">
        <incentable-sandbox></incentable-sandbox>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Other from "./Other.vue";
import Website from "./Website.vue";
import App from "./App.vue";
import Rewards from "./Rewards.vue";
import Terms from "./Terms.vue";
import Privacy from "./Privacy.vue";
import IncentableConfig from "./IncentableConfig.vue";
import IncentableSandbox from "./IncentableSandbox.vue";
import Subscription from "./Subscription.vue";
import AdminsList from "./AdminsList.vue";
import ProgramTitle from "./Title.vue";
import PermittedEmailDomainsList from "./PermittedEmailDomains/PermittedEmailDomainsList.vue";
export default {
  components: {
    IncentableConfig,
    IncentableSandbox,
    Subscription,
    AdminsList,
    Other,
    ProgramTitle,
    Website,
    App,
    Rewards,
    Terms,
    Privacy,
    PermittedEmailDomainsList,
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("setNavMenu", true);
    this.$store.dispatch("loadDatabuckets");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadMembers");
  },
  data: function () {
    return {
      active: "",
      pageTitle: "Settings",
      pageSubTitle: "Manage your program configuration",
    };
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
  },
};
</script>

<style scoped></style>

import { auth, db, timestamp } from "@/firebase";
import router from "../../router";

// Password complexity validation helper
const validatePasswordComplexity = (password) => {
  // At least one uppercase letter
  const hasUpperCase = /[A-Z]/.test(password);
  // At least one lowercase letter
  const hasLowerCase = /[a-z]/.test(password);
  // At least one number
  const hasNumber = /[0-9]/.test(password);
  // At least one special character
  const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
  // At least 8 characters
  const hasMinLength = password.length >= 8;

  return (
    hasUpperCase && hasLowerCase && hasNumber && hasSpecial && hasMinLength
  );
};

const state = {
  user: null,
  name: "",
  email: "",
  created: "",
  allowProgram: false,
  profileEdit: false,
};

const getters = {
  user(state) {
    if (state.user) {
      return state.user;
    } else {
      return auth.currentUser;
    }
  },
  email(state) {
    return state.email;
  },
  allowProgram(state) {
    return state.allowProgram;
  },
};

const reauthenticate = (currentPassword) => {
  console.log("reauth");
  var user = auth.currentUser;
  var cred = auth.EmailAuthProvider.credential(user.email, currentPassword);
  return user.reauthenticateWithCredential(cred);
};

const actions = {
  async signUserUp({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("clearError");

    // Validate password complexity
    if (!validatePasswordComplexity(payload.password)) {
      const error = {
        message:
          "Password must be at least 8 characters and contain uppercase, lowercase, numbers, and special characters.",
      };
      commit("setError", error);
      commit("setSnackbar", error.message);
      commit("setLoading", false);
      return;
    }

    let authResult;
    try {
      authResult = await auth.createUserWithEmailAndPassword(
        payload.email,
        payload.password
      );
      if (!authResult.user) {
        throw "Auth Error";
      }
    } catch (error) {
      commit("setLoading", false);

      // Use generic error messages for common cases to prevent user enumeration
      let genericError = {
        message: "Unable to create account. Please try again.",
      };

      // Handle email-already-in-use with a generic message
      if (error.code === "auth/email-already-in-use") {
        genericError.message =
          "Unable to create account. Please try again with a different email or sign in.";
      }

      // Log original error for debugging purposes
      // eslint-disable-next-line
      console.log("Original signup error:", error);

      commit("setError", genericError);
      commit("setSnackbar", genericError.message);
      return;
    }

    const adminPrograms = [];
    try {
      const programSnapshot = await db.collection("programs").get();
      programSnapshot.forEach((programDoc) => {
        // check already invited programs list
        const programAdmins = programDoc.data().admins || [];
        if (programAdmins.find((admin) => admin.adminEmail === payload.email)) {
          adminPrograms.push(programDoc.id);
        }
      });
    } catch (error) {
      return;
    }

    try {
      await db.collection("admins").doc(authResult.user.uid).set(
        {
          name: payload.name,
          userId: authResult.user.uid,
          email: payload.email,
          terms: payload.terms,
          created: timestamp,
          programs: adminPrograms,
        },
        { merge: true }
      );
    } catch (error) {
      return;
    }

    auth.currentUser.sendEmailVerification();

    commit("setLoading", false);
    commit(
      "setSnackbar",
      "A Verification Email has been sent. Please check your email inbox."
    );
    dispatch("addLog", { action: "Sign Up" });
  },

  signUserIn({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("clearError");
    auth
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((user) => {
        commit("setLoading", false);
        if (user) {
          commit("setEmail", payload.email);
          const currentUser = auth.currentUser;
          const emailVerified = currentUser.emailVerified;
          if (!emailVerified) {
            // send verify email
            currentUser.sendEmailVerification();
            commit(
              "setSnackbar",
              "A Verification Email has been sent. Please check your email inbox."
            );
            dispatch("addLog", { action: "Verification Email Sent" });
          } else {
            // let user log in
            dispatch("addLog", { action: "Sign In" });
            const newUser = {
              id: user.user.uid,
            };
            commit("setUser", newUser);
          }
        }
      })
      .catch((error) => {
        commit("setLoading", false);

        // Generic error for authentication failures to prevent user enumeration
        const genericError = {
          code: "auth/generic-error",
          message: "Invalid email or password. Please try again.",
        };

        commit("setError", genericError);

        // Still log the actual error for debugging purposes
        // eslint-disable-next-line
        console.log("Original error:", error);
      });
  },

  forgotPassword({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("clearError");
    const emailAddress = payload.email;

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
        commit("setLoading", false);
        // Generic success message regardless of whether email exists
        commit(
          "setSnackbar",
          "If an account exists with this email, a password reset link has been sent."
        );
        dispatch("addLog", { action: "Forgot Password" });
      })
      .catch(function (error) {
        // An error happened.
        commit("setLoading", false);

        // Generic message that doesn't reveal if email exists or not
        const genericError = {
          code: "auth/generic-error",
          message:
            "If an account exists with this email, a password reset link has been sent.",
        };

        commit("setError", genericError);

        // Still log the actual error for debugging purposes
        // eslint-disable-next-line
        console.log("Original error:", error);
      });
  },

  checkPermission({ commit, getters }, id) {
    const check =
      getters.currentAdmin.programs.includes(id) || getters.isIncentableAdmin;
    commit("setAllowProgram", check);
  },

  autoSignIn({ commit }, payload) {
    commit("setUser", { id: payload.uid });
    commit("setEmail", payload.email);
  },

  logout({ commit, dispatch }) {
    dispatch("addLog", { action: "Sign Out" });
    auth.signOut();
    commit("setUser", null);
    commit("setIncentableAdmin", false);
  },

  setEmail({ commit }, payload) {
    commit("setEmail", payload);
  },

  async deleteUser({ getters, commit, dispatch }, payload) {
    try {
      const programs = getters.programs;
      // get programLists which only current user is admin
      // we can't delete user until invite another one for avoiding losing access to it.
      const programsWithOneAdmin = programs.filter(
        (program) => program.admins && program.admins.length === 1
      );
      if (programsWithOneAdmin.length > 0) {
        const err = {
          message: `You are an only Admin in ${programsWithOneAdmin[0].title}. Please invite another user as the Admin before deleting your account.`,
        };
        commit("setError", err);
        return Promise.reject(err);
      }
      commit("setLoading", true);
      const currentPassword = payload.currentPassword;
      const user = auth.currentUser;
      await reauthenticate(currentPassword);
      await user.delete();
      dispatch("addLog", { action: "Delete Admin" });
      dispatch("logout");
      commit("setLoading", false);
    } catch (error) {
      commit("setLoading", false);

      // Use generic error message but preserve specific messages for admin-related checks
      let genericError = {
        message:
          "Unable to delete account. Please try again with correct credentials.",
      };

      // Log original error for debugging
      // eslint-disable-next-line
      console.log("Original delete account error:", error);

      commit("setError", genericError);
      return Promise.reject(genericError);
    }
  },

  // Update admin email
  async updateAdminEmail({ commit, getters, dispatch }, payload) {
    commit("setLoading", true);
    const email = payload.email;
    const currentPassword = payload.currentPassword;
    const admins = getters.admins;
    const index = admins.findIndex((admin) => admin.email === getters.email);
    try {
      // check email has been changed
      const emailChanged = email !== getters.email;
      const user = auth.currentUser;
      if (emailChanged) {
        await reauthenticate(currentPassword);
        // update email
        await user.updateEmail(email);
        //await user.updatePassword(newPassword)
        await db.collection("admins").doc(user.uid).set(
          {
            email,
          },
          { merge: true }
        );
        admins[index].email = email;
        dispatch("setAdmins", admins);
      } else {
        return;
      }

      if (emailChanged) {
        // send verify email
        user.sendEmailVerification();
        commit("setEmail", email);
        dispatch("logout");
        router.push("/signin");
        commit(
          "setSnackbar",
          "A Verification Email has been sent. Please check your email inbox."
        );
        dispatch("addLog", { action: "Admin Email Change" });
      } else {
        return;
      }
    } catch (error) {
      // Use generic error message for email update failures
      let genericError = {
        message:
          "Unable to update email. Please verify your credentials and try again.",
      };

      // Log original error for debugging
      // eslint-disable-next-line
      console.log("Original update email error:", error);

      commit("setError", genericError);
      commit("setLoading", false);
    }
  },

  // Update admin password
  async updateAdminPwd({ commit, dispatch }, payload) {
    commit("setLoading", true);
    const currentPassword = payload.currentPassword;
    const newPassword = payload.newPassword;

    // Validate password complexity
    if (!validatePasswordComplexity(newPassword)) {
      const error = {
        message:
          "Password must be at least 8 characters and contain uppercase, lowercase, numbers, and special characters.",
      };
      commit("setError", error);
      commit("setLoading", false);
      return Promise.reject(error);
    }

    try {
      const user = auth.currentUser;
      await reauthenticate(currentPassword);
      await user.updatePassword(newPassword);
      dispatch("setSnackbar", "Password has been updated.");
      dispatch("addLog", { action: "Admin Password Change" });
    } catch (error) {
      // Use generic error message for password update failures
      let genericError = {
        message:
          "Unable to update password. Please verify your current password and try again.",
      };

      // Log original error for debugging
      // eslint-disable-next-line
      console.log("Original update password error:", error);

      commit("setError", genericError);
      commit("setLoading", false);
    }
  },

  async updateAdminName({ dispatch }, payload) {
    const adminsRef = db.collection("admins");

    let storedAdmin;
    try {
      const adminDoc = await adminsRef.doc(payload.id).get();
      storedAdmin = adminDoc.data();
    } catch (e) {
      storedAdmin = null;
    }

    if (!storedAdmin) {
      throw "Error occured when fetching the admin info";
    }

    const { id, ...adminInput } = payload;
    const admin = {
      ...adminInput,
    };

    try {
      await adminsRef.doc(id).update(admin);
    } catch (e) {
      console.error(e);
      throw "An error occured when updating your name";
    }
    dispatch("setSnackbar", "Admin name updated.");
    dispatch("addLog", { action: "Admin Name Change" });
  },
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
  setAllowProgram(state, payload) {
    state.allowProgram = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

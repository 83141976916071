<template>
  <div class="px-0">
    <!-- File size limit warning -->
    <v-alert
      v-if="fileToUpload && fileToUpload.size > effectiveMaxFileSize"
      color="primary"
      text
      class="mt-2"
      dismissible
    >
      File size exceeds {{ effectiveMaxFileSize / (1024 * 1024) }}MB limit
    </v-alert>

    <!-- File count limit warning -->
    <v-alert
      v-if="
        resources.length === effectiveMaxFiles - 1 || showApproachingLimitAlert
      "
      text
      class="mt-2"
      dismissible
      color="primary"
      @input="clearApproachingLimitAlert"
    >
      <v-icon left color="primary">info</v-icon>
      <strong>Approaching file limit:</strong> You have
      {{ resources.length }} of {{ effectiveMaxFiles }} allowed files.
    </v-alert>

    <!-- Max file limit reached warning -->
    <v-alert
      v-if="resources.length >= effectiveMaxFiles || showMaxFilesAlert"
      color="primary"
      text
      class="mt-2"
      dismissible
      @input="clearMaxFilesAlert"
    >
      <v-icon left color="primary">error</v-icon>
      <strong>Maximum file limit reached:</strong> You have
      {{ resources.length }} of {{ effectiveMaxFiles }} allowed files. Please
      delete some files first.
    </v-alert>

    <!-- Upload errors -->
    <v-alert
      v-if="uploadErrors.length > 0"
      color="primary"
      class="mt-2"
      text
      dismissible
      @input="clearUploadErrors"
    >
      <ul>
        <li v-for="(error, index) in uploadErrors" :key="index + '-' + error">
          {{ error }}
        </li>
      </ul>
    </v-alert>

    <!-- Dropzone -->
    <vue-dropzone
      ref="dropzone"
      id="offer-resources-dropzone"
      :options="dropzoneOptions"
      :useCustomSlot="true"
      @vdropzone-file-added="handleDropzoneAddedFile"
      @vdropzone-file-removed="handleDropzoneRemovedFile"
      @vdropzone-processing="handleDropzoneProcessing"
      @vdropzone-success="handleDropzoneSuccess"
      @vdropzone-error="handleDropzoneError"
      @vdropzone-queue-complete="handleDropzoneQueueComplete"
      @vdropzone-total-upload-progress="handleDropzoneTotalUploadProgress"
      @click="handleDropzoneClick"
      class="mt-4 mb-6"
      :class="{ 'disabled-dropzone': resources.length >= effectiveMaxFiles }"
    >
      <div class="dropzone-custom-content">
        <!-- File Counter - simple text only -->
        <div class="file-counter-text">
          {{ resources.length }}/{{ effectiveMaxFiles }} files
        </div>

        <!-- Resources Grid -->
        <v-container fluid class="pa-0">
          <v-row class="resources-grid" :key="renderKey">
            <!-- Current Resources -->
            <v-col
              cols="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              v-for="resource in resources"
              :key="resource.id"
              class="pa-2"
            >
              <v-card
                flat
                outlined
                class="resource-card d-flex flex-column"
                :class="{ 'primary--border': false }"
                style="position: relative; aspect-ratio: 1/1"
                @click.stop
                v-on:mouseover="setHoveredResource(resource.id)"
                v-on:mouseleave="setHoveredResource(null)"
                :style="
                  resource.id === hoveredResourceId
                    ? 'border-color: ' +
                      $vuetify.theme.currentTheme.primary +
                      ' !important'
                    : ''
                "
              >
                <!-- Deletion progress indicator -->
                <v-progress-linear
                  v-if="resource.isDeleting"
                  indeterminate
                  color="primary"
                  height="2"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 10;
                  "
                ></v-progress-linear>

                <v-card-text
                  class="d-flex flex-column align-center justify-center text-center h-100 pa-2"
                  style="
                    padding-left: 12px !important;
                    padding-right: 12px !important;
                  "
                >
                  <!-- Delete button -->
                  <v-btn
                    icon
                    x-small
                    class="delete-btn"
                    @click.stop="handleDeleteResource(resource)"
                    :disabled="demo || resource.isDeleting"
                    :loading="resource.isDeleting"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>

                  <v-icon
                    size="48"
                    :color="fileIcon(getResourceDisplayName(resource)).color"
                    class="mb-4"
                    >{{
                      fileIcon(getResourceDisplayName(resource)).icon
                    }}</v-icon
                  >

                  <vue-clamp class="filename-text" :max-lines="2">
                    {{ getResourceDisplayName(resource) }}
                  </vue-clamp>
                  <div class="text-caption">
                    {{ resource.metadata?.size || "Unknown size" }}
                    <small v-if="false"
                      >Debug: {{ resource.description }}</small
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Processing Files -->
            <v-col
              cols="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              v-for="(file, index) in processingQueue"
              :key="`processing-${index}`"
              class="pa-2"
            >
              <v-card
                elevation="2"
                class="loading-card d-flex flex-column align-center justify-center"
                style="position: relative; aspect-ratio: 1/1"
              >
                <v-progress-linear
                  indeterminate
                  color="primary"
                  height="3"
                  style="position: absolute; top: 0; left: 0; right: 0"
                ></v-progress-linear>
                <div class="text-caption text-center px-2">
                  <div class="filename-text" style="font-weight: 500">
                    {{ file.name }}
                  </div>
                  <div class="text-caption">Processing...</div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <!-- Empty State -->
          <div
            v-if="resources.length === 0 && processingQueue.length === 0"
            class="empty-state"
          >
            <v-icon size="48" color="primary" class="mb-2">cloud_upload</v-icon>
            <div class="empty-state-text">Drop files here...</div>
            <v-btn class="primary mb-4 mt-3" elevation="0" small
              >Browse Files</v-btn
            >
          </div>

          <!-- Limit Reached State -->
          <div
            v-if="
              resources.length >= effectiveMaxFiles &&
              processingQueue.length === 0
            "
            class="empty-state limit-reached"
          >
            <v-icon size="48" color="error" class="mb-2">error_outline</v-icon>
            <div class="empty-state-text limit-text">
              Maximum file limit reached ({{ effectiveMaxFiles }})
            </div>
            <div class="empty-state-text">
              Please delete some files before uploading more.
            </div>
          </div>
        </v-container>

        <!-- File info text at bottom -->
        <div class="file-info-text">
          Maximum file size: {{ effectiveMaxFileSize / (1024 * 1024) }}MB |
          Maximum files: {{ effectiveMaxFiles }} | File types:
          {{
            effectiveAcceptedFileExtensions.map((ext) => "." + ext).join(" ")
          }}
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import VueClamp from "vue-clamp";

export default {
  name: "FileDropzone",
  components: {
    vueDropzone: vue2Dropzone,
    VueClamp,
  },
  props: {
    field: {
      type: Object,
      required: true,
      // The field prop is expected to contain:
      // - maxFileSize: number (in bytes)
      // - maxFiles: number
      // - permittedFileExtensions: array of strings
    },
    pageIdx: {
      type: Number,
      required: true,
    },
    sectionIdx: {
      type: Number,
      required: true,
    },
    columnIdx: {
      type: Number,
      required: true,
    },
    entryId: {
      type: String,
      required: false,
      default: null,
      // When provided, files will be saved to the entry document in Firestore
      // When null, component operates in form builder mode (preview only)
    },
  },
  data: () => ({
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    isProcessing: false,
    fileDescription: "",
    fileToUpload: null,
    fileUploading: false,
    fileUploadingProgress: 0,
    fileUploadTask: null,
    uploadErrors: [],
    retryAttempts: 0,
    maxRetryAttempts: 3,
    uploadStatus: {
      progress: 0,
      status: "", // 'uploading', 'processing', 'complete', 'error'
      message: "",
    },
    fileMetadata: {
      size: "",
      type: "",
      lastModified: "",
      dimensions: null, // for images
    },
    dropzoneOptions: {
      url: "https://httpbin.org/post", // This is a dummy URL, we'll handle uploads manually
      thumbnailWidth: 150,
      maxFilesize: null, // Will be set in created hook from effectiveMaxFileSize
      maxFiles: null, // Will be set in created hook from effectiveMaxFiles
      acceptedFiles: null, // Will be set in created hook from formattedAcceptedFiles
      addRemoveLinks: false, // Disable default remove links
      dictFileTooBig:
        "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
      dictInvalidFileType: "You can't upload files of this type.",
      dictResponseError: "Server responded with {{statusCode}} code.",
      dictCancelUpload: "Cancel upload",
      dictUploadCanceled: "Upload canceled.",
      dictRemoveFile: "Remove file",
      dictMaxFilesExceeded: "You can not upload any more files.",
      createImageThumbnails: false, // Disable automatic thumbnails
      autoProcessQueue: false, // Disable auto-processing - we handle this manually
      previewsContainer: false, // Disable default previews container
      disablePreviews: true, // Disable default previews
      previewTemplate: "<div></div>", // Empty preview template
      parallelUploads: 3, // Allow parallel uploads
      chunking: false, // Disable chunking for better binary file handling
      forceChunking: false, // Never force chunking
      uploadMultiple: false, // Handle one file at a time for better control

      // CRITICAL: These options are essential for binary file handling
      renameFile: (file) => {
        // Don't rename files, preserve original names
        return file.name;
      },
      transformFile: null, // Disable file transformation to preserve binary data
      resizeWidth: null, // Don't resize images
      resizeHeight: null, // Don't resize images
      resizeQuality: 1, // Keep full quality if resizing happens
      resizeMethod: "contain", // Don't crop images

      // Improve binary file handling
      binaryMimeTypes: [
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".csv",
        ".ppt",
        ".pptx",
        ".bin",
        ".exe",
        ".zip",
      ],
      timeout: 120000, // Increase timeout for larger files (2 minutes)
    },
    // Keep track of processed files
    processingQueue: [],
    // Flag to track if processing is in progress
    isProcessingQueue: false,
    // Key to force re-rendering
    renderKey: 0,
    hoveredResourceId: null,
    // Add tracking for uploaded files to show in snackbar
    lastUploadedFiles: [],
    isCheckingOrphans: false,
    // Alert visibility flags
    showMaxFilesAlert: false,
    showApproachingLimitAlert: false,
    // Alert timers
    errorTimer: null,
    maxFilesAlertTimer: null,
    approachingLimitTimer: null,
    // Initial resources array to store files for this field
    fieldResources: [],
    maxFileSize: 10 * 1024 * 1024, // 10MB
    maxFiles: 12,
    acceptedFileExtensions: [
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "pdf",
      "jpg",
      "jpeg",
      "png",
      "csv",
    ],
  }),

  mounted() {
    console.log("[FileDropzone] Component mounted");
    // Force an initial render to ensure everything displays correctly
    this.$nextTick(() => {
      this.forceRender();
      // Update the dropzone maxFiles limit based on current resources
      this.updateDropzoneMaxFiles();
      // Load resources for this field from the entry if available
      this.loadEntryResources();
    });
  },

  updated() {
    // Hide any default dropzone previews that might appear
    const dzPreviews = document.querySelectorAll(".dz-preview");
    if (dzPreviews.length > 0) {
      dzPreviews.forEach((el) => {
        el.style.display = "none";
      });
    }
  },

  watch: {
    // Watch resources array to update maxFiles dynamically
    resources: {
      handler(newResources, oldResources) {
        this.updateDropzoneMaxFiles();

        // Show max files alert if we've just reached the maximum
        if (
          newResources.length >= this.effectiveMaxFiles &&
          (!oldResources || oldResources.length < this.effectiveMaxFiles)
        ) {
          this.setMaxFilesAlert();
        }

        // Show approaching limit alert if we're one away from maximum
        if (
          newResources.length === this.effectiveMaxFiles - 1 &&
          (!oldResources || oldResources.length < this.effectiveMaxFiles - 1)
        ) {
          this.setApproachingLimitAlert();
        }
      },
      deep: true,
    },
    // Watch for changes in max files limit
    effectiveMaxFiles: {
      handler(newLimit, oldLimit) {
        // If the current resource count now exceeds the new limit, show alert
        if (
          this.resources.length >= newLimit &&
          (!oldLimit || this.resources.length < oldLimit)
        ) {
          this.setMaxFilesAlert();
        }
        // If approaching the new limit
        else if (this.resources.length === newLimit - 1) {
          this.setApproachingLimitAlert();
        }
      },
    },
    // Watch field prop for changes to configuration
    field: {
      handler() {
        console.log(
          "[FileDropzone] Field prop changed, updating configuration"
        );
        this.applyFieldConfiguration();
        this.updateDropzoneMaxFiles();
      },
      deep: true,
    },
  },

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    resources: {
      get() {
        // If in entry mode, use fieldResources, otherwise use offer resources
        if (this.isEntryMode) {
          return this.fieldResources || [];
        } else {
          // When in form builder mode (no entryId), use a local array for preview
          return this.fieldResources || [];
        }
      },
      set(value) {
        this.fieldResources = value;
      },
    },
    // Get maxFileSize from field prop with fallback
    effectiveMaxFileSize() {
      // Return field.maxFileSize if provided, otherwise use default
      return (this.field && this.field.maxFileSize) || 10 * 1024 * 1024; // Default to 10MB
    },
    // Get maxFiles from field prop with fallback
    effectiveMaxFiles() {
      // Return field.maxFiles if provided, otherwise use default
      return (this.field && this.field.maxFiles) || 12; // Default to 12 files
    },
    // Get acceptedFileExtensions from field prop with fallback
    effectiveAcceptedFileExtensions() {
      // Return field.permittedFileExtensions if provided, otherwise use default
      return (
        (this.field && this.field.permittedFileExtensions) || [
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "pdf",
          "jpg",
          "jpeg",
          "png",
          "csv",
        ]
      );
    },
    // Computed property to format accepted file extensions for dropzone
    formattedAcceptedFiles() {
      return this.effectiveAcceptedFileExtensions
        .map((ext) => `.${ext}`)
        .join(",");
    },
    // Field identifier for storing in the entry
    fieldIdentifier() {
      return `field_${this.pageIdx}_${this.sectionIdx}_${this.columnIdx}`;
    },
    // Check if we're in entry or form builder mode
    isEntryMode() {
      return !!this.entryId;
    },
  },

  created() {
    // Initialize configuration from field prop
    this.applyFieldConfiguration();

    console.log("[FileDropzone] Initialized dropzone options:", {
      maxFilesize: this.dropzoneOptions.maxFilesize,
      maxFiles: this.dropzoneOptions.maxFiles,
      acceptedFiles: this.dropzoneOptions.acceptedFiles,
    });
  },

  methods: {
    // Add method to apply configuration from field prop
    applyFieldConfiguration() {
      // Apply dropzone configuration from computed properties
      this.dropzoneOptions.maxFilesize =
        this.effectiveMaxFileSize / (1024 * 1024); // Convert bytes to MB
      this.dropzoneOptions.maxFiles = this.effectiveMaxFiles;
      this.dropzoneOptions.acceptedFiles = this.formattedAcceptedFiles;

      // Set custom error message for invalid file types
      this.dropzoneOptions.dictInvalidFileType =
        this.getPermittedFilesMessage();

      console.log("[FileDropzone] Applied field configuration:", {
        maxFileSize: this.effectiveMaxFileSize,
        maxFiles: this.effectiveMaxFiles,
        permittedFileExtensions: this.effectiveAcceptedFileExtensions,
      });
    },

    // Add new method to update maxFiles limit
    updateDropzoneMaxFiles() {
      // If dropzone is initialized
      if (this.$refs.dropzone && this.$refs.dropzone.dropzone) {
        // Adjust max files dynamically based on remaining slots
        const totalAllowed = this.effectiveMaxFiles; // Use the component's maxFiles property
        const currentCount = this.resources.length;
        const remainingSlots = Math.max(1, totalAllowed - currentCount);

        // Update the dropzone options
        this.$refs.dropzone.dropzone.options.maxFiles = remainingSlots;
        console.log(
          `[FileDropzone] Updated maxFiles to ${remainingSlots} (${currentCount}/${totalAllowed} used)`
        );
      }
    },

    // Add method to check for orphaned files in storage
    async checkForOrphanedFiles() {
      try {
        // Skip if we're already checking
        if (this.isCheckingOrphans) return;
        this.isCheckingOrphans = true;

        this.$store.dispatch("setSnackbar", "Checking for orphaned files...");

        // Get list of all resource URLs in the database
        const dbResourceUrls = this.resources
          .map((r) => r.storageRef || r.url?.split("?")[0])
          .filter(Boolean);

        // Get list of all files in storage (simplified - actual implementation would depend on Firebase)
        // This is a placeholder - in production, you would implement a server function to list all storage files
        // const storageFiles = await this.listAllStorageFiles();

        // For demonstration purposes, just log the count
        console.log(
          `[FileDropzone] Current resource count in DB: ${dbResourceUrls.length}`
        );

        this.$store.dispatch(
          "setSnackbar",
          `Found ${dbResourceUrls.length} resources in database`
        );
        this.isCheckingOrphans = false;
      } catch (error) {
        console.error("[FileDropzone] Error checking orphaned files:", error);
        this.isCheckingOrphans = false;
      }
    },

    // Add to data object
    clearFile() {
      this.fileToUpload = null;
      this.fileDescription = "";
      this.uploadErrors = [];
    },
    handleOpenFileBrowser() {
      this.$refs.fileInput.click();
    },
    handleFilePicked(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      const errors = this.validateFile(file);
      if (errors.length > 0) {
        this.uploadErrors = errors;
        return;
      }

      this.fileToUpload = file;
      this.getFileMetadata(file);
    },
    validateFile(file) {
      const errors = [];

      // Check if file exists and has a name property
      if (!file || !file.name) {
        errors.push("Please select a valid file to upload.");
        return errors;
      }

      // Check file size
      if (file.size > this.effectiveMaxFileSize) {
        errors.push(
          `File is too large. Maximum file size is ${this.formatFileSize(
            this.effectiveMaxFileSize
          )}.`
        );
      }

      // Check file type
      if (!this.accept(file.name)) {
        // Use a method to generate the permitted files message
        const permittedFilesMessage = this.getPermittedFilesMessage();
        errors.push(permittedFilesMessage);
      }

      // Check for malicious file names
      if (file.name.includes("..") || file.name.includes("/")) {
        errors.push("Invalid file name");
      }

      return errors;
    },

    // Get a formatted message about permitted file types
    getPermittedFilesMessage() {
      const permittedFiles = this.effectiveAcceptedFileExtensions
        .map((ext) => `.${ext}`)
        .join(", ");
      return `File type is not allowed. Files must be ${permittedFiles}`;
    },

    async handleUploadFile() {
      if (!this.fileToUpload) return;

      try {
        this.fileDescription = this.fileToUpload.name;
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `uploads/${this.programId}/formFileUploads/${Date.now()}_${
            this.fileToUpload.name
          }`
        );

        this.fileUploadTask = uploadBytes(storageRef, this.fileToUpload);
        this.fileUploadingProgress = 0;
        this.fileUploading = true;
        this.uploadErrors = [];
      } catch (error) {
        this.handleUploadError(error);
      }
    },
    handleUploadError(error) {
      console.error("Upload error:", error);

      // Always show the permitted file types regardless of error type
      this.showFileTypeError();

      this.fileUploading = false;

      if (this.retryAttempts < this.maxRetryAttempts) {
        this.retryAttempts++;
      }
    },
    updateUploadProgress(snapshot) {
      const progress = Math.floor(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      this.uploadStatus = {
        progress,
        status: "uploading",
        message: `Uploading: ${progress}%`,
      };
    },
    async getFileMetadata(file) {
      const metadata = {
        size: this.formatFileSize(file.size),
        type: file.type,
        lastModified: new Date(file.lastModified).toLocaleString(),
      };

      if (file.type.startsWith("image/")) {
        metadata.dimensions = await this.getImageDimensions(file);
      }

      this.fileMetadata = metadata;
      return metadata;
    },
    formatFileSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    async getImageDimensions(file) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
        img.src = URL.createObjectURL(file);
      });
    },
    async handleFinishUpload(url, fileName = null, fileMetadata = null) {
      this.fileUploading = false;
      this.fileUploadingProgress = 0;
      this.fileUploadTask = null;
      this.fileToUpload = null;

      // Use provided fileName, this.fileDescription, or a generic name
      let displayName =
        fileName || this.fileDescription || `File ${Date.now()}`;

      // Check for existing resources with the same name and add version number if needed
      const existingResources = this.resources.filter((resource) => {
        // Clean up both names to compare them without version numbers
        const cleanResourceName = resource.description.replace(/ \(\d+\)$/, "");
        const cleanDisplayName = displayName.replace(/ \(\d+\)$/, "");
        return cleanResourceName === cleanDisplayName;
      });

      if (existingResources.length > 0) {
        // Strip any existing version numbers like (1) from the name
        const baseName = displayName.replace(/ \(\d+\)$/, "");
        // Add version number - use the count of existing resources with same name + 1
        displayName = `${baseName} (${existingResources.length})`;
      }

      // Use provided metadata or the currently stored metadata
      const metadata = fileMetadata || this.fileMetadata;

      // Create the new resource object
      const newResource = {
        id: Date.now() + Math.random(),
        type: "file",
        description: displayName,
        filename: displayName,
        url,
        created: new Date(),
        metadata: metadata,
        storageRef: url.split("?")[0], // Store reference to the storage path
      };

      // Update local state first for immediate UI update
      const updatedResources = [...this.resources, newResource];
      this.resources = updatedResources;

      // Force a render to show the new resource immediately
      this.renderKey++;
      this.$forceUpdate();

      // Save to Firestore after UI update
      try {
        await this.saveResourcesToEntry(updatedResources);
        console.log(`[FileDropzone] File saved to database: ${displayName}`);
      } catch (error) {
        console.error("[FileDropzone] Error saving to database:", error);
        // State already updated above
      }

      console.log(
        `[FileDropzone] File uploaded and added to resources: ${displayName}`
      );

      this.fileToUpload = null;
      this.fileDescription = "";
      this.fileMetadata = {
        size: "",
        type: "",
        lastModified: "",
        dimensions: null,
      };
    },
    async handleDeleteResource(resource) {
      try {
        // Mark resource as deleting to show progress indicator
        const resourceIndex = this.resources.findIndex(
          (r) => r.id === resource.id
        );
        if (resourceIndex !== -1) {
          // Create a new resources array with the updated resource to ensure reactivity
          const updatedResources = [...this.resources];
          updatedResources[resourceIndex] = {
            ...updatedResources[resourceIndex],
            isDeleting: true,
          };
          this.resources = updatedResources;

          // Force render update
          this.renderKey++;
          this.$forceUpdate();
        }

        // Delete from Firebase Storage if storageRef exists
        if (resource.storageRef) {
          try {
            const storage = getStorage();
            const storageRef = ref(storage, resource.storageRef);
            await deleteObject(storageRef);
            console.log(
              `[FileDropzone] Successfully deleted file from storage: ${resource.storageRef}`
            );
          } catch (storageError) {
            // If the file doesn't exist in storage, just log it and continue
            if (storageError.code === "storage/object-not-found") {
              console.log(
                `[FileDropzone] File not found in storage, continuing with database update: ${resource.storageRef}`
              );
            } else {
              throw storageError; // Re-throw other storage errors
            }
          }
        }

        // Get current resources array and filter out the deleted resource
        const updatedResources = this.resources.filter(
          (item) => item.id !== resource.id
        );

        // Update local state first
        this.resources = updatedResources;

        // Update entry in Firestore
        await this.saveResourcesToEntry(updatedResources);

        // Force render update
        this.renderKey++;
        this.$forceUpdate();

        console.log(
          `[FileDropzone] Successfully deleted resource: ${resource.description}`
        );

        // Show success message in snackbar using Vuex
        this.$store.dispatch(
          "setSnackbar",
          `${this.getResourceDisplayName(resource)} was successfully deleted`
        );
      } catch (error) {
        console.error("[FileDropzone] Error deleting resource:", error);
        this.uploadErrors = [
          ...this.uploadErrors,
          `Failed to delete ${this.getResourceDisplayName(
            resource
          )}. Please try again. [${Date.now()}]`,
        ];

        // Show error message in snackbar using Vuex
        this.$store.dispatch(
          "setSnackbar",
          `Failed to delete ${this.getResourceDisplayName(resource)}`
        );

        // Reset the resource's isDeleting state if it's still in the array
        const resourceIndex = this.resources.findIndex(
          (r) => r.id === resource.id
        );
        if (resourceIndex !== -1) {
          // Create a new resources array with the updated resource to ensure reactivity
          const updatedResources = [...this.resources];
          updatedResources[resourceIndex] = {
            ...updatedResources[resourceIndex],
            isDeleting: false,
          };
          this.resources = updatedResources;

          // Force render update
          this.renderKey++;
          this.$forceUpdate();
        }
      }
    },
    fileIcon(filename) {
      const defaultIcon = { icon: "fas fa-file", color: "grey" };

      // Basic validation
      if (!filename || typeof filename !== "string" || filename.trim() === "") {
        return defaultIcon;
      }

      try {
        // Remove any version number in parentheses before extracting extension
        const cleanedFilename = filename.replace(/ \(\d+\)$/, "");

        // Check if filename contains a period to ensure we can extract an extension
        if (!cleanedFilename.includes(".")) {
          return defaultIcon;
        }

        const ext = cleanedFilename.split(".").pop().toLowerCase();
        if (!ext) return defaultIcon;

        // Map of file extensions to icons and colors
        const iconMap = {
          pdf: { icon: "fas fa-file-pdf", color: "red" },
          doc: { icon: "fas fa-file-word", color: "blue" },
          docx: { icon: "fas fa-file-word", color: "blue" },
          xls: { icon: "fas fa-file-excel", color: "green" },
          xlsx: { icon: "fas fa-file-excel", color: "green" },
          csv: { icon: "fas fa-file-csv", color: "green" },
          ppt: { icon: "fas fa-file-powerpoint", color: "orange" },
          pptx: { icon: "fas fa-file-powerpoint", color: "orange" },
          jpg: { icon: "fas fa-file-image", color: "purple" },
          jpeg: { icon: "fas fa-file-image", color: "purple" },
          png: { icon: "fas fa-file-image", color: "purple" },
        };

        return iconMap[ext] || defaultIcon;
      } catch (error) {
        console.error("[FileDropzone] Error determining file icon:", error);
        return defaultIcon;
      }
    },
    accept(filename) {
      // Basic validation
      if (!filename || typeof filename !== "string" || filename.trim() === "") {
        return false;
      }

      // Check if filename contains a period to ensure we can extract an extension
      if (!filename.includes(".")) {
        return false;
      }

      try {
        const ext = filename.split(".").pop().toLowerCase();
        if (!ext) return false;

        // Check if the extension is in our accepted list
        return this.effectiveAcceptedFileExtensions.includes(ext.toLowerCase());
      } catch (error) {
        console.error("[FileDropzone] Error checking file type:", error);
        return false; // Reject if any error occurs
      }
    },
    // New method to ensure skeletons are visible
    async forceShowSkeletons() {
      // Force re-render
      this.renderKey++;
      this.$forceUpdate();

      // Brute force approach - check every 100ms for 2 seconds to make sure rendering occurs
      for (let i = 0; i < 20; i++) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.$forceUpdate();
      }
    },

    // Improved method to force re-render
    forceRender() {
      this.renderKey++;
      this.$forceUpdate(); // Force the component to re-render
    },

    // Updated method to handle dropzone file addition with better raw file handling
    async handleDropzoneAddedFile(file) {
      // Basic validation
      if (!file || typeof file !== "object") {
        console.error("[FileDropzone] Invalid file received");
        this.setUploadErrors(["Please select a valid file to upload"]);
        return;
      }

      // Check if max files limit is reached
      if (this.resources.length >= this.effectiveMaxFiles) {
        console.error("[FileDropzone] Maximum file limit reached");
        this.setUploadErrors(
          `You've reached the maximum of ${this.effectiveMaxFiles} files. Please remove some files before adding more.`
        );
        this.setMaxFilesAlert(); // Set max files alert with auto-dismiss
        if (this.$refs.dropzone) {
          this.$refs.dropzone.removeFile(file);
        }
        return;
      }

      // Check if approaching max files limit
      if (this.resources.length === this.effectiveMaxFiles - 1) {
        this.setApproachingLimitAlert(); // Set approaching limit alert with auto-dismiss
      }

      // Ensure file has a name and store it
      const originalFileName = file.name || `unnamed_file_${Date.now()}`;

      if (!file.name) {
        console.error("[FileDropzone] File missing name property");
        file.name = originalFileName;
      }

      const errors = this.validateFile(file);
      if (errors.length > 0) {
        this.setUploadErrors(errors);
        if (this.$refs.dropzone) {
          this.$refs.dropzone.removeFile(file);
        }
        return;
      }

      // IMPORTANT: Preserve the original dropzone file object for upload
      // Capture a reference to the raw file data for later
      let rawFileData = null;

      // Standard dropzone structure
      if (file.upload && file.upload.file) {
        rawFileData = file.upload.file;
      }
      // Check if the file itself is a File object
      else if (file instanceof File) {
        rawFileData = file;
      }

      // Create an enhanced file object with additional properties for display
      const enhancedFile = {
        ...file,
        // Explicitly add the key properties we need later
        name: originalFileName,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        id: Date.now() + Math.random(), // Add unique ID
        metadata: await this.getFileMetadata(file),
        isProcessing: true,
        // Store the raw file data for later upload
        _rawFileData: rawFileData,
      };

      // Add to queue - use the enhanced file object
      this.processingQueue = [...this.processingQueue, enhancedFile];

      // Critical: Force IMMEDIATE render to show loading cards
      this.renderKey++;
      this.$forceUpdate();

      // Also force render in the next animation frame for browser consistency
      requestAnimationFrame(() => {
        this.renderKey++;
        this.$forceUpdate();
      });

      // Show uploading state immediately
      this.fileUploading = true;
      this.uploadStatus = {
        progress: 20,
        status: "uploading",
        message: `Preparing ${this.processingQueue.length} ${
          this.processingQueue.length === 1 ? "file" : "files"
        } for upload...`,
      };

      // Shorter delay before processing to improve responsiveness
      setTimeout(() => {
        if (!this.isProcessingQueue) {
          this.processNextFileInQueue();
        }
      }, 100);
    },

    // Completely reworked queue processing method with better state handling
    async processNextFileInQueue() {
      // Check queue state
      if (this.processingQueue.length === 0) {
        this.isProcessingQueue = false;

        // Reset upload status when queue is empty
        this.fileUploading = false;
        this.uploadStatus = {
          progress: 0,
          status: "idle",
          message: "",
        };
        return;
      }

      // Prevent concurrent processing
      if (this.isProcessingQueue) {
        return;
      }

      // IMPORTANT: Check max files limit here to prevent batch uploads from exceeding limit
      if (this.resources.length >= this.effectiveMaxFiles) {
        console.error(
          `[FileDropzone] Max files limit (${this.effectiveMaxFiles}) reached, canceling pending uploads`
        );

        // Remove all files from processing queue
        const pendingCount = this.processingQueue.length;
        this.setUploadErrors(
          `You've reached the maximum of ${this.effectiveMaxFiles} files. Please remove some files first.`
        );
        this.setMaxFilesAlert(); // Set max files alert with auto-dismiss

        // Show message in snackbar
        this.$store.dispatch(
          "setSnackbar",
          `Maximum file limit (${this.effectiveMaxFiles}) reached. Canceled ${pendingCount} pending upload(s).`
        );

        // Get the names of canceled files for better user feedback
        const canceledFiles = this.processingQueue
          .map((file) => file.name)
          .join(", ");
        console.log(`[FileDropzone] Canceled uploads: ${canceledFiles}`);

        // Clear the processing queue
        this.processingQueue = [];

        // Force render update
        this.renderKey++;
        this.$forceUpdate();

        // Reset processing state
        this.isProcessingQueue = false;
        this.fileUploading = false;
        this.uploadStatus = {
          progress: 0,
          status: "idle",
          message: "",
        };
        return;
      }

      this.isProcessingQueue = true;

      // Get the first file without removing it yet
      const file = this.processingQueue[0];

      // Verify we have a valid file object
      if (!file || typeof file !== "object") {
        console.error("[FileDropzone] Invalid file in queue:", file);
        // Remove invalid file and retry
        this.processingQueue = this.processingQueue.slice(1);
        this.isProcessingQueue = false;
        if (this.processingQueue.length > 0) {
          // Process next file immediately
          this.processNextFileInQueue();
        }
        return;
      }

      try {
        // Process the file - this now handles UI updates internally
        await this.processFile(file);

        // Update global status
        this.uploadStatus = {
          progress: Math.min(
            90,
            Math.round(
              ((this.processingQueue.indexOf(file) + 1) /
                this.processingQueue.length) *
                100
            )
          ),
          status: "processing",
          message: `Processed ${file.name}, ${
            this.processingQueue.length - 1
          } files remaining`,
        };
      } catch (error) {
        console.error(`[FileDropzone] Error processing ${file.name}:`, error);

        // Always show file type error regardless of the actual error
        this.showFileTypeError();
        throw error;
      } finally {
        // Always remove the file from the queue
        this.processingQueue = this.processingQueue.filter(
          (item) => item !== file
        );

        // Explicitly force render after queue update
        this.renderKey++;
        this.$forceUpdate();
        requestAnimationFrame(() => this.$forceUpdate());

        // Reset the processing flag
        this.isProcessingQueue = false;

        if (this.processingQueue.length > 0) {
          // Process the next file in the queue immediately
          this.processNextFileInQueue();
        } else {
          // Queue is now empty - all files processed
          this.fileUploading = false;
          this.uploadStatus = {
            progress: 100,
            status: "complete",
            message: "All files uploaded successfully",
          };

          // Show snackbar message based on number of files uploaded
          if (this.lastUploadedFiles && this.lastUploadedFiles.length > 0) {
            if (this.lastUploadedFiles.length === 1) {
              // Single file upload
              this.$store.dispatch(
                "setSnackbar",
                `${this.lastUploadedFiles[0]} uploaded successfully`
              );
            } else {
              // Multiple file upload
              this.$store.dispatch(
                "setSnackbar",
                `${this.lastUploadedFiles.length} files uploaded successfully`
              );
            }
            // Clear the tracking array
            this.lastUploadedFiles = [];
          }

          // Clear complete message after 1 second
          setTimeout(() => {
            if (this.uploadStatus.status === "complete") {
              this.uploadStatus = {
                progress: 0,
                status: "idle",
                message: "",
              };
            }
          }, 1000); // Reduced from 3000ms to 1000ms
        }
      }
    },

    // Completely rewritten processFile method using uploadBytesResumable for better binary file handling
    async processFile(file) {
      try {
        // Validate the file object has all required properties
        if (!file || typeof file !== "object") {
          throw new Error("Invalid file object received for processing");
        }

        // CRITICAL: Add an additional check for max files limit right before upload
        // This prevents race conditions where multiple uploads could exceed the limit
        if (this.resources.length >= this.effectiveMaxFiles) {
          throw new Error(
            `Maximum file limit of ${this.effectiveMaxFiles} reached. Cannot upload more files.`
          );
        }

        // Make sure the file has a name
        if (!file.name) {
          console.error("[FileDropzone] File missing name property:", file);
          file.name = `unnamed_file_${Date.now()}`;
        }

        // Mark the file as processing in the queue
        if (!file.isProcessing) {
          file.isProcessing = true;
          this.forceRender(); // Update the UI to show processing state
        }

        this.uploadStatus = {
          progress: 0,
          status: "uploading",
          message: `Preparing ${file.name} for upload...`,
        };

        // Get the storage service and create a reference
        const storage = getStorage();
        const timestamp = Date.now();

        // Safely handle the file name
        const safeFileName =
          (file.name || "").replace(/[^a-zA-Z0-9-_.]/g, "_") ||
          `unnamed_file_${timestamp}`; // Sanitize filename

        // Create storage reference with unique path that includes the entryId when available
        const storagePath = this.entryId
          ? `uploads/${this.programId}/entries/${this.entryId}/formFileUploads/${timestamp}_${safeFileName}`
          : `uploads/${this.programId}/formFileUploads/${timestamp}_${safeFileName}`;

        const storageRef = ref(storage, storagePath);

        // CRITICAL: Get the correct binary file data
        // In Dropzone, the file object structure can vary
        let fileData;

        try {
          // Use our already captured raw file data if available
          if (
            file._rawFileData &&
            (file._rawFileData instanceof File ||
              file._rawFileData instanceof Blob)
          ) {
            // Use the raw file data we captured during handleDropzoneAddedFile
            fileData = file._rawFileData;
          }
          // Check all possible locations where file data might be stored
          else if (file.upload && file.upload.file) {
            // Standard Dropzone path - original File in upload.file
            fileData = file.upload.file;
          } else if (file._file) {
            // Sometimes stored in _file
            fileData = file._file;
          } else if (file instanceof File || file instanceof Blob) {
            // Direct File or Blob object
            fileData = file;
          } else if (
            file.file &&
            (file.file instanceof File || file.file instanceof Blob)
          ) {
            // Sometimes Dropzone puts it in a 'file' property
            fileData = file.file;
          } else if (file.dataURL) {
            // Try to get from dataURL if available
            try {
              const dataURL = file.dataURL;
              const parts = dataURL.split(",");
              if (parts.length !== 2) {
                throw new Error("Invalid dataURL format");
              }

              // Get MIME type
              const matches = parts[0].match(/:(.*?);/);
              if (!matches || matches.length !== 2) {
                throw new Error("Could not extract mime type from dataURL");
              }
              const mimeType = matches[1];

              // Convert base64 to binary
              const byteString = atob(parts[1]);
              const ab = new ArrayBuffer(byteString.length);
              const ia = new Uint8Array(ab);
              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }

              fileData = new Blob([ab], { type: mimeType });
            } catch (dataUrlError) {
              console.error(
                "[FileDropzone] Error creating blob from dataURL:",
                dataUrlError
              );
              // Continue to other methods
            }
          } else if (typeof file === "object") {
            // Last resort: Create a new Blob from the file properties we have

            // If we have a stream or array buffer, use it
            if (file.arrayBuffer) {
              const buffer = await file.arrayBuffer();
              fileData = new Blob([buffer], {
                type: file.type || "application/octet-stream",
              });
            } else if (file.stream) {
              // Note: stream() is experimental and may not work in all browsers
              const reader = file.stream().getReader();
              const chunks = [];
              let reading = true;

              while (reading) {
                const { done, value } = await reader.read();
                if (done) {
                  reading = false;
                } else {
                  chunks.push(value);
                }
              }

              fileData = new Blob(chunks, {
                type: file.type || "application/octet-stream",
              });
            } else {
              // Create an empty file with the right name and type as a fallback
              // This is not ideal but better than failing completely
              fileData = new Blob(["File content unavailable"], {
                type: file.type || "application/octet-stream",
              });

              console.warn(
                "[FileDropzone] Created placeholder file - upload may not contain correct data"
              );
            }
          } else {
            throw new Error("Could not determine file data type");
          }

          if (!fileData) {
            throw new Error("File data extraction methods failed");
          }
        } catch (extractError) {
          console.error(
            "[FileDropzone] Error extracting file data:",
            extractError,
            file
          );

          // Last resort fallback - create an empty file to prevent complete failure
          fileData = new Blob(["File upload failed - data extraction error"], {
            type: "text/plain",
          });

          // Continue the process but log the error
          this.setUploadErrors(
            `Warning: Problem accessing file data for ${
              file.name
            }. Upload may be incomplete. [${Date.now()}]`
          );
        }

        // Set metadata with content type
        const metadata = {
          contentType: fileData.type || "application/octet-stream",
          customMetadata: {
            originalName: file.name,
            size: (typeof fileData.size === "number"
              ? fileData.size
              : 0
            ).toString(),
            lastModified: fileData.lastModified
              ? new Date(fileData.lastModified).toString()
              : new Date().toString(),
            uploadedBy: this.$store.state.auth?.user?.uid || "unknown",
            programId: this.programId,
            offerId: this.currentOffer?.id,
            entryId: this.entryId || "form-builder-preview",
            uploadedAt: new Date().toISOString(),
          },
        };

        // Prepare the file metadata for display ahead of time
        const fileMetadata = {
          size: this.formatFileSize(fileData.size || 0),
          type: fileData.type || "application/octet-stream",
          lastModified: new Date(
            fileData.lastModified || Date.now()
          ).toLocaleString(),
        };

        // Pre-create a unique ID for the resource
        const resourceId = Date.now() + Math.random();
        let displayName = file.name || `File ${resourceId}`;

        // Check for existing resources with the same name and add version number if needed
        const existingResources = this.resources.filter((resource) => {
          // Clean up both names to compare them without version numbers
          const cleanResourceName = resource.description.replace(
            / \(\d+\)$/,
            ""
          );
          const cleanDisplayName = displayName.replace(/ \(\d+\)$/, "");
          return cleanResourceName === cleanDisplayName;
        });

        if (existingResources.length > 0) {
          // Strip any existing version numbers like (1) from the name
          const baseName = displayName.replace(/ \(\d+\)$/, "");
          // Add version number - use the count of existing resources with same name + 1
          displayName = `${baseName} (${existingResources.length})`;
        }

        // Use uploadBytesResumable to properly handle binary data and track progress
        const uploadTask = uploadBytesResumable(storageRef, fileData, metadata);

        // Create a promise that resolves when the upload is complete
        return new Promise((resolve, reject) => {
          // Track upload progress
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Update progress
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.uploadStatus = {
                progress: progress,
                status: "uploading",
                message: `Uploading ${file.name}... ${progress.toFixed(0)}%`,
              };
              this.forceRender();
            },
            (error) => {
              // Handle upload errors
              console.error(`[FileDropzone] Upload error:`, error);

              // Always show the permitted file types regardless of error type
              this.showFileTypeError();

              this.uploadStatus = {
                progress: 0,
                status: "error",
                message: `Upload unsuccessful`,
              };
              reject(error);
            },
            async () => {
              // Upload completed successfully
              // Get the download URL
              try {
                this.uploadStatus = {
                  progress: 100,
                  status: "complete",
                  message: `Upload complete!`,
                };

                const downloadURL = await getDownloadURL(
                  uploadTask.snapshot.ref
                );

                // Create the new resource object
                const newResource = {
                  id: resourceId,
                  type: "file",
                  description: displayName,
                  filename: displayName,
                  url: downloadURL,
                  created: new Date(),
                  metadata: fileMetadata,
                  storageRef: downloadURL.split("?")[0], // Store reference to the storage path
                };

                // Track this file for snackbar notification
                if (!this.lastUploadedFiles) {
                  this.lastUploadedFiles = [];
                }
                this.lastUploadedFiles.push(displayName);

                // Update local state first for immediate UI update - pre-remove the processing file
                this.processingQueue = this.processingQueue.filter(
                  (item) => item !== file
                );

                // Add the new resource to the resources array
                const updatedResources = [...this.resources, newResource];
                this.resources = updatedResources;

                // Immediate UI update
                this.renderKey++;
                this.$forceUpdate();
                requestAnimationFrame(() => this.$forceUpdate());

                // Save to Firestore in the background
                try {
                  await this.saveResourcesToEntry(updatedResources);
                } catch (dbSetupError) {
                  console.error(
                    "[FileDropzone] Error setting up database update:",
                    dbSetupError
                  );
                }

                // Clean internal state
                this.fileUploading = false;
                this.fileUploadingProgress = 0;
                this.fileUploadTask = null;
                this.fileToUpload = null;
                this.fileDescription = "";
                this.fileMetadata = {
                  size: "",
                  type: "",
                  lastModified: "",
                  dimensions: null,
                };

                // Resolve the promise to continue processing the queue
                resolve();
              } catch (urlError) {
                console.error(
                  "[FileDropzone] Error getting download URL:",
                  urlError
                );
                this.setUploadErrors(
                  `File uploaded but couldn't get download link: ${
                    urlError.message
                  } [${Date.now()}]`
                );
                reject(urlError);
              }
            }
          );
        });
      } catch (error) {
        console.error(`[FileDropzone] Error processing ${file.name}:`, error);

        // Always show file type error regardless of the actual error
        this.showFileTypeError();
        throw error;
      }
    },

    // Add missing dropzone event handlers
    handleDropzoneRemovedFile(file) {
      // Remove from processing queue if present
      if (file?.name) {
        this.processingQueue = this.processingQueue.filter(
          (item) => item.name !== file.name
        );
        this.forceRender();
      }
    },

    handleDropzoneProcessing() {
      // Dropzone processing
    },

    handleDropzoneSuccess() {
      // Dropzone success
    },

    handleDropzoneError(file, message) {
      console.error("[FileDropzone] Dropzone error:", message);

      // Remove file from dropzone
      if (this.$refs.dropzone && file) {
        this.$refs.dropzone.removeFile(file);
      }

      // Always show file type error regardless of the actual error
      this.showFileTypeError();
    },

    // Helper method to show file type error with consistent messaging
    showFileTypeError() {
      const permittedFiles = this.effectiveAcceptedFileExtensions
        .map((ext) => `.${ext}`)
        .join(", ");
      this.setUploadErrors(
        `File type is not allowed. Files must be ${permittedFiles}`
      );

      // Update UI immediately
      this.renderKey++;
      this.$forceUpdate();
    },

    handleDropzoneQueueComplete() {
      // Dropzone queue complete
    },

    handleDropzoneTotalUploadProgress() {
      // Dropzone upload progress
    },

    handleDropzoneClick(event) {
      // Prevent opening file dialog if max files limit is reached
      if (this.resources.length >= this.effectiveMaxFiles) {
        console.log(
          `[FileDropzone] Preventing file dialog open: max limit (${this.effectiveMaxFiles}) reached`
        );
        this.$store.dispatch(
          "setSnackbar",
          `Maximum file limit (${this.effectiveMaxFiles}) reached. Please delete some files first.`
        );
        this.setMaxFilesAlert(); // Set max files alert with auto-dismiss
        // Prevent default click behavior
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      // Check if approaching max files limit
      else if (this.resources.length === this.effectiveMaxFiles - 1) {
        this.setApproachingLimitAlert(); // Set approaching limit alert with auto-dismiss
      }
      // Allow normal dropzone click behavior if below the limit
    },

    openResource(url) {
      window.open(url, "_blank");
    },

    setHoveredResource(id) {
      this.hoveredResourceId = id;
    },

    formatFileTypes() {
      // Compress the list of file types for display
      const extensions = this.effectiveAcceptedFileExtensions.map(
        (ext) => "." + ext
      );
      // If more than 5 extensions, show first 4 then "and X more"
      if (extensions.length > 5) {
        return (
          extensions.slice(0, 4).join(", ") +
          ` and ${extensions.length - 4} more`
        );
      }
      return extensions.join(", ");
    },

    clearUploadErrors() {
      this.uploadErrors = [];
      if (this.errorTimer) {
        clearTimeout(this.errorTimer);
        this.errorTimer = null;
      }
    },

    setUploadErrors(errors) {
      this.uploadErrors = Array.isArray(errors) ? errors : [errors];

      // Clear any existing timer
      if (this.errorTimer) {
        clearTimeout(this.errorTimer);
      }

      // Set a new timer to clear errors after 5 seconds
      this.errorTimer = setTimeout(() => {
        this.clearUploadErrors();
      }, 5000);
    },

    clearApproachingLimitAlert() {
      this.showApproachingLimitAlert = false;
      if (this.approachingLimitTimer) {
        clearTimeout(this.approachingLimitTimer);
        this.approachingLimitTimer = null;
      }
    },

    clearMaxFilesAlert() {
      this.showMaxFilesAlert = false;
      if (this.maxFilesAlertTimer) {
        clearTimeout(this.maxFilesAlertTimer);
        this.maxFilesAlertTimer = null;
      }
    },

    // Set max files alert with auto-dismiss timer
    setMaxFilesAlert() {
      this.showMaxFilesAlert = true;

      // Clear any existing timer
      if (this.maxFilesAlertTimer) {
        clearTimeout(this.maxFilesAlertTimer);
      }

      // Set a new timer to clear alert after 5 seconds
      this.maxFilesAlertTimer = setTimeout(() => {
        this.clearMaxFilesAlert();
      }, 5000);
    },

    // Set approaching limit alert with auto-dismiss timer
    setApproachingLimitAlert() {
      this.showApproachingLimitAlert = true;

      // Clear any existing timer
      if (this.approachingLimitTimer) {
        clearTimeout(this.approachingLimitTimer);
      }

      // Set a new timer to clear alert after 5 seconds
      this.approachingLimitTimer = setTimeout(() => {
        this.clearApproachingLimitAlert();
      }, 5000);
    },

    // Method to ensure we display the full filename including the version number
    getResourceDisplayName(resource) {
      // If filename or description is missing, use "Unknown file" as fallback
      if (!resource || (!resource.description && !resource.filename)) {
        return "Unknown file";
      }

      // Use description as primary source, fallback to filename if needed
      return resource.description || resource.filename;
    },

    // New method to load resources from the entry
    async loadEntryResources() {
      try {
        if (!this.entryId) {
          console.log(
            "[FileDropzone] No entryId provided, initializing empty resources array"
          );
          this.fieldResources = [];
          return;
        }

        // Get entry from Firestore
        const db = getFirestore();
        const entryRef = doc(
          db,
          "programs",
          this.programId,
          "offers",
          this.currentOffer.id,
          "entries",
          this.entryId
        );

        // If we have existing entries in the store, use them first for faster UI update
        const entries = this.currentOffer.entries || [];
        const storeEntry = entries.find((entry) => entry.id === this.entryId);

        if (storeEntry) {
          // Extract resources for this specific field
          const fieldResources =
            storeEntry[this.fieldIdentifier]?.resources || [];
          this.fieldResources = fieldResources;
          this.forceRender();
        } else {
          console.log(
            "[FileDropzone] Entry not found in store, loading from Firestore"
          );
          // Fetch entry data from Firestore
          const entrySnapshot = await getDoc(entryRef);
          if (entrySnapshot.exists()) {
            const entryData = entrySnapshot.data();
            // Extract resources for this specific field
            const fieldResources =
              entryData[this.fieldIdentifier]?.resources || [];
            this.fieldResources = fieldResources;
            this.forceRender();
          } else {
            console.log(
              "[FileDropzone] Entry not found in Firestore, initializing empty resources array"
            );
            this.fieldResources = [];
          }
        }
      } catch (error) {
        console.error("[FileDropzone] Error loading entry resources:", error);
        this.fieldResources = [];
      }
    },

    // Update method to save resources to entry
    async saveResourcesToEntry(resources) {
      try {
        console.log(
          `[FileDropzone] Saving ${resources.length} resources`,
          resources
        );

        // Update local fieldResources first
        this.fieldResources = resources;

        // If we're in form builder preview mode, don't save to Firestore
        if (!this.entryId) {
          console.log(
            "[FileDropzone] No entryId provided, operating in form builder mode (no save)"
          );

          // Emit input event for parent component's v-model
          this.$emit("input", resources);
          return;
        }

        // Save to Firestore
        const db = getFirestore();
        const entryRef = doc(
          db,
          "programs",
          this.programId,
          "offers",
          this.currentOffer.id,
          "entries",
          this.entryId
        );

        // Create update object with field identifier
        const updateObj = {};
        updateObj[this.fieldIdentifier] = {
          resources: resources,
          lastUpdated: new Date(),
        };

        // Update the entry document
        await updateDoc(entryRef, updateObj);

        console.log(
          `[FileDropzone] Resources saved to Firestore entry: ${this.entryId}`
        );

        // Also update local state in Vuex if entries are available
        const currentEntries = this.currentOffer.entries || [];
        if (currentEntries.length > 0) {
          const updatedEntries = currentEntries.map((entry) => {
            if (entry.id === this.entryId) {
              // Create or update the field in the entry
              return {
                ...entry,
                [this.fieldIdentifier]: {
                  ...entry[this.fieldIdentifier],
                  resources: resources,
                  lastUpdated: new Date(),
                },
              };
            }
            return entry;
          });

          // Update entries in store
          this.$store.dispatch("patchCurrentOffer", {
            entries: updatedEntries,
          });

          console.log(`[FileDropzone] Updated entries in Vuex store`);
        }

        // Always emit the input event for parent components
        console.log(
          `[FileDropzone] Emitting input event with resources`,
          resources
        );
        this.$emit("input", resources);
      } catch (error) {
        console.error("[FileDropzone] Error saving resources to entry:", error);

        // Still emit the resources to maintain UI consistency
        this.$emit("input", resources);
      }
    },
  },
};
</script>

<style>
/* Hide default dropzone styling */
#offer-resources-dropzone .dz-preview {
  display: none !important;
}

/* Custom styling for our dropzone */
#offer-resources-dropzone {
  min-height: 250px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background: #f8f9fa;
  padding: 20px !important;
}

#offer-resources-dropzone .dropzone-custom-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;
  padding: 0;
}

/* Resources grid spacing */
.resources-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; /* Increased from -10px to create more space between rows */
}

.resources-grid .v-col {
  padding: 15px !important; /* Increased from 10px to create more space between rows */
  margin-bottom: 10px !important; /* Added explicit bottom margin for more spacing between rows */
}

/* Loading card styling adjustments */
.resources-grid .loading-card {
  position: relative;
  border: 1px solid var(--v-primary-base) !important;
  background-color: white !important;
  min-height: 100px;
  z-index: 3 !important;
  opacity: 1 !important;
  visibility: visible !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  margin: 0 auto;
  max-width: 200px;
  aspect-ratio: 1/1;
}

.resource-card .delete-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2 !important;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
  text-align: center;
  color: #777;
}

/* Resource card layout improvements */
.resource-card {
  display: flex !important;
  flex-direction: column !important;
  max-width: 200px !important;
  width: 100% !important;
  margin: 0 auto !important;
  aspect-ratio: 1 / 1 !important;
  z-index: 2 !important;
}

.resource-card .v-card__text {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  padding: 16px 12px !important;
}

.resource-card .delete-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 5;
}

/* Clickable card styling */
.clickable-card {
  cursor: pointer;
  transition: all 0.2s ease;
}

.clickable-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.filename-text {
  font-size: 12px !important;
  line-height: 1.2 !important;
  color: var(--v-primary-base) !important;
  word-break: break-word !important;
  text-align: center !important;
  max-width: 100% !important;
  min-height: unset !important;
  margin-bottom: 4px !important;
  display: block !important;
}

/* Processing card text */
.loading-card .text-caption {
  font-size: 10px !important;
  line-height: 1.2 !important;
}

/* Disabled dropzone styling */
.disabled-dropzone {
  cursor: not-allowed !important;
  background-color: #f0f0f0 !important;
  border-color: #ccc !important;
}

.disabled-dropzone:hover {
  background-color: #f0f0f0 !important;
  border-color: #ccc !important;
}

.disabled-dropzone * {
  pointer-events: none;
}

/* Override pointer-events for delete buttons */
.disabled-dropzone .resource-card .delete-btn {
  pointer-events: auto;
}

/* Simple file counter text styling */
.file-counter-text {
  position: absolute;
  top: -35px;
  right: 0px;
  font-size: 12px;
  font-weight: 500;
  color: #666;
  z-index: 5;
}

/* Change color when limit is reached */
.disabled-dropzone .file-counter-text {
  color: #f44336; /* Red color when limit reached */
  font-weight: 600;
}

/* File info text at bottom of dropzone */
.file-info-text {
  position: absolute;
  bottom: -35px;
  right: -8px;
  font-size: 11px;
  font-weight: 500;
  color: #666;
  z-index: 5;
  text-align: right;
  padding: 4px 8px;
  max-width: 90%;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Change color when limit is reached */
.disabled-dropzone .file-info-text {
  color: #666; /* Keep same color when disabled */
}

/* Empty state text styling to match counter/info text */
.empty-state-text {
  font-size: 12px;
  font-weight: 500;
  color: #666;
  margin-bottom: 6px;
}

/* Limit Reached Text */
.limit-text {
  color: #f44336; /* Red color when limit reached */
  font-weight: 600;
}
</style>

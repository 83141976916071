/* eslint-disable no-console */
<template>
  <v-card outlined :style="cardStyle" class="px-4 pt-4 ma-4">
    <div class="page-subheading mb-3 primary--text">
      Incentable Admin Feature (not show to users)
    </div>
    <div class="page-subheading">Permitted Email Domains</div>
    <div class="mb-3 caption">
      If one or more domains are listed, only those domains can be added as
      program administrators
    </div>
    <div>
      <v-row no-gutters justify="space-between" align="center" cols="12">
        <v-col sm="3">
          <v-text-field
            v-model="search"
            solo-inverted
            dense
            flat
            hide-details
            label="Search"
            prepend-inner-icon="search"
            class="mr-4"
          ></v-text-field>
        </v-col>

        <v-col sm="3" v-if="options.filter">
          <v-select
            solo-inverted
            dense
            flat
            hide-details
            label="Filter"
            prepend-inner-icon="filter_list"
            class="mr-4"
          ></v-select>
        </v-col>

        <v-col>
          <v-btn
            @click="handleOpenImportDialog()"
            v-if="options.upload"
            class="primary--text"
            text
            >Upload</v-btn
          >
          <v-btn
            v-if="options.download"
            class="primary--text"
            text
            :href="companiesCsv"
            :download="getFilename()"
            >Download</v-btn
          >
          <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
        </v-col>

        <v-col sm="auto">
          <v-btn @click="handleNew()" color="primary" elevation="0">
            <v-icon>add</v-icon>new
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="permittedEmailDomains"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No Records..."
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left close-on-content-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link :disabled="!isRoleSatisfied('Editor')">
                <v-list-item-title @click="handleEdit(item)"
                  >Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item link :disabled="!isRoleSatisfied('Editor')">
                <v-list-item-title @click="handleDeleteConfirmation(item)"
                  >Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <Gravatar :email="item.email" :size="150" defaultAvatar="mp" />
          {{ item.name }}
        </template>

        <template v-slot:[`item.created`]="{ item }">
          {{ item.created | date }}
        </template>

        <template v-slot:[`item.updated`]="{ item }">
          {{ item.updated | date }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <status-icon :status="item.status" class="pr-3"></status-icon>
          {{ item.status }}
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <div
            v-if="isRoleSatisfied('Editor')"
            @click="handleEdit(item)"
            class="primary--text"
            style="cursor: pointer"
          >
            {{ item.title }}
          </div>
          <div v-else>
            {{ item.title }}
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- New or Edit dialog -->

    <permitted-email-domain-add-or-edit
      v-if="dialogNewOrEditOpen"
      :editingDomain="editingDomain"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <!-- Delete dialog -->

    <permitted-email-domain-delete
      v-if="dialogDeleteOpen"
      :deletingDomain="deletingDomain"
      :open="dialogDeleteOpen"
      @onClose="handleCloseDeleteDialog"
    />
  </v-card>
</template>

<script>
import PermittedEmailDomainAddOrEdit from "./PermittedEmailDomainAddOrEdit.vue";
import PermittedEmailDomainDelete from "./PermittedEmailDomainDelete.vue";
import Gravatar from "../../Shared/Gravatar.vue";

export default {
  name: "PermittedEmailDomainsList",
  components: {
    PermittedEmailDomainAddOrEdit,
    PermittedEmailDomainDelete,
    Gravatar,
  },

  created() {
    // Load domains when component is created
    this.$store.dispatch("loadPermittedEmailDomains");
  },

  data: () => ({
    // Component settings unique to this component
    search: "",
    domain: "",
    editingDomain: null,
    deletingDomain: null,

    // Table columns
    headers: [
      { text: "Domain", value: "domain", align: "left" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: ["domain"],
      sortDesc: [false],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
  }),
  computed: {
    // Functions common to all components
    loadingTable() {
      return this.$store.getters.permittedEmailDomainsLoadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    permittedEmailDomains() {
      return this.$store.getters.permittedEmailDomains;
    },
    cardStyle() {
      return {
        borderColor: this.orgTheme.primary,
      };
    },
  },

  methods: {
    handleNew() {
      // Open New form
      this.editingDomain = {};
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.editingDomain = item;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form and refresh list
      this.editingDomain = null;
      this.dialogNewOrEditOpen = false;
      this.$store.dispatch("loadPermittedEmailDomains");
    },
    handleDeleteConfirmation(item) {
      // Open delete form
      this.deletingDomain = item;
      this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
      // Close delete form and refresh list
      this.dialogDeleteOpen = false;
      this.deletingDomain = null;
      this.$store.dispatch("loadPermittedEmailDomains");
    },
  },
};
</script>

<style>
.primary-border {
  border-color: var(--v-primary-base) !important;
}
</style>

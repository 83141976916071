var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-16"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('Pages',{attrs:{"pages":_vm.pages}})],1),_c('v-col',{staticClass:"available-group col-padding mt-n1",attrs:{"cols":"3"}},[_c('v-row',{staticClass:"page-subheading",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.previewForm}},[_vm._v("Preview form")])],1),_c('v-row',{staticClass:"page-subheading",attrs:{"no-gutters":""}},[_vm._v("Components")]),_c('draggable',{staticClass:"available-components-grid",attrs:{"tag":"div","group":{
            name: 'form-fields',
            pull: 'clone',
            put: false,
          },"ghost-class":"ghost"},model:{value:(_vm.availableComponents),callback:function ($$v) {_vm.availableComponents=$$v},expression:"availableComponents"}},_vm._l((_vm.availableComponents),function(field,index){return _c('v-card',{key:index,staticClass:"available-component-card",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"icon-available-components"},[_vm._v(_vm._s(field.icon))])],1),_c('v-col',[_c('span',{staticClass:"subheading-available-components"},[_vm._v(_vm._s(field.label))])])],1)],1)}),1),_c('v-row',{staticClass:"page-subheading",attrs:{"no-gutters":""}},[_vm._v("Sections")]),_c('draggable',{staticClass:"available-components-grid",attrs:{"tag":"div","group":{
            name: 'form-fields',
            pull: 'clone',
            put: false,
          },"ghost-class":"ghost"},model:{value:(_vm.availableSections),callback:function ($$v) {_vm.availableSections=$$v},expression:"availableSections"}},_vm._l((_vm.availableSections),function(section,index){return _c('v-card',{key:index,staticClass:"available-component-card",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"icon-available-components"},[_vm._v(_vm._s(section.icon))])],1),_c('v-col',[_c('span',{staticClass:"subheading-available-components"},[_vm._v(_vm._s(section.label))])])],1)],1)}),1),_c('v-row',{staticClass:"page-subheading",attrs:{"no-gutters":""}},[_vm._v("Templates")]),_c('draggable',{staticClass:"available-components-grid",attrs:{"tag":"div","group":{
            name: 'form-fields',
            pull: 'clone',
            put: false,
          },"ghost-class":"ghost"},model:{value:(_vm.availableSpecial),callback:function ($$v) {_vm.availableSpecial=$$v},expression:"availableSpecial"}},_vm._l((_vm.availableSpecial),function(field,index){return _c('v-card',{key:index,staticClass:"available-component-card",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"icon-available-components"},[_vm._v(_vm._s(field.icon))])],1),_c('v-col',[_c('span',{staticClass:"subheading-available-components"},[_vm._v(_vm._s(field.label))])])],1)],1)}),1),_c('v-row',{staticClass:"page-subheading",attrs:{"no-gutters":""}},[_vm._v("Headings")]),_c('draggable',{staticClass:"available-components-grid",attrs:{"tag":"div","group":{
            name: 'form-fields',
            pull: 'clone',
            put: false,
          },"ghost-class":"ghost"},model:{value:(_vm.availableHeadings),callback:function ($$v) {_vm.availableHeadings=$$v},expression:"availableHeadings"}},_vm._l((_vm.availableHeadings),function(field,index){return _c('v-card',{key:index,staticClass:"available-component-card",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"icon-available-components"},[_vm._v(_vm._s(field.icon))])],1),_c('v-col',[_c('span',{staticClass:"subheading-available-components"},[_vm._v(_vm._s(field.label))])])],1)],1)}),1),_c('v-row',{staticClass:"page-subheading",attrs:{"no-gutters":""}},[_vm._v("Settings")]),_c('v-switch',{attrs:{"label":"Stepper"},model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}})],1)],1),_c('PreviewForm',{attrs:{"open":_vm.previewFormOpen,"previewArr":_vm.formPreviewArr,"isPreview":_vm.isPreview},on:{"onCancelPreviewForm":_vm.cancelPreviewForm}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="dialogOpen" max-width="1200px">
    <v-card>
      <!-- Loading state -->
      <div v-if="isLoading" class="text-center py-4">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div class="mt-2">Processing form data...</div>
      </div>

      <!-- Error state -->
      <v-alert v-else-if="hasError" type="error" class="mb-4">
        {{ errorMessage }}
      </v-alert>

      <!-- Empty state -->
      <v-alert v-else-if="isEmpty" type="info" class="mb-4">
        No form data available to display.
      </v-alert>

      <!-- Form data display -->
      <template v-else>
        <div class="form-results-container">
          <template v-if="showStepper">
            <!-- Stepper for multi-page forms -->
            <v-stepper v-model="currentStep" class="elevation-0">
              <v-stepper-header class="elevation-0">
                <!-- Render each step and divider directly -->
                <div
                  v-for="(page, index) in formPages"
                  :key="`page-container-${index}`"
                  class="d-flex"
                >
                  <v-stepper-step
                    :key="`page-${index}`"
                    :complete="currentStep > index + 1"
                    :step="index + 1"
                    color="primary"
                  >
                    {{ page.title || `Page ${index + 1}` }}
                  </v-stepper-step>
                  <v-divider
                    v-if="index < formPages.length - 1"
                    :key="`divider-${index}`"
                  ></v-divider>
                </div>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content
                  v-for="(page, pageIndex) in formPages"
                  :key="`content-${pageIndex}`"
                  :step="pageIndex + 1"
                >
                  <div
                    v-for="(section, sectionIndex) in page.sections"
                    :key="`section-${pageIndex}-${sectionIndex}`"
                    class="mb-6"
                  >
                    <div class="pa-4 mb-4">
                      <v-row>
                        <v-col
                          v-for="(column, columnIndex) in section.columns"
                          :key="`column-${pageIndex}-${sectionIndex}-${columnIndex}`"
                          :cols="12"
                          :sm="12 / section.columns.length"
                        >
                          <div
                            v-for="(field, fieldIndex) in column.items"
                            :key="`field-${pageIndex}-${sectionIndex}-${columnIndex}-${fieldIndex}`"
                            class="mb-4"
                          >
                            <field-display
                              :field="field"
                              :value="getFieldValue(field)"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </template>

          <!-- Single page display when no stepper -->
          <template v-else>
            <div class="pa-4">
              <div
                v-for="(section, sectionIndex) in formPages[0]?.sections || []"
                :key="`section-0-${sectionIndex}`"
                class="mb-6"
              >
                <div class="pa-4 mb-4">
                  <v-row>
                    <v-col
                      v-for="(column, columnIndex) in section.columns"
                      :key="`column-0-${sectionIndex}-${columnIndex}`"
                      :cols="12"
                      :sm="12 / section.columns.length"
                    >
                      <div
                        v-for="(field, fieldIndex) in column.items"
                        :key="`field-0-${sectionIndex}-${columnIndex}-${fieldIndex}`"
                        class="mb-4"
                      >
                        <field-display
                          :field="field"
                          :value="getFieldValue(field)"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </template>
        </div>

        <v-card-actions>
          <v-btn color="primary" @click="closeDialog" elevation="0"
            >Close</v-btn
          >
          <v-btn text color="grey darken-1" @click="showRawData = !showRawData">
            {{ showRawData ? "Hide" : "Show" }} Raw Data
          </v-btn>
          <v-spacer></v-spacer>
          <!-- Navigation buttons for stepper -->

          <template v-if="showStepper">
            <v-btn
              v-if="currentStep > 1"
              color="primary"
              elevation="0"
              @click="currentStep--"
            >
              Previous
            </v-btn>
            <v-btn
              v-if="currentStep < formPages.length"
              color="primary"
              elevation="0"
              @click="currentStep++"
            >
              Next
            </v-btn>
          </template>
        </v-card-actions>

        <!-- Raw data display (collapsible) -->
        <div v-if="showRawData">
          <v-card-text>
            <span>Raw Form Data</span>
            <v-sheet outlined class="pa-2 mt-4">
              <pre class="raw-data">{{ formattedResults }}</pre>
            </v-sheet>
          </v-card-text>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldDisplay from "./FieldDisplay.vue";

export default {
  components: {
    FieldDisplay,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    structuredFormValues: {
      type: Object,
      default: () => ({}),
    },
    formPages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      errorMessage: "",
      currentStep: 1,
      showRawData: false,
      presetRecordsCache: {}, // Cache for preset records by group ID
    };
  },
  computed: {
    dialogOpen: {
      get() {
        return this.open;
      },
      set(value) {
        if (!value) {
          this.$emit("update:open", false);
        }
      },
    },
    formattedResults() {
      return JSON.stringify(this.structuredFormValues, null, 2);
    },
    isEmpty() {
      return Object.keys(this.structuredFormValues).length === 0;
    },
    hasError() {
      return !!this.errorMessage;
    },
    hasMetadata() {
      return this.structuredFormValues._meta;
    },
    formattedDate() {
      if (!this.structuredFormValues._meta?.submittedAt) {
        return "Unknown date";
      }

      try {
        const date = new Date(this.structuredFormValues._meta.submittedAt);
        return date.toLocaleString();
      } catch (e) {
        return "Invalid date";
      }
    },
    formTitle() {
      return this.structuredFormValues._meta?.formTitle || "";
    },
    showStepper() {
      return this.formPages.length > 1;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:open", false);
    },
    getFieldValue(field) {
      // Find the field in the structured form values using its idx
      if (!field.idx || !this.structuredFormValues[field.idx]) {
        return null;
      }

      // Create a deep copy of the field data
      const fieldData = JSON.parse(
        JSON.stringify(this.structuredFormValues[field.idx])
      );

      // For radio fields, ensure allOptions reflects the selection state
      if (field.type === "radio" && fieldData.allOptions) {
        fieldData.allOptions.forEach((opt) => {
          opt.isSelected = opt.value === fieldData.value;
        });
      }

      // For table fields, process the data to format dropdown values and handle points
      if (field.type === "table" && Array.isArray(fieldData.value)) {
        // Process the table data before returning
        fieldData.value = this.processTableData(field, [...fieldData.value]);

        // Calculate total points if the table has points columns
        if (this.hasPointsColumns(field)) {
          fieldData.totalPoints = this.calculateTotalPoints(
            field,
            fieldData.value
          );
        }
      }

      return fieldData.value;
    },

    // Process table data to format dropdown values
    processTableData(tableField, tableData) {
      if (!tableField.componentFields || !Array.isArray(tableData)) {
        return tableData;
      }

      return tableData.map((row) => {
        const processedRow = { ...row };

        tableField.componentFields.forEach((field) => {
          if (field.type === "dropdown") {
            const fieldId = field.idx || `field-${field.label}`;
            const value = row[fieldId];
            const pointsKey = `${fieldId}_points`;

            // Process dropdown display value
            if (value) {
              processedRow[fieldId] = this.getDropdownDisplayValue(
                field,
                value
              );
            }

            // Include points data if available
            if (row[pointsKey] !== undefined) {
              processedRow[pointsKey] = row[pointsKey];
            }
          }
        });

        return processedRow;
      });
    },

    // Get display text for dropdown fields
    getDropdownDisplayValue(field, value) {
      // If value is null or undefined, return "Not selected"
      if (value === null || value === undefined || value === "") {
        return "Not selected";
      }

      // For dynamic presets with valuesToDisplay configuration
      if (field.presets && field.presets.presetsType === "dynamic") {
        // Try to find the selected item in the cached dynamic options
        const selectedItem = this.findSelectedDynamicItem(field, value);
        if (selectedItem) {
          // Ensure we're using the field's valuesToDisplay configuration
          return this.generateDisplayText(field, selectedItem);
        } else {
          // If item not found in cache, try to load it on demand
          this.loadPresetRecordsIfNeeded(field.presets.selectedPresetsGroup);
          // Return the raw value for now
          return value;
        }
      }

      // For static options
      if (field.presets && field.presets.staticOptions) {
        const option = field.presets.staticOptions.find(
          (opt) => (typeof opt === "object" ? opt.value : opt) === value
        );
        if (option) {
          return typeof option === "object" ? option.text : option;
        }
      }

      return value;
    },

    // Find selected dynamic item from cache
    findSelectedDynamicItem(field, value) {
      if (!field || !field.presets || !field.presets.selectedPresetsGroup)
        return null;

      const groupId = field.presets.selectedPresetsGroup;
      const records = this.presetRecordsCache[groupId];

      if (records && Array.isArray(records)) {
        return records.find((item) => item.identifier === value);
      }

      return null;
    },

    // Generate display text for dynamic items
    generateDisplayText(field, item) {
      if (!item) return "-";

      // If no valuesToDisplay configuration is provided, show identifier as fallback
      if (
        !field.presets ||
        !field.presets.valuesToDisplay ||
        field.presets.valuesToDisplay.length === 0
      ) {
        return item.identifier || "-";
      }

      const displayValues = field.presets.valuesToDisplay;

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean); // Remove empty values

      return displayParts.length > 0
        ? displayParts.join(" • ")
        : item.identifier || "-";
    },

    // Load all preset records needed for the form fields
    loadAllPresetRecords() {
      if (!this.$store) return;

      // Find all unique preset groups used in the form
      const presetGroups = new Set();

      // Process all pages to find dropdown fields with dynamic presets
      this.formPages.forEach((page) => {
        page.sections.forEach((section) => {
          section.columns.forEach((column) => {
            column.items.forEach((field) => {
              // Check for table fields
              if (field.type === "table" && field.componentFields) {
                field.componentFields.forEach((tableField) => {
                  if (
                    tableField.type === "dropdown" &&
                    tableField.presets &&
                    tableField.presets.presetsType === "dynamic" &&
                    tableField.presets.selectedPresetsGroup
                  ) {
                    presetGroups.add(tableField.presets.selectedPresetsGroup);
                  }
                });
              }
              // Check for regular dropdown fields
              else if (
                field.type === "dropdown" &&
                field.presets &&
                field.presets.presetsType === "dynamic" &&
                field.presets.selectedPresetsGroup
              ) {
                presetGroups.add(field.presets.selectedPresetsGroup);
              }
            });
          });
        });
      });

      // Load records for each preset group
      presetGroups.forEach((groupId) => {
        this.loadPresetRecords(groupId);
      });
    },

    // Load preset records for a specific group
    loadPresetRecords(groupId) {
      if (!this.$store || !groupId) return;

      this.$store
        .dispatch("getPresetRecords", groupId)
        .then((records) => {
          if (records && Array.isArray(records)) {
            // Store in cache
            this.$set(this.presetRecordsCache, groupId, records);
          }
        })
        .catch((error) => {
          console.error(
            `Error loading preset records for group ${groupId}:`,
            error
          );
        });
    },

    // Load preset records for a specific group if not already in cache
    loadPresetRecordsIfNeeded(groupId) {
      if (!this.$store || !groupId || this.presetRecordsCache[groupId]) return;

      this.loadPresetRecords(groupId);
    },

    // Add new methods for points handling
    hasPointsColumns(field) {
      return field.componentFields?.some(
        (subField) =>
          subField.type === "dropdown" &&
          subField.presets?.valuesToDisplay?.includes("points")
      );
    },

    calculateTotalPoints(field, tableData) {
      if (!Array.isArray(tableData)) return 0;

      let total = 0;
      const pointsFields =
        field.componentFields?.filter(
          (subField) =>
            subField.type === "dropdown" &&
            subField.presets?.valuesToDisplay?.includes("points")
        ) || [];

      // Find quantity field - looking for textfield with label 'Quantity'
      const quantityField = field.componentFields?.find(
        (subField) =>
          subField.type === "textfield" && subField.label === "Quantity"
      );

      tableData.forEach((row) => {
        pointsFields.forEach((pointsField) => {
          const fieldId = pointsField.idx || `field-${pointsField.label}`;
          const pointsKey = `${fieldId}_points`;
          if (row[pointsKey] !== undefined) {
            let points = parseFloat(row[pointsKey]) || 0;

            // If quantity field exists and has a value, multiply points by quantity
            if (quantityField) {
              const quantityId =
                quantityField.idx || `field-${quantityField.label}`;
              const quantity = parseFloat(row[quantityId]) || 1;
              points *= quantity;
            }

            total += points;
          }
        });
      });

      return total;
    },

    // Add method to check if field has points enabled
    isPointsEnabledField(field) {
      return (
        field.type === "dropdown" &&
        field.presets?.presetsType === "dynamic" &&
        field.presets?.valuesToDisplay &&
        Array.isArray(field.presets.valuesToDisplay) &&
        field.presets.valuesToDisplay.includes("points")
      );
    },

    // Get points value (for display in table)
    getPointsValue(row, field) {
      if (!field) return "0";

      const fieldId = field.idx || `field-${field.label}`;
      const pointsKey = `${fieldId}_points`;

      // First check if we have a stored points value
      if (row[pointsKey] !== undefined) {
        let points = parseFloat(row[pointsKey]) || 0;

        // Find quantity field - looking for textfield with label 'Quantity'
        const quantityField = this.field?.componentFields?.find(
          (subField) =>
            subField.type === "textfield" && subField.label === "Quantity"
        );

        // If quantity field exists and has a value, multiply points by quantity
        if (quantityField) {
          const quantityId =
            quantityField.idx || `field-${quantityField.label}`;
          const quantity = parseFloat(row[quantityId]) || 1;
          points *= quantity;
        }

        return points.toString();
      }

      const value = row[fieldId];
      if (!value || !field.presets || field.presets.presetsType !== "dynamic") {
        return "0";
      }

      // Try to find the selected item in the cached dynamic options
      const selectedItem = this.findSelectedDynamicItem(field, value);
      if (selectedItem && selectedItem.points !== undefined) {
        let points = parseFloat(selectedItem.points) || 0;

        // Find quantity field - looking for textfield with label 'Quantity'
        const quantityField = this.field?.componentFields?.find(
          (subField) =>
            subField.type === "textfield" && subField.label === "Quantity"
        );

        // If quantity field exists and has a value, multiply points by quantity
        if (quantityField) {
          const quantityId =
            quantityField.idx || `field-${quantityField.label}`;
          const quantity = parseFloat(row[quantityId]) || 1;
          points *= quantity;
        }

        return points.toString();
      }

      return "0";
    },

    // Add method to get field from points header
    getFieldFromPointsHeader(header) {
      if (!header.value.endsWith("_points")) return null;

      const baseFieldId = header.value.replace("_points", "");
      const field = this.field?.componentFields?.find(
        (field) => (field.idx || `field-${field.label}`) === baseFieldId
      );
      return field;
    },
  },
  watch: {
    open(newVal) {
      if (newVal) {
        // Reset to first step when dialog opens
        this.currentStep = 1;
        // Load preset records when dialog opens
        this.loadAllPresetRecords();
      }
    },
  },
};
</script>

<style scoped>
.form-results-container {
  max-height: calc(80vh - 150px); /* Account for header, actions, and padding */
  overflow-y: auto;
  overflow-x: hidden;
}

.raw-data {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  white-space: pre-wrap;
  max-height: 300px;
  overflow-y: auto;
  font-size: 12px;
  font-family: monospace;
}

/* Remove shadow from stepper */
.v-stepper__header {
  box-shadow: none !important;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>

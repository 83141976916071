<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="admin">
        <v-card-title class="page-heading">
          {{ editingDomain.domain ? "Edit " : "Add New " }}
          {{ formTitle }}
        </v-card-title>

        <v-card-text class="px-16">
          <v-row no-gutters>
            <v-text-field
              v-model="domain"
              label="Domain"
              :rules="[domainRules]"
              placeholder="example.com"
              hint="Enter domain without @ symbol (e.g. example.com)"
              :persistent-hint="!isValidDomain"
              @input="validateDomain"
            ></v-text-field>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert text color="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel </v-btn>
          <v-btn
            @click="handleSave()"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            elevation="0"
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  created() {
    const { domain } = this.editingDomain;
    this.domain = domain;
  },
  mounted() {},
  props: {
    editingDomain: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dictionary: {
      custom: {
        domain: {
          required: () => "Domain is required.",
        },
      },
    },
    formTitle: "Domain",
    errorMessage: "",
    domain: "",
  }),

  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    isValidDomain() {
      return this.domain && this.domainRules(this.domain) === true;
    },
    domainRules() {
      return (value) => {
        if (!value) return "Domain is required";
        if (value.includes("@"))
          return "Do not include @ symbol - enter domain only (e.g. example.com)";
        if (!/^[a-zA-Z0-9][a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}$/.test(value)) {
          return "Please enter a valid domain (e.g. example.com)";
        }
        return true;
      };
    },
  },

  methods: {
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.errorMessage = "";
      this.domain = "";
      this.$store.dispatch("setLoading", false);
    },
    validateDomain(value) {
      // Remove @ symbol if user enters it
      if (value && value.includes("@")) {
        this.domain = value.replace("@", "");
      }
    },
    handleSave() {
      // Validate domain format
      const validationResult = this.domainRules(this.domain);
      if (validationResult !== true) {
        this.errorMessage = validationResult;
        return;
      }

      this.$store.dispatch("setLoading", true);

      const payload = {
        domain: this.domain.toLowerCase(), // Ensure consistent lowercase storage
        oldDomain: this.editingDomain.domain,
      };

      const action = this.editingDomain.domain ? "updateDomain" : "addDomain";

      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.$store.dispatch("setLoading", false);
          this.handleClose();
        })
        .catch((e) => {
          this.errorMessage = e;
          this.$store.dispatch("setLoading", false);
        });
    },
  },
};
</script>

<style></style>

<template>
  <div class="component-renderer">
    <!-- Text field -->
    <v-text-field
      v-if="field.type === 'textfield'"
      ref="field"
      :label="field.label"
      :placeholder="field.placeholder"
      :prepend-icon="field.prependIcon"
      :clearable="field.clearable"
      :required="field.required"
      outlined
      dense
      background-color="white"
      :value="value"
      @input="updateValue"
      :error="!!validationError || (showValidation && hasError)"
      :error-messages="validationError || (showValidation ? errorMessages : [])"
      :rules="showValidation ? validationRules : undefined"
      hide-details="auto"
    ></v-text-field>

    <!-- Dropdown / Autocomplete -->
    <v-autocomplete
      v-if="field.type === 'dropdown'"
      :items="getFieldOptions"
      :label="field.label"
      :placeholder="field.placeholder"
      :clearable="field.clearable"
      :required="field.required"
      outlined
      dense
      background-color="white"
      :item-text="getItemText"
      :item-value="getItemValue"
      :value="value"
      @input="updateValue"
      :error="!!validationError || (showValidation && hasError)"
      :error-messages="validationError || (showValidation ? errorMessages : [])"
      :rules="showValidation ? validationRules : undefined"
      hide-details="auto"
    >
      <template v-slot:item="{ item }">
        <v-row no-gutters class="pa-2" v-if="usesDynamicPresets">
          <v-col>
            <div
              v-if="displayValueEnabled('identifier') && item.identifier"
              class="option-identifier"
            >
              {{ item.identifier }}
            </div>
            <div
              v-if="displayValueEnabled('title') && item.title"
              class="option-title"
            >
              {{ item.title }}
            </div>
            <div
              v-if="displayValueEnabled('description') && item.description"
              class="option-description"
            >
              {{ item.description }}
            </div>
            <div
              v-if="displayValueEnabled('points') && item.points"
              class="option-points"
            >
              {{ item.points }} points
            </div>
          </v-col>
        </v-row>
        <div v-else>{{ item.value || item }}</div>
      </template>
    </v-autocomplete>

    <!-- Checkboxes -->
    <div v-if="field.type === 'checkboxes'">
      <div class="field-label mb-2">{{ field.label }}</div>
      <div
        v-for="(option, index) in getFieldOptions"
        :key="`checkbox-${index}`"
      >
        <v-checkbox
          :label="getOptionLabel(option)"
          :value="getOptionValue(option)"
          :input-value="isOptionSelected(option)"
          @change="toggleOption(option)"
          hide-details
          class="mt-1"
        ></v-checkbox>
      </div>
    </div>

    <!-- File Dropzone Preview Mode-->
    <div v-if="isPreview">
      <preview-file-dropzone
        v-if="field.type === 'dropzone'"
        :field="field"
        :value="field.type === 'dropzone' && !Array.isArray(value) ? [] : value"
        :pageIdx="pageIdx"
        :sectionIdx="sectionIdx"
        :columnIdx="columnIdx"
        @input="updateValue"
      ></preview-file-dropzone>
    </div>

    <!-- File Dropzone -->
    <div v-else>
      <file-dropzone
        v-if="field.type === 'dropzone'"
        :field="field"
        :value="field.type === 'dropzone' && !Array.isArray(value) ? [] : value"
        :pageIdx="pageIdx"
        :sectionIdx="sectionIdx"
        :columnIdx="columnIdx"
        :entryId="entryId"
        @input="handleFileDropzoneInput"
      ></file-dropzone>
    </div>

    <!-- Add more field types as needed for tables -->
  </div>
</template>

<script>
import PreviewFileDropzone from "./PreviewModeFileDropzone.vue";
import FileDropzone from "../Layout/FileDropzone.vue";

export default {
  components: {
    PreviewFileDropzone,
    FileDropzone,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null,
    },
    showValidation: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: String,
      default: null,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    pageIdx: {
      type: Number,
      default: 0,
    },
    sectionIdx: {
      type: Number,
      default: 0,
    },
    columnIdx: {
      type: Number,
      default: 0,
    },
    entryId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dynamicOptions: [],
      isLoadingOptions: false,
      localCheckboxValues: [],
    };
  },
  computed: {
    usesDynamicPresets() {
      return (
        this.field &&
        this.field.presets &&
        this.field.presets.presetsType === "dynamic"
      );
    },

    getFieldOptions() {
      if (!this.field || !this.field.presets) return [];

      if (this.field.presets.presetsType === "static") {
        return this.field.presets.staticOptions || [];
      } else if (this.field.presets.presetsType === "dynamic") {
        return this.dynamicOptions;
      }

      return [];
    },

    getItemText() {
      return this.usesDynamicPresets ? "displayText" : "value";
    },

    getItemValue() {
      return this.usesDynamicPresets ? "identifier" : "value";
    },

    selectedPresetGroup() {
      return (
        this.field &&
        this.field.presets &&
        this.field.presets.selectedPresetsGroup
      );
    },

    hasError() {
      // Make sure field is properly defined before checking for errors
      if (!this.field) {
        return false;
      }
      return this.errorMessages.length > 0;
    },
    errorMessages() {
      // Early return if field is not defined
      if (!this.field) {
        return [];
      }

      const messages = [];

      // Check required validation
      if (this.field.required && !this.value) {
        messages.push(`${this.field.label || "Field"} is required`);
      }

      // Make sure validation exists before proceeding
      if (!this.field.validation) {
        return messages;
      }

      // Check static validation
      if (
        this.field.validation.validationFormDataType === "static" &&
        this.field.validation.staticValidationOptions?.length > 0 &&
        this.value
      ) {
        const isValid = this.field.validation.staticValidationOptions.some(
          (option) => option.value === this.value
        );
        if (!isValid) {
          messages.push(
            `${
              this.field.label || "Field"
            } must match one of the allowed options`
          );
        }
      }

      // Check dynamic validation
      if (
        this.field.validation.validationFormDataType === "dynamic" &&
        this.field.validation.selectedFormValidationDataSet &&
        this.value
      ) {
        const dataSetId = this.field.validation.selectedFormValidationDataSet;
        // Check if state.presetRecords exists before accessing it
        const presetRecords = this.$store.state.presetRecords || {};
        const records = presetRecords[dataSetId];

        if (!records || !Array.isArray(records)) {
          messages.push(`${this.field.label || "Field"} is invalid`);
        } else {
          const isValid = records.some(
            (record) => record.identifier === this.value
          );
          if (!isValid) {
            messages.push(`${this.field.label || "Field"} is invalid`);
          }
        }
      }

      return messages;
    },
    validationRules() {
      // Early return if field is not defined
      if (!this.field) {
        return [];
      }

      const rules = [];

      // Add required validation if field is required
      if (this.field.required) {
        rules.push((v) => !!v || `${this.field.label || "Field"} is required`);
      }

      // Make sure validation exists before proceeding
      if (!this.field.validation) {
        return rules;
      }

      // Add validation against static options if configured
      if (
        this.field.validation.validationFormDataType === "static" &&
        this.field.validation.staticValidationOptions?.length > 0
      ) {
        rules.push(this.validateStaticOptions);
      }

      // For dynamic validation, use a simplified approach that doesn't require async
      if (
        this.field.validation.validationFormDataType === "dynamic" &&
        this.field.validation.selectedFormValidationDataSet
      ) {
        rules.push(this.validateDynamicOptions);
      }

      return rules;
    },
    getErrorMessages() {
      // Check for direct validation error first
      if (this.validationError) {
        return [this.validationError];
      }
      // Otherwise use the computed error messages
      return this.errorMessages;
    },
  },
  created() {
    // Log field for debugging
    //console.log("[ComponentRenderer] Created with field:", this.field);
    if (this.field && this.field.type === "dropzone") {
      //  console.log("[ComponentRenderer] Found dropzone field:", this.field);
    }

    // Load dynamic options if needed
    if (this.usesDynamicPresets && this.selectedPresetGroup) {
      this.loadDynamicOptions();
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (this.field && this.field.type === "checkboxes") {
          this.localCheckboxValues = Array.isArray(newVal) ? [...newVal] : [];
        }
      },
      immediate: true,
    },
    selectedPresetGroup: {
      handler(newVal) {
        if (newVal && this.usesDynamicPresets) {
          this.loadDynamicOptions();
        }
      },
      immediate: true,
    },
    "field.presets.presetsType": {
      handler() {
        this.loadDynamicOptions();
      },
    },
    "field.presets.selectedPresetsGroup": {
      handler(newVal) {
        if (newVal && this.usesDynamicPresets) {
          this.loadDynamicOptions();
        }
      },
    },
  },
  methods: {
    updateValue(val) {
      // Let the parent component know the value has changed
      console.log(
        `[ComponentRenderer] Value updated for field ${
          this.field.label || "unnamed"
        }:`,
        val
      );
      this.$emit("input", val);

      // Also emit an event to clear validation errors for this field
      this.$emit("clear-validation");
    },

    // New method to specifically handle FileDropzone input events
    handleFileDropzoneInput(files) {
      console.log(
        `[ComponentRenderer] FileDropzone value updated for field ${
          this.field.label || "unnamed"
        }:`,
        files
      );
      // Make sure we're passing an array
      const fileArray = Array.isArray(files) ? files : [];
      // Pass the value up to the parent
      this.$emit("input", fileArray);
    },

    async loadDynamicOptions() {
      // Only load if using dynamic presets
      if (!this.usesDynamicPresets || !this.selectedPresetGroup) {
        this.dynamicOptions = [];
        return;
      }

      if (this.isLoadingOptions) return;

      this.isLoadingOptions = true;

      try {
        const records = await this.$store.dispatch(
          "getPresetRecords",
          this.selectedPresetGroup
        );

        if (Array.isArray(records) && records.length > 0) {
          this.dynamicOptions = records
            .filter((item) => item.status === "Active")
            .map((item) => ({
              ...item,
              displayText: this.generateDisplayText(item),
            }));
        } else {
          this.dynamicOptions = [];
        }
      } catch (error) {
        console.error("Error loading preset options:", error);
        this.dynamicOptions = [];
      } finally {
        this.isLoadingOptions = false;
      }
    },

    displayValueEnabled(field) {
      return (
        this.field.presets &&
        this.field.presets.valuesToDisplay &&
        this.field.presets.valuesToDisplay.includes(field)
      );
    },

    getOptionValue(option) {
      if (typeof option === "object") {
        return this.usesDynamicPresets ? option.identifier : option.value;
      }
      return option;
    },

    getOptionLabel(option) {
      if (typeof option === "object") {
        if (this.usesDynamicPresets) {
          // Build a label from the fields that should be displayed
          const parts = [];

          if (this.displayValueEnabled("identifier") && option.identifier) {
            parts.push(option.identifier);
          }

          if (this.displayValueEnabled("title") && option.title) {
            parts.push(option.title);
          }

          if (this.displayValueEnabled("description") && option.description) {
            parts.push(option.description);
          }

          if (this.displayValueEnabled("points") && option.points) {
            parts.push(`${option.points} points`);
          }

          return parts.join(" • ");
        }

        return option.value;
      }
      return option;
    },

    isOptionSelected(option) {
      const optionValue = this.getOptionValue(option);
      return Array.isArray(this.value) && this.value.includes(optionValue);
    },

    toggleOption(option) {
      const optionValue = this.getOptionValue(option);

      // Create a new array from existing value or initialize empty array
      let newValue = Array.isArray(this.value) ? [...this.value] : [];

      // Toggle the selection
      if (newValue.includes(optionValue)) {
        newValue = newValue.filter((v) => v !== optionValue);
      } else {
        newValue.push(optionValue);
      }

      // Update the value
      this.updateValue(newValue);
    },

    generateDisplayText(item) {
      const displayValues = this.field.presets?.valuesToDisplay || [];

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean);

      return displayParts.join(" • ");
    },

    validateStaticOptions(v) {
      if (!v) return true; // Skip validation if no value and not required
      if (
        !this.field ||
        !this.field.validation ||
        !this.field.validation.staticValidationOptions
      ) {
        return true; // Skip validation if missing required properties
      }

      const isValid = this.field.validation.staticValidationOptions.some(
        (option) => option.value === v
      );

      return (
        isValid ||
        `${this.field.label || "Field"} must match one of the allowed options`
      );
    },

    validateDynamicOptions(v) {
      if (!v) return true; // Skip validation if no value and not required

      // If we're missing required objects, return valid to avoid errors
      if (
        !this.field ||
        !this.field.validation ||
        !this.field.validation.selectedFormValidationDataSet
      ) {
        return true;
      }

      try {
        const dataSetId = this.field.validation.selectedFormValidationDataSet;
        // Check if state.presetRecords exists before accessing it
        const presetRecords = this.$store.state.presetRecords || {};
        const records = presetRecords[dataSetId];

        // If no records available, treat as valid to avoid breaking the UI
        // Actual validation will happen during form submission
        if (!records || !Array.isArray(records)) {
          return true;
        }

        const isValid = records.some((record) => record.identifier === v);
        return isValid || `${this.field.label || "Field"} is invalid`;
      } catch (error) {
        console.error(
          `Error validating field ${this.field.label || "Field"}:`,
          error
        );
        return true; // Return valid to avoid breaking the UI, error is logged
      }
    },
  },
};
</script>

<style scoped>
.component-renderer {
  width: 100%;
}

.option-identifier {
  font-family: "Figtree", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #494949de;
  line-height: 1;
}

.option-title {
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000000de;
  line-height: 1.3;
}

.option-description {
  font-family: "Figtree", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #a1a1a1;
  line-height: 1;
}

.option-points {
  font-family: "Figtree", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #a1a1a1;
  line-height: 1;
}
</style>

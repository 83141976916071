<template>
  <div>
    <v-navigation-drawer
      app
      permanant
      width="100"
      v-model="isRailOpen"
      color="gradient-background-reversed"
      dark
      floating
      class="drawer"
    >
      <v-row no-gutters justify="center" align="center">
        <img
          :src="require('@/assets/IncentableStar.png')"
          height="45px"
          contain
          class="logo-margin"
        />
      </v-row>
      <v-list dense nav class="nav-top-margin">
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            class="d-flex flex-column justify-center align-center pt-4 pa-0"
            @mouseenter="startShowSubmenu(item)"
            @mouseleave="cancelShowSubmenu"
            @click.stop="
              navigate(item);
              toggleSubmenu(item);
            "
            link
          >
            <v-icon class="material-symbols-outlined" size="32">
              {{ item.icon }}
            </v-icon>
            <span class="v-btn mb-n5">{{ item.label }}</span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Secondary Navigation Drawer for Submenus -->
    <v-navigation-drawer
      app
      temporary
      width="250"
      v-model="isSubmenuDrawerOpen"
      color="gradient-background-reversed-submenu"
      dark
      class="submenu-drawer"
      overlay-opacity="0"
    >
      <v-list dense nav>
        <transition-group
          name="fade"
          tag="div"
          style="position: static !important"
        >
          <v-list-item
            v-for="(subItem, index) in submenuItems"
            :key="`${subItem.label}-${index}`"
            @click="navigate(subItem)"
            link
          >
            <v-icon class="material-symbols-outlined" size="28">{{
              subItem.icon
            }}</v-icon>
            <span class="sub-menu-label">{{ subItem.label }}</span>
            <div v-show="bookmarkFeature">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="material-symbols-outlined bookmark-icon"
                    size="19"
                    v-bind="attrs"
                    v-on="on"
                  >
                    arrow_circle_up
                  </v-icon>
                </template>
                <span>Upgrade to activate this app</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="material-symbols-outlined"
                    size="19"
                    v-bind="attrs"
                    v-on="on"
                  >
                    bookmark
                  </v-icon>
                </template>
                <span>Unbookmark this app</span>
              </v-tooltip>
            </div>
          </v-list-item>
        </transition-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { ROLES_DICT } from "@/constants/roles";
import * as utils from "@/utils";
export default {
  data() {
    const { programId } = this.$store.getters;
    return {
      bookmarkFeature: false,
      isSubmenuDrawerOpen: false,
      selectedItem: 0,
      submenuItems: [],
      submenuTimeout: null,
      isRailOpen: true,
      activeSubmenuItem: null,
      menuItems: [
        {
          icon: "dashboard",
          label: "Dashboard",
          submenus: [],
          link: `/dashboard/${programId}`,
          requiredAuth: ROLES_DICT.observer,
        },
        {
          icon: "face",
          label: "Members",
          link: "",
          requiredAuth: ROLES_DICT.observer,
          submenus: [
            {
              label: "Members",
              icon: "face",
              link: `/members/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Companies",
              icon: "domain",
              link: `/companies/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Communicate",
              icon: "send",
              link: `/communicate/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
          ],
        },
        {
          icon: "featured_seasonal_and_gifts",
          label: "Rewards",
          link: "",
          requiredAuth: ROLES_DICT.observer,
          submenus: [
            {
              label: "Rewards",
              icon: "featured_seasonal_and_gifts",
              link: `/rewards/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Orders",
              icon: "shopping_cart",
              link: `/orders/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Points Import",
              icon: "stars",
              link: `/points/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
          ],
        },
        {
          icon: "database",
          label: "Data",
          link: "",
          requiredAuth: ROLES_DICT.observer,
          submenus: [
            {
              label: "Data Buckets",
              icon: "database",
              link: `/databuckets/${programId}`,
              requiredAuth: ROLES_DICT.admin,
            },
          ],
        },
        {
          icon: "apps",
          label: "Apps",
          link: "",
          requiredAuth: ROLES_DICT.observer,
          submenus: [
            {
              label: "Leaderboards",
              icon: "trophy",
              link: `/leaderboards/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Target Trackers",
              icon: "target",
              link: `/tables/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Awards",
              icon: "social_leaderboard",
              link: `/awards/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Tiers",
              icon: "diamond",
              link: `/tiers/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Claims",
              icon: "list_alt_check",
              link: `/offers/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Quizzes",
              icon: "quiz",
              link: `/quizzes/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
          ],
        },
        {
          icon: "design_services",
          label: "Content",
          link: "",
          requiredAuth: ROLES_DICT.observer,
          submenus: [
            {
              label: "Articles",
              icon: "newsmode",
              link: `/blog/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Pages",
              icon: "web",
              link: `/pages/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Menus",
              icon: "menu",
              link: `/menus/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
            {
              label: "Designer",
              icon: "palette",
              link: `/design/${programId}`,
              requiredAuth: ROLES_DICT.editor,
            },
          ],
        },
        {
          icon: "tune",
          label: "Settings",
          link: "",
          requiredAuth: ROLES_DICT.observer,
          submenus: [
            {
              label: "General Settings",
              icon: "settings",
              link: `/settings/${programId}`,
              requiredAuth: ROLES_DICT.admin,
            },
            {
              label: "Translations",
              icon: "translate",
              link: `/translate/${programId}`,
              requiredAuth: ROLES_DICT.admin,
            },
          ],
        },
      ],
    };
  },
  computed: {
    trial() {
      const starts = this.currentProgram.trial.starts.seconds;
      const ends = this.currentProgram.trial.ends.seconds;
      const date = new Date();
      const now = Math.floor(date.getTime() / 1000);
      const isTrial = now > starts && now < ends;
      return isTrial;
    },
    expired() {
      if (this.trial) {
        return false;
      }
      return this.currentSubscription.status === "active" ||
        this.isIncentableAdmin
        ? false
        : true;
    },
    currentUserRole() {
      return this.$store.getters.currentUserRole;
    },
  },
  methods: {
    startShowSubmenu(item) {
      this.cancelShowSubmenu();
      this.submenuTimeout = setTimeout(() => {
        if (item.submenus.length > 0) {
          this.submenuItems = item.submenus;
          this.isSubmenuDrawerOpen = true;
          this.activeSubmenuItem = item;
        } else {
          this.isSubmenuDrawerOpen = false;
          this.submenuItems = [];
          this.activeSubmenuItem = null;
        }
      }, 20);
    },
    cancelShowSubmenu() {
      clearTimeout(this.submenuTimeout);
      this.submenuTimeout = null;
      // Optionally, you can uncomment these if you want to close on mouse leave
      // this.isSubmenuDrawerOpen = false;
      // this.submenuItems = [];
    },

    toggleSubmenu(item) {
      // Clear any existing timeout
      this.cancelShowSubmenu();

      // If the item has no submenus, close the drawer and exit
      if (!item.submenus || item.submenus.length === 0) {
        this.isSubmenuDrawerOpen = false;
        this.submenuItems = [];
        this.activeSubmenuItem = null;
        return;
      }

      // If clicking the same item that's currently active
      if (this.activeSubmenuItem === item) {
        // Close the drawer
        this.isSubmenuDrawerOpen = false;
        this.submenuItems = [];
        this.activeSubmenuItem = null;
      } else {
        // Open the submenu for this item
        this.submenuItems = item.submenus;
        this.isSubmenuDrawerOpen = true;
        this.activeSubmenuItem = item;
      }
    },
    navigate(item) {
      const permitted = utils.isAuthReqFulfilled(
        item.requiredAuth,
        this.currentUserRole
      );
      if (permitted) {
        this.$router.push(item.link).catch(() => {});
      } else {
        this.$store.dispatch(
          "setSnackbar",
          "Sorry, your role does not have access to that feature"
        );
      }
    },
  },
  beforeDestroy() {
    this.cancelShowSubmenu();
  },
};
</script>

<style scoped>
.v-list-item-icon {
  display: flex;
  justify-content: center; /* Center icon horizontally */
  align-items: center; /* Center icon vertically */
  margin-bottom: 2px;
}
.v-icon.large {
  font-size: 24px; /* Icon size */
}
.v-list-item-title {
  font-size: 0.75rem; /* Label font size */
}
.menu-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

.nav-top-margin {
  margin-top: 10px !important;
}
.menu-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.sub-menu-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  margin-left: 12px !important;
}

.nav-top-margin {
  margin-top: 6px !important;
}
.logo-margin {
  margin-top: 22px !important;
  margin-bottom: 8px !important;
}
.drawer {
  z-index: 10 !important; /* Ensures the submenu stays layered under the primary drawer */
}
.submenu-drawer {
  margin-left: 100px !important;
  position: fixed !important;
  top: 90px !important;
  height: calc(100vh - 90px) !important;
  width: 250px !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  z-index: 9 !important; /* Ensures the submenu stays layered under the primary drawer */
}
.parent-container {
  padding-top: 90px; /* Adjust based on your toolbar height */
}
.fade-enter-active {
  transition: opacity 0.4s ease 0.2s; /* Delay the fade-in by 0.3s to allow fade-out to finish */
}
.fade-leave-active {
  transition: opacity 0.2s ease; /* Fade out smoothly with no delay */
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-slide-x-transition {
  transition-duration: 6s !important; /* Adjust the duration as needed */
}
.bookmark-icon {
  margin-left: auto;
}
</style>

/* eslint-disable no-console */
<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <div>
        <v-card-title class="page-heading"> Are You Sure? </v-card-title>

        <v-card-text class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ domain }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <v-card-text class="px-16">
        <v-row dense>
          <v-col v-if="errorMessage">
            <v-alert type="error" :value="true">
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleClose()">Cancel</v-btn>
        <v-btn
          class="white--text"
          color="red"
          @click="handleDelete()"
          :loading="loading"
          :disabled="!allowDeleteAdmin"
          elevation="0"
          >Confirm Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    // Component settings unique to this component
    errorMessage: "",
  }),
  created() {
    const { domain } = this.deletingDomain;
    this.domain = domain;
  },
  props: {
    deletingDomain: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    // Functions common to all components
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    allowDeleteAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
  },

  methods: {
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.errorMessage = "";
      this.$store.dispatch("setLoading", false);
    },
    handleDelete() {
      // Delete record
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("deleteDomain", this.deletingDomain.domain)
        .then(() => {
          this.handleClose();
          this.$store.dispatch("setLoading", false);
        })
        .catch((e) => {
          this.errorMessage = e;
          this.$store.dispatch("setLoading", false);
        });
    },
  },
};
</script>

<style></style>

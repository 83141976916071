<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-sheet :class="{ 'on-hover': hover || settingsLock }" elevation="0">
        <!-- textfield -->
        <v-col v-if="localField.type === 'textfield'">
          <v-text-field
            outlined
            dense
            :label="field.label"
            :clearable="field.clearable"
            background-color="white"
            class="mb-n6"
            :placeholder="field.placeholder"
            :prepend-icon="field.prependIcon"
          ></v-text-field>
        </v-col>

        <!-- textarea -->
        <v-col v-if="localField.type === 'textarea'">
          <v-textarea
            outlined
            dense
            :label="field.label"
            :clearable="field.clearable"
            background-color="white"
            class="mb-n6"
            :placeholder="field.placeholder"
          ></v-textarea>
        </v-col>

        <!-- dropzone preview mode -->

        <v-col v-if="localField.type === 'dropzone'">
          <preview-mode-file-dropzone
            :field="field"
            :pageIdx="pageIdx"
            :sectionIdx="sectionIdx"
            :columnIdx="columnIdx"
          >
          </preview-mode-file-dropzone>
        </v-col>

        <!-- template-table -->
        <v-col v-if="localField.type === 'table'">
          <table-preview
            :field="field"
            :pageIdx="pageIdx"
            :sectionIdx="sectionIdx"
            :columnIdx="columnIdx"
          >
          </table-preview>
        </v-col>

        <!-- template-facebook -->
        <v-col v-if="localField.type === 'facebook'">
          <facebook-form
            :field="field"
            :pageIdx="pageIdx"
            :sectionIdx="sectionIdx"
            :columnIdx="columnIdx"
          >
          </facebook-form>
        </v-col>

        <!-- dropdown -->
        <v-col v-if="localField.type === 'dropdown'">
          <v-autocomplete
            :items="formOptionsListData || []"
            :key="JSON.stringify(formOptionsListData?.length || 0)"
            outlined
            dense
            background-color="white"
            :item-text="
              field?.presets?.presetsType === 'dynamic'
                ? 'displayText'
                : 'value'
            "
            :item-value="
              field?.presets?.presetsType === 'dynamic' ? 'identifier' : 'value'
            "
            :label="field?.label"
            :clearable="field?.clearable"
            class="mb-n6"
            :placeholder="field?.placeholder"
          >
            <template v-slot:item="data">
              <v-row
                v-if="field?.presets?.presetsType === 'dynamic'"
                no-gutters
                class="mb-2 mt-2"
              >
                <v-col>
                  <v-row
                    v-if="displayValue('identifier') && data.item?.identifier"
                    no-gutters
                  >
                    <span class="form-option-medium">{{
                      data.item.identifier
                    }}</span>
                  </v-row>
                  <v-row
                    v-if="displayValue('title') && data.item?.title"
                    no-gutters
                  >
                    <span class="form-option-large">{{ data.item.title }}</span>
                  </v-row>
                  <v-row
                    v-if="displayValue('description') && data.item?.description"
                    no-gutters
                  >
                    <span class="form-option-small">{{
                      data.item.description
                    }}</span>
                  </v-row>
                  <v-row
                    v-if="displayValue('points') && data.item?.points"
                    no-gutters
                  >
                    <span class="form-option-small"
                      >{{ data.item.points }} points</span
                    >
                  </v-row>
                </v-col>
              </v-row>
              <div v-else>{{ data.item?.value }}</div>
            </template>
          </v-autocomplete>
        </v-col>

        <!-- radio -->
        <v-col v-if="localField.type === 'radio'">
          <v-radio-group
            :key="JSON.stringify(formOptionsListData?.length || 0)"
            :label="field.label"
            class="radio-group-margins"
          >
            <v-skeleton-loader
              v-if="
                formOptionsListData.length === 0 &&
                field?.presets?.presetsType === 'dynamic'
              "
              type="list-item-three-line"
              class="mt-2"
            ></v-skeleton-loader>
            <v-radio
              v-for="(formOption, key) in formOptionsList"
              :key="`radio-${key}-${formOptionsListKey}`"
              :value="key"
            >
              <template v-slot:label>
                <v-row no-gutters>
                  <v-col>
                    <v-row
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('identifier') &&
                        formOption.identifier
                      "
                      no-gutters
                    >
                      <span
                        :class="
                          displayValue('title')
                            ? 'form-option-medium'
                            : 'form-option-large'
                        "
                        >{{ formOption.identifier }}</span
                      >
                    </v-row>
                    <v-row v-if="formOption.value" no-gutters>
                      <span class="form-option-large">{{
                        formOption.value
                      }}</span>
                    </v-row>
                    <v-row
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('title')
                      "
                      no-gutters
                    >
                      <span class="form-option-large">{{
                        formOption.title
                      }}</span>
                    </v-row>
                    <v-row
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('description') &&
                        formOption.description
                      "
                      no-gutters
                    >
                      <span class="form-option-small">{{
                        formOption.description
                      }}</span>
                    </v-row>
                    <v-row
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('points') &&
                        formOption.points
                      "
                      no-gutters
                    >
                      <span class="form-option-small"
                        >{{ formOption.points }} points</span
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>

        <!-- checkboxes -->
        <v-col v-if="localField.type === 'checkboxes'">
          <div class="checkbox-group-margins">
            <v-row class="mt-n3 mb-n2 custom-label" no-gutters>
              {{ field.label }}
            </v-row>
            <v-skeleton-loader
              v-if="
                formOptionsListData.length === 0 &&
                field?.presets?.presetsType === 'dynamic'
              "
              type="list-item-three-line"
              class="mt-2"
            ></v-skeleton-loader>
            <v-checkbox
              v-for="(formOption, key) in formOptionsList"
              :key="`checkbox-${key}-${formOptionsListKey}`"
              :value="key"
              class="checkbox-margins"
            >
              <template v-slot:label>
                <v-row no-gutters align="center">
                  <v-col>
                    <v-row
                      align="center"
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('identifier') &&
                        formOption.identifier
                      "
                      no-gutters
                    >
                      <span
                        :class="
                          displayValue('title')
                            ? 'form-option-medium'
                            : 'form-option-large'
                        "
                        >{{ formOption.identifier }}</span
                      >
                    </v-row>
                    <v-row v-if="formOption.value" no-gutters align="center">
                      <span class="form-option-large">{{
                        formOption.value
                      }}</span>
                    </v-row>
                    <v-row
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('title') &&
                        formOption.title
                      "
                      no-gutters
                      align="center"
                    >
                      <span class="form-option-large">{{
                        formOption.title
                      }}</span>
                    </v-row>
                    <v-row
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('description') &&
                        formOption.description
                      "
                      no-gutters
                      align="center"
                    >
                      <span class="form-option-small">{{
                        formOption.description
                      }}</span>
                    </v-row>
                    <v-row
                      v-if="
                        field?.presets?.presetsType === 'dynamic' &&
                        displayValue('points') &&
                        formOption.points
                      "
                      no-gutters
                      align="center"
                    >
                      <span class="form-option-small"
                        >{{ formOption.points }} points</span
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
          </div>
        </v-col>

        <!-- date -->
        <v-col v-if="localField.type === 'date-picker'">
          <v-menu
            v-model="menu"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="field.label"
                prepend-inner-icon="calendar_month"
                v-bind="attrs"
                v-on="on"
                background-color="white"
                outlined
                dense
                :clearable="field.clearable"
                class="mb-n6"
                :placeholder="field.placeholder"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date"></v-date-picker>
          </v-menu>
        </v-col>

        <!-- time -->
        <v-col v-if="localField.type === 'time-picker'">
          <v-menu
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            v-model="menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :label="field.label"
                prepend-inner-icon="calendar_month"
                v-bind="attrs"
                v-on="on"
                background-color="white"
                outlined
                dense
                :clearable="field.clearable"
                class="mb-n6"
                :placeholder="field.placeholder"
              ></v-text-field>
            </template>
            <v-time-picker v-model="time"></v-time-picker>
          </v-menu>
        </v-col>

        <!-- switch -->
        <v-col v-if="localField.type === 'switch'">
          <v-switch
            outlined
            dense
            :label="field.label"
            :clearable="field.clearable"
            background-color="white"
            class="mb-n2 mt-1"
          ></v-switch>
        </v-col>

        <!-- slider -->
        <v-col v-if="localField.type === 'slider'">
          <v-slider
            outlined
            dense
            :label="field.label"
            :thumb-label="field.thumbLabel"
            :min="field.min"
            :max="field.max"
            :clearable="field.clearable"
            class="mb-n3 mt-1"
          ></v-slider>
        </v-col>

        <!-- title -->
        <v-col v-if="localField.type === 'title'" class="ma-0 pa-0">
          <view-text :settings="field.styles"></view-text>
        </v-col>

        <!-- subtitle -->
        <v-col v-if="localField.type === 'subtitle'" class="ma-0 pa-0">
          <view-text :settings="field.styles"></view-text>
        </v-col>

        <!-- note -->
        <v-col v-if="localField.type === 'note'" class="ma-0 pa-0">
          <view-text :settings="field.styles"></view-text>
        </v-col>

        <v-fade-transition>
          <v-sheet v-if="hover || settingsLock" class="hover-actions">
            <v-icon class="action-icon">drag_indicator</v-icon>

            <FieldSettings
              @onSettingsEdit="handleSettingsLock"
              @onUpdateSettings="handleUpdateSettings"
              :field="field"
            ></FieldSettings>

            <v-icon class="action-icon" @click="handleDelete(field)"
              >delete_forever</v-icon
            >
          </v-sheet>
        </v-fade-transition>
      </v-sheet>
    </v-hover>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import FieldSettings from "./FieldSettings.vue";
import ViewText from "../../../../Design/View/ViewComponents/Shared/Text.vue";

export default {
  name: "FormField",
  props: {
    field: {
      type: Object,
      required: true,
    },
    pageIdx: {
      type: Number,
      required: true,
    },
    sectionIdx: {
      type: Number,
      required: true,
    },
    columnIdx: {
      type: Number,
      required: true,
    },
    fieldIndex: {
      type: Number,
      required: true,
    },
    isTableField: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TablePreview: () => import("./TablePreview.vue"),
    FacebookForm: () => import("./FacebookForm.vue"),
    PreviewModeFileDropzone: () =>
      import("../Preview/PreviewModeFileDropzone.vue"),
    FieldSettings,
    ViewText,
  },
  data: () => ({
    menu: false,
    date: null,
    time: null,
    settingsLock: false,
    presetData: [],
    validationData: [],
    formOptionsListData: [], // Raw data source
    localField: null,
    formOptionsListKey: Date.now().toString(), // Unique key for forcing updates
    isLoading: false,
    loadRetryCount: 0,
  }),
  watch: {
    "field.presets": {
      handler() {
        this.fetchPresetData();
      },
      deep: true,
    },

    "field.presets.presetsType": "fetchPresetData",
    "field.presets.selectedFormPresetDataSet": "fetchPresetData",
    field: {
      handler(newVal) {
        // Ensure the field has an idx
        if (newVal && !newVal.idx) {
          // Create a deep copy before modifying
          const updatedField = JSON.parse(JSON.stringify(newVal));
          updatedField.idx = this.generateUniqueId();
          this.$emit("updateField", updatedField);
        }

        // For table fields, ensure all component fields have idx
        if (
          newVal &&
          newVal.type === "table" &&
          Array.isArray(newVal.componentFields)
        ) {
          const updatedField = JSON.parse(JSON.stringify(newVal));
          let hasChanges = false;

          updatedField.componentFields = updatedField.componentFields.map(
            (compField) => {
              if (!compField.idx) {
                hasChanges = true;
                return {
                  ...compField,
                  idx: this.generateUniqueId("table_field"),
                };
              }
              return compField;
            }
          );

          if (hasChanges) {
            this.$emit("updateField", updatedField);
          }
        }

        // Safely update localField
        this.localField = newVal ? JSON.parse(JSON.stringify(newVal)) : null;

        // Check if presets have changed
        const newPresets = JSON.stringify(newVal?.presets);
        const oldPresets = JSON.stringify(this.field?.presets);

        if (newPresets !== oldPresets) {
          this.fetchPresetData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.field?.type === "radio" || this.field?.type === "checkboxes") {
      if (this.formOptionsListData.length === 0) {
        this.retryLoadingData();
      }
    }

    // Ensure field has idx on mount
    this.ensureFieldHasIdx();
  },
  async created() {
    // Ensure field has idx on create
    this.ensureFieldHasIdx();

    this.localField = this.field ? { ...this.field } : null;

    try {
      // Immediately load options for radio and checkboxes
      if (this.field?.type === "radio" || this.field?.type === "checkboxes") {
        // Set priority loading flag
        this.isPriorityLoading = true;
        await this.fetchPresetData();
        this.isPriorityLoading = false;
      } else {
        // Regular loading for other field types
        this.fetchPresetData();
      }
    } catch (error) {
      console.error("Error in created lifecycle hook:", error);
    }

    this.$root.$on("field-settings-updated", this.handleFieldSettingsUpdate);
  },
  beforeDestroy() {
    // Remove the event listener to prevent memory leaks
    this.$root.$off("field-settings-updated", this.handleFieldSettingsUpdate);
  },
  computed: {
    formOptionsKey() {
      // Consistent key generation for all components
      return `options-${this.formOptionsListKey}-${
        (this.formOptionsListData || []).length
      }`;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    formOptionsList() {
      // Defensive checks to prevent errors
      if (!this.field?.presets?.presetsType) {
        return this.formOptionsListData || [];
      }

      // Handle dynamic case
      if (this.field.presets.presetsType === "dynamic") {
        if (!this.formOptionsListData) return [];

        return this.formOptionsListData.map((item) => {
          if (!item) return {};
          return {
            ...item,
            displayText: this.generateDisplayText(item),
          };
        });
      }

      // Handle static case
      return this.formOptionsListData || [];
    },
  },
  methods: {
    /**
     * Generate a unique ID for fields
     * @param {string} prefix - Optional prefix for the ID
     * @returns {string} - A unique ID with proper formatting
     */
    generateUniqueId(prefix = "") {
      const id = nanoid();

      // If no prefix is provided, just return the ID
      if (!prefix) {
        return id;
      }

      // Make sure the prefix ends with a single underscore instead of dash
      // This avoids double dash issues and is more reliable as a separator
      const cleanPrefix = prefix.endsWith("_")
        ? prefix
        : prefix.replace(/-+$/, "") + "_";

      return cleanPrefix + id;
    },

    /**
     * Ensure the field and all its component fields have idx properties
     */
    ensureFieldHasIdx() {
      if (this.field) {
        // Ensure the main field has an idx
        if (!this.field.idx) {
          this.$set(this.field, "idx", this.generateUniqueId());
        }

        // For table fields, ensure component fields have idx
        if (
          this.field.type === "table" &&
          Array.isArray(this.field.componentFields)
        ) {
          this.field.componentFields.forEach((compField, index) => {
            if (!compField.idx) {
              this.$set(this.field.componentFields, index, {
                ...compField,
                idx: this.generateUniqueId("table_field"),
              });
            }
          });
        }
      }
    },

    handleFieldSettingsUpdate(fieldId) {
      // Check if this event is for our field
      if (fieldId === this.field?.idx) {
        this.formOptionsListKey = Date.now().toString();
        this.fetchPresetData();
      }
    },
    retryLoadingData() {
      if (this.loadRetryCount < 3) {
        this.loadRetryCount++;
        setTimeout(() => {
          this.fetchPresetData().then(() => {
            if (
              this.formOptionsListData.length === 0 &&
              this.field?.presets?.presetsType === "dynamic"
            ) {
              this.retryLoadingData();
            }
          });
        }, 200);
      } else {
        console.warn(`Max retries reached for field type ${this.field?.type}`);
      }
    },

    displayValue(val) {
      return this.field?.presets?.valuesToDisplay?.includes(val) || false;
    },
    handleSettingsLock(val) {
      this.settingsLock = val;
    },
    patchCurrentOffer() {
      // Create a deep copy of the current form
      const updatedForm = JSON.parse(JSON.stringify(this.currentOffer.form));

      // Dispatch the update
      this.$store.dispatch("patchCurrentOffer", { form: updatedForm });
    },
    handleUpdateSettings(update) {
      // Ensure the update has an idx
      if (!update.idx) {
        update.idx = this.generateUniqueId();
      }

      // If this is a table, ensure all component fields have idx
      if (update.type === "table" && Array.isArray(update.componentFields)) {
        update.componentFields = update.componentFields.map((compField) => {
          return {
            ...compField,
            idx: compField.idx || this.generateUniqueId("table_field"),
          };
        });
      }

      if (this.isTableField) {
        // Emit update for table field
        this.$emit("onUpdateTableField", update);
      } else {
        // Create a deep copy of the current form
        const updatedForm = JSON.parse(JSON.stringify(this.currentOffer.form));

        // Update the specific field
        const items =
          updatedForm.pages[this.pageIdx].sections[this.sectionIdx].columns[
            this.columnIdx
          ].items;

        items[this.fieldIndex] = {
          ...items[this.fieldIndex],
          ...JSON.parse(JSON.stringify(update)),
        };

        // Dispatch the update
        this.$store.dispatch("patchCurrentOffer", { form: updatedForm });
      }
      this.settingsLock = false;
    },
    handleDelete() {
      if (this.isTableField) {
        this.$emit("onDeleteTableField", this.field);
      } else {
        // Create a deep copy of the current form
        const updatedForm = JSON.parse(JSON.stringify(this.currentOffer.form));

        // Remove the field
        updatedForm.pages[this.pageIdx].sections[this.sectionIdx].columns[
          this.columnIdx
        ].items.splice(this.fieldIndex, 1);

        // Dispatch the update
        this.$store.dispatch("patchCurrentOffer", { form: updatedForm });
      }
    },

    async fetchPresetData() {
      this.isLoading = true;

      try {
        // Defensive checks
        if (!this.field?.presets) {
          this.isLoading = false;
          return Promise.resolve([]);
        }

        // Static presets
        if (this.field.presets.presetsType === "static") {
          const staticOptions = this.field.presets.staticOptions || [];
          this.$set(
            this,
            "formOptionsListData",
            JSON.parse(JSON.stringify(staticOptions))
          );
          this.$forceUpdate();
          this.isLoading = false;
          return Promise.resolve(this.formOptionsListData);
        }

        // Dynamic presets
        if (!this.field.presets.selectedPresetsGroup) {
          this.isLoading = false;
          return Promise.resolve([]);
        }

        // Make sure to wait for the store action to complete
        const data = await this.$store.dispatch(
          "getPresetRecords",
          this.field.presets.selectedPresetsGroup
        );

        if (!data || data.length === 0) {
          console.warn(
            "No data received from API for",
            this.field.presets.selectedPresetsGroup
          );
          this.isLoading = false;
          return Promise.resolve([]);
        }

        // Process the data
        const processedData = (data || [])
          .filter((item) => item.status === "Active")
          .map((item) => ({
            ...item,
            displayText: this.generateDisplayText(item),
          }));

        // Set the data using Vue's reactivity system
        this.$set(
          this,
          "formOptionsListData",
          JSON.parse(JSON.stringify(processedData))
        );
        this.formOptionsListKey = Date.now().toString();

        // Emit an event so the form submission can capture these options
        this.$root.$emit("dynamic-options-loaded", {
          fieldId: this.field.idx,
          options: processedData,
        });

        // Force updates
        this.$nextTick(() => {
          this.$forceUpdate();
        });

        this.isLoading = false;
        return Promise.resolve(processedData);
      } catch (error) {
        console.error("Error in fetchPresetData:", error);
        this.isLoading = false;
        return Promise.reject(error);
      }
    },
    generateDisplayText(item) {
      // Generate display text based on selected values
      const displayValues = this.field?.presets?.valuesToDisplay || [];

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean);

      return displayParts.join(" • ");
    },

    refreshData() {
      this.fetchPresetData();
    },
    refreshOptions() {
      // Force refresh the options list
      this.$nextTick(() => {
        this.formOptionsListData = [...this.formOptionsListData];
        this.$forceUpdate();
      });
    },
    triggerCompleteRefresh() {
      // Reset form options list
      this.$set(this, "formOptionsListData", []);

      // Fetch fresh data
      this.fetchPresetData();

      // Force update of the component
      this.$forceUpdate();

      // Trigger nextTick to ensure updates
      this.$nextTick(() => {
        if (this.$parent && this.$parent.refreshTableFields) {
          this.$parent.refreshTableFields();
        }
      });
    },
  },
};
</script>

<style scoped>
.ghost {
  border: 2px dotted rgb(134, 134, 134);
}
.available-items {
  cursor: pointer;
}
.icon {
  font-size: 2rem;
}
.type-subheading {
  font-size: 0.8rem;
}
.checkbox-margins {
  margin-bottom: -30px !important;
  margin-top: 12px;
}
.checkbox-group-margins {
  margin-bottom: 4px !important;
  margin-top: 5px;
  padding: 8px;
}
.custom-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.radio-group-margins {
  margin-bottom: -24px;
  margin-top: -8px;
  padding: 8px;
}
.v-sheet {
  cursor: all-scroll;
  border: 2px solid #e91e63 !important;
  position: relative; /* Ensure it remains in flow for absolute children */
}
.v-sheet:not(.on-hover) {
  border: 2px solid transparent !important;
}
.hover-actions {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: #e91e63 !important;
}
.action-sheet {
  outline: 1px solid #e91e63 !important;
}
.action-icon {
  color: #ffffff !important;
  font-size: 20px !important;
  margin: 3px;
}
.form-option-small {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #a1a1a1 !important;
  line-height: 1;
}
.form-option-medium {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #494949de !important;
  line-height: 1;
}
.form-option-large {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000000de !important;
  line-height: 1.3;
}
</style>

export const AUTH_SIGNIN_DEFAULTS = {
  settings: {
    heightContext: "vh",
    mode: "image",
    cols: 12,
    color: {
      alpha: 1,
      hex: "#212121",
      hexa: "#212121FF",
      hsla: {
        a: 1,
        h: 74.01869158878505,
        l: 0.12941176470588237,
        s: 0,
      },
      hsva: {
        a: 1,
        h: 74.01869158878505,
        s: 0,
        v: 0.12941176470588237,
        hue: 0,
      },
      rgba: {
        a: 1,
        b: 33,
        g: 33,
        r: 33,
      },
    },
  },
  heightValue: {
    px: 600,
    vh: 100,
  },
  button: {
    dark: false,
    justify: "center",
    block: true,
    width: 200, // 30 to 300
    height: 40, // 30 to 60
    color: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
  },
  stepper: {
    dark: false,
    color: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
  },
  card: {
    icon: {
      marginLeft: 133,
      marginTop: 0,
      size: 105,
      opacity: 55,
    },
    width: 380,
    height: 510,
    borderSize: 0,
    borderColor: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
    elevation: 6,
    rounded: "rounded-lg",
    color: {
      type: "gradient",
      solidColor: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientFrom: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientTo: {
        alpha: 1,
        hex: "#94143F",
        hexa: "#94143FFF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148,
        },
      },
      direction: "to bottom",
      gradientHeight: 100,
    },
    padding: {
      top: 45,
      bottom: 30,
      left: 50,
      right: 50,
    },
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    group: {
      cols: 12,
    },
  },
  title: {
    align: "start",
    boxHeight: 115,
    color: "#FFFFFFFF",
    cols: 12,
    field: "title",
    floatText: "text-center",
    font: "Quicksand, sans-serif",
    justify: "center",
    lineHeight: 1.6,
    size: 30,
    style: "normal",
    value: "Sign In",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  subtitle: {
    align: "start",
    boxHeight: 60,
    color: "#FFFFFFFF",
    cols: 12,
    field: "subtitle",
    floatText: "text-center",
    font: "Arial, sans-serif",
    justify: "center",
    lineHeight: 1.2,
    size: 12,
    style: "normal",
    value: "",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  forgot: {
    align: "center",
    boxHeight: 55,
    color: "#FFFFFFFF",
    cols: 12,
    field: "forgot",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.2,
    size: 13,
    style: "normal",
    value: "Forgot Password",
    weight: "bold",
    marginLeft: 0,
    marginRight: 0,
  },
  signing: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "signing",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "By signing in, you agree to the",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  account: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "account",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "Don't have an account?",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  signup: {
    align: "start",
    boxHeight: 35,
    color: "#FFFFFFFF",
    cols: 7,
    field: "signup",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 13,
    style: "normal",
    value: "Sign up here",
    weight: "bold",
    marginLeft: 0,
    marginRight: 0,
  },
  terms: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "terms",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "Terms and Conditions",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  privacy: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "privacy",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "Privacy Policy",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  viewour: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "viewour",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "View our program",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: "screen",
    direction: "to top",
    color: {
      alpha: 0,
      hex: "#00000000",
      hexa: "#00000000",
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0,
      },
    },
    fromColor: {
      alpha: 0,
      hex: "#000000",
      hexa: "#00000000",
      hsla: { h: 0, s: 0, l: 0, a: 0 },
      hsva: { h: 0, s: 0, v: 0, a: 0 },
      hue: 0,
      rgba: { r: 0, g: 0, b: 0, a: 0 },
    },
    toColor: {
      alpha: 0,
      hex: "#000000",
      hexa: "#00000000",
      hsla: { h: 0, s: 0, l: 0, a: 0 },
      hsva: { h: 0, s: 0, v: 0, a: 0 },
      hue: 0,
      rgba: { r: 0, g: 0, b: 0, a: 0 },
    },
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 3,
    align: "center",
    justify: "center",
    padding: {
      bottom: 50,
      left: 8,
      right: 8,
      top: 50,
    },
  },
  logo: {
    align: "center",
    placement: "top",
    justify: "center",
    height: 50,
    boxHeight: 10,
    image: "",
    previewImage: "",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  background: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_2560x2560.png?alt=media&token=62cd0b3e-a963-42ef-a633-cf9a063f99f1",
    previewImage:
      "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_600x600.png?alt=media&token=3a2aa0cd-b5fe-45a5-9466-ec39af90630b",
  },
};

export const AUTH_SIGNUP_DEFAULTS = {
  settings: {
    heightContext: "vh",
    mode: "image",
    cols: 12,
    color: {
      alpha: 1,
      hex: "#212121",
      hexa: "#212121FF",
      hsla: {
        a: 1,
        h: 0,
        l: 0.12941176470588237,
        s: 0,
      },
      hsva: {
        a: 1,
        h: 0,
        s: 0,
        v: 0.12941176470588237,
        hue: 0,
      },
      rgba: {
        a: 1,
        b: 33,
        g: 33,
        r: 33,
      },
    },
  },
  heightValue: {
    px: 600,
    vh: 100,
  },
  button: {
    dark: false,
    justify: "center",
    block: false,
    width: 100, // 30 to 300
    height: 40, // 30 to 60
    color: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
  },
  card: {
    icon: {
      marginLeft: 133,
      marginTop: 0,
      size: 105,
      opacity: 55,
    },
    width: 380,
    height: 655,
    borderSize: 0,
    borderColor: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
    elevation: 6,
    rounded: "rounded-lg",
    color: {
      type: "gradient",
      solidColor: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientFrom: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientTo: {
        alpha: 1,
        hex: "#94143F",
        hexa: "#94143FFF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148,
        },
      },
      direction: "to bottom",
      gradientHeight: 100,
    },
    padding: {
      top: 30,
      bottom: 20,
      left: 20,
      right: 20,
    },
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    group: {
      cols: 12,
    },
  },
  title: {
    align: "start",
    boxHeight: 60,
    color: "#FFFFFFFF",
    cols: 12,
    field: "title",
    floatText: "text-center",
    font: "Quicksand, sans-serif",
    justify: "center",
    lineHeight: 1.6,
    size: 30,
    style: "normal",
    value: "Sign Up",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  subtitle: {
    align: "start",
    boxHeight: 60,
    color: "#FFFFFFFF",
    cols: 12,
    field: "subtitle",
    floatText: "text-center",
    font: "Arial, sans-serif",
    justify: "center",
    lineHeight: 1.2,
    size: 12,
    style: "normal",
    value: "",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  signing: {
    align: "end",
    boxHeight: 30,
    color: "#FFFFFFFF",
    cols: 7,
    field: "signing",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "By signing up, you agree to the",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  terms: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "terms",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 13,
    style: "normal",
    value: "Terms and Conditions",
    weight: "bold",
    marginLeft: 0,
    marginRight: 0,
  },
  privacy: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "terms",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 13,
    style: "normal",
    value: "Privacy Policy",
    weight: "bold",
    marginLeft: 0,
    marginRight: 0,
  },
  viewour: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "viewour",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 13,
    style: "normal",
    value: "View our program",
    weight: "bold",
    marginLeft: 0,
    marginRight: 0,
  },
  already: {
    align: "end",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "already",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "Already a member?",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  signinLink: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 7,
    field: "signinLink",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 13,
    style: "normal",
    value: "Sign In Here",
    weight: "bold",
    marginLeft: 0,
    marginRight: 0,
  },
  stepperLabels: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 12,
    field: "stepperLabels",
    floatText: "text-left",
    font: "Quicksand, sans-serif",
    justify: "start",
    lineHeight: 1.5,
    size: 15,
    style: "normal",
    value: "Your Details",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  stepperSummary: {
    align: "start",
    boxHeight: 8,
    color: "#FFFFFFFF",
    cols: 12,
    field: "stepperSummary",
    floatText: "text-center",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1.1,
    size: 12,
    style: "normal",
    value: "Details summary",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: "screen",
    direction: "to top",
    color: {
      alpha: 0,
      hex: "#00000000",
      hexa: "#00000000",
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0,
      },
    },
    fromColor: {
      alpha: 0,
      hex: "#000000",
      hexa: "#00000000",
      hsla: { h: 0, s: 0, l: 0, a: 0 },
      hsva: { h: 0, s: 0, v: 0, a: 0 },
      hue: 0,
      rgba: { r: 0, g: 0, b: 0, a: 0 },
    },
    toColor: {
      alpha: 0,
      hex: "#000000",
      hexa: "#00000000",
      hsla: { h: 0, s: 0, l: 0, a: 0 },
      hsva: { h: 0, s: 0, v: 0, a: 0 },
      hue: 0,
      rgba: { r: 0, g: 0, b: 0, a: 0 },
    },
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 3,
    align: "center",
    justify: "center",
    padding: {
      bottom: 50,
      left: 8,
      right: 8,
      top: 50,
    },
  },
  logo: {
    align: "center",
    placement: "top",
    justify: "center",
    height: 50,
    boxHeight: 10,
    image: "",
    previewImage: "",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  background: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_2000x2000.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7",
    previewImage:
      "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_300x300.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7",
  },
};

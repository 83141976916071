/* eslint-disable no-console */
<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
        <v-overlay absolute color="red" :opacity="systemTheme.cardOpacity">
        </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar size="100" color="white" outline>
          <v-icon size="99" color="red">error</v-icon>
        </v-avatar>
      </v-row>

      <div v-if="allowDeleteAdmin">
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ email }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <div v-else>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading"> Ooops, Sorry... </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6 mt-n4">
          <v-row dense>
            <v-col align="center">
              This administrator cannot be deleted because you need at least one
              ACTIVE administrator in the ADMIN role
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <v-card-text class="px-16">
        <v-row dense>
          <v-col v-if="errorMessage">
            <v-alert type="error" :value="true">
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleClose()">Cancel</v-btn>
        <v-btn
          class="white--text"
          color="red"
          @click="handleDelete()"
          :loading="loading"
          :disabled="!allowDeleteAdmin"
          elevation="0"
          >Confirm Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    // Component settings unique to this component
    errorMessage: "",
  }),
  created() {
    const { email, role, status } = this.deletingAdmin;
    this.email = email;
    this.role = role;
    this.status = status;
  },
  props: {
    deletingAdmin: {
      type: Object,
      required: true,
    },
    activeCount: {
      type: Number,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    // Functions common to all components
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    allowDeleteAdmin() {
      if (this.deletingAdmin.status === "Invited") {
        return true;
      } else if (this.deletingAdmin.role === "Editor") {
        return true;
      } else if (this.deletingAdmin.role === "Observer") {
        return true;
      } else {
        if (this.activeCount <= 1) {
          return false;
        } else {
          return true;
        }
      }
    },
  },

  methods: {
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.errorMessage = "";
      this.$validator.reset();
      this.$store.dispatch("setLoading", false);
    },
    handleDelete() {
      // Delete record
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("deleteAdminFromProgram", this.deletingAdmin.email)
        .then(() => {
          this.handleClose();
          this.$store.dispatch("setLoading", false);
        })
        .catch((e) => {
          this.errorMessage = e;
          this.$store.dispatch("setLoading", false);
        });
    },
  },
};
</script>

<style></style>

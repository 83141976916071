<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-alert
          v-if="!permitted && !avatar"
          border="left"
          elevation="0"
          color="primary"
          type="error"
        >
          <span class="pl-1 incentable-alert"
            >Invalid file type. Images must be .jpg, .jpeg or .png</span
          >
        </v-alert>

        <!-- Avatar ----------------------------------------------------------------------->
        <div v-if="avatar">
          <!-- Uploading image -->
          <div v-if="imageUrl && permitted">
            <v-avatar size="200">
              <v-img :src="imageUrl" alt="Image">
                <v-overlay
                  v-if="processing"
                  absolute
                  transition="fade-transition"
                  opacity="0.5"
                >
                  <v-progress-circular
                    v-if="processing"
                    indeterminate
                    size="80"
                    width="10"
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-img>
            </v-avatar>
          </div>

          <!-- Actual image -->
          <div
            v-else-if="
              (currentImageOnFile.mediumUrl && !imageUrl) ||
              (externalImageUrl && !imageUrl) ||
              (currentImageOnFile.mediumUrl && !permitted)
            "
            color="white"
          >
            <v-hover v-slot="{ hover }">
              <v-avatar size="200">
                <v-img
                  :src="
                    externalImageUrl
                      ? externalImageUrl
                      : currentImageOnFile.mediumUrl
                  "
                  :lazy-src="
                    externalImageUrl
                      ? externalImageUrl
                      : currentImageOnFile.mediumUrl
                  "
                  alt="Image"
                  :class="radius ? 'radius hover' : 'hover'"
                >
                  <v-overlay
                    v-if="(hover && !externalImageUrl) || processing"
                    absolute
                    transition="fade-transition"
                    opacity="0.5"
                  >
                    <v-btn
                      v-if="!processing"
                      @click="handleOpenFileBrowser"
                      color="primary"
                      fab
                      ><v-icon>photo_camera</v-icon></v-btn
                    >
                    <v-btn
                      v-if="!processing"
                      @click="handleDeleteMedia(false)"
                      color="primary"
                      fab
                      ><v-icon>delete</v-icon></v-btn
                    >
                    <v-progress-circular
                      v-if="processing"
                      indeterminate
                      size="80"
                      width="10"
                      color="primary"
                    ></v-progress-circular>
                  </v-overlay>
                </v-img>
              </v-avatar>
            </v-hover>
          </div>

          <!-- External image via URL --->
          <div v-else-if="externalImageUrl">
            <v-card
              flat
              outlined
              max-height="300px"
              class="mb-5 pt-0 mt-0 rounded"
            >
              <v-avatar size="200">
                <v-img
                  :src="externalImageUrl ? externalImageUrl : imageUrl"
                  :lazy-src="require('@/assets/placeholder.png')"
                  transition="fade-transition"
                  contain
                >
                </v-img>
              </v-avatar>
            </v-card>
          </div>

          <!-- Placeholder -->
          <v-avatar
            v-else
            color="grey lighten-3"
            elevation="0"
            height="200"
            width="200"
            @click="id ? handleOpenFileBrowser() : ''"
            class="pointer"
          >
            <v-row
              no-gutters
              justify="center"
              align="center"
              style="height: 300px"
            >
              <v-col>
                <v-row no-gutters justify="center" align="center">
                  <v-icon color="grey lighten-2" size="50">photo_camera</v-icon>
                </v-row>
                <v-row
                  v-if="!id"
                  no-gutters
                  justify="center"
                  align="center"
                  class="note"
                >
                  The item must be saved before an image can be added
                </v-row>
              </v-col>
            </v-row>
          </v-avatar>

          <v-alert
            v-if="!permitted && avatar"
            class="ml-n9 mr-6 mt-n12"
            border="left"
            elevation="0"
            color="primary"
            type="error"
          >
            <span class="pl-1 incentable-alert"
              >Invalid file type. Images must be .jpg, .jpeg or .png</span
            >
          </v-alert>
        </div>

        <!-- Non Avatar ----------------------------------------------------------------------->
        <div v-else>
          <!-- Uploading image -->
          <div v-if="imageUrl && permitted">
            <v-img :src="imageUrl" alt="Image">
              <v-overlay
                v-if="processing"
                absolute
                transition="fade-transition"
                opacity="0.5"
              >
                <v-progress-circular
                  v-if="processing"
                  indeterminate
                  size="80"
                  width="10"
                  color="primary"
                ></v-progress-circular>
              </v-overlay>
            </v-img>
          </div>

          <!-- Actual image -->
          <div
            v-else-if="
              (currentImageOnFile.mediumUrl && !imageUrl) ||
              (externalImageUrl && !imageUrl) ||
              (currentImageOnFile.mediumUrl && !permitted)
            "
            color="white"
          >
            <v-hover v-slot="{ hover }">
              <v-img
                :src="
                  externalImageUrl
                    ? externalImageUrl
                    : currentImageOnFile.mediumUrl
                "
                :lazy-src="
                  externalImageUrl
                    ? externalImageUrl
                    : currentImageOnFile.mediumUrl
                "
                alt="Image"
                :class="radius ? 'radius hover' : 'hover'"
              >
                <v-overlay
                  v-if="(hover && !externalImageUrl) || processing"
                  absolute
                  transition="fade-transition"
                  opacity="0.5"
                >
                  <v-btn
                    v-if="!processing"
                    @click="handleOpenFileBrowser"
                    color="primary"
                    fab
                    ><v-icon>photo_camera</v-icon></v-btn
                  >
                  <v-btn
                    v-if="!processing"
                    @click="handleDeleteMedia(false)"
                    color="primary"
                    fab
                    ><v-icon>delete</v-icon></v-btn
                  >
                  <v-progress-circular
                    v-if="processing"
                    indeterminate
                    size="80"
                    width="10"
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-img>
            </v-hover>
          </div>

          <!-- External image via URL --->
          <div v-else-if="externalImageUrl">
            <v-card
              flat
              outlined
              max-height="300px"
              class="mb-5 pt-0 mt-0 rounded"
            >
              <v-img
                :src="externalImageUrl ? externalImageUrl : imageUrl"
                :lazy-src="require('@/assets/placeholder.png')"
                transition="fade-transition"
                contain
              >
              </v-img>
            </v-card>
          </div>

          <!-- Placeholder -->
          <v-card
            v-else-if="!isGallery && !isUploads"
            color="grey lighten-3"
            elevation="0"
            width="100%"
            @click="id ? handleOpenFileBrowser() : ''"
            class="pointer"
            height="333px"
          >
            <v-row
              no-gutters
              justify="center"
              align="center"
              :style="'height: ' + height + 'px'"
            >
              <v-col>
                <v-row no-gutters justify="center" align="center">
                  <v-icon color="grey lighten-2" size="50">photo_camera</v-icon>
                </v-row>
                <v-row
                  v-if="!id"
                  no-gutters
                  justify="center"
                  align="center"
                  class="note"
                >
                  The item must be saved before an image can be added
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <!-- Gallery -->
          <v-card
            v-else-if="isGallery || isUploads"
            color="grey lighten-3"
            elevation="0"
            @click="handleOpenFileBrowser()"
            class="pointer"
            tile
          >
            <v-row
              no-gutters
              justify="center"
              align="center"
              :style="'height: ' + height + 'px'"
            >
              <v-col>
                <v-row
                  no-gutters
                  justify="center"
                  align="center"
                  style="height: 209px"
                >
                  <v-btn elevation="0" color="primary" fab>
                    <v-icon color="white">add</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <!-- Filename display --->
        <v-row
          no-gutters
          v-if="showMetaData && !externalImageUrl && currentImageOnFile.name"
          justify="end"
        >
          <v-col>
            <v-row no-gutters class="image-meta-heading" justify="end">
              File Name:
            </v-row>
          </v-col>
          <v-col class="pl-1" cols="auto">
            <v-row no-gutters class="image-meta">
              {{ currentImageOnFile.name }}
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <input
      type="file"
      style="display: none"
      ref="imageFileInput"
      accept="image/*"
      @change="handleFilePicked"
    />
  </div>
</template>

<script>
import { storage, timestamp } from "@/firebase";
import { nanoid } from "nanoid";

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    currentImageOnFile: {
      type: Object,
      required: false,
      default: null,
    },
    showMetaData: {
      type: Boolean,
      required: false,
      default: false,
    },
    isGallery: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUploads: {
      type: Boolean,
      required: false,
      default: false,
    },
    externalImageUrl: {
      type: String,
      required: false,
      default: "",
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    height: {
      type: Number,
      required: false,
      default: null,
    },
    radius: {
      type: Boolean,
      required: false,
      default: false,
    },
    avatar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {},
  data: () => ({
    rewardSupplier: "",
    title: "",
    created: "",
    updated: "",
    createdBy: "",
    updatedBy: "",
    imageUrl: "",
    permitted: true,
    processing: false,
    fileUploading: false,
    fileUploadTask: null,
    fileUploadingProgress: 0,
    filename: "",
    smallUrl: "",
    mediumUrl: "",
    largeUrl: "",
    small: "",
    medium: "",
    large: "",
    image: "",
    imageUrlObj: {
      id: "",
      name: "",
      ext: "",
      updated: "",
      smallUrl: "",
      mediumUrl: "",
      largeUrl: "",
      smallStoragePath: "",
      mediumStoragePath: "",
      largeStoragePath: "",
    },
    storageRef: "",
    permittedFiles: [
      { ext: "jpg", icon: "fas fa-file-file", color: "purple" },
      { ext: "jpeg", icon: "fas fa-file-file", color: "purple" },
      { ext: "png", icon: "fas fa-file-file", color: "purple" },
      { ext: "PNG", icon: "fas fa-file-file", color: "purple" },
      { ext: "JPG", icon: "fas fa-file-file", color: "purple" },
      { ext: "JPEG", icon: "fas fa-file-file", color: "purple" },
    ],
  }),
  computed: {
    programId() {
      return this.$store.getters.programId;
    },
  },
  methods: {
    clear() {
      this.externalImageUrl = "";
      this.imageUrlObj = {
        id: "",
        name: "",
        ext: "",
        updated: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: "",
      };
      this.image = "";
      this.filename = "";
      this.imageUrl = "";
      this.fileUploading = false;
      this.fileUploadTask = null;
      this.fileUploadingProgress = 0;
      this.$refs.imageFileInput.value = "";
      this.processing = false;
      this.$validator.reset();
      this.$store.dispatch("setLoading", false);
      this.permitted = true;
    },
    handleFilePicked(event) {
      this.$emit("onImageProcessing", true);
      this.processing = true;
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return console.log("Invalid file type");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });

      fileReader.readAsDataURL(files[0]);

      this.image = files[0];

      this.imageUrlObj.ext = this.image.name.split(".").pop();
      this.imageUrlObj.id = nanoid();
      if (this.permittedFiles.find((el) => el.ext === this.imageUrlObj.ext)) {
        this.permitted = true;
        var prefix = `${this.imageUrlObj.id}_${this.image.name.split(".")[0]}`;

        var folder = this.isGallery
          ? "gallery/"
          : "media/" + this.programId + "/";
        // Firebase storage image image resize is used so the original image is compressed into 3 versions and the original is deleted
        // Firebase adds the image size to the file name automatically upon resize EG 76x76, so we need to also add it here to the stored path amd url
        this.imageUrlObj.smallStoragePath =
          folder + prefix + "_76x76." + this.imageUrlObj.ext; // max 76 x 76 px
        this.imageUrlObj.mediumStoragePath =
          folder + prefix + "_600x600." + this.imageUrlObj.ext; // max 600 x 600 px
        this.imageUrlObj.largeStoragePath =
          folder + prefix + "_2560x2560." + this.imageUrlObj.ext; // max 2560 x 2560 px

        var existing = this.currentImageOnFile.name ? true : false;
        console.log(existing);
        if (existing) {
          this.handleDeleteMedia(true);
          this.saveImageFile(folder);
        } else {
          this.saveImageFile(folder);
        }
      } else {
        this.processing = false;
        this.$emit("onImageProcessing", false);
        this.permitted = false;
        setTimeout(() => {
          this.clear();
        }, 4000);
      }
    },
    saveImageFile(folder) {
      this.storageRef = storage.ref(
        folder + this.imageUrlObj.id + "_" + this.image.name
      );
      this.storageRefSmall = storage.ref(this.imageUrlObj.smallStoragePath);
      this.storageRefMedium = storage.ref(this.imageUrlObj.mediumStoragePath);
      this.storageRefLarge = storage.ref(this.imageUrlObj.largeStoragePath);
      this.fileUploading = true;
      this.fileUploadTask = this.storageRef.put(this.image);
    },
    handleOpenFileBrowser() {
      this.$refs.imageFileInput.click();
    },
    async handleDeleteMedia(replacing) {
      this.$emit("onImageProcessing", true);
      this.processing = true;
      console.log(this.currentImageOnFile);
      await this.$store.dispatch(
        "deleteMediaFilesFromStorage",
        this.currentImageOnFile
      );
      var image = {
        updated: "",
        name: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: "",
      };
      this.processing = false;
      if (!replacing) {
        await this.$emit("onImageSaved", image);
        this.$emit("onImageProcessing", false);
      }
      return "ok";
    },
  },
  watch: {
    fileUploadTask: function () {
      if (!this.fileUploadTask) {
        return;
      }
      this.fileUploadTask.on(
        "state_changed",
        (snapshot) => {
          if (snapshot.totalBytes <= 0) {
            return;
          }
          this.processing = true;
          this.$emit("onImageProcessing", true);
          this.fileUploadingProgress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          console.log("Upload error", error);
          this.fileUploading = false;
          this.clear();
        },
        () => {
          setTimeout(() => {
            this.storageRefSmall.getDownloadURL().then((downloadURL) => {
              this.imageUrlObj.smallUrl = downloadURL;
            });
            this.storageRefMedium.getDownloadURL().then((downloadURL) => {
              this.imageUrlObj.mediumUrl = downloadURL;
            });
            this.storageRefLarge.getDownloadURL().then((downloadURL) => {
              this.imageUrlObj.largeUrl = downloadURL;
            });
          }, 10000);

          setTimeout(() => {
            if (this.imageUrlObj) {
              this.imageUrlObj.name = this.image.name;
              this.imageUrlObj.updated = timestamp;
              this.$emit("onImageSaved", this.imageUrlObj);
              this.clear();
            } else {
              this.clear();
            }
          }, 12000);
        }
      );
    },
  },
};
</script>

<style scoped>
.image-meta-heading {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.note {
  font-size: 12px !important;
  font-weight: 300 !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.image-meta {
  font-size: 12px !important;
}
.radius {
  border-radius: 15px !important;
}
.media-upload {
  height: 333px;
  display: flex;
  flex-direction: column;
}

.media-upload > * {
  flex: 1;
  height: 100%;
}

/* Add these new styles */
.v-img {
  height: 100% !important;
  object-fit: cover !important;
}

.v-card {
  height: 100% !important;
}

.v-row {
  height: 100% !important;
}

.v-col {
  height: 100% !important;
}
</style>

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  // ADDED: Setting the cookie domain
  cookieDomain: "app.incentable.com",
};

const webApi =
  "https://us-central1-incentable-app-37d9c.cloudfunctions.net/webApi/api/v1/";

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const functions = firebase.functions();
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const timestamp = firebase.firestore.FieldValue.serverTimestamp();
const stripePortalRef = firebase
  .app()
  .functions("us-central1")
  .httpsCallable("ext-firestore-stripe-payments-createPortalLink");

export {
  firestore,
  db,
  auth,
  functions,
  storage,
  webApi,
  timestamp,
  stripePortalRef,
};

<template>
  <div class="facebook-component">
    <!-- Facebook post upload section -->
    <div class="subtitle mb-3">Facebook Post Upload</div>

    <v-text-field
      v-model="localValue.url"
      label="Facebook Post URL"
      placeholder="https://facebook.com/..."
      prepend-icon="link"
      outlined
      dense
      @input="updateValues"
    ></v-text-field>

    <v-file-input
      label="Post Screenshot"
      placeholder="Choose image file..."
      outlined
      dense
      prepend-icon="photo_camera"
      @input="
        (file) => {
          localValue.screenshot = file;
          updateValues();
        }
      "
    ></v-file-input>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({
        url: "",
        screenshot: null,
      }),
    },
  },
  data() {
    return {
      localValue: {
        url: "",
        screenshot: null,
      },
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.localValue = { ...newValue };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateValues() {
      this.$emit("input", { ...this.localValue });
    },
  },
};
</script>

<style scoped>
.facebook-component {
  width: 100%;
  background: white;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.subtitle {
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
</style>

<template>
  <v-dialog
    v-model="isVisible"
    :width="component.dialog.width"
    :fullscreen="component.dialog.fullscreen"
    persistent
    scrollable
    content-class="rou"
  >
    <v-card :class="classes" :style="styles + ';' + gradient">
      <v-btn
        v-if="
          component.dialog.title.floatText === 'text-right' &&
          component.dialog.fullscreen === true
        "
        top
        left
        absolute
        small
        icon
        @click="onClose"
        style="z-index: 100"
      >
        <v-icon :color="component.dialog.title.color">clear</v-icon>
      </v-btn>
      <v-btn
        v-if="
          component.dialog.title.floatText !== 'text-right' &&
          component.dialog.fullscreen === true
        "
        top
        right
        absolute
        small
        icon
        @click="onClose"
        style="z-index: 100"
      >
        <v-icon :color="component.dialog.title.color">clear</v-icon>
      </v-btn>
      <v-row no-gutters>
        <v-col>
          <view-text
            :settings="component.dialog.quizTitle"
            :value="quiz.displayTitle || ''"
          ></view-text>
        </v-col>
        <v-col cols="auto">
          <v-row no-gutters justify="end">
            <view-text
              :settings="component.dialog.quizTitle"
              :value="stageDescription || ''"
            ></view-text>
          </v-row>
        </v-col>
      </v-row>

      <div v-if="isVisible">
        <v-row no-gutters v-if="quiz.media.youTubeId && step === 0">
          <v-card width="100%" class="el mt-6 mb-4 mx-8">
            <vue-plyr :options="options">
              <div
                data-plyr-provider="youtube"
                :data-plyr-embed-id="quiz.media.youTubeId"
              ></div>
            </vue-plyr>
          </v-card>
        </v-row>
        <v-row no-gutters v-if="step === 0" class="mb-3">
          <v-col>
            <v-row no-gutters>
              <v-col>
                <view-text
                  :settings="component.dialog.deadline"
                  :value="
                    'Opens: ' +
                    formatDate(quiz.timing.openDate) +
                    (quiz.timing.openTime
                      ? ' at ' + formatTime(quiz.timing.openTime)
                      : ' ' || '')
                  "
                ></view-text>
                <view-text
                  :settings="component.dialog.deadline"
                  :value="
                    'Closes: ' +
                    formatDate(quiz.timing.closeDate) +
                    (quiz.timing.closeTime
                      ? ' at ' + formatTime(quiz.timing.closeTime)
                      : ' ' || '')
                  "
                ></view-text>
              </v-col>
            </v-row>
          </v-col>
          <view-text
            :settings="component.dialog.points"
            :value="formatNumber(quiz.pointsOffered) + ' Points'"
          ></view-text>
        </v-row>
        <view-text
          v-if="step === 0"
          :settings="component.dialog.introduction"
          :value="quiz.description"
        ></view-text>
        <v-row v-if="currentQuestion !== null" no-gutters>
          <v-col>
            <view-text
              :settings="component.dialog.question"
              :value="currentQuestion.text"
            ></view-text>
          </v-col>
        </v-row>
        <v-row
          v-if="stageDescription !== 'Results' && step > 0"
          no-gutters
          :justify="component.dialog.answer.justify"
        >
          <div v-if="currentQuestion.type === 'radio'">
            <v-radio-group
              v-model="response[step - 1].selection"
              :dark="component.dialog.answer.dark"
            >
              <v-radio
                v-for="(answer, id) in currentAnswer"
                :key="id"
                :label="answer.answer"
                :value="answer"
                :color="component.dialog.answer.color"
              >
                <template v-slot:label>
                  <view-text
                    :settings="component.dialog.answer"
                    :value="answer.answer"
                  ></view-text>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div v-if="currentQuestion.type === 'dropdown'">
            <v-select
              v-model="response[step - 1].selection"
              :items="currentAnswer"
              item-text="answer"
              item-value="currentAnswer"
              return-object
              outlined
              dense
              class="mt-5"
              :dark="component.dialog.answer.dark"
            >
            </v-select>
          </div>
          <div v-if="currentQuestion.type === 'checkboxes'" class="mb-4">
            <v-checkbox
              v-for="(answer, id) in currentAnswer"
              :key="id"
              v-model="checkboxSelect"
              :label="answer.answer"
              :value="answer"
              class="mb-n9"
              @change="handleCheckbox()"
              :dark="component.dialog.answer.dark"
            >
              <template v-slot:label>
                <span :style="'color: ' + component.dialog.answer.color">{{
                  answer.answer
                }}</span>
              </template>
            </v-checkbox>
          </div>
        </v-row>
        <v-row no-gutters>
          <v-alert color="primary" text :value="checkboxMaxAlert">
            Maximum reached
          </v-alert>
        </v-row>
        <div v-if="stageDescription === 'Results'">
          <v-row no-gutters justify="center">
            <v-icon v-if="passed" color="green" size="90" class="mt-6 mb-6"
              >task_alt</v-icon
            >
            <v-icon v-else color="red" size="90" class="mt-6 mb-6"
              >highlight_off</v-icon
            >
          </v-row>
          <view-text
            :settings="component.dialog.result"
            :value="'You scored ' + rateAchieved + '%'"
          ></view-text>
          <view-text
            :settings="component.dialog.result"
            :value="'The pass rate is ' + passRate + '%'"
          ></view-text>
          <view-text
            :settings="component.dialog.result"
            :value="
              'You answered ' +
              score.correct +
              ' out of ' +
              numberOfQuestions +
              ' questions correctly'
            "
          ></view-text>
          <view-text
            :settings="component.dialog.result"
            :value="'You earned ' + pointsEarned + ' points'"
          ></view-text>
        </div>
      </div>
      <v-card-actions class="mt-6 pa-0">
        <v-btn
          :color="component.dialog.quizTitle.color.hexa"
          text
          @click="close()"
          >close</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="stageDescription !== 'Results' && step > 0"
          :color="component.dialog.title.color"
          @click="back"
          elevation="0"
          text
          >back</v-btn
        >
        <v-btn
          v-if="stageDescription !== 'Results'"
          :color="component.dialog.buttonColor.hexa"
          :class="component.dialog.buttonText.color"
          @click="next"
          elevation="0"
          dark
          >next</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ViewText from "../Text.vue";
import moment from "moment";

export default {
  components: {
    ViewText,
  },
  props: ["isVisible", "onClose", "quiz", "component"],
  created() {},
  data: () => {
    return {
      show: false,
      step: 0,
      initialized: false,
      selection: null,
      checkboxSelect: [],
      checkboxMaxAlert: false,
      answer: "",
      youTubeId: "",
      response: [],
      options: {
        autopause: true,
        resetOnEnd: true,
        hideYouTubeDOMError: true,
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          controls: 0,
        },
      },
    };
  },
  computed: {
    cssVars() {
      return {
        "--header-text-color": this.component.dialog.content.heading.color,
        "--header-text-size": this.component.dialog.content.heading.size + "px",
        "--header-text-style": this.component.dialog.content.heading.style,
        "--header-text-weight": this.component.dialog.content.heading.weight,
        "--header-line-height":
          this.component.dialog.content.heading.lineHeight,
        "--header-font": this.component.dialog.content.heading.font,
        "--row-text-color": this.component.dialog.content.data.color,
        "--row-text-size": this.component.dialog.content.data.size + "px",
        "--row-text-style": this.component.dialog.content.data.style,
        "--row-text-weight": this.component.dialog.content.data.weight,
        "--row-line-height": this.component.dialog.content.data.lineHeight,
        "--row-font": this.component.dialog.content.data.font,
        "--text-align": this.component.dialog.content.align,
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.dialog.rowHoverColor.hexa,
        "--current-page-color": this.component.dialog.title.color,
      };
    },
    classes() {
      if (this.component.dialog.fullscreen === true) return "rounded-0";
      return this.component.card.rounded;
    },
    grid() {
      return this.$store.getters.grid;
    },
    gradient() {
      if (this.component.dialog.color.type === "gradient") {
        var color = this.component.dialog.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return (
          "background: " + this.component.dialog.color.solidColor.hexa + ";"
        );
      }
    },
    styles() {
      var styles =
        "padding-bottom: " +
        this.component.dialog.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.dialog.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.dialog.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.dialog.padding.right +
        "px ;" +
        "border: " +
        this.component.dialog.borderSize +
        "px solid " +
        ";";
      this.component.dialog.borderColor.hexa + "; ";
      return styles;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    questions() {
      return this.quiz.questions;
    },
    count() {
      if (this.isVisible === true) {
        const arr = this.response;
        return arr.length;
      }
      return 0;
    },
    currentQuestion() {
      const questions = this.response;
      if (this.step > 0 && this.step <= this.count) {
        return questions[this.step - 1];
      }
      return null;
    },
    currentAnswer() {
      const questions = this.response;
      if (this.step > 0 && this.step <= this.count) {
        return questions[this.step - 1].answers;
      }
      return null;
    },
    stageDescription() {
      if (this.step === 0 && this.step < this.count + 1) {
        return "Introduction";
      } else if (this.step === this.count + 1) {
        return "Results";
      } else if (this.step > 0 && this.step < this.count + 1) {
        return "Question " + this.step + " of " + this.count;
      }
      return null;
    },
    score() {
      const totalPoints = this.sumCorrectPoints(this.response);
      const correctCount = this.countCorrectQuestions(this.response);
      return {
        points: totalPoints,
        correct: correctCount,
      };
    },
    passRate() {
      return this.quiz.passRate;
    },
    passed() {
      if (this.passRate < this.rateAchieved) {
        return true;
      }
      return false;
    },
    pointsEarned() {
      if (this.passRate < this.rateAchieved) {
        const val = this.score.points;
        return this.$options.filters.number(val);
      }
      return 0;
    },
    rateAchieved() {
      if (this.score.correct > 0) {
        const score = this.score.correct;
        const questions = this.response.length;
        const rate = score / questions;
        return Math.round(rate * 100);
      }
      return 0;
    },
    numberOfQuestions() {
      if (this.response.length > 0) {
        return this.response.length;
      }
      return 0;
    },
  },
  methods: {
    close() {
      this.step = 0;
      this.initialized = false;
      this.response = [];
      this.selection = null;
      this.checkboxMaxAlert = false;
      this.checkboxSelect = [];
      this.$emit("onClose");
    },
    formatDate(date) {
      return date ? moment(date).format("MMM Do YYYY") : "";
    },
    formatTime(inputTime) {
      const parsedTime = moment(inputTime, "HH:mm");
      return parsedTime ? moment(parsedTime).format("h:mma") : "";
    },
    formatNumber(val) {
      return this.$options.filters.number(val);
    },
    next() {
      this.checkboxSelect = [];
      if (this.step === 0 && this.initialized === false) {
        const arr = [...this.questions];
        const updatedArr = arr.map((obj) => {
          return {
            ...obj,
            selection: "",
          };
        });
        this.response = updatedArr;
        this.initialized = true;
      }
      this.step += 1;
      this.selection = null;
    },
    back() {
      this.step -= 1;
    },
    handleCheckbox() {
      this.checkboxMaxAlert = false;
      const selected = this.checkboxSelect.length;
      const max = 2;
      if (selected > max) {
        this.checkboxSelect.pop();
        this.checkboxMaxAlert = true;
      }
      this.response[this.step - 1].selection = this.checkboxSelect;
    },
    sumCorrectPoints(responseArray) {
      let totalPoints = 0;

      responseArray.forEach((question) => {
        if (question.type === "radio" || question.type === "dropdown") {
          // For radio and dropdown type questions, check if any of the selected answers are correct
          if (question.selection) {
            const selectedAnswers = Array.isArray(question.selection)
              ? question.selection.map((s) => s.answer)
              : [question.selection.answer];

            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);

            const anyCorrect = selectedAnswers.some((selectedAnswer) =>
              correctAnswers.includes(selectedAnswer)
            );

            if (anyCorrect) {
              totalPoints += question.selection.points;
            }
          }
        } else if (question.type === "checkboxes") {
          // For checkboxes type questions, add points if all selected answers are correct
          if (Array.isArray(question.selection)) {
            const selectedAnswers = question.selection.map((s) => s.answer);
            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);
            const allCorrect =
              selectedAnswers.sort().join() === correctAnswers.sort().join();
            if (allCorrect) {
              totalPoints += question.selection.reduce(
                (acc, selectedAnswer) => acc + selectedAnswer.points,
                0
              );
            }
          }
        }
      });

      return totalPoints;
    },
    countCorrectQuestions(responseArray) {
      let correctCount = 0;

      responseArray.forEach((question) => {
        if (question.type === "radio" || question.type === "dropdown") {
          // For radio and dropdown type questions, check if any of the selected answers are correct
          if (question.selection) {
            const selectedAnswers = Array.isArray(question.selection)
              ? question.selection.map((s) => s.answer)
              : [question.selection.answer];

            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);

            const anyCorrect = selectedAnswers.some((selectedAnswer) =>
              correctAnswers.includes(selectedAnswer)
            );

            if (anyCorrect) {
              correctCount++;
            }
          }
        } else if (question.type === "checkboxes") {
          // For checkboxes type questions, check if all selected answers are correct
          if (Array.isArray(question.selection)) {
            const selectedAnswers = question.selection.map((s) => s.answer);
            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);
            const allCorrect =
              selectedAnswers.sort().join() === correctAnswers.sort().join();
            if (allCorrect) {
              correctCount++;
            }
          }
        }
      });

      return correctCount;
    },
  },
};
</script>

<style scoped>
.text-color {
  color: #a8a8a8;
}
.dz {
  border: 1px white;
  background-color: transparent;
  border-style: dashed;
}
.animation-text {
  position: absolute !important;
  margin-top: 25px;
  font-size: 50px;
  z-index: 1000;
  font-weight: 700;
  font-family: Roboto;
  color: rgb(168, 0, 0);
}

.animated-icon {
  position: absolute !important;
  margin-top: -50px;
}
.animated-confetti {
  position: absolute !important;
  z-index: 1000;
  width: 70% !important;
  height: 70% !important;
  margin-left: -250px;
}
.svg-opacity {
  opacity: 0.5 !important;
}
.table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border-bottom: var(--row-border-bottom) !important;
}
.incentable-pagination {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
}
.incentable-current-page {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--current-page-color) !important;
}
.btn-pagination {
  padding-top: 5px;
}
.table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: var(--row-font) !important;
  font-size: var(--row-text-size) !important;
  font-style: var(--row-text-style) !important;
  font-weight: var(--row-text-weight) !important;
  color: var(--row-text-color) !important;
  line-height: var(--row-line-height) !important;
  text-align: var(--text-align) !important;
  background-color: transparent !important;
  border-bottom: var(--row-border-bottom) !important;
}
.table.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border: none !important;
}
.table.theme--light.v-data-table {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: var(--row-hover-color) !important;
}
</style>

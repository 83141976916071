<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <draggable
          v-model="localfieldsArr"
          class="draggable-card w-100"
          :group="{
            name: 'form-fields', // Same group name as available components
            pull: true,
            put: true,
          }"
          ghost-class="ghost"
          :move="handleMove"
          :clone="cloneField"
          @add="handleAdd"
        >
          <div
            v-for="(field, index) in localfieldsArr"
            :key="field.idx"
            :class="isLastChild(index) ? 'pb-7' : 'pb-0'"
          >
            <FormField
              :fieldIndex="index"
              :pageIdx="pageIdx"
              :sectionIdx="sectionIdx"
              :columnIdx="columnIdx"
              :field="field"
              :isPreview="isPreview"
              @onEditField="handleEditField()"
              @updateField="updateField(index, $event)"
            ></FormField>
          </div>
        </draggable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import FormField from "./FormField.vue";

export default {
  components: { FormField },
  props: {
    pageIdx: {
      type: Number,
      required: true,
    },
    sectionIdx: {
      type: Number,
      required: true,
    },
    columnIdx: {
      type: Number,
      required: true,
    },
    fieldsArr: {
      type: Array,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    localfieldsArr: {
      get() {
        return (
          this.currentOffer.form?.pages[this.pageIdx]?.sections[this.sectionIdx]
            ?.columns[this.columnIdx]?.items || []
        );
      },
      set(newValue) {
        // Create a deep copy of the form structure
        const updatedForm = JSON.parse(JSON.stringify(this.currentOffer.form));

        // Ensure all items have unique IDs
        const updatedItems = newValue.map((item) => {
          const itemCopy = JSON.parse(JSON.stringify(item));

          // Regenerate ID if not present or if it's a duplicate
          if (
            !itemCopy.idx ||
            newValue.filter((i) => i.idx === itemCopy.idx).length > 1
          ) {
            itemCopy.idx = nanoid();
          }

          return itemCopy;
        });

        // Update only the specific column's items
        updatedForm.pages[this.pageIdx].sections[this.sectionIdx].columns[
          this.columnIdx
        ].items = updatedItems;

        // Dispatch the update
        this.$store.dispatch("patchCurrentOffer", { form: updatedForm });
      },
    },
  },

  methods: {
    updateField(index, newData) {
      // Create a deep copy of the current form
      const updatedForm = JSON.parse(JSON.stringify(this.currentOffer.form));

      // Update the specific field with new data
      updatedForm.pages[this.pageIdx].sections[this.sectionIdx].columns[
        this.columnIdx
      ].items[index] = {
        ...JSON.parse(
          JSON.stringify(
            updatedForm.pages[this.pageIdx].sections[this.sectionIdx].columns[
              this.columnIdx
            ].items[index]
          )
        ), // Deep clone the existing item
        ...JSON.parse(JSON.stringify(newData)), // Deep clone the new data
      };

      // Dispatch the update
      this.$store.dispatch("patchCurrentOffer", { form: updatedForm });
    },

    handleMove(evt) {
      // Get the source and destination elements
      const fromEl = evt.from;
      const toEl = evt.to;
      const draggedElement = evt.draggedContext.element;

      // Ensure the dragged element has an idx
      if (!draggedElement.idx) {
        draggedElement.idx = nanoid();
      }

      // If it's a table, ensure all component fields have an idx
      if (
        draggedElement.type === "table" &&
        Array.isArray(draggedElement.componentFields)
      ) {
        draggedElement.componentFields = draggedElement.componentFields.map(
          (field) => {
            return {
              ...field,
              idx: field.idx || "nested-" + nanoid(),
            };
          }
        );
      }

      // Check if moving from available components
      if (fromEl.classList.contains("available-items-group")) {
        // Only allow dropping into column containers
        return toEl.classList.contains("draggable-card");
      }

      // If moving an existing field
      if (fromEl.classList.contains("draggable-card")) {
        // Only allow dropping into other column containers
        return toEl.classList.contains("draggable-card");
      }

      return false;
    },

    cloneField(field) {
      return {
        ...JSON.parse(JSON.stringify(field)),
        idx: nanoid(), // Ensure unique ID every time
        originalId: field.idx || nanoid(), // Preserve original field ID if exists
      };
    },

    handleAdd(evt) {
      if (evt.added) {
        // Create a deep copy of the current form
        const updatedForm = JSON.parse(JSON.stringify(this.currentOffer.form));
        const addedItem = evt.added.element;

        // Ensure unique ID
        if (!addedItem.idx) {
          addedItem.idx = nanoid();
        }

        // Ensure all component fields in tables have unique idx
        if (
          addedItem.type === "table" &&
          Array.isArray(addedItem.componentFields)
        ) {
          addedItem.componentFields = addedItem.componentFields.map((field) => {
            return {
              ...field,
              idx: field.idx || "nested-" + nanoid(),
            };
          });
        }

        // Additional check to prevent duplicate keys
        const currentItems =
          updatedForm.pages[this.pageIdx].sections[this.sectionIdx].columns[
            this.columnIdx
          ].items;

        const isDuplicate = currentItems.some(
          (item) => item.idx === addedItem.idx
        );

        if (isDuplicate) {
          addedItem.idx = nanoid(); // Regenerate if duplicate found
        }

        // Update the items array
        updatedForm.pages[this.pageIdx].sections[this.sectionIdx].columns[
          this.columnIdx
        ].items = [...currentItems, addedItem];

        // Dispatch the update
        this.$store.dispatch("patchCurrentOffer", { form: updatedForm });
      }
    },

    isLastChild(index) {
      return index === this.localfieldsArr.length - 1;
    },
  },
};
</script>

<style scoped>
.handle {
  cursor: pointer;
}
.ghost {
  border: 2px dotted rgb(134, 134, 134);
}
.draggable-card {
  background-color: #ffffff;
  display: block;
  min-height: 80px;
}
.w-100 {
  width: 100%;
}
.div-item > .last-pad:last-child {
  margin-bottom: 26px;
}
</style>

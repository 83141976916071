<template>
  <div class="px-0">
    <!-- File size limit warning -->
    <v-alert
      v-if="fileToUpload && fileToUpload.size > effectiveMaxFileSize"
      color="primary"
      text
      class="mt-2"
      dismissible
    >
      File size exceeds {{ formatFileSize(effectiveMaxFileSize) }} limit
    </v-alert>

    <!-- File count limit warning -->
    <v-alert
      v-if="
        resources.length === effectiveMaxFiles - 1 || showApproachingLimitAlert
      "
      text
      class="mt-2"
      dismissible
      color="primary"
      @input="clearApproachingLimitAlert"
    >
      <v-icon left color="primary">info</v-icon>
      <strong>Approaching file limit:</strong> You have
      {{ resources.length }} of {{ effectiveMaxFiles }} allowed files.
    </v-alert>

    <!-- Max file limit reached warning -->
    <v-alert
      v-if="resources.length >= effectiveMaxFiles || showMaxFilesAlert"
      color="primary"
      text
      class="mt-2"
      dismissible
      @input="clearMaxFilesAlert"
    >
      <v-icon left color="primary">error</v-icon>
      <strong>Maximum file limit reached:</strong> You have
      {{ resources.length }} of {{ effectiveMaxFiles }} allowed files. Please
      delete some files first.
    </v-alert>

    <!-- Upload errors -->
    <v-alert
      v-if="uploadErrors.length > 0"
      color="primary"
      class="mt-2"
      text
      dismissible
      @input="clearUploadErrors"
    >
      <ul>
        <li v-for="(error, index) in uploadErrors" :key="index + '-' + error">
          {{ error }}
        </li>
      </ul>
    </v-alert>

    <!-- Dropzone -->
    <vue-dropzone
      ref="dropzone"
      id="offer-resources-dropzone"
      :options="_dropzoneOptions || {}"
      :useCustomSlot="true"
      @vdropzone-file-added="handleDropzoneAddedFile"
      @vdropzone-file-removed="handleDropzoneRemovedFile"
      @vdropzone-error="(file, message) => handleDropzoneError(file, message)"
      @vdropzone-sending="handleDropzoneSending"
      @vdropzone-success="handleDropzoneSuccess"
      class="mt-4 mb-6"
      :class="{ 'disabled-dropzone': resources.length >= effectiveMaxFiles }"
    >
      <div class="dropzone-custom-content">
        <!-- Preview Mode chip -->
        <v-chip
          small
          color="grey lighten-2"
          class="preview-mode-chip"
          text-color="grey darken-2"
        >
          Preview Mode
        </v-chip>

        <!-- File Counter - simple text only -->
        <div class="file-counter-text">
          {{ resources.length }}/{{ effectiveMaxFiles }} files
        </div>

        <!-- Resources Grid -->
        <v-container fluid class="pa-0">
          <v-row class="resources-grid" :key="renderKey">
            <!-- Current Resources -->
            <v-col
              cols="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              v-for="resource in resources"
              :key="resource.id"
              class="pa-2"
            >
              <v-card
                flat
                outlined
                class="resource-card d-flex flex-column"
                :class="{
                  'primary--border': false,
                  'preview-file': resource.isPreviewFile,
                }"
                style="position: relative; aspect-ratio: 1/1"
                @click.stop
                v-on:mouseover="setHoveredResource(resource.id)"
                v-on:mouseleave="setHoveredResource(null)"
                :style="
                  resource.id === hoveredResourceId
                    ? 'border-color: ' +
                      $vuetify.theme.currentTheme.primary +
                      ' !important'
                    : ''
                "
              >
                <!-- Deletion progress indicator -->
                <v-progress-linear
                  v-if="resource.isDeleting"
                  indeterminate
                  color="primary"
                  height="2"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 10;
                  "
                ></v-progress-linear>

                <v-card-text
                  class="d-flex flex-column align-center justify-center text-center h-100 pa-2"
                  style="
                    padding-left: 12px !important;
                    padding-right: 12px !important;
                  "
                >
                  <!-- Delete button -->
                  <v-btn
                    icon
                    x-small
                    class="delete-btn"
                    @click.stop="handleDeleteResource(resource)"
                    :disabled="resource.isDeleting"
                    :loading="resource.isDeleting"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>

                  <v-icon
                    size="48"
                    :color="fileIcon(getResourceDisplayName(resource)).color"
                    class="mb-4 file-icon"
                    >{{
                      fileIcon(getResourceDisplayName(resource)).icon
                    }}</v-icon
                  >

                  <vue-clamp class="filename-text" :max-lines="2">
                    {{ getResourceDisplayName(resource) }}
                  </vue-clamp>
                  <div class="text-caption">
                    {{ getFileSize(resource) }}
                    <small v-if="false" class="debug-info">
                      Debug: {{ resource.description }} | Size:
                      {{ resource.originalSize }}
                    </small>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Processing Files -->
            <v-col
              cols="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              v-for="(file, index) in processingQueue"
              :key="`processing-${index}`"
              class="pa-2"
            >
              <v-card
                elevation="2"
                class="loading-card d-flex flex-column align-center justify-center"
                style="position: relative; aspect-ratio: 1/1"
              >
                <v-progress-linear
                  indeterminate
                  color="primary"
                  height="3"
                  style="position: absolute; top: 0; left: 0; right: 0"
                ></v-progress-linear>
                <div class="text-caption text-center px-2">
                  <div class="filename-text" style="font-weight: 500">
                    {{ file.name }}
                  </div>
                  <div class="text-caption">Processing...</div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <!-- Empty State -->
          <div
            v-if="resources.length === 0 && processingQueue.length === 0"
            class="empty-state"
          >
            <v-icon size="48" color="primary" class="mb-2">cloud_upload</v-icon>
            <div class="empty-state-text">Drop files here...</div>
            <v-btn class="primary mb-4 mt-3" elevation="0" small
              >Browse Files</v-btn
            >
          </div>

          <!-- Limit Reached State -->
          <div
            v-if="
              resources.length >= effectiveMaxFiles &&
              processingQueue.length === 0
            "
            class="empty-state limit-reached"
          >
            <v-icon size="48" color="error" class="mb-2">error_outline</v-icon>
            <div class="empty-state-text limit-text">
              Maximum file limit reached ({{ effectiveMaxFiles }})
            </div>
            <div class="empty-state-text">
              Please delete some files before uploading more.
            </div>
          </div>
        </v-container>

        <!-- File info text at bottom -->
        <div class="file-info-text">
          Maximum file size: {{ formatFileSize(effectiveMaxFileSize) }} |
          Maximum files: {{ effectiveMaxFiles }} | File types:
          {{
            effectiveAcceptedFileExtensions.map((ext) => "." + ext).join(" ")
          }}
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueClamp from "vue-clamp";

export default {
  name: "PreviewFileDropzone",
  components: {
    vueDropzone: vue2Dropzone,
    VueClamp,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    entryId: {
      type: String,
      default: null,
    },
    // Preview-specific props
    pageIdx: {
      type: Number,
      default: 0,
    },
    fieldId: {
      type: String,
      default: null,
    },
    sectionIdx: {
      type: Number,
      default: 0,
    },
    columnIdx: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    isProcessing: false,
    fileDescription: "",
    fileToUpload: null,
    fileUploading: false,
    fileUploadingProgress: 0,
    fileUploadTask: null,
    uploadErrors: [],
    retryAttempts: 0,
    maxRetryAttempts: 3,
    uploadStatus: {
      progress: 0,
      status: "", // 'uploading', 'processing', 'complete', 'error'
      message: "",
    },
    fileMetadata: {
      size: "",
      type: "",
      lastModified: "",
      dimensions: null, // for images
    },
    // Keep track of processed files
    processingQueue: [],
    // Flag to track if processing is in progress
    isProcessingQueue: false,
    // Key to force re-rendering
    renderKey: 0,
    hoveredResourceId: null,
    // Add tracking for uploaded files to show in snackbar
    lastUploadedFiles: [],
    isCheckingOrphans: false,
    // Alert visibility flags
    showMaxFilesAlert: false,
    showApproachingLimitAlert: false,
    // Alert timers
    errorTimer: null,
    maxFilesAlertTimer: null,
    approachingLimitTimer: null,
    // Initial resources array to store files for this field
    fieldResources: [],
    maxFileSize: 10 * 1024 * 1024, // 10MB
    maxFiles: 12,
    acceptedFileExtensions: [
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "pdf",
      "jpg",
      "jpeg",
      "png",
      "csv",
    ],
  }),

  created() {
    console.log(
      "[PreviewFileDropzone] Component created with field:",
      this.field
    );
    if (this.field) {
      console.log("[PreviewFileDropzone] Field type:", this.field.type);
      console.log("[PreviewFileDropzone] Field properties:", {
        label: this.field.label,
        maxFiles: this.field.maxFiles,
        maxFileSize: this.field.maxFileSize,
        permittedFileExtensions: this.field.permittedFileExtensions,
      });
    } else {
      console.warn("[PreviewFileDropzone] No field prop provided!");
    }

    // Initialize options before dropzone is created
    this._dropzoneOptions = {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: this.effectiveMaxFileSize / (1024 * 1024),
      maxFiles: this.effectiveMaxFiles,
      acceptedFiles:
        this.acceptedFileTypes ||
        ".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv",
      addRemoveLinks: false,
      autoProcessQueue: false,
      dictDefaultMessage: "Drop files here to upload",
      dictFileTooBig:
        "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
      dictInvalidFileType: "You can't upload files of this type.",
      dictResponseError: "Server responded with {{statusCode}} code.",
      dictMaxFilesExceeded: "You can not upload any more files.",
      previewsContainer: false,
      disablePreviews: true,
      previewTemplate: "<div></div>",
      createImageThumbnails: false,
      // Override internal methods to prevent errors
      accept: (file, done) => {
        // Always accept files in preview mode
        if (typeof done === "function") {
          done();
        }
        return true;
      },
    };

    console.log(
      "[PreviewFileDropzone] Using dropzone options:",
      this._dropzoneOptions
    );

    this.initialized = true;
  },

  mounted() {
    try {
      console.log("[PreviewFileDropzone] Component mounted");

      // Ensure dropzone is properly initialized
      if (this.$refs.dropzone && this.$refs.dropzone.dropzone) {
        // Set any additional configuration after mount if needed
        console.log("[PreviewFileDropzone] Dropzone successfully initialized");
      } else {
        console.warn(
          "[PreviewFileDropzone] Dropzone initialization failed or not complete"
        );

        // Give it a moment to initialize if needed
        this.$nextTick(() => {
          if (this.$refs.dropzone && this.$refs.dropzone.dropzone) {
            console.log(
              "[PreviewFileDropzone] Dropzone initialized on nextTick"
            );
            this.updateDropzoneMaxFiles();
          }
        });
      }
    } catch (error) {
      console.error(
        "[PreviewFileDropzone] Error during component mount:",
        error
      );
    }
  },

  watch: {
    // Watch resources array to update maxFiles dynamically
    resources: {
      handler(newResources, oldResources) {
        this.updateDropzoneMaxFiles();

        // Show max files alert if we've just reached the maximum
        if (
          newResources.length >= this.effectiveMaxFiles &&
          (!oldResources || oldResources.length < this.effectiveMaxFiles)
        ) {
          this.setMaxFilesAlert();
        }

        // Show approaching limit alert if we're one away from maximum
        if (
          newResources.length === this.effectiveMaxFiles - 1 &&
          (!oldResources || oldResources.length < this.effectiveMaxFiles - 1)
        ) {
          this.setApproachingLimitAlert();
        }
      },
      deep: true,
    },
    // Watch for changes in max files limit
    effectiveMaxFiles: {
      handler(newLimit, oldLimit) {
        // If the current resource count now exceeds the new limit, show alert
        if (
          this.resources.length >= newLimit &&
          (!oldLimit || this.resources.length < oldLimit)
        ) {
          this.setMaxFilesAlert();
        }
        // If approaching the new limit
        else if (this.resources.length === newLimit - 1) {
          this.setApproachingLimitAlert();
        }
      },
    },
    // Watch field prop for changes to configuration
    field: {
      handler() {
        console.log(
          "[FileDropzone] Field prop changed, updating configuration"
        );
        // Update dropzone config through computed properties
        if (this.$refs.dropzone && this.$refs.dropzone.dropzone) {
          this.$refs.dropzone.dropzone.options.maxFilesize =
            this.effectiveMaxFileSize / (1024 * 1024);
          this.$refs.dropzone.dropzone.options.maxFiles =
            this.effectiveMaxFiles;
          this.$refs.dropzone.dropzone.options.acceptedFiles =
            this.acceptedFileTypes;
        }
        this.updateDropzoneMaxFiles();
      },
      deep: true,
    },
    // Watch for changes to showMaxFilesAlert
    showMaxFilesAlert: {
      handler(isShown) {
        if (isShown) {
          // Ensure timer is set when alert becomes visible
          console.log(
            "[PreviewFileDropzone] showMaxFilesAlert changed to true, ensuring timer is set"
          );

          // Clear any existing timer first
          if (this.maxFilesAlertTimer) {
            clearTimeout(this.maxFilesAlertTimer);
            this.maxFilesAlertTimer = null;
          }

          // Set timer for auto-dismiss
          this.maxFilesAlertTimer = setTimeout(() => {
            console.log(
              "[PreviewFileDropzone] Auto-dismissing max files alert from watcher"
            );
            this.showMaxFilesAlert = false;
            this.maxFilesAlertTimer = null;
          }, 10000);
        }
      },
    },
  },

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    // Add property to explicitly indicate preview mode
    isPreview() {
      return true; // This component is specifically for preview mode
    },
    programId() {
      return this.$store.getters.programId;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    resources: {
      get() {
        // Use value prop for preview functionality
        return this.value || [];
      },
      set(newValue) {
        // Emit input event to update parent's value
        this.$emit("input", newValue);
      },
    },
    // Get maxFileSize from field prop with fallback
    effectiveMaxFileSize() {
      // Return field.maxFileSize if provided, otherwise use default
      return (this.field && this.field.maxFileSize) || 10 * 1024 * 1024; // Default to 10MB
    },
    // Get maxFiles from field prop with fallback
    effectiveMaxFiles() {
      // Return field.maxFiles if provided, otherwise use default
      return (this.field && this.field.maxFiles) || 12; // Default to 12 files
    },
    // Get acceptedFileExtensions from field prop with fallback
    effectiveAcceptedFileExtensions() {
      // First check if field exists
      if (!this.field) {
        console.warn(
          "[PreviewFileDropzone] Field prop is missing, using default extensions"
        );
        return [
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "pdf",
          "jpg",
          "jpeg",
          "png",
          "csv",
        ];
      }

      // Check for permittedFileExtensions
      if (
        this.field.permittedFileExtensions &&
        Array.isArray(this.field.permittedFileExtensions)
      ) {
        return this.field.permittedFileExtensions;
      }

      // Return default if not found
      return [
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "pdf",
        "jpg",
        "jpeg",
        "png",
        "csv",
      ];
    },
    // Computed property to format accepted file extensions for dropzone
    acceptedFileTypes() {
      const extensions = this.effectiveAcceptedFileExtensions;
      return extensions.map((ext) => `.${ext}`).join(",");
    },
    // Field identifier for form preview
    fieldIdentifier() {
      return `field_${this.pageIdx}_${this.sectionIdx}_${this.columnIdx}`;
    },
    // Check if we're in entry or form builder mode
    isEntryMode() {
      return false;
    },
    // Get dropzone options from field prop with fallbacks
    dropzoneOptions() {
      // Default base configuration
      const baseOptions = {
        url: "https://httpbin.org/post", // This is a dummy URL, we'll handle uploads manually
        thumbnailWidth: 150,
        addRemoveLinks: false, // Disable default remove links
        dictFileTooBig:
          "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
        dictInvalidFileType: "You can't upload files of this type.",
        dictResponseError: "Server responded with {{statusCode}} code.",
        dictCancelUpload: "Cancel upload",
        dictUploadCanceled: "Upload canceled.",
        dictRemoveFile: "Remove file",
        dictMaxFilesExceeded: "You can not upload any more files.",
        createImageThumbnails: false, // Disable automatic thumbnails
        autoProcessQueue: false, // Disable auto-processing - we handle this manually
        previewsContainer: false, // Disable default previews container
        disablePreviews: true, // Disable default previews
        previewTemplate: "<div></div>", // Empty preview template
        parallelUploads: 3, // Allow parallel uploads
        chunking: false, // Disable chunking for better binary file handling
        forceChunking: false, // Never force chunking
        uploadMultiple: false, // Handle one file at a time for better control

        // CRITICAL: These options are essential for binary file handling
        renameFile: (file) => {
          // Don't rename files, preserve original names
          return file.name;
        },
        transformFile: null, // Disable file transformation to preserve binary data
        resizeWidth: null, // Don't resize images
        resizeHeight: null, // Don't resize images
        resizeQuality: 1, // Keep full quality if resizing happens
        resizeMethod: "contain", // Don't crop images

        // Improve binary file handling
        binaryMimeTypes: [
          ".pdf",
          ".doc",
          ".docx",
          ".xls",
          ".xlsx",
          ".csv",
          ".ppt",
          ".pptx",
          ".bin",
          ".exe",
          ".zip",
        ],
        timeout: 120000, // Increase timeout for larger files (2 minutes)
      };

      // Default values for field-specific options
      const defaults = {
        maxFilesize: 10, // 10MB by default
        maxFiles: 5,
        dictDefaultMessage: "Drop files here to upload",
        autoProcessQueue: true,
      };

      // If field is missing completely, return defaults
      if (!this.field) {
        console.warn(
          "[PreviewFileDropzone] Field prop is missing, using default dropzone options"
        );
        return {
          ...baseOptions,
          ...defaults,
          acceptedFiles: this.acceptedFileTypes,
        };
      }

      // Merge baseOptions with field-specific options
      return {
        ...baseOptions,
        maxFilesize: this.effectiveMaxFileSize / (1024 * 1024),
        maxFiles: this.effectiveMaxFiles,
        dictDefaultMessage:
          this.field.placeholder || defaults.dictDefaultMessage,
        acceptedFiles: this.acceptedFileTypes,
        autoProcessQueue: true,
      };
    },
  },

  methods: {
    /**
     * Handle dropzone sending event to prevent errors
     */
    handleDropzoneSending(file, xhr, formData) {
      console.log(
        "[PreviewFileDropzone] Dropzone sending file:",
        file?.name || "unknown file"
      );
      // We don't need to do anything here in preview mode, but this helps prevent errors
      try {
        // Add any extra form data if needed
        if (formData) {
          formData.append("preview_mode", "true");
        }
      } catch (error) {
        console.error("[PreviewFileDropzone] Error in sending handler:", error);
      }
    },

    /**
     * Process a file for upload including validation and UI updates
     */
    processFile(file) {
      try {
        // Validate file
        if (!file || typeof file !== "object") {
          console.warn(
            "[PreviewFileDropzone] Invalid file object received for processing"
          );

          // Create a dummy file object if the original is invalid
          file = {
            name: `unnamed_file_${Date.now()}`,
            size: 0,
            type: "application/octet-stream",
            lastModified: Date.now(),
          };
        }

        console.log("[PreviewFileDropzone] Processing file:", file.name);

        // Validate file before processing
        const validationErrors = this.validateFile(file);
        if (validationErrors.length > 0) {
          this.setUploadErrors(validationErrors);
          throw new Error(
            `File validation failed: ${validationErrors.join(", ")}`
          );
        }

        // Check max files limit
        if (this.resources.length >= this.effectiveMaxFiles) {
          throw new Error(
            `Maximum file limit of ${this.effectiveMaxFiles} reached.`
          );
        }

        // Make sure the file has a name
        if (!file.name) {
          console.error(
            "[PreviewFileDropzone] File missing name property:",
            file
          );
          file.name = `unnamed_file_${Date.now()}`;
        }

        // Mark the file as processing
        if (!file.isProcessing) {
          file.isProcessing = true;
          this.forceRender();
        }

        this.uploadStatus = {
          progress: 0,
          status: "uploading",
          message: `Preparing ${file.name} for upload...`,
        };

        // Simulate upload progress
        let progress = 0;
        const progressInterval = setInterval(() => {
          progress += 10;
          this.uploadStatus = {
            progress: progress,
            status: "uploading",
            message: `Uploading ${file.name}... ${progress.toFixed(0)}%`,
          };
          this.forceRender();

          if (progress >= 100) {
            clearInterval(progressInterval);
            this.simulateUploadComplete(file);
          }
        }, 300);

        // Return a promise that resolves when the "upload" is complete
        return new Promise((resolve) => {
          setTimeout(resolve, 3000);
        });
      } catch (error) {
        console.error(
          `[PreviewFileDropzone] Error processing ${
            file?.name || "unknown file"
          }:`,
          error
        );

        // Check if this is a known validation error
        if (error.message && error.message.includes("File validation failed")) {
          // The error was already handled in validateFile
        } else if (
          error.message &&
          error.message.includes("Maximum file limit")
        ) {
          this.setMaxFilesAlert();
        } else {
          // Handle other errors with the generic file type error
          this.showFileTypeError();
        }

        throw error;
      }
    },

    /**
     * Handle deleting a resource
     */
    handleDeleteResource(resource) {
      try {
        if (!resource) {
          console.warn(
            "[PreviewFileDropzone] Delete attempted on null resource"
          );
          return;
        }

        console.log(
          `[PreviewFileDropzone] Deleting resource: ${
            resource.name || "unknown"
          }`
        );

        // Remove from display list
        const index = this.resources.findIndex((r) => r.id === resource.id);
        if (index !== -1) {
          // Get the resource name before removal
          const resourceName = this.getResourceDisplayName(resource);

          this.resources.splice(index, 1);

          // Attempt to remove from dropzone if it exists
          if (this.$refs.dropzone && this.$refs.dropzone.dropzone) {
            try {
              const mockFile = {
                name: resource.name,
                size: resource.size,
                upload: { uuid: resource.id },
              };
              this.$refs.dropzone.removeFile(mockFile);
            } catch (dropzoneError) {
              console.warn(
                "[PreviewFileDropzone] Error removing file from dropzone:",
                dropzoneError
              );
            }
          }

          // Show success message with actual filename
          this.showSnackbar({
            text: `${resourceName} deleted`,
            color: "success",
          });
        } else {
          console.warn(
            `[PreviewFileDropzone] Resource ${
              resource.name || "unknown"
            } not found for deletion`
          );
        }
      } catch (error) {
        console.error(
          "[PreviewFileDropzone] Error in handleDeleteResource:",
          error
        );
        this.showSnackbar({
          text: "Error deleting file",
          color: "error",
        });
      }
    },

    /**
     * Handle delete confirmation dialog
     */
    handleDeleteIconClick(resource) {
      if (confirm(`Are you sure you want to delete ${resource.name}?`)) {
        this.handleDeleteResource(resource);
      }
    },

    /**
     * Save resources to the entry
     */
    async saveResourcesToEntry(resources) {
      // In preview mode, just update the local state
      this.resources = resources;
      console.log(`[PreviewFileDropzone] Resources updated in preview mode`);
    },

    /**
     * Update dropzone max files limit based on current resource count
     */
    updateDropzoneMaxFiles() {
      // If dropzone is initialized
      if (this.$refs.dropzone && this.$refs.dropzone.dropzone) {
        // Adjust max files dynamically based on remaining slots
        const totalAllowed = this.effectiveMaxFiles;
        const currentCount = this.resources.length;
        const remainingSlots = Math.max(1, totalAllowed - currentCount);

        // Update the dropzone options
        this.$refs.dropzone.dropzone.options.maxFiles = remainingSlots;
        console.log(
          `[PreviewFileDropzone] Updated maxFiles to ${remainingSlots} (${currentCount}/${totalAllowed} used)`
        );
      }
    },

    /**
     * Generate a UUID for resource identification
     */
    generateResourceId() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    /**
     * Event handler for when a file is added to the dropzone
     */
    onFileAdded(file) {
      console.log("[PreviewFileDropzone] File added:", file.name);
    },

    /**
     * Event handler for successful upload
     */
    onUploadSuccess(file) {
      console.log("[PreviewFileDropzone] Upload success:", file.name);
    },

    /**
     * Event handler for upload error
     */
    onUploadError(_file, message) {
      console.error("[PreviewFileDropzone] Upload error:", message);
    },

    /**
     * Event handler for upload progress
     */
    onUploadProgress() {
      // No-op in preview mode
    },

    /**
     * Get file icon based on file extension
     */
    fileIcon(filename) {
      const defaultIcon = { icon: "fas fa-file", color: "grey" };

      // Basic validation
      if (!filename || typeof filename !== "string" || filename.trim() === "") {
        return defaultIcon;
      }

      try {
        // Remove any version number in parentheses before extracting extension
        const cleanedFilename = filename.replace(/ \(\d+\)$/, "");

        // Check if filename contains a period to ensure we can extract an extension
        if (!cleanedFilename.includes(".")) {
          return defaultIcon;
        }

        const ext = cleanedFilename.split(".").pop().toLowerCase();
        if (!ext) return defaultIcon;

        // Map of file extensions to icons and colors
        const iconMap = {
          pdf: { icon: "fas fa-file-pdf", color: "red" },
          doc: { icon: "fas fa-file-word", color: "blue" },
          docx: { icon: "fas fa-file-word", color: "blue" },
          xls: { icon: "fas fa-file-excel", color: "green" },
          xlsx: { icon: "fas fa-file-excel", color: "green" },
          csv: { icon: "fas fa-file-csv", color: "green" },
          ppt: { icon: "fas fa-file-powerpoint", color: "orange" },
          pptx: { icon: "fas fa-file-powerpoint", color: "orange" },
          jpg: { icon: "fas fa-file-image", color: "purple" },
          jpeg: { icon: "fas fa-file-image", color: "purple" },
          png: { icon: "fas fa-file-image", color: "purple" },
        };

        return iconMap[ext] || defaultIcon;
      } catch (error) {
        console.error(
          "[PreviewFileDropzone] Error determining file icon:",
          error
        );
        return defaultIcon;
      }
    },

    /**
     * Check if a file is of an accepted type
     */
    accept(filename) {
      // Basic validation
      if (!filename || typeof filename !== "string" || filename.trim() === "") {
        return false;
      }

      // Check if filename contains a period to ensure we can extract an extension
      if (!filename.includes(".")) {
        return false;
      }

      try {
        const ext = filename.split(".").pop().toLowerCase();
        if (!ext) return false;

        // Check if the extension is in our accepted list
        return this.effectiveAcceptedFileExtensions.includes(ext.toLowerCase());
      } catch (error) {
        console.error("[PreviewFileDropzone] Error checking file type:", error);
        return false; // Reject if any error occurs
      }
    },

    /**
     * Get a formatted message about permitted file types
     */
    getPermittedFilesMessage() {
      const permittedFiles = this.effectiveAcceptedFileExtensions
        .map((ext) => `.${ext}`)
        .join(", ");
      return `File type is not allowed. Files must be ${permittedFiles}`;
    },

    /**
     * Show file type error with consistent messaging
     */
    showFileTypeError() {
      const permittedFiles = this.effectiveAcceptedFileExtensions
        .map((ext) => `.${ext}`)
        .join(", ");
      this.setUploadErrors(
        `File type is not allowed. Files must be ${permittedFiles}`
      );

      // Update UI immediately
      this.renderKey++;
      this.$forceUpdate();
    },

    /**
     * Format file size in human-readable format
     */
    formatFileSize(bytes) {
      // Ensure bytes is a number and not NaN
      if (!bytes || isNaN(bytes) || bytes === 0) {
        console.log(
          "[PreviewFileDropzone] Formatting zero or invalid file size:",
          bytes
        );
        return "Unknown size";
      }

      try {
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
      } catch (error) {
        console.error(
          "[PreviewFileDropzone] Error formatting file size:",
          error
        );
        return "Unknown size";
      }
    },

    /**
     * Simulator for upload completion
     */
    async simulateUploadComplete(file) {
      this.uploadStatus = {
        progress: 100,
        status: "complete",
        message: `Upload complete!`,
      };

      // Log file size for debugging
      console.log(`[PreviewFileDropzone] File original size:`, file.size);

      // Generate a blob with appropriate content based on file type
      let contentBlob;
      const fileType = file.type || this.guessFileTypeFromName(file.name);

      // Use appropriate dummy content based on file type
      if (fileType.includes("image/")) {
        // For images, create a small colored rectangle
        const canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = 200;
        const ctx = canvas.getContext("2d");
        // Different colors for different image types
        const colorMap = {
          "image/jpeg": "#3498db",
          "image/png": "#2ecc71",
          "image/gif": "#e74c3c",
        };
        const color = colorMap[fileType] || "#9b59b6";
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, 200, 200);

        // Add some text to indicate it's a preview
        ctx.fillStyle = "white";
        ctx.font = "16px Arial";
        ctx.textAlign = "center";
        ctx.fillText("Preview Mode", 100, 100);
        ctx.fillText(file.name, 100, 120);

        // Get the blob from the canvas
        contentBlob = await new Promise((resolve) =>
          canvas.toBlob(resolve, fileType)
        );
      } else if (fileType.includes("pdf")) {
        // Use larger content for PDFs
        contentBlob = new Blob(
          ["%PDF-1.5\nThis is a simulated PDF preview file.".padEnd(2048, " ")],
          { type: "application/pdf" }
        );
      } else if (fileType.includes("word") || fileType.includes("doc")) {
        // Use larger content for docs
        contentBlob = new Blob(
          ["This is a simulated Word document preview.".padEnd(2048, " ")],
          { type: fileType }
        );
      } else if (
        fileType.includes("excel") ||
        fileType.includes("sheet") ||
        fileType.includes("csv")
      ) {
        // Use larger content for spreadsheets
        contentBlob = new Blob(
          ["This is a simulated spreadsheet preview.".padEnd(2048, " ")],
          { type: fileType }
        );
      } else {
        // Default for other file types
        contentBlob = new Blob(
          [
            `This is a simulated file (${file.name}) in preview mode.`.padEnd(
              2048,
              " "
            ),
          ],
          { type: fileType }
        );
      }

      // Ensure the blob has a minimum size if original size is missing
      const effectiveSize = file.size || contentBlob.size || 2048;

      // Create a fake download URL for preview
      const downloadURL = URL.createObjectURL(contentBlob);

      // Generate a unique ID for the resource
      const resourceId = Date.now() + Math.random();
      let displayName = file.name || `File ${resourceId}`;

      // Check for existing resources with the same name
      const existingResources = this.resources.filter((resource) => {
        const cleanResourceName =
          resource.description?.replace(/ \(\d+\)$/, "") || "";
        const cleanDisplayName = displayName.replace(/ \(\d+\)$/, "");
        return cleanResourceName === cleanDisplayName;
      });

      if (existingResources.length > 0) {
        const baseName = displayName.replace(/ \(\d+\)$/, "");
        displayName = `${baseName} (${existingResources.length})`;
      }

      // Create file metadata
      const fileMetadata = {
        size: this.formatFileSize(effectiveSize),
        type: fileType,
        lastModified: new Date(
          file.lastModified || Date.now()
        ).toLocaleString(),
      };

      // Debug log for metadata
      console.log(`[PreviewFileDropzone] Created metadata:`, {
        originalSize: file.size,
        blobSize: contentBlob.size,
        formattedSize: fileMetadata.size,
      });

      // Create the new resource object
      const newResource = {
        id: resourceId,
        type: "file",
        description: displayName,
        filename: displayName,
        url: downloadURL,
        created: new Date(),
        metadata: fileMetadata,
        storageRef: `preview-mode-storage-ref-${resourceId}`,
        isPreviewFile: true,
        // Ensure we preserve the file size
        originalSize: effectiveSize,
        size: effectiveSize,
      };

      // Debug log for metadata
      console.log(`[PreviewFileDropzone] Original file:`, {
        name: file.name,
        size: file.size,
        type: file.type,
      });

      // Create an enhanced file object with additional properties for display
      const enhancedFile = {
        ...file,
        id: Date.now() + Math.random(),
        isProcessing: true,
        name: file.name || `unnamed_file_${Date.now()}`,
        // Explicitly preserve size (in case the spread doesn't copy it correctly)
        size: file.size || (file.size === 0 ? 1024 : file.size), // If size is 0, default to 1KB
      };

      // Log the enhanced file for debugging
      console.log(`[PreviewFileDropzone] Enhanced file:`, {
        name: enhancedFile.name,
        size: enhancedFile.size,
        type: enhancedFile.type,
      });

      // Update local state
      this.processingQueue = this.processingQueue.filter(
        (item) => item !== file
      );

      // Add the new resource
      const updatedResources = [...this.resources, newResource];
      this.resources = updatedResources;

      // Force UI update
      this.renderKey++;
      this.$forceUpdate();

      // Save to entry in the background
      await this.saveResourcesToEntry(updatedResources);

      console.log(
        `[PreviewFileDropzone] Preview file simulated: ${displayName}`
      );

      // Show success message in snackbar
      this.$store.dispatch(
        "setSnackbar",
        `${displayName} was simulated in preview mode (no actual upload)`
      );

      // Clean internal state
      this.fileUploading = false;
      this.fileUploadingProgress = 0;
      this.fileUploadTask = null;
      this.fileToUpload = null;
      this.fileDescription = "";
      this.fileMetadata = {
        size: "",
        type: "",
        lastModified: "",
        dimensions: null,
      };

      // Add to last uploaded files for notification
      if (!this.lastUploadedFiles) {
        this.lastUploadedFiles = [];
      }
      this.lastUploadedFiles.push(displayName);
    },

    /**
     * Helper to guess file type from filename
     */
    guessFileTypeFromName(filename) {
      if (!filename) return "application/octet-stream";

      const ext = filename.split(".").pop().toLowerCase();
      const typeMap = {
        pdf: "application/pdf",
        doc: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ppt: "application/vnd.ms-powerpoint",
        pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        csv: "text/csv",
        txt: "text/plain",
      };

      return typeMap[ext] || "application/octet-stream";
    },

    /**
     * Improved method to force re-render
     */
    forceRender() {
      this.renderKey++;
      this.$forceUpdate(); // Force the component to re-render
    },

    /**
     * Set upload errors with auto-dismiss timer
     */
    setUploadErrors(errors) {
      this.uploadErrors = Array.isArray(errors) ? errors : [errors];

      // Clear any existing timer
      if (this.errorTimer) {
        clearTimeout(this.errorTimer);
      }

      // Set a new timer to clear errors after 5 seconds
      this.errorTimer = setTimeout(() => {
        this.clearUploadErrors();
      }, 5000);
    },

    /**
     * Clear upload errors
     */
    clearUploadErrors() {
      this.uploadErrors = [];
      if (this.errorTimer) {
        clearTimeout(this.errorTimer);
        this.errorTimer = null;
      }
    },

    /**
     * Set max files alert with auto-dismiss timer
     */
    setMaxFilesAlert() {
      this.showMaxFilesAlert = true;

      // Clear any existing timer
      if (this.maxFilesAlertTimer) {
        clearTimeout(this.maxFilesAlertTimer);
        this.maxFilesAlertTimer = null;
      }

      // Log that we're setting the timer for debugging
      console.log(
        "[PreviewFileDropzone] Setting max files alert timer for auto-dismiss"
      );

      // Set a new timer to clear alert after 10 seconds (increased from 5 seconds)
      this.maxFilesAlertTimer = setTimeout(() => {
        console.log(
          "[PreviewFileDropzone] Auto-dismissing max files alert after timer completion"
        );
        this.clearMaxFilesAlert();
      }, 10000);

      // Verify the timer was created
      console.log(
        "[PreviewFileDropzone] Max files alert timer set:",
        this.maxFilesAlertTimer ? "Yes" : "No"
      );
    },

    /**
     * Clear max files alert
     */
    clearMaxFilesAlert() {
      console.log("[PreviewFileDropzone] Clearing max files alert");
      this.showMaxFilesAlert = false;
      if (this.maxFilesAlertTimer) {
        console.log("[PreviewFileDropzone] Clearing max files alert timer");
        clearTimeout(this.maxFilesAlertTimer);
        this.maxFilesAlertTimer = null;
      }
    },

    /**
     * Set approaching limit alert with auto-dismiss timer
     */
    setApproachingLimitAlert() {
      this.showApproachingLimitAlert = true;

      // Clear any existing timer
      if (this.approachingLimitTimer) {
        clearTimeout(this.approachingLimitTimer);
      }

      // Set a new timer to clear alert after 5 seconds
      this.approachingLimitTimer = setTimeout(() => {
        this.clearApproachingLimitAlert();
      }, 5000);
    },

    /**
     * Clear approaching limit alert
     */
    clearApproachingLimitAlert() {
      this.showApproachingLimitAlert = false;
      if (this.approachingLimitTimer) {
        clearTimeout(this.approachingLimitTimer);
        this.approachingLimitTimer = null;
      }
    },

    /**
     * Get resource display name
     */
    getResourceDisplayName(resource) {
      // If filename or description is missing, use "Unknown file" as fallback
      if (!resource || (!resource.description && !resource.filename)) {
        return "Unknown file";
      }

      // Use description as primary source, fallback to filename if needed
      return resource.description || resource.filename;
    },

    // Dropzone event handlers
    handleDropzoneAddedFile(file) {
      try {
        // Validate the file
        if (!file || !file.name) {
          console.warn("[PreviewFileDropzone] Invalid file added to dropzone");
          return;
        }

        // Debug file info
        console.log("[PreviewFileDropzone] File added:", {
          name: file.name,
          size: file.size,
          type: file.type || "unknown",
        });

        // Validate the file before proceeding
        const errors = this.validateFile(file);
        if (errors.length > 0) {
          this.setUploadErrors(errors);
          if (this.$refs.dropzone) {
            try {
              this.$refs.dropzone.removeFile(file);
            } catch (e) {
              console.error("[PreviewFileDropzone] Error removing file:", e);
            }
          }
          return;
        }

        // Check if this file is already in the processing queue (prevent duplicates)
        const isDuplicate = this.processingQueue.some(
          (queuedFile) =>
            queuedFile.name === file.name && queuedFile.size === file.size
        );

        if (isDuplicate) {
          console.log(
            `[PreviewFileDropzone] Preventing duplicate processing of file: ${file.name}`
          );
          return;
        }

        // Check if this file already exists in the resources list (already uploaded)
        const fileAlreadyUploaded = this.resources.some(
          (resource) =>
            resource.description === file.name ||
            resource.filename === file.name
        );

        if (fileAlreadyUploaded) {
          console.log(
            `[PreviewFileDropzone] File already exists in resources: ${file.name}`
          );
          this.$store.dispatch("setSnackbar", `${file.name} already exists`);
          return;
        }

        // Check if max files limit is reached
        if (this.resources.length >= this.effectiveMaxFiles) {
          console.error("[PreviewFileDropzone] Maximum file limit reached");
          this.setUploadErrors(
            `You've reached the maximum of ${this.effectiveMaxFiles} files. Please remove some files before adding more.`
          );
          this.setMaxFilesAlert();
          if (this.$refs.dropzone) {
            try {
              this.$refs.dropzone.removeFile(file);
            } catch (e) {
              console.error("[PreviewFileDropzone] Error removing file:", e);
            }
          }
          return;
        }

        // Check if approaching max files limit
        if (this.resources.length === this.effectiveMaxFiles - 1) {
          this.setApproachingLimitAlert();
        }

        // Create an enhanced file object with additional properties for display
        const enhancedFile = {
          ...file,
          id: Date.now() + Math.random(),
          isProcessing: true,
          name: file.name || `unnamed_file_${Date.now()}`,
          // Explicitly preserve size (in case the spread doesn't copy it correctly)
          size: file.size || (file.size === 0 ? 1024 : file.size), // If size is 0, default to 1KB
        };

        // Log the enhanced file for debugging
        console.log(`[PreviewFileDropzone] Enhanced file:`, {
          name: enhancedFile.name,
          size: enhancedFile.size,
          type: enhancedFile.type,
        });

        // Add to queue
        this.processingQueue = [...this.processingQueue, enhancedFile];

        // Force IMMEDIATE render to show loading cards
        this.renderKey++;
        this.$forceUpdate();

        // Show uploading state immediately
        this.fileUploading = true;
        this.uploadStatus = {
          progress: 20,
          status: "uploading",
          message: `Preparing ${this.processingQueue.length} ${
            this.processingQueue.length === 1 ? "file" : "files"
          } for upload...`,
        };

        // Shorter delay before processing to improve responsiveness
        setTimeout(() => {
          if (!this.isProcessingQueue) {
            this.processNextFileInQueue();
          }
        }, 100);
      } catch (error) {
        console.error(
          "[PreviewFileDropzone] Error in handleDropzoneAddedFile:",
          error
        );
      }
    },

    handleDropzoneRemovedFile(file) {
      // Remove from processing queue if present
      if (file?.name) {
        this.processingQueue = this.processingQueue.filter(
          (item) => item.name !== file.name
        );
        this.forceRender();
      }
    },

    handleDropzoneProcessing() {
      // Dropzone processing
    },

    handleDropzoneSuccess(file) {
      try {
        if (!file) {
          console.warn(
            "[PreviewFileDropzone] handleSuccess called with null file"
          );
          return;
        }

        console.log(
          `[PreviewFileDropzone] File upload success: ${
            file.name || "unknown file"
          }`
        );

        // In preview mode, we don't actually upload files to the server
        // But we simulate the behavior for demonstration

        // Find the file in the displayedResources array
        const fileIndex = this.resources.findIndex(
          (item) => item.id === file.upload.uuid
        );

        if (fileIndex !== -1) {
          // Update the file status
          this.$set(this.resources[fileIndex], "status", "success");
          this.$set(this.resources[fileIndex], "progress", 100);
        } else {
          console.warn(
            `[PreviewFileDropzone] Could not find file ${
              file.name || "unknown"
            } in displayed resources`
          );
        }

        // Process next file in queue if available
        this.processNextFileInQueue();
      } catch (error) {
        console.error(`[PreviewFileDropzone] Error in handleSuccess:`, error);
      }
    },

    handleDropzoneError(file, message) {
      try {
        if (!file) {
          console.warn("[PreviewFileDropzone] Error with null/undefined file");
          return;
        }

        const fileName = file.name || "unknown file";
        console.error(
          `[PreviewFileDropzone] Upload error for ${fileName}:`,
          message
        );

        // Check if this is a file type error
        if (
          message &&
          (message.toLowerCase().includes("file type") ||
            message.toLowerCase().includes("mime") ||
            !this.accept(fileName))
        ) {
          // Show specific file type error with permitted extensions
          this.showFileTypeError();
        }
        // Check if this is a file size error
        else if (message && message.toLowerCase().includes("file size")) {
          this.setUploadErrors(
            `File "${fileName}" exceeds the maximum file size of ${this.formatFileSize(
              this.effectiveMaxFileSize
            )}.`
          );
        }
        // Generic error
        else {
          // Add to upload errors
          this.uploadErrors.push(
            `Error uploading ${fileName}: ${
              message || "Unknown error"
            } [${Date.now()}]`
          );
        }

        // Remove from dropzone
        if (this.$refs.dropzone && file) {
          try {
            this.$refs.dropzone.removeFile(file);
          } catch (removeError) {
            console.error(
              "[PreviewFileDropzone] Error removing file from dropzone:",
              removeError
            );
          }
        }

        // Find the file in resources if it exists
        const fileIndex = this.resources.findIndex(
          (item) => item.id === (file.upload && file.upload.uuid)
        );

        if (fileIndex !== -1) {
          // Update file status to error
          this.$set(this.resources[fileIndex], "status", "error");
          this.$set(
            this.resources[fileIndex],
            "error",
            message || "Upload failed"
          );
        }

        // Show error message
        this.showSnackbar({
          text: `Failed to upload ${fileName}`,
          color: "error",
        });

        // Continue with next file in queue
        this.processNextFileInQueue();
      } catch (error) {
        console.error(
          "[PreviewFileDropzone] Error in handleDropzoneError:",
          error
        );
      }
    },

    handleDropzoneQueueComplete() {
      // Dropzone queue complete
    },

    handleDropzoneTotalUploadProgress() {
      // Dropzone upload progress
    },

    handleDropzoneClick(event) {
      // Prevent opening file dialog if max files limit is reached
      if (this.resources.length >= this.effectiveMaxFiles) {
        console.log(
          `[PreviewFileDropzone] Preventing file dialog open: max limit (${this.effectiveMaxFiles}) reached`
        );
        this.$store.dispatch(
          "setSnackbar",
          `Maximum file limit (${this.effectiveMaxFiles}) reached. Please delete some files first.`
        );
        this.setMaxFilesAlert();
        // Prevent default click behavior
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        return false;
      }
      // Check if approaching max files limit
      else if (this.resources.length === this.effectiveMaxFiles - 1) {
        this.setApproachingLimitAlert();
      }
    },

    // Queue processing
    async processNextFileInQueue() {
      try {
        // Check queue state
        if (!this.processingQueue || this.processingQueue.length === 0) {
          this.isProcessingQueue = false;

          // Reset upload status when queue is empty
          this.fileUploading = false;
          this.uploadStatus = {
            progress: 0,
            status: "idle",
            message: "",
          };
          return;
        }

        // Prevent concurrent processing
        if (this.isProcessingQueue) {
          return;
        }

        // Check max files limit to prevent batch uploads from exceeding limit
        if (this.resources.length >= this.effectiveMaxFiles) {
          console.error(
            `[PreviewFileDropzone] Max files limit (${this.effectiveMaxFiles}) reached, canceling pending uploads`
          );

          // Remove all files from processing queue
          const pendingCount = this.processingQueue.length;
          this.setUploadErrors(
            `You've reached the maximum of ${this.effectiveMaxFiles} files. Please remove some files first.`
          );
          this.setMaxFilesAlert();

          // Show message in snackbar
          this.$store.dispatch(
            "setSnackbar",
            `Maximum file limit (${this.effectiveMaxFiles}) reached. Canceled ${pendingCount} pending upload(s).`
          );

          // Get the names of canceled files for better user feedback
          const canceledFiles = this.processingQueue
            .map((file) => file.name || "unnamed")
            .join(", ");
          console.log(
            `[PreviewFileDropzone] Canceled uploads: ${canceledFiles}`
          );

          // Clear the processing queue
          this.processingQueue = [];

          // Force render update
          this.renderKey++;
          this.$forceUpdate();

          // Reset processing state
          this.isProcessingQueue = false;
          this.fileUploading = false;
          this.uploadStatus = {
            progress: 0,
            status: "idle",
            message: "",
          };
          return;
        }

        this.isProcessingQueue = true;

        // Get the first file without removing it yet
        const file =
          this.processingQueue.length > 0 ? this.processingQueue[0] : null;

        // Verify we have a valid file object
        if (!file || typeof file !== "object") {
          console.error("[PreviewFileDropzone] Invalid file in queue:", file);
          // Remove invalid file and retry
          this.processingQueue = this.processingQueue.slice(1);
          this.isProcessingQueue = false;
          if (this.processingQueue.length > 0) {
            // Process next file immediately
            this.processNextFileInQueue();
          }
          return;
        }

        try {
          // Process the file - this now handles UI updates internally
          await this.processFile(file);

          // Update global status
          this.uploadStatus = {
            progress: Math.min(
              90,
              Math.round(
                ((this.processingQueue.indexOf(file) + 1) /
                  this.processingQueue.length) *
                  100
              )
            ),
            status: "processing",
            message: `Processed ${file.name || "file"}, ${
              this.processingQueue.length - 1
            } files remaining`,
          };
        } catch (error) {
          console.error(
            `[PreviewFileDropzone] Error processing ${file.name || "file"}:`,
            error
          );
          this.showFileTypeError();
        } finally {
          // Always remove the file from the queue
          this.processingQueue = this.processingQueue.filter(
            (item) => item !== file
          );

          // Explicitly force render after queue update
          this.renderKey++;
          this.$forceUpdate();

          // Reset the processing flag
          this.isProcessingQueue = false;

          if (this.processingQueue.length > 0) {
            // Process the next file in the queue immediately
            this.processNextFileInQueue();
          } else {
            // Queue is now empty - all files processed
            this.fileUploading = false;
            this.uploadStatus = {
              progress: 100,
              status: "complete",
              message: "All files uploaded successfully",
            };

            // Show snackbar message based on number of files uploaded
            if (this.lastUploadedFiles && this.lastUploadedFiles.length > 0) {
              if (this.lastUploadedFiles.length === 1) {
                // Single file upload
                this.$store.dispatch(
                  "setSnackbar",
                  `${this.lastUploadedFiles[0]} uploaded successfully`
                );
              } else {
                // Multiple file upload
                this.$store.dispatch(
                  "setSnackbar",
                  `${this.lastUploadedFiles.length} files uploaded successfully`
                );
              }
              // Clear the tracking array
              this.lastUploadedFiles = [];
            }

            // Clear complete message after 1 second
            setTimeout(() => {
              if (this.uploadStatus.status === "complete") {
                this.uploadStatus = {
                  progress: 0,
                  status: "idle",
                  message: "",
                };
              }
            }, 1000);
          }
        }
      } catch (error) {
        console.error(
          "[PreviewFileDropzone] Error in processNextFileInQueue:",
          error
        );
        this.isProcessingQueue = false;
      }
    },

    openResource(url) {
      window.open(url, "_blank");
    },

    setHoveredResource(id) {
      this.hoveredResourceId = id;
    },

    formatFileTypes() {
      // Compress the list of file types for display
      const extensions = this.effectiveAcceptedFileExtensions.map(
        (ext) => "." + ext
      );
      // If more than 5 extensions, show first 4 then "and X more"
      if (extensions.length > 5) {
        return (
          extensions.slice(0, 4).join(", ") +
          ` and ${extensions.length - 4} more`
        );
      }
      return extensions.join(", ");
    },

    showSnackbar(options) {
      try {
        if (this.$store && this.$store.dispatch) {
          // Use the store if available
          this.$store.dispatch(
            "setSnackbar",
            options.text || "Operation completed"
          );
        } else {
          // Fallback to console if store not available
          console.log(
            `[PreviewFileDropzone] Snackbar message: ${
              options.text || "Operation completed"
            }`
          );
        }
      } catch (error) {
        console.error("[PreviewFileDropzone] Error showing snackbar:", error);
      }
    },

    /**
     * Get file size with fallbacks for various scenarios
     */
    getFileSize(resource) {
      // Check all possible places where size might be stored
      if (resource.metadata && resource.metadata.size) {
        return resource.metadata.size;
      }

      if (resource.originalSize) {
        return this.formatFileSize(resource.originalSize);
      }

      if (resource.size) {
        return this.formatFileSize(resource.size);
      }

      // Return a debug-friendly fallback
      return "1.00 KB"; // Default 1KB size for preview
    },

    /**
     * Validate a file for upload
     * @param {File} file The file to validate
     * @returns {Array} Array of error messages, empty if valid
     */
    validateFile(file) {
      const errors = [];

      // Check if file exists and has a name property
      if (!file || !file.name) {
        errors.push("Please select a valid file to upload.");
        return errors;
      }

      // Check file size
      if (file.size > this.effectiveMaxFileSize) {
        errors.push(
          `File is too large. Maximum file size is ${this.formatFileSize(
            this.effectiveMaxFileSize
          )}.`
        );
      }

      // Check file type
      if (!this.accept(file.name)) {
        // Use a method to generate the permitted files message
        const permittedFilesMessage = this.getPermittedFilesMessage();
        errors.push(permittedFilesMessage);
      }

      // Check for malicious file names
      if (file.name.includes("..") || file.name.includes("/")) {
        errors.push("Invalid file name");
      }

      return errors;
    },
  },
};
</script>

<style>
/* Hide default dropzone styling */
#offer-resources-dropzone .dz-preview {
  display: none !important;
}

/* Custom styling for our dropzone */
#offer-resources-dropzone {
  min-height: 250px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background: transparent;
  padding: 20px !important;
  /* Remove transition to prevent border fading */
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

#offer-resources-dropzone:hover {
  /* Removed border-color change on hover */
  /* Keep the dashed style and width consistent */
  border-style: dashed;
  border-width: 2px;
}

#offer-resources-dropzone .dropzone-custom-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;
  padding: 0;
}

/* Resources grid spacing */
.resources-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; /* Increased from -10px to create more space between rows */
}

.resources-grid .v-col {
  padding: 15px !important; /* Increased from 10px to create more space between rows */
  margin-bottom: 10px !important; /* Added explicit bottom margin for more spacing between rows */
}

/* Loading card styling adjustments */
.resources-grid .loading-card {
  position: relative;
  border: 1px solid var(--v-primary-base) !important;
  background-color: white !important;
  min-height: 100px;
  z-index: 3 !important;
  opacity: 1 !important;
  visibility: visible !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  margin: 0 auto;
  max-width: 200px;
  aspect-ratio: 1/1;
  border-radius: 8px !important;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--v-primary-base), 0.4);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(var(--v-primary-base), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--v-primary-base), 0);
  }
}

.resource-card .delete-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2 !important;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
  text-align: center;
  color: #777;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Resource card layout improvements */
.resource-card {
  display: flex !important;
  flex-direction: column !important;
  max-width: 200px !important;
  width: 100% !important;
  margin: 0 auto !important;
  aspect-ratio: 1 / 1 !important;
  z-index: 2 !important;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px !important;
}

.resource-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  transform: translateY(-2px);
}

.resource-card .v-card__text {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  padding: 16px 12px !important;
}

.resource-card .delete-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.resource-card:hover .delete-btn {
  opacity: 1;
}

/* Clickable card styling */
.clickable-card {
  cursor: pointer;
  transition: all 0.2s ease;
}

.clickable-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.filename-text {
  font-size: 12px !important;
  line-height: 1.2 !important;
  color: var(--v-primary-base) !important;
  word-break: break-word !important;
  text-align: center !important;
  max-width: 100% !important;
  min-height: unset !important;
  margin-bottom: 4px !important;
  display: block !important;
  font-weight: 500 !important;
}

/* Processing card text */
.loading-card .text-caption {
  font-size: 10px !important;
  line-height: 1.2 !important;
}

/* Disabled dropzone styling */
.disabled-dropzone {
  cursor: not-allowed !important;
  background-color: transparent !important;
  border-color: #ccc !important;
  /* Ensure no transitions for border */
  transition: none !important;
}

.disabled-dropzone:hover {
  background-color: transparent !important;
  border-color: #ccc !important;
  border-style: dashed !important;
  border-width: 2px !important;
}

.disabled-dropzone * {
  pointer-events: none;
}

/* Override pointer-events for delete buttons */
.disabled-dropzone .resource-card .delete-btn {
  pointer-events: auto;
}

/* Simple file counter text styling */
.file-counter-text {
  position: absolute;
  top: -35px;
  right: 0px;
  font-size: 12px;
  font-weight: 500;
  color: #666;
  z-index: 5;
}

/* Change color when limit is reached */
.disabled-dropzone .file-counter-text {
  color: #f44336; /* Red color when limit reached */
  font-weight: 600;
}

/* File info text at bottom of dropzone */
.file-info-text {
  position: absolute;
  bottom: -35px;
  right: -8px;
  font-size: 11px;
  font-weight: 500;
  color: #666;
  z-index: 5;
  text-align: right;
  padding: 4px 8px;
  max-width: 90%;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Change color when limit is reached */
.disabled-dropzone .file-info-text {
  color: #666; /* Keep same color when disabled */
}

/* Empty state text styling to match counter/info text */
.empty-state-text {
  font-size: 12px;
  font-weight: 500;
  color: #666;
  margin-bottom: 6px;
}

/* Limit Reached Text */
.limit-text {
  color: #f44336; /* Red color when limit reached */
  font-weight: 600;
}

.preview-chip {
  position: absolute !important;
  top: 4px !important;
  left: 4px !important;
  z-index: 5 !important;
  font-size: 9px !important;
  height: 20px !important;
  opacity: 0.9 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.preview-file {
  position: relative;
  overflow: hidden;
}

.file-icon {
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
  transition: all 0.2s ease;
}

.resource-card:hover .file-icon {
  transform: scale(1.1);
}

/* Styles for Preview Mode chip */
.preview-mode-chip {
  position: absolute !important;
  top: -30px !important;
  left: -5px !important;
  z-index: 5 !important;
  font-size: 11px !important;
  height: 22px !important;
  opacity: 0.9 !important;
  font-weight: 500 !important;
}
</style>

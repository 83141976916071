<template>
  <div class="form-data-results">
    <!-- Loading state -->
    <div v-if="isLoading" class="text-center py-4">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <div class="mt-2">Processing form data...</div>
    </div>

    <!-- Form data display -->
    <template v-else>
      <div class="form-results-container">
        <template v-if="showStepper">
          <!-- Stepper for multi-page forms -->
          <v-stepper v-model="currentStep" class="elevation-0">
            <v-stepper-header class="elevation-0">
              <!-- Render each step and divider directly -->
              <div
                v-for="(page, index) in formPages"
                :key="`page-container-${index}`"
                class="d-flex"
              >
                <v-stepper-step
                  :key="`page-${index}`"
                  :complete="currentStep > index + 1"
                  :step="index + 1"
                  color="primary"
                >
                  {{ page.title || `Page ${index + 1}` }}
                </v-stepper-step>
                <v-divider
                  v-if="index < formPages.length - 1"
                  :key="`divider-${index}`"
                ></v-divider>
              </div>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                v-for="(page, pageIndex) in formPages"
                :key="`content-${pageIndex}`"
                :step="pageIndex + 1"
              >
                <div
                  v-for="(section, sectionIndex) in page.sections"
                  :key="`section-${pageIndex}-${sectionIndex}`"
                  class="mb-6"
                >
                  <div class="pa-4 mb-4">
                    <v-row>
                      <v-col
                        v-for="(column, columnIndex) in section.columns"
                        :key="`column-${pageIndex}-${sectionIndex}-${columnIndex}`"
                        :cols="12"
                        :sm="12 / section.columns.length"
                      >
                        <div
                          v-for="(field, fieldIndex) in column.items"
                          :key="`field-${pageIndex}-${sectionIndex}-${columnIndex}-${fieldIndex}`"
                          class="mb-4"
                        >
                          <field-display
                            :field="field"
                            :value="getFieldValue(field)"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>

        <!-- Single page display when no stepper -->
        <template v-else>
          <div class="pa-4">
            <div
              v-for="(section, sectionIndex) in formPages[0]?.sections || []"
              :key="`section-0-${sectionIndex}`"
              class="mb-6"
            >
              <div class="pa-4 mb-4">
                <v-row>
                  <v-col
                    v-for="(column, columnIndex) in section.columns"
                    :key="`column-0-${sectionIndex}-${columnIndex}`"
                    :cols="12"
                    :sm="12 / section.columns.length"
                  >
                    <div
                      v-for="(field, fieldIndex) in column.items"
                      :key="`field-0-${sectionIndex}-${columnIndex}-${fieldIndex}`"
                      class="mb-4"
                    >
                      <field-display
                        :field="field"
                        :value="getFieldValue(field)"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- Actions -->
      <v-card-actions>
        <v-btn text color="grey darken-1" @click="downloadCsv" class="mr-2">
          <v-icon left>download</v-icon>
          Download CSV
        </v-btn>
        <v-btn text color="grey darken-1" @click="showRawData = !showRawData">
          {{ showRawData ? "Hide" : "Show" }} Raw Data
        </v-btn>
        <v-spacer></v-spacer>
        <!-- Navigation buttons for stepper -->
        <template v-if="showStepper">
          <v-btn
            v-if="currentStep > 1"
            color="primary"
            elevation="0"
            @click="currentStep--"
          >
            Previous
          </v-btn>
          <v-btn
            v-if="currentStep < formPages.length"
            color="primary"
            elevation="0"
            @click="currentStep++"
          >
            Next
          </v-btn>
        </template>
      </v-card-actions>

      <!-- Raw data display -->
      <div v-if="showRawData">
        <v-card-text>
          <span>Raw Form Data</span>
          <v-sheet outlined class="pa-2 mt-4">
            <pre class="raw-data">{{ formattedResults }}</pre>
          </v-sheet>
        </v-card-text>
      </div>
    </template>
  </div>
</template>

<script>
import FieldDisplay from "../FormBuilder/Preview/FieldDisplay.vue";

export default {
  components: {
    FieldDisplay,
  },
  props: {
    structuredFormValues: {
      type: Object,
      default: () => ({}),
    },
    formPages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      errorMessage: "",
      currentStep: 1,
      showRawData: false,
      presetRecordsCache: {}, // Cache for preset records by group ID
    };
  },
  computed: {
    formattedResults() {
      return JSON.stringify(this.structuredFormValues, null, 2);
    },
    isEmpty() {
      return Object.keys(this.structuredFormValues).length === 0;
    },
    hasError() {
      return !!this.errorMessage;
    },
    showStepper() {
      return this.formPages.length > 1;
    },
  },
  methods: {
    getFieldValue(field) {
      if (!field.idx || !this.structuredFormValues[field.idx]) {
        return null;
      }

      const fieldData = JSON.parse(
        JSON.stringify(this.structuredFormValues[field.idx])
      );

      if (field.type === "radio" && fieldData.allOptions) {
        fieldData.allOptions.forEach((opt) => {
          opt.isSelected = opt.value === fieldData.value;
        });
      }

      if (field.type === "table" && Array.isArray(fieldData.value)) {
        fieldData.value = this.processTableData(field, [...fieldData.value]);
      }

      return fieldData.value;
    },

    processTableData(tableField, tableData) {
      if (!tableField.componentFields || !Array.isArray(tableData)) {
        return tableData;
      }

      return tableData.map((row) => {
        const processedRow = { ...row };

        tableField.componentFields.forEach((field) => {
          if (field.type === "dropdown") {
            const fieldId = field.idx || `field-${field.label}`;
            const value = row[fieldId];
            const pointsKey = `${fieldId}_points`;

            if (value) {
              processedRow[fieldId] = this.getDropdownDisplayValue(
                field,
                value
              );
            }

            if (row[pointsKey] !== undefined) {
              processedRow[pointsKey] = row[pointsKey];
            }
          }
        });

        return processedRow;
      });
    },

    getDropdownDisplayValue(field, value) {
      if (value === null || value === undefined || value === "") {
        return "Not selected";
      }

      if (field.presets && field.presets.presetsType === "dynamic") {
        const selectedItem = this.findSelectedDynamicItem(field, value);
        if (selectedItem) {
          return this.generateDisplayText(field, selectedItem);
        }
        return value;
      }

      if (field.presets && field.presets.staticOptions) {
        const option = field.presets.staticOptions.find(
          (opt) => (typeof opt === "object" ? opt.value : opt) === value
        );
        if (option) {
          return typeof option === "object" ? option.text : option;
        }
      }

      return value;
    },

    findSelectedDynamicItem(field, value) {
      if (!field?.presets?.selectedPresetsGroup) return null;

      const groupId = field.presets.selectedPresetsGroup;
      const records = this.presetRecordsCache[groupId];

      if (records && Array.isArray(records)) {
        return records.find((item) => item.identifier === value);
      }

      return null;
    },

    generateDisplayText(field, item) {
      if (!item) return "-";

      if (!field.presets?.valuesToDisplay?.length) {
        return item.identifier || "-";
      }

      const displayValues = field.presets.valuesToDisplay;

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean);

      return displayParts.length > 0
        ? displayParts.join(" • ")
        : item.identifier || "-";
    },

    downloadCsv() {
      const formattedData = this.formatFormDataForCsv();
      if (!formattedData) return;

      const { headers, data } = formattedData;
      const csvContent = [headers.join(","), data.join(",")].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "form_data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    formatFormDataForCsv() {
      if (!this.formPages) return null;

      const fields = this.formPages.reduce((acc, page) => {
        page.sections.forEach((section) => {
          section.columns.forEach((column) => {
            column.items.forEach((field) => {
              acc.push(field);
            });
          });
        });
        return acc;
      }, []);

      const headers = fields
        .map((field) => {
          if (field.type === "table") {
            const tableHeaders = field.componentFields
              .filter((f) => f.active)
              .map((f) => `${field.label} - ${f.label}`);
            return tableHeaders;
          }
          return field.label;
        })
        .flat();

      const data = fields
        .map((field) => {
          const value = this.getFieldValue(field);

          if (field.type === "table" && Array.isArray(value)) {
            const tableData = value.map((row) => {
              return field.componentFields
                .filter((f) => f.active)
                .map((f) => {
                  const fieldId = f.idx || `field-${f.label}`;
                  return row[`${fieldId}_display`] || row[fieldId] || "";
                });
            });

            return tableData.map((row) => row.join(", ")).join("; ");
          }

          if (typeof value === "object") {
            return JSON.stringify(value);
          }
          return value || "";
        })
        .flat();

      return {
        headers,
        data,
      };
    },

    getTableHeaders(field) {
      if (!field.componentFields) return [];

      const headers = field.componentFields.map((subField) => ({
        text: subField.label || "Column",
        value: subField.idx || `field-${subField.label}`,
        sortable: true,
        align:
          subField.type === "dropdown" &&
          subField.presets?.valuesToDisplay?.includes("points")
            ? "right"
            : "start",
        width: subField.cols ? `${subField.cols * 8}%` : "auto",
      }));

      // Add points columns for fields that support points
      field.componentFields.forEach((subField) => {
        if (this.isPointsEnabledField(subField)) {
          headers.push({
            text: "Points",
            value: `${subField.idx || `field-${subField.label}`}_points`,
            align: "right",
            sortable: true,
            width: "auto",
          });
        }
      });

      return headers;
    },

    getColumnSlotName(header) {
      return header.value.replace(/[^a-zA-Z0-9]/g, "_");
    },

    getPointsColumnCount(field) {
      return (
        field.componentFields?.filter((subField) =>
          this.isPointsEnabledField(subField)
        ).length || 0
      );
    },

    getFieldFromPointsHeader(header) {
      if (!header.isPointsColumn) return null;

      const baseFieldId = header.value.replace("_points", "");
      return this.field?.componentFields?.find(
        (field) => (field.idx || `field-${field.label}`) === baseFieldId
      );
    },

    getSubFieldValue(item, subField) {
      const fieldId = subField.idx || `field-${subField.label}`;
      const value = item[fieldId];

      // Create a field data object that matches what getFieldValue expects
      return {
        value,
        allOptions: subField.type === "radio" ? subField.allOptions : undefined,
        // Add any other necessary field data properties
      };
    },
  },
};
</script>

<style scoped>
.form-data-results {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.form-results-container {
  max-height: calc(80vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}

.raw-data {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  white-space: pre-wrap;
  max-height: 300px;
  overflow-y: auto;
  font-size: 12px;
  font-family: monospace;
}

.v-stepper__header {
  box-shadow: none !important;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>

<template>
  <div class="field-container">
    <!-- Text field -->
    <v-text-field
      v-if="field.type === 'textfield'"
      ref="field"
      :label="field.label"
      :placeholder="field.placeholder"
      :prepend-icon="field.prependIcon"
      :clearable="field.clearable"
      :required="field.required"
      outlined
      dense
      background-color="white"
      :value="value"
      @input="updateValue"
      :error="hasError || !!field.validationError"
      :error-messages="getErrorMessages"
      :rules="validationRules"
    ></v-text-field>

    <!-- Textarea -->
    <v-textarea
      v-if="field.type === 'textarea'"
      :label="field.label"
      :placeholder="field.placeholder"
      :clearable="field.clearable"
      :required="field.required"
      outlined
      dense
      background-color="white"
      :value="value"
      @input="updateValue"
    ></v-textarea>

    <!-- Dropdown / Autocomplete -->
    <v-autocomplete
      v-if="field.type === 'dropdown'"
      :items="getFieldOptions"
      :label="field.label"
      :placeholder="field.placeholder"
      :clearable="field.clearable"
      :required="field.required"
      outlined
      dense
      background-color="white"
      :item-text="getItemText"
      :item-value="getItemValue"
      :value="getSelectedValue"
      @input="handleDropdownChange"
      return-object
      :error="hasError || !!field.validationError"
      :error-messages="getErrorMessages"
    >
      <template v-slot:item="{ item }">
        <v-row no-gutters class="pa-2" v-if="usesDynamicPresets">
          <v-col>
            <div
              v-if="displayValueEnabled('identifier') && item.identifier"
              class="option-identifier"
            >
              {{ item.identifier }}
            </div>
            <div
              v-if="displayValueEnabled('title') && item.title"
              class="option-title"
            >
              {{ item.title }}
            </div>
            <div
              v-if="displayValueEnabled('description') && item.description"
              class="option-description"
            >
              {{ item.description }}
            </div>
            <div
              v-if="displayValueEnabled('points') && item.points"
              class="option-points"
            >
              {{ item.points }} points
            </div>
          </v-col>
        </v-row>
        <div v-else>{{ item.value || item }}</div>
      </template>
    </v-autocomplete>

    <!-- Radio buttons -->
    <div v-if="field.type === 'radio'">
      <div class="field-label mb-2">{{ field.label }}</div>
      <v-radio-group v-model="radioValue">
        <v-radio
          v-for="(option, index) in getFieldOptions"
          :key="`radio-${index}`"
          :value="usesDynamicPresets ? option.identifier : option.value"
          :label="getOptionLabel(option)"
        >
        </v-radio>
      </v-radio-group>
    </div>

    <!-- Checkboxes -->
    <div v-if="field.type === 'checkboxes'">
      <div class="field-label mb-2">{{ field.label }}</div>
      <div
        v-for="(option, index) in getFieldOptions"
        :key="`checkbox-${index}`"
      >
        <v-checkbox
          :label="getOptionLabel(option)"
          :value="usesDynamicPresets ? option.identifier : option.value"
          v-model="checkboxValue"
          hide-details
          class="mt-1"
        ></v-checkbox>
      </div>
    </div>

    <!-- Date picker -->
    <v-menu
      v-if="field.type === 'date-picker'"
      ref="dateMenu"
      v-model="dateMenu"
      :close-on-content-click="false"
      :close-on-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="value"
          :label="field.label"
          :placeholder="field.placeholder"
          prepend-inner-icon="calendar_month"
          readonly
          outlined
          dense
          v-bind="attrs"
          v-on="on"
          clearable
          @click:clear="updateValue('')"
        ></v-text-field>
      </template>
      <v-date-picker
        :value="value"
        @input="updateDateValue"
        scrollable
        no-title
      ></v-date-picker>
    </v-menu>

    <!-- Time picker -->
    <v-menu
      v-if="field.type === 'time-picker'"
      ref="timeMenu"
      v-model="timeMenu"
      :close-on-content-click="false"
      :close-on-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="value"
          :label="field.label"
          :placeholder="field.placeholder"
          prepend-inner-icon="schedule"
          readonly
          outlined
          dense
          v-bind="attrs"
          v-on="on"
          clearable
          @click:clear="updateValue('')"
        ></v-text-field>
      </template>
      <v-time-picker :value="value" @input="updateTimeValue"></v-time-picker>
    </v-menu>

    <!-- Switch -->
    <v-switch
      v-if="field.type === 'switch'"
      :label="field.label"
      :input-value="value"
      @change="updateValue"
      class="mt-0"
    ></v-switch>

    <!-- Slider -->
    <div v-if="field.type === 'slider'">
      <div class="field-label">{{ field.label }}</div>
      <v-slider
        :min="field.min || 0"
        :max="field.max || 100"
        :thumb-label="field.thumbLabel"
        :value="value"
        @input="updateValue"
      ></v-slider>
    </div>

    <!-- Table -->
    <div v-if="field.type === 'table'">
      <div class="field-label mb-2">{{ field.label }}</div>
      <preview-table
        :field="field"
        :value="value || [{}]"
        @input="updateValue"
        @edit-state-change="handleTableEditStateChange"
      ></preview-table>
    </div>

    <!-- Facebook -->
    <div v-if="field.type === 'facebook'">
      <preview-facebook
        :field="field"
        :value="value || {}"
        @input="updateValue"
      ></preview-facebook>
    </div>

    <!-- Dropzone -->
    <component-renderer
      v-if="field.type === 'dropzone'"
      :field="field"
      :value="value"
      :validation-error="validationError"
      :isPreview="isPreview"
      :entryId="getCurrentEntryId"
      @input="updateValue"
    ></component-renderer>

    <!-- Headings -->
    <div
      v-if="
        field.type === 'title' ||
        field.type === 'subtitle' ||
        field.type === 'note'
      "
    >
      <div
        class="text-content"
        :style="{
          fontSize: `${field.styles.size}px`,
          fontWeight: field.styles.weight,
          fontStyle: field.styles.style,
          color: field.styles.color,
          fontFamily: field.styles.font,
          lineHeight: field.styles.lineHeight,
          textAlign: getTextAlign(field.styles.floatText),
          marginLeft: `${field.styles.marginLeft}px`,
          marginRight: `${field.styles.marginRight}px`,
        }"
      >
        {{ field.styles.value }}
      </div>
    </div>
  </div>
</template>

<script>
import PreviewTable from "./PreviewTable.vue";
import PreviewFacebook from "./PreviewFacebook.vue";
import ComponentRenderer from "./ComponentRenderer.vue";

export default {
  components: {
    PreviewTable,
    PreviewFacebook,
    ComponentRenderer,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null,
    },
    validationError: {
      type: String,
      default: null,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateMenu: false,
      timeMenu: false,
      dynamicOptions: [],
      isLoadingOptions: false,
      localCheckboxValues: [],
    };
  },

  created() {
    //console.log("[PreviewField] Component created with field:", this.field);
    if (this.field) {
      //console.log("[PreviewField] Field type:", this.field.type);

      // Add specific logging for dropzone fields
      if (this.field.type === "dropzone") {
        //console.log(
        //  "[PreviewField] Found dropzone field - should be handled by parent ComponentRenderer"
        //);
      }
    }

    // Initialize checkbox values if needed
    if (
      this.field &&
      this.field.type === "checkboxes" &&
      Array.isArray(this.value)
    ) {
      this.localCheckboxValues = [...this.value];
    }

    // Load dynamic options if needed
    if (this.usesDynamicPresets && this.selectedPresetGroup) {
      this.loadDynamicOptions();
    }
  },
  computed: {
    usesDynamicPresets() {
      return this.field.presets && this.field.presets.presetsType === "dynamic";
    },

    getFieldOptions() {
      if (!this.field.presets) return [];

      if (this.field.presets.presetsType === "static") {
        return this.field.presets.staticOptions || [];
      } else if (this.field.presets.presetsType === "dynamic") {
        return this.dynamicOptions;
      }

      return [];
    },

    getItemText() {
      return this.usesDynamicPresets ? "displayText" : "value";
    },

    getItemValue() {
      return this.usesDynamicPresets ? "identifier" : "value";
    },

    selectedPresetGroup() {
      return this.field.presets && this.field.presets.selectedPresetsGroup;
    },

    // Get selected value for dropdown and radio
    getSelectedValue() {
      if (!this.value) return null;

      if (this.usesDynamicPresets) {
        // For dynamic presets, find the matching object by identifier
        if (typeof this.value === "object" && this.value.identifier) {
          // Value is already a complete object
          return this.value;
        } else {
          // Value is just the identifier, find the full object
          return (
            this.getFieldOptions.find(
              (option) => option.identifier === this.value
            ) || null
          );
        }
      } else {
        // For static presets or simple values
        const option = this.getFieldOptions.find((opt) =>
          typeof opt === "object"
            ? opt.value === this.value
            : opt === this.value
        );
        return option || this.value;
      }
    },

    // Use v-model for checkboxes
    checkboxValue: {
      get() {
        return Array.isArray(this.value) ? this.value : [];
      },
      set(newValue) {
        this.updateValue(newValue);
      },
    },

    radioValue: {
      get() {
        return this.value || "";
      },
      set(newValue) {
        // Update allOptions first
        if (this.field.allOptions) {
          this.field.allOptions.forEach((opt) => {
            opt.isSelected = this.usesDynamicPresets
              ? opt.identifier === newValue
              : opt.value === newValue;
          });
        }
        // Then update the value
        this.updateValue(newValue);
      },
    },

    hasError() {
      return this.errorMessages.length > 0;
    },

    getErrorMessages() {
      // Check for direct validation error on the field object first
      if (this.field.validationError) {
        return [this.field.validationError];
      }
      // Otherwise use the computed error messages
      return this.errorMessages;
    },

    errorMessages() {
      const messages = [];

      // Check required validation
      if (this.field.required && !this.value) {
        messages.push(`${this.field.label} is required`);
      }

      // Check static validation
      if (
        this.field.validation?.validationFormDataType === "static" &&
        this.field.validation?.staticValidationOptions?.length > 0 &&
        this.value
      ) {
        const isValid = this.field.validation.staticValidationOptions.some(
          (option) => option.value === this.value
        );
        if (!isValid) {
          messages.push(
            `${this.field.label} must match one of the allowed options`
          );
        }
      }

      // Check dynamic validation
      if (
        this.field.validation?.validationFormDataType === "dynamic" &&
        this.field.validation?.selectedFormValidationDataSet &&
        this.value
      ) {
        const dataSetId = this.field.validation.selectedFormValidationDataSet;
        const records = this.$store.state.presetRecords[dataSetId];

        if (!records || !Array.isArray(records)) {
          messages.push(`${this.field.label} is invalid`);
        } else {
          const isValid = records.some(
            (record) => record.identifier === this.value
          );
          if (!isValid) {
            messages.push(`${this.field.label} is invalid`);
          }
        }
      }

      return messages;
    },
    validationRules() {
      const rules = [];

      // Add required validation if field is required
      if (this.field.required) {
        rules.push((v) => !!v || `${this.field.label} is required`);
      }

      // Add validation against static options if configured
      if (
        this.field.validation?.validationFormDataType === "static" &&
        this.field.validation?.staticValidationOptions?.length > 0
      ) {
        rules.push(this.validateStaticOptions);
      }

      // Add validation against preset data set if configured
      if (
        this.field.validation?.validationFormDataType === "dynamic" &&
        this.field.validation?.selectedFormValidationDataSet
      ) {
        rules.push(this.validateDynamicOptions);
      }

      return rules;
    },

    getCurrentEntryId() {
      // Get the entryId from the LiveFormEntry props if available
      const liveFormEntryComponent = this.findParentComponent("LiveFormEntry");
      if (liveFormEntryComponent && liveFormEntryComponent.entryId) {
        return liveFormEntryComponent.entryId;
      }

      // Otherwise check the AddOrEditEntry component
      const addOrEditEntryComponent =
        this.findParentComponent("AddOrEditEntry");
      if (addOrEditEntryComponent && addOrEditEntryComponent.entryId) {
        return addOrEditEntryComponent.entryId;
      }

      // Return null if no entry ID can be found
      return null;
    },
  },

  watch: {
    selectedPresetGroup: {
      handler() {
        this.loadDynamicOptions();
      },
      immediate: true,
    },

    "field.presets.presetsType": {
      handler() {
        this.loadDynamicOptions();
      },
    },
  },
  methods: {
    findParentComponent(componentName) {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options.name === componentName) {
          return parent;
        }
        parent = parent.$parent;
      }
      return null;
    },

    updateValue(val) {
      // Clear validation errors when field is updated
      if (this.field.validationError) {
        this.field.validationError = null;
      }
      this.$emit("update:value", val);
    },

    updateDateValue(date) {
      this.updateValue(date);
      this.dateMenu = false;
    },

    updateTimeValue(time) {
      this.updateValue(time);
      this.timeMenu = false;
    },

    // Handle dropdown selection
    handleDropdownChange(selectedItem) {
      // If nothing is selected, pass null
      if (!selectedItem) {
        this.updateValue(null);
        return;
      }

      // For dynamic presets, store the complete object
      this.updateValue(selectedItem);
    },

    displayValueEnabled(field) {
      return (
        this.field.presets &&
        this.field.presets.valuesToDisplay &&
        this.field.presets.valuesToDisplay.includes(field)
      );
    },

    getOptionValue(option) {
      if (typeof option === "object") {
        return this.usesDynamicPresets ? option.identifier : option.value;
      }
      return option;
    },

    getOptionLabel(option) {
      if (typeof option === "object") {
        if (this.usesDynamicPresets) {
          // Build a label from the fields that should be displayed
          const parts = [];
          if (this.displayValueEnabled("identifier")) {
            parts.push(option.identifier);
          }
          if (this.displayValueEnabled("title")) {
            parts.push(option.title);
          }
          if (this.displayValueEnabled("description")) {
            parts.push(option.description);
          }
          return parts.join(" - ");
        }
        return option.value;
      }
      return option;
    },

    getTextAlign(floatText) {
      switch (floatText) {
        case "text-left":
          return "left";
        case "text-center":
          return "center";
        case "text-right":
          return "right";
        default:
          return "left";
      }
    },

    async loadDynamicOptions() {
      // Only load if using dynamic presets
      if (!this.usesDynamicPresets || !this.selectedPresetGroup) {
        this.dynamicOptions = [];
        return;
      }

      // Avoid loading multiple times at once
      if (this.isLoadingOptions) return;

      this.isLoadingOptions = true;

      try {
        // Call the Vuex action to get preset records
        const records = await this.$store.dispatch(
          "getPresetRecords",
          this.selectedPresetGroup
        );

        // Process the records
        if (Array.isArray(records)) {
          this.dynamicOptions = records
            .filter((item) => item.status === "Active")
            .map((item) => ({
              ...item,
              value: item.identifier, // Ensure value is set for compatibility
              text: this.generateDisplayText(item), // Set text for display
              displayText: this.generateDisplayText(item),
            }));

          // Emit the loaded options
          this.$root.$emit("dynamic-options-loaded", {
            fieldId: this.fieldId,
            options: this.dynamicOptions,
          });
        } else {
          this.dynamicOptions = [];
        }
      } catch (error) {
        console.error("Error loading preset options:", error);
        this.dynamicOptions = [];
      } finally {
        this.isLoadingOptions = false;
      }
    },

    generateDisplayText(item) {
      // Generate display text based on selected values to display
      const displayValues = this.field.presets?.valuesToDisplay || [];

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean);

      return displayParts.join(" • ");
    },

    isOptionSelected(option) {
      if (!this.value || !Array.isArray(this.value)) return false;
      const optionValue = this.getOptionValue(option);
      return this.value.includes(optionValue);
    },

    toggleOption(option) {
      const optionValue = this.getOptionValue(option);
      let newValue = Array.isArray(this.value) ? [...this.value] : [];

      if (newValue.includes(optionValue)) {
        newValue = newValue.filter((v) => v !== optionValue);
      } else {
        newValue.push(optionValue);
      }

      this.updateValue(newValue);
    },

    handleTableEditStateChange(isEditing) {
      console.log(
        "[PreviewField] Received edit-state-change",
        isEditing,
        "for field",
        this.fieldId,
        "- forwarding to parent"
      );
      this.$emit("table-edit-state-change", {
        fieldId: this.fieldId,
        isEditing,
      });
    },

    validateStaticOptions(v) {
      if (!v) return true; // Skip validation if no value and not required
      const isValid = this.field.validation.staticValidationOptions.some(
        (option) => option.value === v
      );
      return (
        isValid || `${this.field.label} must match one of the allowed options`
      );
    },

    async validateDynamicOptions(v) {
      if (!v) return true; // Skip validation if no value and not required

      try {
        const dataSetId = this.field.validation.selectedFormValidationDataSet;
        let records = this.$store.state.presetRecords[dataSetId];

        // If records are not loaded, try to load them
        if (!records || !Array.isArray(records)) {
          records = await this.$store.dispatch("getPresetRecords", dataSetId);
        }

        if (!records || !Array.isArray(records)) {
          return `${this.field.label} is invalid`;
        }

        const isValid = records.some((record) => record.identifier === v);
        return isValid || `${this.field.label} is invalid`;
      } catch (error) {
        console.error(`Error validating field ${this.field.label}:`, error);
        return `Error validating ${this.field.label}`;
      }
    },
  },
};
</script>

<style scoped>
.field-container {
  width: 100%;
}

.field-label {
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.option-identifier {
  font-family: "Figtree", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #494949de;
  line-height: 1;
}

.option-title {
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000000de;
  line-height: 1.3;
}

.option-description {
  font-family: "Figtree", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #a1a1a1;
  line-height: 1;
}

.option-points {
  font-family: "Figtree", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #a1a1a1;
  line-height: 1;
}

.text-content {
  margin-bottom: 16px;
}
</style>

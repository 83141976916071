<template>
  <div>
    <v-dialog v-model="addOrEditDialogOpen" max-width="400px" persistent>
      <v-card elevation="0">
        <form @submit.prevent="handleSave" data-vv-scope="opportunity">
          <v-row no-gutters class="page-heading ml-4 pt-4">
            {{ selectedItem.id ? "Edit " : "Add New " }}
            Opportunity
          </v-row>
          <v-card-text class="px-9">
            <v-text-field
              v-validate="'required'"
              :error-messages="errors.collect('opportunity.title')"
              data-vv-name="title"
              label="Title"
              v-model="selectedItem.title"
              name="title"
              type="text"
            />
            <v-text-field
              v-validate="'required'"
              :error-messages="errors.collect('opportunity.subtitle')"
              data-vv-name="subtitle"
              label="Subtitle"
              v-model="selectedItem.subtitle"
              name="subtitle"
              type="text"
            />
            <v-textarea
              v-validate="'required'"
              :error-messages="errors.collect('opportunity.description')"
              data-vv-name="description"
              label="Description"
              v-model="selectedItem.description"
              name="description"
              rows="3"
              auto-grow
            />
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="formatDate(selectedItem.deadline)"
                  clearable
                  label="Due Date"
                  readonly
                  v-on="on"
                  @click:clear="selectedItem.deadline = null"
                  v-validate="'required'"
                  :error-messages="errors.collect('opportunity.deadline')"
                  data-vv-name="deadline"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedItem.deadline"
                @change="dateMenu = false"
              ></v-date-picker>
            </v-menu>

            <v-text-field
              v-validate="'required'"
              :error-messages="errors.collect('opportunity.points')"
              data-vv-name="points"
              label="Points"
              v-model="selectedItem.points"
              name="points"
              type="number"
              @wheel="$event.target.blur()"
            />

            <v-checkbox
              label="Redeemable points"
              v-model="selectedItem.redeemable"
            ></v-checkbox>

            <v-checkbox
              v-model="selectedItem.addToDatabucket"
              label="Add Points to Data Bucket"
              hint="When checked, this opportunity will be added to the data bucket's Claims column"
              persistent-hint
            ></v-checkbox>

            <v-select
              v-model="selectedItem.databucket"
              :items="databuckets"
              item-text="title"
              item-value="id"
              label="Data Bucket"
              v-validate="'required'"
              :error-messages="errors.collect('opportunity.databucket')"
              data-vv-name="databucket"
              name="databucket"
            />

            <v-select
              v-model="selectedItem.targetCode"
              :items="targetCodes"
              label="Data Bucket Category"
              v-validate="'required'"
              :error-messages="errors.collect('opportunity.targetCode')"
              data-vv-name="targetCode"
              name="targetCode"
            />
          </v-card-text>
          <v-card-actions class="pb-2 pr-2">
            <v-spacer></v-spacer>
            <v-btn text @click="closeAddOrEditDialog"> Cancel </v-btn>
            <v-btn color="primary" type="submit" elevation="0" :disabled="demo">
              Save
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    addOrEditDialogOpen: { type: Boolean, required: true },
    selectedItem: { type: Object, required: true },
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
    if (this.selectedItem.addToDatabucket === undefined) {
      this.$set(this.selectedItem, "addToDatabucket", true);
    }
  },

  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => "Title is required",
        },
        subtitle: {
          required: () => "Subtitle is required",
        },
        deadline: {
          required: () => "Due Date is required",
        },
        description: {
          required: () => "Description is required",
        },
        points: {
          required: () => "Points are required",
        },
        databucket: {
          required: () => "Data Bucket is required",
        },
        targetCode: {
          required: () => "Category is required",
        },
      },
    },
    dateMenu: null,
  }),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    databuckets() {
      return this.$store.state.databuckets.databuckets;
    },
    targetCodes() {
      if (this.selectedItem.databucket) {
        const databucket =
          this.databuckets.find(
            (el) => el.id === this.selectedItem.databucket
          ) || "";
        return databucket.targetCodes;
      }
      return [];
    },
  },
  methods: {
    closeAddOrEditDialog() {
      this.$emit("onCloseAddOrEditDialog");
      this.$validator.reset();
    },
    getDatabucketTitle(item) {
      const found = this.databuckets.find((el) => el.id === item) || "";
      return found.title;
    },
    formatDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    async handleSave() {
      const validationResult = await this.$validator.validateAll("opportunity");
      if (!validationResult) {
        return;
      }
      this.$emit("onHandleSave", this.selectedItem);
      this.$validator.reset();
    },
  },
};
</script>

<style></style>

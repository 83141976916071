<template>
  <div class="field-display">
    <!-- Skip rendering for hidden fields -->
    <div v-if="shouldRender">
      <!-- Title field -->
      <div v-if="field.type === 'title'" class="title-field">
        <h3 class="text-h6 font-weight-bold" :style="getStyles">
          {{ field.styles.value }}
        </h3>
      </div>

      <!-- Subtitle field -->
      <div v-else-if="field.type === 'subtitle'" class="subtitle-field">
        <h4 class="text-subtitle-1 font-weight-medium" :style="getStyles">
          {{ field.label }}
        </h4>
      </div>

      <!-- Note field -->
      <div v-else-if="field.type === 'note'" class="note-field">
        <p class="text-body-2" :style="getStyles" v-html="field.label"></p>
      </div>

      <!-- Text field -->
      <div v-else-if="field.type === 'textfield'" class="text-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <template v-if="displayValue">{{ displayValue }}</template>
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            Not provided
          </div>
        </div>
      </div>

      <!-- Textarea field -->
      <div v-else-if="field.type === 'textarea'" class="textarea-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <template v-if="displayValue">
            <div class="text-pre-wrap">{{ displayValue }}</div>
          </template>
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            Not provided
          </div>
        </div>
      </div>

      <!-- Number field -->
      <div v-else-if="field.type === 'number'" class="number-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <template v-if="displayValue">{{ displayValue }}</template>
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            Not provided
          </div>
        </div>
      </div>

      <!-- Dropdown field -->
      <div v-else-if="field.type === 'dropdown'" class="dropdown-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <template v-if="usesDynamicPresets && value">
            <div class="dynamic-preset-display-dropdown">
              <span
                v-if="displayValueEnabled('identifier') && value.identifier"
                class="option-part option-identifier"
              >
                {{ value.identifier }}
              </span>
              <span
                v-if="displayValueEnabled('title') && value.title"
                class="option-part option-title"
              >
                {{ value.title }}
              </span>
              <span
                v-if="displayValueEnabled('description') && value.description"
                class="option-part option-description"
              >
                {{ value.description }}
              </span>
              <span
                v-if="displayValueEnabled('points') && value.points"
                class="option-part option-points"
              >
                {{ value.points }} points
              </span>
            </div>
          </template>
          <template v-else>
            <!-- Show message when no options are available -->
            <div v-if="getFieldOptions.length === 0" class="text-grey">
              No options available
            </div>
            <!-- Show message when no option is selected but options exist -->
            <div v-else-if="!value" class="no-selection-message">
              <v-icon color="grey" class="mr-1" size="21">info</v-icon>
              No option selected
            </div>
            <!-- Show selected value when available -->
            <template v-else>
              <!-- For string values, display directly -->
              <template v-if="typeof value === 'string'">{{ value }}</template>
              <!-- For Vue reactive objects with value property -->
              <template
                v-else-if="
                  typeof value === 'object' &&
                  value &&
                  value.__ob__ &&
                  value.value !== undefined
                "
              >
                {{ value.value }}
              </template>
              <!-- For other cases, use the computed property -->
              <template v-else>{{ getDropdownDisplayValue }}</template>
            </template>
          </template>
        </div>
      </div>

      <!-- Radio field -->
      <div v-else-if="field.type === 'radio'" class="radio-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <!-- Show all options -->
          <div v-if="getFieldOptions.length > 0" class="options-list">
            <div
              v-for="(option, index) in getFieldOptions"
              :key="index"
              class="option-item"
              :class="{ selected: isRadioSelected(option) }"
            >
              <v-icon
                :color="isRadioSelected(option) ? 'primary' : 'grey'"
                class="mr-2"
              >
                {{
                  isRadioSelected(option)
                    ? "radio_button_checked"
                    : "radio_button_unchecked"
                }}
              </v-icon>
              <span>{{ getOptionDisplayText(option) }}</span>
            </div>
            <!-- Show "Not Selected" message only when no option is selected -->
            <div
              v-if="!field.allOptions?.some((opt) => opt.isSelected)"
              class="no-selection-message"
            >
              <v-icon color="grey" class="mr-1" size="21">info</v-icon>
              No option selected
            </div>
          </div>
          <div v-else class="text-grey">No options available</div>
        </div>
      </div>

      <!-- Checkbox field -->
      <div v-else-if="field.type === 'checkbox'" class="checkbox-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <div class="option-item" :class="{ selected: value }">
            <v-icon :color="value ? 'primary' : 'grey'" class="mr-2">
              {{ value ? "check_box" : "check_box_outline_blank" }}
            </v-icon>
            {{ field.label }}
          </div>
        </div>
      </div>

      <!-- Checkboxes field -->
      <div v-else-if="field.type === 'checkboxes'" class="checkboxes-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <div v-if="getFieldOptions.length > 0" class="options-list">
            <div
              v-for="(option, index) in getFieldOptions"
              :key="index"
              class="option-item"
              :class="{ selected: isCheckboxSelected(option) }"
            >
              <v-icon
                :color="isCheckboxSelected(option) ? 'primary' : 'grey'"
                class="mr-2"
              >
                {{
                  isCheckboxSelected(option)
                    ? "check_box"
                    : "check_box_outline_blank"
                }}
              </v-icon>
              {{ getOptionDisplayText(option) }}
            </div>
            <!-- Show "Not Selected" message only when no option is selected -->
            <div
              v-if="!value || (Array.isArray(value) && value.length === 0)"
              class="no-selection-message"
            >
              <v-icon color="grey" class="mr-1" size="21">info</v-icon>
              No option selected
            </div>
          </div>
          <div v-else class="text-grey">No options available</div>
        </div>
      </div>

      <!-- Switch field -->
      <div v-else-if="field.type === 'switch'" class="switch-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <v-icon :color="value ? 'primary' : 'grey'" class="mr-2" size="40">
            {{ value ? "toggle_on" : "toggle_off" }}
          </v-icon>
          <span>{{ value ? "Yes" : "No" }} </span>
        </div>
      </div>

      <!-- Date field -->
      <div v-else-if="field.type === 'date'" class="date-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <template v-if="value">{{ formatDate(value) }}</template>
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            Not provided
          </div>
        </div>
      </div>

      <!-- Time field -->
      <div v-else-if="field.type === 'time'" class="time-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <template v-if="value">{{ value }}</template>
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            Not provided
          </div>
        </div>
      </div>

      <!-- Dropzone -->
      <div v-else-if="field.type === 'dropzone'" class="file-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <!-- When we have an array of files -->
          <div
            v-if="Array.isArray(value) && value.length > 0"
            class="file-cards-container"
          >
            <v-row class="resources-grid" no-gutters>
              <v-col
                v-for="(file, index) in value"
                :key="index"
                cols="6"
                sm="4"
                md="3"
                lg="2"
                xl="2"
                class="pa-2"
              >
                <v-card
                  @click="openFile(file.url)"
                  flat
                  outlined
                  class="resource-card d-flex flex-column"
                  style="position: relative; aspect-ratio: 1/1"
                  @mouseover="hoveredCardIndex = index"
                  @mouseleave="hoveredCardIndex = null"
                  :style="
                    hoveredCardIndex === index
                      ? 'border-color: ' +
                        $vuetify.theme.currentTheme.primary +
                        ' !important'
                      : ''
                  "
                >
                  <v-card-text
                    class="d-flex flex-column align-center justify-center text-center h-100 pa-2"
                    style="
                      padding-left: 12px !important;
                      padding-right: 12px !important;
                    "
                  >
                    <v-icon
                      size="48"
                      :color="getFileIconColor(file)"
                      class="mb-4 file-icon"
                      >{{ getFileIcon(file) }}</v-icon
                    >
                    <div class="filename-text">{{ getFileName(file) }}</div>
                    <div class="text-caption">{{ getFileSize(file) }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <!-- When we have a single file (non-array) -->
          <div v-else-if="value && !Array.isArray(value)" class="single-file">
            <v-chip small>
              <v-icon left>attach_file</v-icon>
              File uploaded
            </v-chip>
          </div>
          <!-- When no files are uploaded -->
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            No file uploaded
          </div>
        </div>
      </div>

      <!-- Slider field -->
      <div v-else-if="field.type === 'slider'" class="slider-field">
        <div class="field-label">{{ field.label }}</div>
        <template v-if="value">
          <v-chip small class="field-value" color="primary">{{ value }}</v-chip>
          <span class="field-value ml-1">
            From a range of {{ field.min }} to {{ field.max }}
          </span>
        </template>
        <div v-else class="no-selection-message">
          <v-icon color="grey" class="mr-1" size="21">info</v-icon>
          Not set
        </div>
      </div>

      <!-- Table field -->
      <div v-else-if="field.type === 'table'" class="table-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <div v-if="Array.isArray(value) && value.length > 0">
            <v-sheet class="mb-6 mx-1" outlined rounded color="grey lighten-2">
              <v-data-table
                :headers="getTableHeaders"
                :items="value"
                :items-per-page="tableOptions.itemsPerPage"
                :page.sync="tableOptions.page"
                :footer-props="{
                  'items-per-page-options': [5, 10, 15, -1],
                  'items-per-page-text': 'Rows per page:',
                  'show-first-last-page': false,
                  'show-current-page': true,
                  'pagination-options': { page: tableOptions.page },
                }"
                outlined
                :mobile-breakpoint="0"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td v-for="header in getTableHeaders" :key="header.value">
                      <template v-if="header.isPointsColumn">
                        {{
                          getPointsValue(item, getFieldFromPointsHeader(header))
                        }}
                      </template>
                      <template v-else>
                        {{ formatTableValue(item, header) }}
                      </template>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-sheet>
            <!-- Total Points Display -->
            <v-chip v-if="hasPointsColumns" color="primary" class="mt-2">
              <v-icon left>stars</v-icon>
              Total Points: {{ Math.floor(calculateTotalPoints()) }}
            </v-chip>
          </div>
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            No data available
          </div>
        </div>
      </div>

      <!-- Default field display for other types -->
      <div v-else class="default-field">
        <div class="field-label">{{ field.label }}</div>
        <div class="field-value">
          <template v-if="displayValue">{{ displayValue }}</template>
          <div v-else class="no-selection-message">
            <v-icon color="grey" class="mr-1" size="21">info</v-icon>
            Not provided
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null,
    },
  },
  data() {
    return {
      tableOptions: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
      },
      hoveredCardIndex: null,
    };
  },
  computed: {
    processedField() {
      // Create a deep copy of the field to avoid prop mutations
      return JSON.parse(JSON.stringify(this.field));
    },
    shouldRender() {
      // Skip rendering for hidden fields or fields without labels (except for special types)
      const specialTypes = ["title", "subtitle", "note", "divider"];
      if (this.processedField.type === "hidden") return false;
      if (
        !this.processedField.label &&
        !specialTypes.includes(this.processedField.type)
      )
        return false;
      return true;
    },
    usesDynamicPresets() {
      return (
        this.processedField.presets &&
        this.processedField.presets.presetsType === "dynamic"
      );
    },
    displayValue() {
      if (this.value === null || this.value === undefined) return "";

      // Handle Vue reactive objects
      if (typeof this.value === "object" && this.value.__ob__) {
        if (this.value.value !== undefined) {
          return this.value.value.toString();
        }
      }

      if (typeof this.value === "object") return JSON.stringify(this.value);
      return this.value.toString();
    },
    getStyles() {
      return this.processedField.styles || {};
    },
    getDropdownDisplayValue() {
      if (!this.value) return "Not selected";

      // Handle case where value is a Vue reactive object with __ob__ property
      if (typeof this.value === "object" && this.value.__ob__) {
        // For Vue reactive objects with value property
        if (this.value.value !== undefined) {
          return this.value.value;
        }
        // For Vue reactive objects with text property
        if (this.value.text) {
          return this.value.text;
        }
      }

      // Handle case where value is a regular object
      if (typeof this.value === "object") {
        return (
          this.value.text || this.value.value || JSON.stringify(this.value)
        );
      }

      // Try to find the display text from field presets
      if (
        this.processedField.presets &&
        this.processedField.presets.staticOptions
      ) {
        const option = this.processedField.presets.staticOptions.find(
          (opt) => (typeof opt === "object" ? opt.value : opt) === this.value
        );
        if (option) {
          return typeof option === "object" ? option.text : option;
        }
      }

      return this.value;
    },
    getTableHeaders() {
      if (!this.field.componentFields) return [];

      const headers = [];

      // First add all regular field headers
      this.field.componentFields
        .filter((field) => field.active)
        .forEach((field) => {
          const fieldId = field.idx || `field-${field.label}`;
          // Add the regular field header
          headers.push({
            text: field.label || "Column",
            value: fieldId,
            sortable: true,
            align: "start",
            width: field.cols ? `${field.cols * 8}%` : "auto",
            fieldType: field.type,
            isPointsEnabled: this.isPointsEnabledField(field),
          });

          // If this is a dropdown field with points capability, add points column immediately after
          if (this.isPointsEnabledField(field)) {
            headers.push({
              text: "Points",
              value: `${fieldId}_points`,
              sortable: false,
              align: "right",
              parentField: fieldId,
              isPointsColumn: true,
            });
          }
        });

      return headers;
    },
    hasPointsColumns() {
      return (
        this.field.componentFields?.some((field) =>
          this.isPointsEnabledField(field)
        ) || false
      );
    },
    getFieldOptions() {
      // First check allOptions since it contains selection state
      if (this.processedField.allOptions) {
        return Array.isArray(this.processedField.allOptions)
          ? this.processedField.allOptions
          : [];
      }

      // Then check presets.staticOptions
      if (this.processedField.presets?.staticOptions) {
        return Array.isArray(this.processedField.presets.staticOptions)
          ? this.processedField.presets.staticOptions
          : [];
      }

      // Then check options array directly on field
      if (this.processedField.options) {
        return Array.isArray(this.processedField.options)
          ? this.processedField.options
          : [];
      }

      // Check for items array (another possible location)
      if (this.processedField.items) {
        return Array.isArray(this.processedField.items)
          ? this.processedField.items
          : [];
      }

      return [];
    },
    isDevelopment() {
      return process.env.NODE_ENV === "development";
    },
  },
  methods: {
    openFile(url) {
      window.open(url, "_blank");
    },
    displayValueEnabled(field) {
      return (
        this.processedField.presets &&
        this.processedField.presets.valuesToDisplay &&
        this.processedField.presets.valuesToDisplay.includes(field)
      );
    },
    getOptionDisplayText(option) {
      if (typeof option === "string") return option;
      if (typeof option === "object") {
        // First try to get the text property
        if (option.text) return option.text;
        // Fall back to value if text is not available
        if (option.value) return option.value;
        // Last resort - stringify the object
        return JSON.stringify(option);
      }
      return String(option);
    },
    formatDate(dateString) {
      if (!dateString) return "Not provided";

      try {
        const date = new Date(dateString);
        return date.toLocaleDateString();
      } catch (e) {
        return dateString;
      }
    },
    isRadioSelected(option) {
      // Get the option value
      const optionValue = typeof option === "object" ? option.value : option;

      // First check if the option exists in allOptions and is selected
      if (this.processedField.allOptions) {
        const matchingOption = this.processedField.allOptions.find(
          (opt) => opt.value === optionValue
        );

        // Add debug logging in development
        if (this.isDevelopment) {
          console.log("Radio selection check:", {
            optionValue,
            option,
            matchingOption,
            isSelected: matchingOption?.isSelected,
            allOptions: this.processedField.allOptions,
          });
        }

        if (matchingOption?.isSelected) {
          return true;
        }
      }

      // If no match in allOptions, check the field value
      const fieldValue = this.value;

      // Then check if the field value matches the option value
      if (typeof fieldValue === "object") {
        return fieldValue.value === optionValue;
      } else {
        return String(fieldValue || "") === String(optionValue || "");
      }
    },
    isCheckboxSelected(option) {
      if (!this.value) return false;

      let selectedValues = Array.isArray(this.value)
        ? this.value
        : [this.value];
      const optionValue = typeof option === "object" ? option.value : option;

      return selectedValues.some((val) => {
        let selectedValue = val;
        if (typeof selectedValue === "object") {
          if (selectedValue.__ob__) {
            selectedValue = selectedValue.value;
          } else {
            selectedValue = selectedValue.value || selectedValue;
          }
        }
        return (
          optionValue === selectedValue ||
          String(optionValue) === String(selectedValue)
        );
      });
    },
    getFileIconColor(file) {
      try {
        const filename = this.getFileName(file);
        if (
          !filename ||
          typeof filename !== "string" ||
          filename.trim() === ""
        ) {
          return "grey";
        }

        // Remove any version number in parentheses before extracting extension
        const cleanedFilename = filename.replace(/ \(\d+\)$/, "");

        // Check if filename contains a period to ensure we can extract an extension
        if (!cleanedFilename.includes(".")) {
          return "grey";
        }

        const ext = cleanedFilename.split(".").pop().toLowerCase();
        if (!ext) return "grey";

        // Map of file extensions to colors (same as in PreviewFileDropzone)
        const colorMap = {
          pdf: "red",
          doc: "blue",
          docx: "blue",
          xls: "green",
          xlsx: "green",
          csv: "green",
          ppt: "orange",
          pptx: "orange",
          jpg: "purple",
          jpeg: "purple",
          png: "purple",
        };

        return colorMap[ext] || "grey";
      } catch (error) {
        console.error("Error determining file icon color:", error);
        return "grey";
      }
    },
    getFileIcon(file) {
      try {
        const filename = this.getFileName(file);
        if (
          !filename ||
          typeof filename !== "string" ||
          filename.trim() === ""
        ) {
          return "insert_drive_file";
        }

        // Remove any version number in parentheses before extracting extension
        const cleanedFilename = filename.replace(/ \(\d+\)$/, "");

        // Check if filename contains a period to ensure we can extract an extension
        if (!cleanedFilename.includes(".")) {
          return "insert_drive_file";
        }

        const ext = cleanedFilename.split(".").pop().toLowerCase();
        if (!ext) return "insert_drive_file";

        // Map of file extensions to Material Design icons
        // Use the same logic as in PreviewFileDropzone but convert to Material Design icons
        const iconMap = {
          pdf: "fas fa-file-pdf",
          doc: "fas fa-file-word",
          docx: "fas fa-file-word",
          xls: "fas fa-file-excel",
          xlsx: "fas fa-file-excel",
          csv: "fas fa-file-csv",
          ppt: "fas fa-file-powerpoint",
          pptx: "fas fa-file-powerpoint",
          jpg: "fas fa-file-image",
          jpeg: "fas fa-file-image",
          png: "fas fa-file-image",
        };

        return iconMap[ext] || "insert_drive_file";
      } catch (error) {
        console.error("Error determining file icon:", error);
        return "insert_drive_file";
      }
    },
    getFileName(file) {
      // If filename or description is missing, use "Unknown file" as fallback
      if (!file || (!file.description && !file.filename)) {
        return "Unknown file";
      }

      // Use description as primary source, fallback to filename if needed
      return file.description || file.filename;
    },
    getFileSize(file) {
      // Check all possible places where size might be stored
      if (file.metadata && file.metadata.size) {
        return file.metadata.size;
      }

      if (file.originalSize) {
        return this.formatFileSize(file.originalSize);
      }

      if (file.size) {
        return this.formatFileSize(file.size);
      }

      // Return a debug-friendly fallback
      return "Unknown size";
    },
    formatFileSize(bytes) {
      // Ensure bytes is a number and not NaN
      if (!bytes || isNaN(bytes) || bytes === 0) {
        return "Unknown size";
      }

      try {
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
      } catch (error) {
        console.error("Error formatting file size:", error);
        return "Unknown size";
      }
    },
    isPointsEnabledField(field) {
      return (
        field.type === "dropdown" &&
        field.presets?.presetsType === "dynamic" &&
        field.presets?.valuesToDisplay &&
        Array.isArray(field.presets.valuesToDisplay) &&
        field.presets.valuesToDisplay.includes("points")
      );
    },
    getFieldFromPointsHeader(header) {
      if (!header.isPointsColumn) return null;
      const baseFieldId = header.value.replace("_points", "");
      return this.field.componentFields?.find(
        (field) => (field.idx || `field-${field.label}`) === baseFieldId
      );
    },
    formatTableValue(item, header) {
      const field = this.field.componentFields?.find(
        (f) => (f.idx || `field-${f.label}`) === header.value
      );

      if (!field) return item[header.value] || "-";

      const value = item[header.value];
      if (value === undefined || value === null || value === "") return "-";

      // Format based on field type
      switch (field.type) {
        case "dropdown":
          return item[`${header.value}_display`] || value;
        case "checkbox":
          return value ? "Yes" : "No";
        case "date":
          return this.formatDate(value);
        default:
          return value;
      }
    },
    getPointsValue(item, field) {
      if (!field) return "0";

      const fieldId = field.idx || `field-${field.label}`;
      const pointsKey = `${fieldId}_points`;

      // First check if we have a stored points value
      if (item[pointsKey] !== undefined) {
        let points = parseFloat(item[pointsKey]) || 0;

        // Check for quantity field
        const quantityField = this.field.componentFields?.find(
          (f) => f.type === "textfield" && f.label === "Quantity"
        );

        if (quantityField) {
          const quantityId =
            quantityField.idx || `field-${quantityField.label}`;
          const quantity = parseFloat(item[quantityId]) || 1;
          points *= quantity;
        }

        return Math.round(points).toString();
      }

      return "0";
    },
    calculateTotalPoints() {
      if (!Array.isArray(this.value)) return 0;

      let total = 0;
      const pointsFields =
        this.field.componentFields?.filter((field) =>
          this.isPointsEnabledField(field)
        ) || [];

      // Find quantity field
      const quantityField = this.field.componentFields?.find(
        (field) => field.type === "textfield" && field.label === "Quantity"
      );

      this.value.forEach((row) => {
        pointsFields.forEach((field) => {
          const fieldId = field.idx || `field-${field.label}`;
          const pointsKey = `${fieldId}_points`;

          if (row[pointsKey] !== undefined) {
            let points = parseFloat(row[pointsKey]) || 0;

            // If quantity field exists and has a value, multiply points by quantity
            if (quantityField) {
              const quantityId =
                quantityField.idx || `field-${quantityField.label}`;
              const quantity = parseFloat(row[quantityId]) || 1;
              points *= quantity;
            }

            total += Math.round(points);
          }
        });
      });

      return total;
    },
  },
};
</script>

<style scoped>
.field-display {
  margin-bottom: 16px;
}

.field-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}

.field-value {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.6);
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.options-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.option-item.selected {
  background-color: rgba(240, 20, 108, 0.096);
}

.radio-field .option-item.selected,
.checkboxes-field .option-item.selected,
.checkbox-field .option-item.selected {
  background-color: rgba(240, 20, 108, 0.096);
}

.checkbox-item {
  margin-bottom: 4px;
}

.title-field,
.subtitle-field,
.note-field {
  margin-bottom: 8px;
}

.option-item.not-selected {
  opacity: 0.8;
}

.radio-field .option-item,
.checkboxes-field .option-item {
  min-height: 32px;
  padding: 4px 12px;
  border: 1px solid transparent;
}

.radio-field .option-item:hover,
.checkboxes-field .option-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.radio-field .option-item.selected,
.checkboxes-field .option-item.selected,
.checkbox-field .option-item.selected {
  background-color: rgba(240, 20, 108, 0.08);
}

.no-selection-message {
  margin-top: 8px;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
}

.text-grey {
  color: rgba(0, 0, 0, 0.6);
}

.dynamic-preset-display-dropdown {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 8px;
}

.dynamic-preset-display-dropdown .option-part {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.6);
}

.dynamic-preset-display-dropdown .option-part:not(:last-child)::after {
  content: "•";
  margin-left: 8px;
  color: #66666680;
}

/* Remove individual font styles since we're using the common style */
.dynamic-preset-display-dropdown .option-identifier,
.dynamic-preset-display-dropdown .option-title,
.dynamic-preset-display-dropdown .option-description,
.dynamic-preset-display-dropdown .option-points {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.file-cards-container {
  margin-top: 12px;
}

/* Resources grid spacing */
.resources-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; /* Increased from -10px to create more space between rows */
}

.resources-grid .v-col {
  padding: 15px !important; /* Increased from 10px to create more space between rows */
  margin-bottom: 10px !important; /* Added explicit bottom margin for more spacing between rows */
}

/* Resource card layout improvements */
.resource-card {
  display: flex !important;
  flex-direction: column !important;
  max-width: 200px !important;
  width: 100% !important;
  margin: 0 auto !important;
  aspect-ratio: 1 / 1 !important;
  z-index: 2 !important;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px !important;
}

.resource-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  transform: translateY(-2px);
}

.resource-card .v-card__text {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  padding: 16px 12px !important;
}

.file-icon {
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
  transition: all 0.2s ease;
}

.resource-card:hover .file-icon {
  transform: scale(1.1);
}

.filename-text {
  font-size: 12px !important;
  line-height: 1.2 !important;
  color: var(--v-primary-base) !important;
  word-break: break-word !important;
  text-align: center !important;
  max-width: 100% !important;
  min-height: unset !important;
  margin-bottom: 4px !important;
  display: block !important;
  font-weight: 500 !important;
}

.single-file {
  margin-top: 8px;
}
</style>

<template>
  <div>
    <v-container
      fluid
      tile
      class="pa-0"
      :style="
        'z-index: 500 !important; background-color: ' +
        this.webTheme.colors.canvas.hexa
      "
    >
      <v-row justify="center" no-gutters>
        <v-col cols="12" :sm="component.settings.cols">
          <v-card
            elevation="0"
            :height="height"
            tile
            :color="component.settings.color.hexa"
            :img="
              component.settings.mode === 'image'
                ? component.background.image
                : ''
            "
          >
            <v-row
              no-gutters
              :justify="component.block.justify"
              :align="component.block.align"
              class="fill-height"
            >
              <v-col xs="12" sm="10" md="8" :lg="component.block.cols">
                <v-col>
                  <v-card
                    :class="classes"
                    :style="styles + ';' + gradient"
                    :elevation="component.card.elevation"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="auto"
                        v-if="
                          component.logo.placement === 'left' &&
                          component.logo.image
                        "
                      >
                        <view-logo
                          :style="logoMargin"
                          :settings="component.logo"
                        />
                      </v-col>
                      <v-col>
                        <view-logo
                          :style="logoMargin"
                          v-if="
                            component.logo.placement === 'top' &&
                            component.logo.image
                          "
                          :settings="component.logo"
                        />
                        <view-text :settings="component.title"></view-text>
                        <view-text :settings="component.subtitle"></view-text>
                        <v-stepper
                          flat
                          class="stepper-style mb-n6"
                          v-model="stage"
                          vertical
                        >
                          <!----------------------------------------------->

                          <v-stepper-step
                            step="1"
                            :complete="stage > 1"
                            :color="
                              component.stepper
                                ? component.stepper.color.hex
                                : 'primary'
                            "
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  :value="'Contact Details'"
                                ></view-text
                              ></v-row>
                              <v-row v-if="stage > 1" class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="
                                    firstname + ' ' + lastname + ' ' + email
                                  "
                                ></view-text
                              ></v-row>
                              <v-row
                                v-if="
                                  stage > 1 && mobileAtRegistration && mobile
                                "
                                class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="'Mobile ' + mobile"
                                ></view-text
                              ></v-row>
                              <v-row
                                v-if="
                                  stage > 1 &&
                                  memberDataKeyAtRegistration &&
                                  memberDataKey
                                "
                                class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="
                                    memberDataKeyLabel + ': ' + memberDataKey
                                  "
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>
                          <v-stepper-content step="1">
                            <v-row no-gutters class="mt-1">
                              <v-text-field
                                v-model="firstname"
                                id="firstname"
                                name="firstname"
                                label="First Name"
                                :outlined="true"
                                :dense="true"
                                background-color="white"
                                :color="fieldBorder"
                                filled
                                :disabled="!registrationOpen"
                              />
                            </v-row>
                            <v-row no-gutters>
                              <v-text-field
                                v-model="lastname"
                                id="lastname"
                                name="lastname"
                                label="Last Name"
                                :outlined="true"
                                :dense="true"
                                background-color="white"
                                :color="fieldBorder"
                                filled
                                :disabled="!registrationOpen"
                              />
                            </v-row>
                            <v-row no-gutters>
                              <v-text-field
                                v-model="email"
                                id="email"
                                name="Email"
                                label="Email"
                                :outlined="true"
                                :dense="true"
                                background-color="white"
                                :color="fieldBorder"
                                filled
                                :disabled="!registrationOpen"
                              />
                            </v-row>
                            <v-row no-gutters>
                              <v-text-field
                                v-if="mobileAtRegistration"
                                v-model="mobile"
                                id="mobile"
                                name="mobile"
                                label="Mobile"
                                :outlined="true"
                                :dense="true"
                                background-color="white"
                                :color="fieldBorder"
                                filled
                                :disabled="!registrationOpen"
                              />
                            </v-row>
                            <v-row
                              v-if="memberDataKeyAtRegistration"
                              no-gutters
                            >
                              <v-text-field
                                v-model="memberDataKey"
                                id="memberDataKey"
                                name="memberDataKey"
                                :label="memberDataKeyLabel"
                                :outlined="true"
                                :dense="true"
                                background-color="white"
                                :color="fieldBorder"
                                filled
                                :disabled="!registrationOpen"
                              />
                            </v-row>
                            <v-row
                              v-if="memberDataKeyAtRegistration"
                              no-gutters
                              class="mt-n3 ml-0 mb-3"
                            >
                              <view-text
                                :settings="component.stepperSummary"
                                :value="memberDataKeyDescription"
                              ></view-text>
                            </v-row>
                            <v-row no-gutters>
                              <v-btn
                                :disabled="!registrationOpen"
                                class="ml-0"
                                :color="component.button.color.hex"
                                :block="component.button.block"
                                :dark="!component.button.dark"
                                @click="nextStep()"
                                elevation="0"
                              >
                                {{
                                  registrationOpen
                                    ? "Next"
                                    : "registration closed"
                                }}
                              </v-btn>
                            </v-row>
                          </v-stepper-content>
                          <!----------------------------------------------->
                          <v-stepper-step
                            :step="step.company || ''"
                            v-if="showCompanyStep"
                            :complete="stage > step.company"
                            :color="
                              component.stepper
                                ? component.stepper.color.hex
                                : 'primary'
                            "
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  :value="'Company'"
                                ></view-text
                              ></v-row>
                              <v-row v-if="stage > step.company" class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="getCompanyName(selectedCompany)"
                                ></view-text
                              ></v-row>
                              <v-row
                                v-if="
                                  stage > step.company &&
                                  companyDataKeyAtRegistration
                                "
                                class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="
                                    companyDataKeyLabel + ': ' + companyDataKey
                                  "
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>
                          <v-stepper-content
                            :step="step.company || ''"
                            v-if="showCompanyStep"
                          >
                            <v-row no-gutters>
                              <v-select
                                v-if="companyRegistrationGroupsExist"
                                v-model="selectedCompanyTag"
                                :items="companyRegistrationGroups"
                                item-text="tag"
                                item-value="id"
                                :label="companyRegistrationGroupLabel"
                                background-color="white"
                                dense
                                outlined
                                filled
                                clearable
                                @change="clearSelectedCompany()"
                              />
                            </v-row>
                            <v-row no-gutters>
                              <v-select
                                :disabled="storeSelectDisabled"
                                v-model="selectedCompany"
                                :items="companies"
                                item-text="title"
                                item-value="id"
                                :label="companyAtRegistrationLabel"
                                background-color="white"
                                dense
                                outlined
                                filled
                                no-data-text="No companies found"
                              />
                            </v-row>
                            <v-row
                              v-if="companyDataKeyAtRegistration"
                              no-gutters
                            >
                              <v-text-field
                                v-model="companyDataKey"
                                id="companyDataKey"
                                name="companyDataKey"
                                :label="companyDataKeyLabel"
                                :outlined="true"
                                :dense="true"
                                background-color="white"
                                color="grey"
                                filled
                                :disabled="!registrationOpen"
                              />
                            </v-row>
                            <v-row
                              v-if="companyDataKeyAtRegistration"
                              no-gutters
                              class="mt-n3 ml-0 mb-3"
                            >
                              <view-text
                                :settings="component.stepperSummary"
                                :value="companyDataKeyDescription"
                              ></view-text>
                            </v-row>
                            <v-row no-gutters>
                              <v-btn
                                class="ml-0"
                                :color="component.button.color.hex"
                                :block="component.button.block"
                                :dark="!component.button.dark"
                                @click="nextStep()"
                                elevation="0"
                              >
                                Next
                              </v-btn>
                              <v-btn
                                :color="component.button.color.hex"
                                :dark="!component.button.dark"
                                @click="backStep()"
                                elevation="0"
                                text
                              >
                                Back
                              </v-btn>
                            </v-row>
                          </v-stepper-content>
                          <!----------------------------------------------->
                          <v-stepper-step
                            :step="step.photo || ''"
                            v-if="memberPhotoIdAtRegistration"
                            :complete="stage > step.photo"
                            :color="
                              component.stepper
                                ? component.stepper.color.hex
                                : 'primary'
                            "
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  :value="memberPhotoIdLabel"
                                ></view-text
                              ></v-row>
                              <v-row v-if="stage > step.photo" class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="photoIdFile.name"
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>
                          <v-stepper-content
                            :step="step.photo || ''"
                            v-if="memberPhotoIdAtRegistration"
                          >
                            <v-row no-gutters>
                              <v-file-input
                                v-model="photoIdFile"
                                dense
                                outlined
                                truncate-length="20"
                                background-color="white"
                                color="white"
                                filled
                                clearable
                              ></v-file-input>
                            </v-row>
                            <v-row no-gutters class="mt-n3 ml-0 mb-3">
                              <view-text
                                :settings="component.stepperSummary"
                                :value="memberPhotoIdDescription"
                              ></view-text>
                            </v-row>
                            <v-row no-gutters>
                              <v-btn
                                class="ml-0"
                                :color="component.button.color.hex"
                                :block="component.button.block"
                                :dark="!component.button.dark"
                                @click="nextStep()"
                                elevation="0"
                              >
                                Next
                              </v-btn>
                              <v-btn
                                :color="component.button.color.hex"
                                :dark="!component.button.dark"
                                @click="backStep()"
                                elevation="0"
                                text
                              >
                                Back
                              </v-btn>
                            </v-row>
                          </v-stepper-content>
                          <!----------------------------------------------->
                          <v-stepper-step
                            :step="step.pass || ''"
                            :complete="stage > step.pass"
                            :color="
                              component.stepper
                                ? component.stepper.color.hex
                                : 'primary'
                            "
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  :value="'Password'"
                                ></view-text
                              ></v-row>
                              <v-row v-if="stage > step.pass" class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="'********'"
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>
                          <v-stepper-content :step="step.pass || ''">
                            <v-row no-gutters>
                              <v-text-field
                                id="password"
                                Name="Password"
                                label="Password"
                                :outlined="true"
                                :dense="true"
                                background-color="white"
                                color="grey"
                                :type="showPass ? 'text' : 'password'"
                                :append-icon="
                                  showPass ? 'visibility' : 'visibility_off'
                                "
                                @click:append="showPass = !showPass"
                              />
                            </v-row>
                            <v-btn
                              class="ml-0"
                              :color="component.button.color.hex"
                              :dark="!component.button.dark"
                              @click="nextStep()"
                              elevation="0"
                            >
                              Next
                            </v-btn>
                            <v-btn
                              :color="component.button.color.hex"
                              :dark="!component.button.dark"
                              @click="backStep()"
                              elevation="0"
                              text
                              :disabled="loading"
                            >
                              Back
                            </v-btn>
                          </v-stepper-content>
                          <!----------------------------------------------->
                          <v-stepper-step
                            :step="step.review || ''"
                            :complete="stage > step.review"
                            :color="
                              component.stepper
                                ? component.stepper.color.hex
                                : 'primary'
                            "
                          >
                            <div>
                              <v-row class="ml-0"
                                ><view-text
                                  :settings="component.stepperLabels"
                                  :value="'Review'"
                                ></view-text
                              ></v-row>
                              <v-row v-if="stage > step.review - 1" class="ml-0"
                                ><view-text
                                  :settings="component.stepperSummary"
                                  :value="'Ready to submit?'"
                                ></view-text
                              ></v-row>
                            </div>
                          </v-stepper-step>
                          <v-stepper-content :step="step.review || ''">
                            <v-btn
                              :color="component.button.color.hex"
                              :dark="!component.button.dark"
                              :block="component.button.block"
                              class="ml-0"
                              elevation="0"
                              :width="component.button.width"
                              :height="component.button.height"
                            >
                              Sign Up
                            </v-btn>
                            <v-btn
                              :color="component.button.color.hex"
                              :dark="!component.button.dark"
                              @click="backStep()"
                              elevation="0"
                              text
                              :disabled="loading"
                            >
                              Back
                            </v-btn>
                          </v-stepper-content>
                          <!----------------------------------------------->
                        </v-stepper>

                        <view-text
                          :settings="component.already"
                          :value="component.value"
                        ></view-text>
                        <a @click="handleSignin()"
                          ><view-text
                            :settings="component.signinLink"
                            :value="component.value"
                          ></view-text
                        ></a>

                        <view-text
                          :settings="component.signing"
                          :value="component.value"
                        ></view-text>
                        <a @click="handleTermsDialog()"
                          ><view-text
                            :settings="component.terms"
                            :value="component.value"
                          ></view-text
                        ></a>

                        <view-logo
                          :style="logoMargin"
                          v-if="
                            component.logo.placement === 'bottom' &&
                            component.logo.image
                          "
                          :settings="component.logo"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="
                          component.logo.placement === 'right' &&
                          component.logo.image
                        "
                      >
                        <view-logo
                          :style="logoMargin"
                          :settings="component.logo"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
          <footnote />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ViewLogo from "../Logo.vue";
import ViewText from "../Text.vue";
import Footnote from "../../../Footnote.vue";
export default {
  components: {
    ViewLogo,
    ViewText,
    Footnote,
  },
  created() {
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadCompanies");
  },
  data() {
    return {
      termsText: "By signing up, you agree to the",
      termsLink: "Terms and Conditions",
      forgotText: "Forgot Password",
      stage: 1,
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      selectedCompanyTag: "",
      selectedCompany: "",
      companyDataKey: "",
      memberDataKey: "",
      photoIdFile: [],
      showPass: false,
      fieldBorder: "#bdbdbd",
    };
  },
  props: ["component", "index"],
  computed: {
    step() {
      const neither = { company: "", photo: "", pass: 2, review: 3 };
      const both = { company: 2, photo: 3, pass: 4, review: 5 };
      const company = { company: 2, photo: "", pass: 3, review: 4 };
      const photo = { company: "", photo: 2, pass: 3, review: 4 };
      if (this.showCompanyStep && this.memberPhotoIdAtRegistration) {
        return both;
      } else if (this.showCompanyStep && !this.memberPhotoIdAtRegistration) {
        return company;
      } else if (!this.showCompanyStep && this.memberPhotoIdAtRegistration) {
        return photo;
      }
      return neither;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    companyRegistrationGroups() {
      const tags = this.$store.state.companytag.companyTags;
      const groups =
        this.$store.getters.currentProgram.companyRegistrationGroups;
      const objs = [];
      groups.forEach((id) => {
        const val = tags.find((rec) => rec.id === id);
        objs.push(val);
      });
      const final = objs.sort((a, b) =>
        a.tag > b.tag ? 1 : b.tag > a.tag ? -1 : 0
      );
      return final;
    },
    companyRegistrationGroupsExist() {
      return this.companyRegistrationGroups.length > 0;
    },
    companyRegistrationGroupLabel() {
      return this.$store.getters.currentProgram.companyRegistrationGroupLabel;
    },
    companyAtRegistrationLabel() {
      return this.$store.getters.currentProgram.companyAtRegistrationLabel;
    },
    mobileAtRegistration() {
      return this.$store.getters.currentProgram.mobileAtRegistration;
    },
    mobileAtRegistrationRequired() {
      return this.$store.getters.currentProgram.mobileAtRegistrationRequired;
    },
    memberDataKeyAtRegistration() {
      return this.$store.getters.currentProgram.memberDataKeyAtRegistration;
    },
    memberDataKeyAtRegistrationRequired() {
      return this.$store.getters.currentProgram
        .memberDataKeyAtRegistrationRequired;
    },
    memberDataKeyLabel() {
      return this.$store.getters.currentProgram.memberDataKeyLabel;
    },
    memberDataKeyDescription() {
      return this.$store.getters.currentProgram.memberDataKeyDescription;
    },
    memberPhotoIdAtRegistration() {
      return this.$store.getters.currentProgram.memberPhotoIdAtRegistration;
    },
    memberPhotoIdAtRegistrationRequired() {
      return this.$store.getters.currentProgram
        .memberPhotoIdAtRegistrationRequired;
    },
    memberPhotoIdLabel() {
      return this.$store.getters.currentProgram.memberPhotoIdLabel;
    },
    memberPhotoIdDescription() {
      return this.$store.getters.currentProgram.memberPhotoIdDescription;
    },
    companyDataKeyAtRegistration() {
      return this.$store.getters.currentProgram.companyDataKeyAtRegistration;
    },
    companyDataKeyAtRegistrationRequired() {
      return this.$store.getters.currentProgram
        .companyDataKeyAtRegistrationRequired;
    },
    companyDataKeyLabel() {
      return this.$store.getters.currentProgram.companyDataKeyLabel;
    },
    companyDataKeyDescription() {
      return this.$store.getters.currentProgram.companyDataKeyDescription;
    },
    storeSelectDisabled() {
      if (!this.companyRegistrationGroupsExist && this.selectedCompanyTag) {
        return false;
      } else if (
        this.companyRegistrationGroupsExist &&
        this.selectedCompanyTag
      ) {
        return false;
      } else if (
        this.companyRegistrationGroupsExist &&
        !this.selectedCompanyTag
      ) {
        return true;
      }
      return false;
    },
    companies() {
      const companies = this.$store.state.company.companies;
      if (this.selectedCompanyTag) {
        return companies.filter((el) =>
          el.tags.includes(this.selectedCompanyTag)
        );
      }
      return companies;
    },
    registrationOpen() {
      return this.$store.getters.currentProgram.registrationOpen || false;
    },
    showCompanyStep() {
      return (
        this.$store.getters.currentProgram.joinCompanyAtRegistration ||
        this.$store.getters.currentProgram.addCompanyAtRegistration
      );
    },
    loading() {
      return this.$store.getters.loading;
    },
    gradient() {
      if (this.component.card.color.type === "gradient") {
        var color = this.component.card.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.card.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "margin-bottom: " +
        this.component.card.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.card.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.card.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.card.margin.right +
        "px ;" +
        "padding-bottom: " +
        this.component.card.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.card.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.card.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.card.padding.right +
        "px ;" +
        "border: " +
        this.component.card.borderSize +
        "px solid " +
        "z-index: 5000 !important; " +
        this.component.card.borderColor.hexa +
        "; ";
      return styles;
    },
    classes() {
      var classes = this.component.card.rounded;
      return classes;
    },
    grid() {
      return this.$store.getters.grid;
    },
    logoMargin() {
      const val =
        "margin-bottom: " +
        this.component.logo.marginBottom +
        "px ;" +
        "margin-top: " +
        this.component.logo.marginTop +
        "px ;" +
        "margin-left: " +
        this.component.logo.marginLeft +
        "px ;" +
        "margin-right: " +
        this.component.logo.marginRight +
        "px ;";
      return val;
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    height() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + "px";
      } else {
        return this.component.heightValue.vh + "vh";
      }
    },
    imageHeight() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + this.component.overlap.px + "px";
      } else {
        return this.component.heightValue.vh + this.component.overlap.vh + "vh";
      }
    },
    image() {
      if (this.component.settings.mode === "color") {
        return "";
      } else {
        return this.component.settings.image;
      }
    },
  },
  methods: {
    nextStep() {
      this.stage++;
    },
    backStep() {
      this.stage--;
    },
    getCompanyName(id) {
      return (
        this.$store.state.company.companies.find((el) => el.id === id).title ||
        ""
      );
    },
    clearSelectedCompany() {
      this.selectedCompany = "";
    },
    handleSignin() {
      console.log("Sign in selected");
      // this.$router.push('/signin')
    },
    handleTermsDialog() {
      console.log("Terms dialog selected");
    },
  },
};
</script>

<style scoped>
.theme-page {
  padding: 0px 10px 0px 0px !important;
}

.stepper-style {
  background-color: transparent !important;
}
</style>

<template>
  <div class="ma-3">
    <v-card elevation="0">
      <v-row no-gutters align="center">
        <v-col sm="3">
          <v-text-field
            v-model="search"
            solo-inverted
            flat
            dense
            hide-details
            label="Search"
            prepend-inner-icon="search"
            class="mr-4"
          ></v-text-field>
        </v-col>

        <v-col sm="3" v-if="options.filter">
          <v-select
            solo-inverted
            flat
            dense
            hide-details
            label="Filter"
            prepend-inner-icon="filter_list"
            class="mr-4"
            :items="statusList"
            v-model="filteredStatus"
          ></v-select>
        </v-col>

        <v-col>
          <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
        </v-col>

        <v-col sm="auto">
          <v-btn
            @click="openEditDialog()"
            color="primary"
            elevation="0"
            class="mt-n2 mr-6"
            :disabled="processingOffer"
          >
            <v-icon>add</v-icon>new
          </v-btn>
        </v-col>
      </v-row>

      <!-- Data table -->
      <v-data-table
        :key="JSON.stringify(entries)"
        class="pr-6 pt-4"
        :headers="headers"
        :items="entries"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No claims yet..."
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            close-on-content-click
            :disabled="processingOffer"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
                @click="openEditDialog(item)"
              >
                <v-list-item-title>Review Submission </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor') || hasOfferPendingUpdates"
                @click="openDelDialog(item)"
              >
                <v-list-item-title>Delete </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.submitted="{ item }">
          {{ formatDisplayDate(item.submitted) }},
          {{ formatTime(item.submitted) }}
        </template>

        <template v-slot:item.reviewed="{ item }">
          <template v-if="item.reviewed">
            {{ formatDisplayDate(item.reviewed) }},
            {{ formatTime(item.reviewed) }}
          </template>
        </template>

        <template v-slot:item.opportunity="{ item }">
          {{ opportunityName(item.opportunity) }}
        </template>

        <template v-slot:item.redeemable="{ item }">
          <v-row justify="center">
            <v-checkbox v-model="item.redeemable" readonly></v-checkbox>
          </v-row>
        </template>

        <template v-slot:item.addToDatabucket="{ item }">
          <v-row justify="center">
            <template v-if="item.addToDatabucket !== undefined">
              <v-checkbox v-model="item.addToDatabucket" readonly></v-checkbox>
            </template>
            <template v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" v-bind="attrs" v-on="on"
                    >help_outline</v-icon
                  >
                </template>
                <span
                  >This will update from the opportunity next time the claim is
                  saved</span
                >
              </v-tooltip>
            </template>
          </v-row>
        </template>

        <template v-slot:item.member="{ item }">
          <span
            v-if="item.member"
            class="primary--text"
            style="cursor: pointer"
            @click="openMemberDialog(item.member)"
          >
            {{
              memberName(item.member) ||
              "Member Deleted" + " (ID:" + item.member + ")"
            }}
          </span>
          <span v-else>No Member</span>
        </template>

        <template v-slot:item.company="{ item }">
          {{ companyName(item.company) || "" }}
        </template>

        <template v-slot:item.totalPoints="{ item }">
          {{ calculateTotalPoints(item) | number }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <status-icon :status="item.status" class="pr-3"></status-icon>
          {{ item.status }}
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <div
            v-if="isRoleSatisfied('Editor')"
            @click="handleEdit(item)"
            class="primary--text"
            style="cursor: pointer"
          >
            {{ item.title }}
          </div>
          <div v-else>
            {{ item.title }}
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="delDialog" max-width="400px" persistent>
      <v-card>
        <template v-if="!claimHasEntries">
          <v-card-title class="page-heading mt-0 ml-0">
            Are You Sure?
          </v-card-title>
          <v-card-text class="px-16 pb-6 pt-6">
            <v-row dense>
              <v-col align="center">
                You are about to delete the entry by
                <span class="incentable-form-bold"
                  >{{ memberName(selectedItem.member) }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-else>
          <v-row class="page-heading pt-3 pl-3" no-gutters>
            Sorry, no can do
          </v-row>
          <v-card-text class="px-6 pb-6 pt-6">
            <v-row dense>
              <v-col align="center">
                You cannot delete a claim that has transacted points. You must
                delete the points transactions from the claim before deleting
                the claim.
              </v-col>
            </v-row>
          </v-card-text>
        </template>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="delDialog = false">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDeleteEntry()"
            :loading="loading"
            elevation="0"
            :disabled="demo || claimHasEntries"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <add-or-edit-entry
      :open="editDialog"
      :entryId="entryId"
      @onCloseEditDialog="closeEditDialog"
      @deletedPoints="handleDeletedPoints"
    ></add-or-edit-entry>

    <member-add-or-edit
      :open="memberDialog"
      :memberId="selectedMemberId"
      @onClose="closeMemberDialog"
    ></member-add-or-edit>
  </div>
</template>

<script>
import moment from "moment";
import { CLAIM_ENTRY_STATUS } from "@/constants/offer";

import AddOrEditEntry from "./AddOrEditEntry";
import MemberAddOrEdit from "@/components/Member/MemberAddOrEdit.vue";

export default {
  components: {
    AddOrEditEntry,
    MemberAddOrEdit,
  },

  data: () => ({
    search: "",
    dictionary: {
      custom: {
        deadline: {
          required: () => "Deadline is required",
        },
        status: {
          required: () => "Status is required",
        },
        points: {
          required: () => "Points are required",
        },
        submitted: {
          required: () => "Submitted date is required",
        },
      },
    },

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Member", value: "member", align: "left" },
      { text: "Company", value: "company", align: "left" },
      { text: "Submitted", value: "submitted", align: "left" },
      { text: "Opportunity", value: "opportunity", align: "left" },
      { text: "Reviewed", value: "reviewed", align: "left" },
      { text: "Total Points Awarded", value: "totalPoints", align: "right" },
      { text: "Redeemable", value: "redeemable", align: "center" },
      { text: "Add to Databucket", value: "addToDatabucket", align: "center" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Table settings
    options: {
      sortBy: ["submitted"],
      sortDesc: [true],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: true,
      download: true,
      more: false,
    },

    isProcessing: false,
    editDialog: false,
    delDialog: false,
    dateMenu: false,
    submittedMenu: false,
    reviewedMenu: false,
    selectedItem: {
      history: [],
      submitted: moment().toDate(),
    },
    defaultItem: {
      status: "Pending",
      history: [],
      redeemable: false,
      submitted: moment().toDate(),
    },
    bonusPoints: 0,
    formPoints: 0,
    description: "",
    entryId: "",
    statusList: CLAIM_ENTRY_STATUS,
    filteredStatus: [],
    memberDialog: false,
    selectedMemberId: "",
    localEntries: [],
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    entries: {
      get() {
        if (this.localEntries && this.localEntries.length > 0) {
          const sortedEntries = [...this.localEntries].sort((a, b) => {
            const dateA = this.getTimestamp(a.submitted);
            const dateB = this.getTimestamp(b.submitted);
            return dateB - dateA;
          });
          return sortedEntries;
        }

        const entries = this.currentOffer?.entries || [];
        const sortedEntries = [...entries].sort((a, b) => {
          const dateA = this.getTimestamp(a.submitted);
          const dateB = this.getTimestamp(b.submitted);
          return dateB - dateA;
        });
        return sortedEntries;
      },
      set(value) {
        this.localEntries = [...value];
        this.$store.dispatch("patchCurrentOffer", {
          entries: value,
        });
      },
    },
    filteredEntries() {
      return this.entries.filter();
    },
    claimHasEntries() {
      if (this.selectedItem && this.selectedItem.history.length > 0) {
        return true;
      }
      return false;
    },
    processingOffer() {
      return this.$store.getters.processingOffer;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    opportunities() {
      const opps = this.$store.getters.currentOfferOpportunities;
      return opps;
    },
    companies() {
      return this.$store.state.company.companies;
    },
    members() {
      return this.$store.state.member.members;
    },
    hasOfferPendingUpdates() {
      return this.$store.getters.hasOfferPendingUpdates;
    },
  },

  watch: {
    "currentOffer.id": {
      handler(newValue) {
        if (newValue) {
          this.fetchEntriesFromSubcollection();
        }
      },
      immediate: true,
    },

    "$store.state.offer.pendingOfferUpdates": {
      handler() {
        if (
          Object.keys(this.$store.state.offer.pendingOfferUpdates).length === 0
        ) {
          this.fetchEntriesFromSubcollection();
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.currentOffer && this.currentOffer.id) {
      this.fetchEntriesFromSubcollection();
    }
  },

  methods: {
    async fetchEntriesFromSubcollection() {
      if (!this.currentOffer || !this.currentOffer.id) return;

      console.log("[OfferEntries] Fetching entries from subcollection");
      try {
        this.$store.commit("setLoadingTable", true);

        const entries = await this.$store.dispatch(
          "loadOfferEntries",
          this.currentOffer.id
        );

        console.log(
          `[OfferEntries] Retrieved ${entries.length} entries from subcollection`
        );

        this.localEntries = entries;

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      } catch (error) {
        console.error("[OfferEntries] Error fetching entries:", error);
      } finally {
        this.$store.commit("setLoadingTable", false);
      }
    },

    getTimestamp(date) {
      if (!date) return null;
      if (date instanceof Date) {
        return date;
      }
      if (date && typeof date.toDate === "function") {
        return date.toDate();
      }
      if (typeof date === "string") {
        if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
          return moment(date).hours(12).minutes(0).seconds(0).toDate();
        }
        return moment(date).toDate();
      }
      return moment(date).toDate();
    },

    clearFile() {
      this.fileToUpload = null;
      this.fileDescription = "";
    },

    formatDate(date) {
      if (!date) return "";
      const timestamp = this.getTimestamp(date);
      return timestamp ? moment(timestamp).format("YYYY-MM-DD HH:mm:ss") : "";
    },

    formatDisplayDate(date) {
      if (!date) return "";
      const timestamp = this.getTimestamp(date);
      return timestamp ? moment(timestamp).format("ll") : "";
    },

    formatTime(date) {
      if (!date) return "";
      const timestamp = this.getTimestamp(date);
      return timestamp ? moment(timestamp).format("h:mm A") : "";
    },

    openDelDialog(item) {
      this.selectedItem = Object.assign({}, item);
      this.delDialog = true;
    },
    closeDelDialog() {
      this.selectedItem = Object.assign({}, this.defaultItem);
      this.delDialog = false;
    },
    handleDeleteEntry() {
      const index = this.entries.findIndex(
        (el) => el.id === this.selectedItem.id
      );
      if (index === -1) {
        console.error("[OfferEntries] Entry not found for deletion");
        return;
      }

      console.log(
        "[OfferEntries] Marking entry for deletion:",
        this.selectedItem.id
      );
      const deletedEntry = this.entries[index];

      // Update local state for immediate UI feedback
      const updatedEntries = [...this.entries];
      updatedEntries.splice(index, 1);
      this.localEntries = updatedEntries;

      // Send the updated entries array to the store
      this.$store.dispatch("patchCurrentOffer", {
        entries: updatedEntries,
      });

      // Add to the deleted entries tracker in the store
      // This is crucial for the entry to be deleted from the subcollection when the user saves
      this.$store.commit("addDeletedEntry", deletedEntry);

      // Show notification that the entry is marked for deletion
      this.$store.commit("setSnackbar", {
        text: "Entry marked for deletion. Click Save to confirm changes.",
        color: "info",
      });

      this.closeDelDialog();
    },
    openEditDialog(item) {
      if (item) {
        this.entryId = item.id;
      } else {
        this.entryId = "";
      }
      this.editDialog = true;
    },
    closeEditDialog() {
      this.entryId = "";
      this.editDialog = false;
    },

    opportunityName(id) {
      if (!id) return "Not found";
      const opp = this.opportunities.find((el) => el.id === id);
      return opp ? opp.title : "Not found";
    },
    memberName(id) {
      const member = this.members.find((member) => member.id === id) || {};
      return member.fullname;
    },
    companyName(id) {
      const company = this.companies.find((company) => company.id === id) || {};
      return company.title;
    },
    openMemberDialog(memberId) {
      this.selectedMemberId = memberId;
      this.memberDialog = true;
    },
    closeMemberDialog() {
      this.selectedMemberId = "";
      this.memberDialog = false;
    },
    handleDeletedPoints(deduction) {
      this.$store.dispatch("addPendingPointDeduction", deduction);
    },
    calculateTotalPoints(item) {
      if (!item || !item.history || !Array.isArray(item.history)) {
        return 0;
      }

      return item.history.reduce((sum, entry) => {
        if (
          entry.outcome === "approved" ||
          (entry.total > 0 && entry.outcome !== "rejected")
        ) {
          return sum + (parseFloat(entry.total) || 0);
        }
        return sum;
      }, 0);
    },
    refreshEntries() {
      this.fetchEntriesFromSubcollection();
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      min-width="400"
      max-width="400"
      offset-x
      content-class="white-background"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          @click="onSettingsEdit(true)"
          class="action-icon"
        >
          settings
        </v-icon>
      </template>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-tabs v-model="tabs">
          <v-tab class="tab"> Settings </v-tab>
          <v-tab class="tab" v-if="presetDataRequired"> Presets </v-tab>
          <v-tab class="tab" v-if="validationDataRequired"> Validation </v-tab>
          <v-tab class="tab" v-if="showEntryLimits"> Limits </v-tab>
          <v-tabs-items v-model="tabs">
            <!-- Settings -->
            <v-tab-item>
              <v-list>
                <v-list-item v-if="showLabel">
                  <v-text-field
                    class="mt-2"
                    v-model="editingField.label"
                    label="Label"
                    :rules="labelRules"
                    @input="updateStoreField"
                  ></v-text-field>
                </v-list-item>

                <v-list-item v-if="showPlaceholder">
                  <v-text-field
                    v-model="editingField.placeholder"
                    label="Placeholder"
                    clearable
                  ></v-text-field>
                </v-list-item>

                <v-list-item v-if="editingField.editor === 'headings'">
                  <v-text-field
                    v-model="editingField.styles.value"
                  ></v-text-field>
                </v-list-item>

                <v-list-item v-if="showMin">
                  <v-text-field
                    v-model="editingField.min"
                    label="Min"
                    :rules="numberRules"
                    clearable
                  ></v-text-field>
                </v-list-item>

                <v-list-item v-if="showMax">
                  <v-text-field
                    v-model="editingField.max"
                    label="Max"
                    :rules="numberRules"
                    clearable
                  ></v-text-field>
                </v-list-item>

                <v-list-item v-if="showMaxFiles">
                  <v-text-field
                    v-model="editingField.maxFiles"
                    label="Maximum Files Allowed"
                    :rules="maxFilesRules"
                    hint="Must be a number between 1 and 20"
                  ></v-text-field>
                </v-list-item>

                <v-list-item v-if="showPermittedFileExtensions">
                  <v-select
                    v-model="editingField.permittedFileExtensions"
                    label="Permitted File Extensions"
                    :items="availableFileExtensions"
                    chips
                    small-chips
                    multiple
                    hint="Select one or more file types that users can upload"
                    persistent-hint
                  >
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click:close="parent.selectItem(item)"
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-list-item>

                <v-list-item v-if="showThumbLabel">
                  <v-list-item-action>
                    <v-switch
                      v-model="editingField.thumbLabel"
                      color="primary"
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Thumb label</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="showRequired" no-gutters align="center">
                  <v-switch
                    v-model="editingField.required"
                    color="primary"
                    @change="updateStoreField"
                  ></v-switch>
                  <span>Mandatory</span>
                </v-list-item>

                <v-list-item v-if="showClearable" no-gutters align="center">
                  <v-switch
                    v-model="editingField.clearable"
                    color="primary"
                  ></v-switch>
                  <span>Clearable</span>
                </v-list-item>
                <v-list-item v-if="showCols" no-gutters align="center">
                  <v-slider
                    class="mt-6"
                    v-model="editingField.cols"
                    color="primary"
                    min="1"
                    max="12"
                    step="1"
                    show-current-value="true"
                    label="Width"
                    thumb-label="always"
                  ></v-slider>
                </v-list-item>
              </v-list>
            </v-tab-item>

            <!-- Presets -->
            <v-tab-item v-if="presetDataRequired">
              <div class="pa-4">
                <div class="form-heading">Field Presets</div>
                <div class="caption">
                  Predefined options to populate the form field
                </div>
                <v-row no-gutters>
                  <v-radio-group
                    v-model="editingField.presets.presetsType"
                    row
                    @change="updateStoreField"
                  >
                    <v-radio label="Simple" value="static"></v-radio>
                    <v-radio label="Advanced" value="dynamic"></v-radio>
                  </v-radio-group>
                </v-row>

                <v-card
                  v-if="editingField.presets.presetsType === 'static'"
                  elevation="0"
                  outlined
                  color="grey lighten-4"
                  class="mt-n2"
                >
                  <form data-vv-scope="newFormOption">
                    <v-row no-gutters class="mt-2 mx-2 mb-n6" align="center">
                      <v-col>
                        <v-text-field
                          v-model="newFormOption.value"
                          label="Add new option"
                          outlined
                          dense
                          v-validate="'required'"
                          name="formOption"
                          :error-messages="errors.collect('field.value')"
                          background-color="white"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          :disabled="disableAdd"
                          @click="addFormOption('newFormOption')"
                          color="primary"
                          elevation="0"
                          class="mt-n6"
                          small
                          icon
                          ><v-icon>add</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </form>

                  <v-data-table
                    class="grey lighten-4 mt-n6"
                    :headers="headers"
                    :items="editingField.presets.staticOptions"
                    :search="search"
                    :options.sync="options"
                    :loading="loadingTable"
                    no-data-text="No options yet..."
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    dense
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu bottom left close-on-content-click>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item link @click="handleEditOptionOpen(item)">
                            <v-list-item-title> Edit </v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="handleDeleteOption(item)">
                            <v-list-item-title>Delete </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </v-card>

                <div v-else>
                  <v-row no-gutters>
                    <v-col>
                      <v-select
                        v-model="editingField.presets.selectedPresetsGroup"
                        :items="presetDataSets"
                        item-text="dataSetTitle"
                        item-value="id"
                        clearable
                        v-validate="'required'"
                        :error-messages="errors.collect('field.dataset')"
                        name="dataset"
                        @change="onPresetDataChange"
                        label="Form Presets Group"
                      ></v-select>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        small
                        elevation="0"
                        class="mr-2"
                        color="primary"
                        text
                        :disabled="!editingField.presets.selectedPresetsGroup"
                        @click="
                          handleEditFormDataSet(
                            editingField.presets.selectedPresetsGroup
                          )
                        "
                        >edit</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      editingField && editingField.presets.selectedPresetsGroup
                    "
                    no-gutters
                  >
                    <v-select
                      v-model="editingField.presets.valuesToDisplay"
                      :items="itemsAvailableToDisplay"
                      label="Values To Display"
                      multiple
                      deletable-chips
                      chips
                    >
                      <template v-slot:selection="{ item, index, attrs }">
                        <v-chip
                          v-bind="attrs"
                          :key="index"
                          class="capitalize"
                          close
                          @click:close="
                            editingField.presets.valuesToDisplay.splice(
                              index,
                              1
                            )
                          "
                        >
                          {{ item }}
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                          class="capitalize"
                        >
                          <v-list-item-action>
                            <v-checkbox
                              v-model="attrs.inputValue"
                              :ripple="false"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-row>
                </div>
              </div>
            </v-tab-item>

            <!-- Validation -->
            <v-tab-item v-if="validationDataRequired">
              <div class="pa-4">
                <div class="form-heading">Validation</div>
                <div class="caption text-wrap">
                  Verify that entries match a preset value
                </div>

                <v-radio-group
                  v-model="editingField.validation.validationFormDataType"
                  row
                  @change="updateStoreField"
                >
                  <v-radio label="None" value="none"></v-radio>
                  <v-radio label="Simple" value="static"></v-radio>
                  <v-radio label="Advanced" value="dynamic"></v-radio>
                </v-radio-group>

                <v-card
                  v-if="
                    editingField.validation.validationFormDataType === 'static'
                  "
                  elevation="0"
                  outlined
                  color="grey lighten-4"
                  class="mt-0"
                >
                  <form data-vv-scope="newFormOption">
                    <v-row no-gutters class="mt-2 mx-2 mb-n6" align="center">
                      <v-col>
                        <v-text-field
                          v-model="newFormValidationOption.value"
                          label="Add new option"
                          outlined
                          dense
                          v-validate="'required'"
                          name="formOption"
                          :error-messages="errors.collect('field.value')"
                          background-color="white"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          :disabled="disableAdd"
                          @click="
                            addFormValidationOption('newFormValidationOption')
                          "
                          color="primary"
                          elevation="0"
                          class="mt-n6"
                          small
                          icon
                          ><v-icon>add</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </form>

                  <v-data-table
                    class="grey lighten-4 mt-n6 mb-6"
                    :headers="headers"
                    :items="editingField.validation.staticValidationOptions"
                    :search="search"
                    :options.sync="options"
                    :loading="loadingTable"
                    no-data-text="No options yet. Use the form above to enter them."
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    dense
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu bottom left close-on-content-click>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            @click="handleEditValidationOptionOpen(item)"
                          >
                            <v-list-item-title> Edit </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            @click="handleDeleteValidationOption(item)"
                          >
                            <v-list-item-title>Delete </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </v-card>

                <div
                  v-if="
                    editingField.validation.validationFormDataType === 'dynamic'
                  "
                >
                  <v-row no-gutters class="mx-2 mt-n4">
                    <v-select
                      v-model="
                        editingField.validation.selectedFormValidationDataSet
                      "
                      :items="presetDataSets"
                      item-text="dataSetTitle"
                      item-value="id"
                      clearable
                      label="Form Presets Group"
                      v-validate="'required'"
                      :error-messages="errors.collect('field.dataset')"
                      name="dataset"
                      @change="onValidationDataChange"
                    ></v-select>
                  </v-row>
                </div>
              </div>
            </v-tab-item>

            <!-- Entry limits -->
            <v-tab-item v-if="showEntryLimits">
              <div class="pa-4">
                <div class="form-heading">Entry Limit</div>
                <div class="caption text-wrap">
                  Sets the maximum number of times a unique value can be
                  submitted
                </div>
                <v-text-field
                  label="Maximum Entries Per Value"
                  v-model="editingField.entryLimits.maximumEntriesPerValue"
                  :rules="limitRules"
                  hint="Leave blank for unlimited"
                  persistent-hint
                  clearable
                ></v-text-field>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-form>
      <v-card-actions class="pb-0">
        <v-spacer></v-spacer>

        <v-btn text @click="handleCancel"> Cancel </v-btn>
        <v-btn color="primary" @click="handleSave('field')" elevation="0" small>
          submit
        </v-btn>
      </v-card-actions>
    </v-menu>

    <EditOption
      v-if="editOptionDialogOpen"
      :editingOption="editingOption"
      :open="editOptionDialogOpen"
      @onCancelEditOption="handleEditOptionClose"
      @onSaveEditOption="handleEditOptionSave"
    >
    </EditOption>

    <EditValidationOption
      v-if="editValidationOptionDialogOpen"
      :editingOption="editingValidationOption"
      :open="editValidationOptionDialogOpen"
      @onCancelEditOption="handleEditValidationOptionClose"
      @onSaveEditOption="handleEditValidationOptionSave"
    >
    </EditValidationOption>

    <preset-data-set-add-or-edit
      :presetDataSetId="selectedPresetDataSetId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
  </div>
</template>

<script>
import EditOption from "./EditOption.vue";
import EditValidationOption from "./EditValidationOption.vue";
//import HeadingEditor from "../../../../Design/Editor/Shared/HeadingEditor.vue";
import PresetDataSetAddOrEdit from "../../FormPresetLibrary/PresetDataSetAddOrEdit.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  components: {
    EditOption,
    EditValidationOption,
    PresetDataSetAddOrEdit,
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  created() {
    this.editingField = Object.assign({}, this.field);
  },
  data: () => ({
    tabs: null,
    fav: true,
    message: false,
    hints: true,
    menu: false,
    editingField: {},
    editedIndex: -1,
    editingOption: {},
    editOptionDialogOpen: false,
    newFormOption: {
      value: "",
    },
    editingValidationOption: {},
    editValidationOptionDialogOpen: false,
    newFormValidationOption: {
      value: "",
    },
    valid: true,
    numberRules: [
      (v) => !!v || "Number is required",
      (v) => !isNaN(v) || "Input must be a number",
    ],
    maxFilesRules: [
      (v) => !!v || "Number is required",
      (v) => !isNaN(v) || "Input must be a number",
      (v) => (v >= 1 && v <= 20) || "Input must be a number between 1 and 20",
    ],
    limitRules: [(v) => !isNaN(v) || "Input must be a number"],
    labelRules: [(v) => !!v || "Label is required"],
    headers: [
      { text: "Option", value: "value", align: "left" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    search: "",
    dictionary: {
      custom: {
        label: {
          required: () => "Label is required",
        },
        value: {
          required: () => "Data option is required",
        },
        dataset: {
          required: () => "Data set is required",
        },
      },
    },
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
    dialogNewOrEditOpen: false,
    selectedPresetDataSetId: "",
    itemsAvailableToDisplay: ["identifier", "title", "description", "points"],
    availableFileExtensions: [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "csv",
    ],
  }),

  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    localFormOptions() {
      return this.editingField.presets.staticOptions;
    },
    showMin() {
      return Object.prototype.hasOwnProperty.call(this.editingField, "min");
    },
    showMax() {
      return Object.prototype.hasOwnProperty.call(this.editingField, "max");
    },
    showThumbLabel() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "thumbLabel"
      );
    },
    showClearable() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "clearable"
      );
    },
    showRequired() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "required"
      );
    },
    showPlaceholder() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "placeholder"
      );
    },
    showLabel() {
      if (this.editingField.editor === "headings") {
        return false;
      } else {
        return true;
      }
    },
    showMaxFiles() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "maxFiles"
      );
    },
    showPermittedFileExtensions() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "permittedFileExtensions"
      );
    },
    presetDataRequired() {
      return Object.prototype.hasOwnProperty.call(this.editingField, "presets");
    },
    validationDataRequired() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "validation"
      );
    },
    showEntryLimits() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "entryLimits"
      );
    },
    showCols() {
      return Object.prototype.hasOwnProperty.call(this.editingField, "cols");
    },
    formOptions() {
      if (this.editingField.presets.presetsType === "dynamic") {
        return this.dynamicFormOptions;
      } else {
        return this.editingField.presets.staticOptions.map((el) => ({
          identifier: el.value,
          title: el.value,
        }));
      }
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    disableAdd() {
      return this.newFormOption.formOption === "";
    },
    presetDataSets() {
      return this.$store.getters.presetDataSets;
    },
    presetRecords() {
      return this.$store.getters.presetRecords;
    },
    loadingValidationRecords() {
      return this.$store.getters.loadingValidationRecords;
    },
  },
  methods: {
    onSettingsEdit(val) {
      this.$emit("onSettingsEdit", val);
    },
    handleCancel() {
      this.$emit("onSettingsEdit", false);
    },
    handleSave() {
      this.validate();
      if (!this.valid) {
        console.log("invalid");
        return;
      }

      // Create a deep copy of the edited field
      const updatedField = JSON.parse(JSON.stringify(this.editingField));

      // Emit the updated field
      this.$emit("onUpdateSettings", updatedField);
      this.$emit("onSettingsEdit", false);

      // Broadcast a message to tell everyone settings changed
      this.$root.$emit("field-settings-updated", updatedField.idx);

      // Update the current offer's form fields
      const currentOffer = this.$store.state.offer.currentOffer;
      const updatedFormFields = currentOffer.formFields.map((field) => {
        if (field.idx === updatedField.idx) {
          return updatedField;
        }
        return field;
      });

      // Update the form structure
      const updatedForm = {
        ...currentOffer.form,
        pages: currentOffer.form.pages.map((page) => ({
          ...page,
          sections: page.sections.map((section) => ({
            ...section,
            columns: section.columns.map((column) => ({
              ...column,
              items: column.items.map((item) => {
                if (item.idx === updatedField.idx) {
                  return updatedField;
                }
                return item;
              }),
            })),
          })),
        })),
      };

      // First update formFields
      this.$store.dispatch("patchCurrentOffer", {
        formFields: updatedFormFields,
      });

      // Then update form structure
      this.$store.dispatch("patchCurrentOffer", {
        form: updatedForm,
      });

      this.reset;
    },
    onRadioChange() {
      // Create a deep copy of the current field
      const updatedField = JSON.parse(JSON.stringify(this.editingField));

      // Reset selections
      this.selectedCheckboxes = [];
      this.selectedRadio = null;
      this.selectedDropdown = null;

      // Check for dynamic selection
      this.checkDynamicSelected();

      // Update the field
      this.editingField = updatedField;

      // Update the current offer's form fields
      const currentOffer = this.$store.state.offer.currentOffer;
      const updatedFormFields = currentOffer.formFields.map((field) => {
        if (field.idx === updatedField.idx) {
          return updatedField;
        }
        return field;
      });

      // Update the form structure
      const updatedForm = {
        ...currentOffer.form,
        pages: currentOffer.form.pages.map((page) => ({
          ...page,
          sections: page.sections.map((section) => ({
            ...section,
            columns: section.columns.map((column) => ({
              ...column,
              items: column.items.map((item) => {
                if (item.idx === updatedField.idx) {
                  return updatedField;
                }
                return item;
              }),
            })),
          })),
        })),
      };

      // Update both formFields and form structure in the store
      this.$store.dispatch("patchCurrentOffer", {
        formFields: updatedFormFields,
        form: updatedForm,
      });
    },
    checkDynamicSelected() {
      if (
        (this.editingField.validation &&
          this.editingField.validation.validationFormDataType === "dynamic") ||
        (this.editingField.presets &&
          this.editingField.presets.presetsType === "dynamic")
      ) {
        this.$store.dispatch("loadPresetDataSets");
      }
    },
    async onPresetDataChange() {
      this.dynamicFormOptions = await this.$store.dispatch(
        "loadPresetDataSets",
        this.editingField.presets.selectedPresetsGroup
      );
      this.updateStoreField();
    },
    async onValidationDataChange() {
      this.dynamicFormOptions = await this.$store.dispatch(
        "loadPresetDataSets",
        this.editingField.validation.selectedFormValidationDataSet
      );
      this.updateStoreField();
    },
    addFormOption(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        if (!validationResult) {
          return;
        }

        // Create deep copies
        const formOption = JSON.parse(JSON.stringify(this.newFormOption));
        const updatedField = JSON.parse(JSON.stringify(this.editingField));

        // Update options array
        const newOptions = [...updatedField.presets.staticOptions, formOption];
        updatedField.presets.staticOptions = newOptions;

        // Update the field
        this.editingField = updatedField;

        this.resetNewFormOption();
      });
    },
    addFormValidationOption(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        if (!validationResult) {
          return;
        }

        // Create deep copies
        const formOption = JSON.parse(
          JSON.stringify(this.newFormValidationOption)
        );
        const updatedField = JSON.parse(JSON.stringify(this.editingField));

        // Update validation options array
        const newOptions = [
          ...updatedField.validation.staticValidationOptions,
          formOption,
        ];
        updatedField.validation.staticValidationOptions = newOptions;

        // Update the field
        this.editingField = updatedField;

        // Update the store
        this.updateStoreField();

        this.resetNewFormValidationOption();
      });
    },
    resetNewFormOption() {
      this.newFormOption = {
        value: "",
      };
      this.$validator.reset();
    },
    resetNewFormValidationOption() {
      this.newFormValidationOption = {
        value: "",
      };
      this.$validator.reset();
    },
    handleDeleteOption(item) {
      // Create a deep copy of the current field
      const updatedField = JSON.parse(JSON.stringify(this.editingField));

      this.editedIndex = updatedField.presets.staticOptions.indexOf(item);
      updatedField.presets.staticOptions.splice(this.editedIndex, 1);

      // Update the field
      this.editingField = updatedField;
      this.editedIndex = -1;
    },
    handleDeleteValidationOption(item) {
      // Create a deep copy of the current field
      const updatedField = JSON.parse(JSON.stringify(this.editingField));

      this.editedIndex =
        updatedField.validation.staticValidationOptions.indexOf(item);
      updatedField.validation.staticValidationOptions.splice(
        this.editedIndex,
        1
      );

      // Update the field
      this.editingField = updatedField;
      this.editedIndex = -1;

      // Update the store
      this.updateStoreField();
    },
    handleEditOptionOpen(item) {
      this.editedIndex = this.editingField.presets.staticOptions.indexOf(item);
      this.editingOption = JSON.parse(
        JSON.stringify(
          this.editingField.presets.staticOptions[this.editedIndex]
        )
      );
      this.editOptionDialogOpen = true;
    },
    handleEditValidationOptionOpen(item) {
      this.editedIndex =
        this.editingField.validation.staticValidationOptions.indexOf(item);
      this.editingValidationOption = JSON.parse(
        JSON.stringify(
          this.editingField.validation.staticValidationOptions[this.editedIndex]
        )
      );
      this.editValidationOptionDialogOpen = true;
    },
    handleEditOptionClose() {
      this.editOptionDialogOpen = false;
      this.editingOption = {};
      this.editedIndex = -1;
    },
    handleEditValidationOptionClose() {
      this.editValidationOptionDialogOpen = false;
      this.editingValidationOption = {};
      this.editedIndex = -1;
    },
    handleEditOptionSave(item) {
      // Create a deep copy of the current field
      const updatedField = JSON.parse(JSON.stringify(this.editingField));

      // Update the option
      updatedField.presets.staticOptions[this.editedIndex] = JSON.parse(
        JSON.stringify(item)
      );

      // Update the field
      this.editingField = updatedField;

      this.editOptionDialogOpen = false;
      this.editingOption = {};
      this.editedIndex = -1;
    },
    handleEditValidationOptionSave(item) {
      // Create a deep copy of the current field
      const updatedField = JSON.parse(JSON.stringify(this.editingField));

      // Update the validation option
      updatedField.validation.staticValidationOptions[this.editedIndex] =
        JSON.parse(JSON.stringify(item));

      // Update the field
      this.editingField = updatedField;

      this.editValidationOptionDialogOpen = false;
      this.editingValidationOption = {};
      this.editedIndex = -1;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    handleEditFormDataSet(id) {
      this.$store.dispatch("loadPresetRecords", id);
      this.selectedPresetDataSetId = id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      this.selectedPresetDataSetId = "";
      this.dialogNewOrEditOpen = false;
    },
    updateStoreField() {
      // Only proceed if we have a valid field
      if (!this.editingField || !this.editingField.idx) return;

      // Create a deep copy of the edited field
      const updatedField = JSON.parse(JSON.stringify(this.editingField));

      // Update the store directly
      const currentOffer = this.$store.state.offer.currentOffer;

      // Update formFields
      const updatedFormFields = currentOffer.formFields.map((field) => {
        if (field.idx === updatedField.idx) {
          return updatedField;
        }
        return field;
      });

      // Dispatch update to store
      this.$store.dispatch("patchCurrentOffer", {
        formFields: updatedFormFields,
      });
    },
  },
  watch: {
    field: {
      handler(newField) {
        this.editingField = Object.assign({}, newField);
      },
      deep: true,
    },
    "editingField.validation.validationFormDataType": {
      handler() {
        // Only proceed if we have a valid field
        if (!this.editingField || !this.editingField.idx) return;

        // Create a deep copy of the edited field
        const updatedField = JSON.parse(JSON.stringify(this.editingField));

        // Update the store directly
        const currentOffer = this.$store.state.offer.currentOffer;

        // Update formFields
        const updatedFormFields = currentOffer.formFields.map((field) => {
          if (field.idx === updatedField.idx) {
            return updatedField;
          }
          return field;
        });

        // Dispatch update to store
        this.$store.dispatch("patchCurrentOffer", {
          formFields: updatedFormFields,
        });
      },
    },
    "editingField.presets.presetsType": {
      handler() {
        // Only proceed if we have a valid field
        if (!this.editingField || !this.editingField.idx) return;

        // Create a deep copy of the edited field
        const updatedField = JSON.parse(JSON.stringify(this.editingField));

        // Update the store directly
        const currentOffer = this.$store.state.offer.currentOffer;

        // Update formFields
        const updatedFormFields = currentOffer.formFields.map((field) => {
          if (field.idx === updatedField.idx) {
            return updatedField;
          }
          return field;
        });

        // Dispatch update to store
        this.$store.dispatch("patchCurrentOffer", {
          formFields: updatedFormFields,
        });
      },
    },
  },
};
</script>

<style scoped>
.action-icon {
  color: #ffffff !important;
  font-size: 20px !important;
  margin: 3px;
}
.icon {
  color: #505050 !important;
  margin-left: 14px;
}
.type-subheading {
  font-weight: 300 !important;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #505050 !important;
  margin-left: 6px;
}
.editor {
  width: 360px;
  margin-left: 20px;
  margin-right: 20px;
}
.white-background {
  background-color: white !important;
}
</style>

<template>
  <div>
    <v-dialog v-model="open" :width="member ? '1200px' : '450px'" persistent>
      <v-card>
        <v-form ref="form" v-model="isFormValid" @submit.prevent="handleSave">
          <v-row no-gutters>
            <v-card-title class="page-heading mb-n3">
              {{ entryId ? "Review Entry" : "Add New Entry" }}
            </v-card-title>
            <v-spacer></v-spacer>
            <v-chip
              v-if="entryId && calculatedTotalPoints >= 0"
              :color="calculatedTotalPoints > 0 ? 'primary' : 'grey lighten-3'"
              :class="{ 'points-pulse': pointsChanged } + ' mt-3 mr-3'"
            >
              <v-icon left>{{
                calculatedTotalPoints > 0 ? "stars" : "remove_circle_outline"
              }}</v-icon>
              <span class="points-chip">
                {{
                  calculatedTotalPoints > 0
                    ? formatNumber(calculatedTotalPoints) + " points"
                    : "No points"
                }}
              </span>
            </v-chip>
          </v-row>

          <v-card-text :class="member ? 'px-9 pt-0' : 'px-9 pt-0'">
            <v-row :no-gutters="member ? false : true" class="mt-n3">
              <v-col :cols="member ? '4' : '12'">
                <v-select
                  :items="opportunities"
                  v-model="opportunity"
                  item-text="title"
                  item-value="id"
                  label="Opportunity"
                  :rules="opportunityRules"
                  :readonly="!!id"
                />
              </v-col>
              <v-col :cols="member ? '4' : '12'">
                <v-text-field
                  :value="DateUtils.formatDisplay(deadline)"
                  label="Deadline"
                  readonly
                  prepend-icon="insert_invitation"
                ></v-text-field
              ></v-col>
              <v-col v-if="member" :cols="member ? '4' : '12'">
                <v-row no-gutters align="center">
                  <status-icon :status="status"></status-icon>
                  <v-text-field
                    v-model="status"
                    label="Status"
                    name="status"
                    readonly
                    class="ml-3"
                  />
                </v-row>
              </v-col>
            </v-row>

            <v-row :no-gutters="member ? false : true" class="mt-n5">
              <v-col :cols="member ? '4' : '12'">
                <v-autocomplete
                  :items="members"
                  v-model="member"
                  item-text="fullname"
                  item-value="id"
                  label="Member"
                  prepend-icon="person"
                  :rules="memberRules"
                  :readonly="!!id"
                  :class="member ? '' : 'mt-6'"
                >
                  <template v-slot:append>
                    <v-icon
                      v-if="member"
                      color="primary"
                      class="clickable"
                      @click.stop="openMemberDialog(member)"
                    >
                      open_in_new
                    </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col :cols="member ? '4' : '12'">
                <v-menu
                  v-model="submittedMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="DateUtils.formatDisplay(submitted)"
                      label="Submitted"
                      readonly
                      v-on="on"
                      prepend-icon="event_note"
                      @click:clear="submitted = null"
                      :rules="submittedDateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="submitted"
                    @input="submittedMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col :cols="member ? '4' : '12'">
                <v-text-field
                  :value="DateUtils.formatDisplay(reviewed)"
                  label="Reviewed"
                  prepend-icon="event_available"
                  readonly
                ></v-text-field
              ></v-col>
            </v-row>

            <v-alert
              v-if="needsApproval"
              text
              dense
              class="mb-n2 mt-4"
              color="primary"
              border="left"
            >
              <span
                v-if="
                  formSubmissionData &&
                  !hasFormPointsInHistory &&
                  parseFloat(formPoints) > 0 &&
                  !hasOpportunityPointsInHistory &&
                  parseFloat(points) > 0
                "
              >
                The form has been submitted and both opportunity and form points
                have been calculated, but they have not yet been awarded to the
                member's account.
              </span>
              <span
                v-else-if="
                  formSubmissionData &&
                  !hasFormPointsInHistory &&
                  parseFloat(formPoints) > 0
                "
              >
                The form has been submitted and points calculated, but they have
                not yet been awarded to the member's account.
              </span>
              <span
                v-else-if="
                  !hasOpportunityPointsInHistory && parseFloat(points) > 0
                "
              >
                Points have been calculated but not yet awarded to the member's
                account.
              </span>
              Please click the <strong>Approve</strong> button to award these
              points.
            </v-alert>

            <!-- Tabs - Only show when a member is selected -->
            <template v-if="member">
              <v-tabs v-model="activeTab" class="mt-4">
                <v-tab class="tab">Evaluation</v-tab>
                <v-tab class="tab">Form Data</v-tab>
                <!-- Alert for pending form points -->
              </v-tabs>

              <v-tabs-items v-model="activeTab" class="mt-4">
                <!-- History Tab -->
                <v-tab-item v-if="member">
                  <div class="mt-4">
                    <v-row no-gutters align="center" data-vv-scope="points">
                      <v-text-field
                        label="Opportunity Points"
                        :value="points"
                        type="number"
                        @wheel="$event.target.blur()"
                        outlined
                        dense
                        class="mr-2"
                        :rules="pointsRules"
                        @input="handlePointsInput"
                      />
                      <v-text-field
                        label="Form Points"
                        v-model="formPoints"
                        type="number"
                        persistent-hint
                        hint="Any form based points"
                        @wheel="$event.target.blur()"
                        outlined
                        dense
                        class="mr-2"
                      />
                      <v-text-field
                        label="Add Bonus Points"
                        v-model="bonusPoints"
                        type="number"
                        persistent-hint
                        hint="*Optional"
                        @wheel="$event.target.blur()"
                        outlined
                        dense
                        class="mr-2"
                        :rules="bonusPointsRules"
                      />
                      <v-text-field
                        label="Comment"
                        v-model="description"
                        name="description"
                        persistent-hint
                        hint="Appears in members transaction history"
                        outlined
                        dense
                        class="mr-1"
                      />
                      <v-btn
                        color="red"
                        class="white--text mt-n5"
                        :disabled="isPointsInvalid || demo"
                        @click.stop="reject"
                        elevation="0"
                      >
                        Reject
                      </v-btn>
                      <v-btn
                        color="green"
                        class="white--text mt-n5"
                        :disabled="isPointsInvalid || demo"
                        @click.stop="award"
                        elevation="0"
                      >
                        Approve {{ totalPointsCalc }} points
                      </v-btn>
                    </v-row>
                    <v-data-table
                      class="px-0 pt-0"
                      :headers="historyHeaders"
                      :items="Array.isArray(history) ? history : []"
                      :search="search"
                      :options.sync="historyOptions"
                      :loading="loadingTable"
                      no-data-text="No records"
                    >
                      <template v-slot:item.redeemable="{ item }">
                        <v-row justify="center">
                          <v-checkbox
                            v-model="item.redeemable"
                            readonly
                          ></v-checkbox>
                        </v-row>
                      </template>

                      <template v-slot:[`item.created`]="{ item }">
                        {{ DateUtils.formatDisplay(item.created) }},
                        {{ DateUtils.formatTime(item.created) }}
                      </template>

                      <template v-slot:[`item.outcome`]="{ item }">
                        <status-icon
                          :status="
                            item.outcome ||
                            (item.total === 0 ? 'rejected' : 'approved')
                          "
                          class="pr-3"
                        ></status-icon>
                        <span class="capitalize">
                          {{
                            item.outcome ||
                            (item.total === 0 ? "rejected" : "approved")
                          }}
                        </span>
                      </template>

                      <template v-slot:[`item.submitted`]="{ item }">
                        {{ DateUtils.formatDisplay(item.submitted) }}
                        <span class="caption grey--text">{{
                          DateUtils.formatTime(item.submitted)
                        }}</span>
                      </template>

                      <template v-slot:[`item.reviewed`]="{ item }">
                        {{ DateUtils.formatDisplay(item.reviewed) }}
                        <span class="caption grey--text">{{
                          DateUtils.formatTime(item.reviewed)
                        }}</span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                          icon
                          color="red"
                          @click="deleteHistory(item)"
                          :disabled="demo"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>
                <!-- Form Data Tab -->
                <v-tab-item>
                  <v-row no-gutters class="mt-4">
                    <v-col>
                      <v-card flat>
                        <div v-if="formExistsIsCompleted">
                          <form-data-results
                            :structured-form-values="
                              formSubmissionData ||
                              currentEntry?.formSubmissionData ||
                              {}
                            "
                            :form-pages="currentOffer?.form?.pages || []"
                          />
                        </div>
                        <div v-else-if="formExistsNotCompleted">
                          <v-card-text class="text-center">
                            <v-icon color="warning" large>assignment</v-icon>
                            <div class="title mt-2">Form Not Submitted</div>
                            <div class="grey--text mt-2">
                              A form has not been submitted for this claim yet.
                            </div>
                            <v-btn
                              @click="openForm"
                              color="primary"
                              elevation="0"
                              class="mt-4"
                            >
                              Complete Form
                            </v-btn>
                          </v-card-text>
                        </div>
                        <div v-else>
                          <v-card-text class="text-center">
                            <v-icon color="grey" large>info</v-icon>
                            <div class="title mt-2">No Form Available</div>
                            <div class="grey--text mt-2">
                              No form has been created for this opportunity.
                            </div>
                          </v-card-text>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeEditDialog"> Cancel </v-btn>
            <v-btn
              :disabled="!isFormValid || demo"
              color="primary"
              type="submit"
              elevation="0"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Form Dialog -->
    <live-form-entry
      v-if="showFormDialog"
      :open="showFormDialog"
      :previewArr="currentOffer?.form?.pages || []"
      :entryId="id || entryId"
      :memberId="member"
      :opportunityId="
        typeof opportunity === 'object' ? opportunity.id : opportunity
      "
      @onCancel="handleFormCancel"
      @onSubmitForm="handleFormSubmit"
    />

    <!-- Member Dialog -->
    <member-add-or-edit
      :open="memberDialog"
      :memberId="selectedMemberId"
      @onClose="closeMemberDialog"
    ></member-add-or-edit>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import moment from "moment";
import LiveFormEntry from "./LiveFormEntry.vue";
import MemberAddOrEdit from "@/components/Member/MemberAddOrEdit.vue";
import FormDataResults from "./FormDataResults.vue";
import { DateUtils } from "@/utils/DateUtils"; // Import the DateUtils
import numberFilter from "@/filters/number"; // Import the number filter

export default {
  components: {
    LiveFormEntry,
    MemberAddOrEdit,
    FormDataResults,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    entryId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // Set initial points if opportunity is selected
    if (this.opportunity) {
      const selectedOpportunity = this.opportunities.find(
        (el) => el.id === this.opportunity
      );
      this.points = selectedOpportunity ? selectedOpportunity.points : 0;
    }
  },
  data: () => ({
    search: "",
    availableStatus: [
      { label: "Pending", value: "Pending" },
      { label: "Completed", value: "Completed" },
      { label: "Rejected", value: "Rejected" },
    ],
    historyOptions: {
      sortBy: ["created"],
      sortDesc: [true],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: false,
      filter: false,
    },
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    historyHeaders: [
      {
        text: "Determination",
        value: "outcome",
        align: "left",
        sortable: false,
      },
      { text: "Created", value: "created", align: "left" },
      { text: "Comments", value: "comments", align: "left", sortable: false },
      {
        text: "Opportunity Points",
        value: "points",
        align: "right",
        sortable: false,
      },
      {
        text: "Bonus Points",
        value: "bonusPoints",
        align: "right",
        sortable: false,
      },
      {
        text: "Form Points",
        value: "formPoints",
        align: "right",
        sortable: false,
      },
      { text: "Total Points", value: "total", align: "right", sortable: false },
      {
        text: "Redeemable",
        value: "redeemable",
        align: "center",
        sortable: false,
      },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    submittedMenu: false,
    formPoints: 0,
    bonusPoints: 0,
    description: "",
    history: [],
    company: "",
    member: "",
    points: 0,
    opportunity: "",
    submitted: "",
    status: "",
    totalPoints: 0,
    reviewed: "",
    id: "",
    redeemable: false,
    deletedHistoryPoints: 0,
    activeTab: 0,
    showFormDialog: false,
    currentStep: 1,
    showRawData: false,
    memberDialog: false,
    selectedMemberId: "",
    formSubmissionData: null,
    formSubmittedAt: null,
    isFormValid: false,
    pointsChanged: false,
    // Add validation rules
    requiredRule: [(v) => !!v || "This field is required"],
    opportunityRules: [(v) => !!v || "Opportunity is required"],
    memberRules: [(v) => !!v || "Member is required"],
    submittedDateRules: [(v) => !!v || "Submitted date is required"],
    pointsRules: [
      (v) =>
        (v !== null && v !== undefined && v !== "") || "Points are required",
      (v) => !isNaN(v) || "Points must be a number",
      (v) => v >= 0 || "Points cannot be negative",
    ],
    bonusPointsRules: [
      (v) => !v || !isNaN(v) || "Bonus points must be a number",
      (v) => !v || v >= 0 || "Bonus points cannot be negative",
    ],
    // Provide DateUtils to the template
    DateUtils,
    // Provide numberFilter to the template
    numberFilter,
  }),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    opportunities() {
      return (this.currentOffer?.opportunities || []).map((opportunity) => {
        return {
          ...opportunity,
          redeemable:
            opportunity.redeemable !== undefined
              ? opportunity.redeemable
              : false,
        };
      });
    },
    // Check if form points are already in history
    hasFormPointsInHistory() {
      if (
        !this.history ||
        !Array.isArray(this.history) ||
        !this.formSubmissionData
      )
        return false;

      // Check if any history entry contains formPoints and has been approved
      return this.history.some(
        (entry) =>
          entry.formPoints > 0 &&
          (entry.outcome === "approved" ||
            (entry.total > 0 && entry.outcome !== "rejected"))
      );
    },

    // Check if opportunity points are already in history
    hasOpportunityPointsInHistory() {
      if (!this.history || !Array.isArray(this.history)) return false;

      // Check if any history entry contains opportunity points and has been approved
      return this.history.some(
        (entry) =>
          entry.points > 0 &&
          (entry.outcome === "approved" ||
            (entry.total > 0 && entry.outcome !== "rejected"))
      );
    },

    // Check if we need to show any point approval alerts
    needsApproval() {
      // If form has been submitted and points not in history
      const formNeedsApproval =
        this.formSubmissionData &&
        !this.hasFormPointsInHistory &&
        parseFloat(this.formPoints) > 0;

      // If opportunity points are not in history
      const opportunityNeedsApproval =
        !this.hasOpportunityPointsInHistory && parseFloat(this.points) > 0;

      return formNeedsApproval || opportunityNeedsApproval;
    },
    deadline() {
      if (this.opportunity) {
        const opportunityDetail = this.opportunities.find(
          (el) => el.id === this.opportunity
        );
        return opportunityDetail ? opportunityDetail.deadline : "";
      }
      return "";
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    members() {
      return this.$store.state.member.members;
    },

    totalPointsCalc() {
      let total = 0;
      if (this.points) {
        total += +this.points;
      }
      if (this.bonusPoints) {
        total += +this.bonusPoints;
      }
      if (this.formPoints) {
        total += +this.formPoints;
      }
      return total;
    },
    entries: {
      get() {
        return this.currentOffer.entries || [];
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          entries: value,
        });
      },
    },
    isPointsInvalid() {
      return (
        this.points === null ||
        this.points === undefined ||
        this.points === "" ||
        isNaN(parseFloat(this.points))
      );
    },
    // Form existence checks
    formExists() {
      return this.currentOffer?.form !== undefined;
    },
    formExistsIsCompleted() {
      // Check if form exists and has been completed for this entry
      // Check both currentEntry and local formSubmissionData
      return (
        this.formExists &&
        // Check current entry form data
        ((this.currentEntry?.formSubmissionData !== undefined &&
          this.currentEntry?.formSubmissionData !== null &&
          Object.keys(this.currentEntry?.formSubmissionData || {}).length >
            0) ||
          // Check local form submission data
          (this.formSubmissionData !== undefined &&
            this.formSubmissionData !== null &&
            Object.keys(this.formSubmissionData || {}).length > 0))
      );
    },
    formExistsNotCompleted() {
      // For new claims (no entryId), just check if form exists
      if (!this.entryId) {
        return this.formExists;
      }

      // For existing claims, check if form exists but hasn't been completed
      return (
        this.formExists &&
        (!this.currentEntry?.formSubmissionData ||
          Object.keys(this.currentEntry?.formSubmissionData || {}).length === 0)
      );
    },
    formPages() {
      return this.currentOffer?.form?.pages || [];
    },

    showStepper() {
      return this.formPages.length > 1;
    },

    formattedResults() {
      if (!this.entryId || !this.currentOffer?.entries) return "{}";
      const entry = this.currentOffer.entries.find(
        (e) => e.id === this.entryId
      );
      return JSON.stringify(entry?.formSubmissionData || {}, null, 2);
    },

    presetRecordsCache() {
      return {}; // Initialize empty cache for preset records
    },

    calculatedTotalPoints() {
      if (!this.history || !Array.isArray(this.history)) return 0;

      // Sum up all approved entries' totals
      return this.history.reduce((sum, entry) => {
        if (
          entry.outcome === "approved" ||
          (entry.total > 0 && entry.outcome !== "rejected")
        ) {
          return sum + (parseFloat(entry.total) || 0);
        }
        return sum;
      }, 0);
    },

    currentEntry() {
      if (!this.entryId || !this.currentOffer?.entries) return null;
      return this.currentOffer.entries.find((e) => e.id === this.entryId);
    },
  },
  methods: {
    formatNumber(value) {
      return numberFilter(value);
    },

    async handleSave() {
      console.log("[AddOrEditEntry] handleSave called");
      // Use Vuetify form validation
      if (!this.$refs.form.validate()) {
        console.log("[AddOrEditEntry] Form validation failed");
        return;
      }

      try {
        // Validate form fields if form data exists
        if (this.formSubmissionData) {
          console.log(
            "[AddOrEditEntry] Validating form fields for formSubmissionData:",
            this.formSubmissionData
          );
          const validationErrors = await this.validateFormFields();
          if (validationErrors.length > 0) {
            console.log(
              "[AddOrEditEntry] Form validation errors:",
              validationErrors
            );
            this.$store.commit("setSnackbar", {
              text: "Please fix form validation errors before saving",
              color: "error",
            });
            return;
          }
        }

        // Process submitted date from v-date-picker (which returns YYYY-MM-DD format)
        let submittedDate;

        // Handle date string from v-date-picker
        if (
          typeof this.submitted === "string" &&
          this.submitted.match(/^\d{4}-\d{2}-\d{2}$/)
        ) {
          // Create with current time to ensure time part is consistent
          const now = new Date();
          const [year, month, day] = this.submitted.split("-").map(Number);
          submittedDate = new Date(
            year,
            month - 1,
            day,
            now.getHours(),
            now.getMinutes(),
            now.getSeconds()
          );
        } else {
          // Use DateUtils for other date formats
          submittedDate = DateUtils.toJsDate(this.submitted);
        }

        // Fallback to current entry's submitted date if available, or create new date
        if (!submittedDate || isNaN(submittedDate.getTime())) {
          submittedDate =
            DateUtils.toJsDate(this.currentEntry?.submitted) || new Date();
        }

        if (!submittedDate || isNaN(submittedDate.getTime())) {
          console.log("[AddOrEditEntry] Invalid submitted date");
          this.$store.commit(
            "setSnackbar",
            "Invalid submitted date. Please check the date format."
          );
          return;
        }

        // Process history dates with the DateUtils
        const processedHistory = this.history.map((item) => {
          return {
            ...item,
            created: DateUtils.toJsDate(item.created) || new Date(),
            submitted: item.submitted
              ? DateUtils.toJsDate(item.submitted)
              : null,
            reviewed: item.reviewed ? DateUtils.toJsDate(item.reviewed) : null,
            processed: item.processed || false,
            points: Number(item.points) || 0,
            bonusPoints: Number(item.bonusPoints) || 0,
            formPoints: Number(item.formPoints) || 0,
            total: Number(item.total) || 0,
          };
        });

        // Current time for lastModifiedAt
        const now = new Date();

        // Get the selected opportunity details
        const selectedOpportunity = this.opportunities.find(
          (el) => el.id === this.opportunity
        );
        console.log(
          "[AddOrEditEntry] Selected opportunity:",
          selectedOpportunity
        );

        // Create the entry data object
        const entryData = {
          id: this.id || nanoid(),
          offerId: this.currentOffer.id,
          history: processedHistory || [],
          reviewed: this.reviewed ? DateUtils.toJsDate(this.reviewed) : null,
          company: this.company || "",
          member: this.member,
          points: Number(this.calculatedTotalPoints) || 0,
          opportunity: this.opportunity,
          submitted: submittedDate,
          totalPoints: Number(this.calculatedTotalPoints) || 0,
          bonusPoints: Number(processedHistory[0]?.bonusPoints) || 0,
          formPoints: Number(this.formPoints) || 0,
          status: this.status || "Pending",
          redeemable: selectedOpportunity?.redeemable || false,
          addToDatabucket: selectedOpportunity?.addToDatabucket !== false, // true by default
          // Include form data if it exists
          formSubmissionData: this.formSubmissionData || {},
          formSubmittedAt: DateUtils.toJsDate(this.formSubmittedAt) || null,
          createdAt: !this.id ? now : undefined, // Use same timestamp instance
          lastModifiedAt: now, // Use same timestamp instance for consistency
        };
        console.log("[AddOrEditEntry] Created entry data object:", entryData);

        // Update the entries in the store
        const currentEntries = this.currentOffer.entries || [];
        let updatedEntries;

        if (!this.id) {
          // For new entries, add to the beginning of the array
          this.id = entryData.id; // Store the generated ID
          updatedEntries = [entryData, ...currentEntries];
          console.log("[AddOrEditEntry] Adding new entry to entries array");
        } else {
          // For existing entries, update the matching entry
          updatedEntries = currentEntries.map((e) =>
            e.id === entryData.id ? entryData : e
          );
          console.log(
            "[AddOrEditEntry] Updating existing entry in entries array"
          );
        }

        console.log(
          "[AddOrEditEntry] Calling patchCurrentOffer with entries:",
          updatedEntries
        );
        // Call patchCurrentOffer and log the result
        await this.$store
          .dispatch("patchCurrentOffer", {
            entries: updatedEntries,
          })
          .then(() => {
            console.log(
              "[AddOrEditEntry] patchCurrentOffer completed successfully"
            );
            // Check if the store was updated correctly
            const updatedStoreEntries =
              this.$store.state.offer.currentOffer.entries;
            console.log(
              "[AddOrEditEntry] Store entries after update:",
              updatedStoreEntries
            );
            const pendingUpdates = this.$store.state.offer.pendingOfferUpdates;
            console.log(
              "[AddOrEditEntry] Pending updates after patch:",
              pendingUpdates
            );
          })
          .catch((error) => {
            console.error(
              "[AddOrEditEntry] Error in patchCurrentOffer:",
              error
            );
          });

        this.closeEditDialog();
      } catch (error) {
        console.error("[AddOrEditEntry] Error in handleSave:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to prepare entry. Please try again."
        );
      }
    },

    closeEditDialog() {
      // Reset Vuetify form
      this.$refs.form.reset();
      this.reset();
      this.$emit("onCloseEditDialog");
    },

    async reject() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.history) {
        this.history = [
          {
            created: new Date(),
            points: 0,
            bonusPoints: 0,
            formPoints: 0,
            total: 0,
            comments: this.description,
            redeemable: this.redeemable,
            status: "Completed",
            outcome: "rejected",
          },
          ...this.history,
        ];
      } else {
        this.history = [
          {
            created: new Date(),
            points: 0,
            bonusPoints: 0,
            formPoints: 0,
            total: 0,
            redeemable: this.redeemable,
            comments: this.description,
            status: "Completed",
            outcome: "rejected",
          },
        ];
      }
      this.status = "Completed";
      this.reviewed = new Date();

      // Trigger points change animation if points were previously awarded
      if (this.calculatedTotalPoints > 0) {
        this.pointsChanged = true;
        setTimeout(() => {
          this.pointsChanged = false;
        }, 1500);
      }

      // Reset all points to 0 after rejection
      this.points = 0; // Reset opportunity points to 0 instead of default value
      this.bonusPoints = 0;
      this.formPoints = 0;
      this.description = "";

      // Only reset the form if it's a new entry
      if (!this.entryId) {
        this.reset();
      }

      // Don't automatically save - let the user click save when ready
    },

    async award() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const currentPoints = parseInt(this.points) || 0;
      const currentBonusPoints =
        this.bonusPoints && !isNaN(this.bonusPoints) && this.bonusPoints > 0
          ? parseInt(this.bonusPoints)
          : 0;
      const currentFormPoints =
        this.formPoints && !isNaN(this.formPoints) && this.formPoints > 0
          ? parseInt(this.formPoints)
          : 0;
      const awardTotal = currentPoints + currentBonusPoints + currentFormPoints;

      // Get the selected opportunity details
      const selectedOpportunity = this.opportunities.find(
        (el) => el.id === this.opportunity
      );

      // Create new history entry
      const historyEntry = {
        created: new Date(),
        points: currentPoints,
        bonusPoints: currentBonusPoints,
        formPoints: currentFormPoints,
        total: awardTotal,
        comments: this.description,
        status: "Completed",
        redeemable: selectedOpportunity?.redeemable || false,
        addToDatabucket: selectedOpportunity?.addToDatabucket !== false, // true by default
        outcome: "approved",
        processed: false,
      };

      // Update local history first - don't update the store yet
      this.history = [historyEntry, ...(this.history || [])];
      this.status = "Completed";
      this.reviewed = new Date();

      // Trigger points change animation
      this.pointsChanged = true;
      setTimeout(() => {
        this.pointsChanged = false;
      }, 1500);

      // Reset all points to 0 after they've been approved
      this.points = 0; // Reset opportunity points to 0 instead of default value
      this.bonusPoints = 0;
      this.formPoints = 0;
      this.description = "";

      // Don't automatically save - let the user click save when ready
    },

    async deleteHistory(value) {
      if (this.history) {
        // Check if the deleted entry had form points or opportunity points
        const hadFormPoints = value.formPoints > 0;
        const hadOpportunityPoints = value.points > 0;

        // Check if points need to be deducted (entry was approved and has points)
        const shouldDeductPoints =
          value.redeemable &&
          (value.outcome === "approved" ||
            (value.total > 0 && value.outcome !== "rejected"));

        if (shouldDeductPoints) {
          // Get the selected opportunity details to include in deduction
          const selectedOpportunity = this.opportunities.find(
            (el) => el.id === this.opportunity
          );

          // Create deduction object with all required information
          const deduction = {
            points: -value.total, // Negative points for deduction
            type: "Award",
            description: "Claim Reversed",
            notes:
              selectedOpportunity?.description || "Points removed from claim",
            memberId: this.member,
          };

          console.log("[AddOrEditEntry] Emitting point deduction:", deduction);

          // Emit the deduction to the parent component for processing
          this.$emit("deletedPoints", deduction);
        }

        // Track the deleted history entry in the store
        this.$store.commit("addDeletedHistoryEntry", {
          entryId: this.id,
          historyEntry: value,
          memberId: this.member,
          opportunity: this.opportunity,
          redeemable: value.redeemable,
        });

        // Remove the entry from history
        this.history = this.history.filter(
          (item) => JSON.stringify(item) != JSON.stringify(value)
        );

        // Trigger points change animation if deleting affects total points
        if (
          value.outcome === "approved" ||
          (value.total > 0 && value.outcome !== "rejected")
        ) {
          this.pointsChanged = true;
          setTimeout(() => {
            this.pointsChanged = false;
          }, 1500);
        }

        try {
          // If the deleted entry had form points, restore them to the form points field
          if (hadFormPoints && this.formSubmissionData) {
            this.formPoints = value.formPoints;
          }

          // If the deleted entry had opportunity points, restore them
          if (hadOpportunityPoints) {
            // Check if the opportunity points were the default or custom
            const selectedOpportunity = this.opportunities.find(
              (el) => el.id === this.opportunity
            );
            const defaultPoints = selectedOpportunity
              ? selectedOpportunity.points
              : 0;

            // If the deleted points match the default, keep using the default
            // Otherwise, restore the exact deleted points
            if (value.points !== defaultPoints) {
              this.points = value.points;
            } else {
              this.points = defaultPoints;
            }
          }

          // Update local status only - don't update the store until Save is clicked
          this.status = this.history.length === 0 ? "Pending" : "Completed";
        } catch (error) {
          console.error("Error updating local state:", error);
          this.$store.commit(
            "setSnackbar",
            "Failed to update entry. Please try again."
          );
        }
      }
    },

    reset() {
      this.history = [];
      this.reviewed = "";
      this.company = "";
      this.member = "";
      this.points = 0;
      this.opportunity = "";
      this.submitted = "";
      this.status = "";
      this.totalPoints = 0;
      this.bonusPoints = 0;
      this.formPoints = 0;
      this.id = "";
      this.redeemable = false;
      this.deletedHistoryPoints = 0;
      this.formSubmissionData = null;
      this.formSubmittedAt = null;
    },

    handlePointsInput(value) {
      // Handle empty string or invalid input
      if (
        value === "" ||
        value === null ||
        value === undefined ||
        isNaN(value)
      ) {
        const defaultPoints = this.opportunity
          ? this.opportunities.find((el) => el.id === this.opportunity)
              ?.points || 0
          : 0;
        this.points = defaultPoints;
        return;
      }

      // Ensure it's a valid number
      const numValue = Number(value);
      if (!isNaN(numValue)) {
        this.points = numValue;
      } else {
        // If invalid, revert to default
        const defaultPoints = this.opportunity
          ? this.opportunities.find((el) => el.id === this.opportunity)
              ?.points || 0
          : 0;
        this.points = defaultPoints;
      }
    },

    openForm() {
      this.showFormDialog = true;
    },

    handleFormSubmit(formData) {
      console.log(
        "[AddOrEditEntry] handleFormSubmit called with formData:",
        formData
      );
      try {
        // If no form data is provided (LiveFormEntry not used), just proceed without form data
        if (!formData) {
          console.log(
            "[AddOrEditEntry] No form data provided, continuing without form data"
          );
          this.$store.commit(
            "setSnackbar",
            "No form data provided. Entry will be saved without form data."
          );
          return;
        }

        // Store complete form data structure including all field values
        this.formSubmissionData = {
          ...formData, // This includes all the structured form values
          _meta: {
            ...formData._meta,
            submittedAt: new Date(),
          },
        };

        // Update form points
        this.formPoints = formData.formPoints || 0;
        this.formSubmittedAt = new Date();

        // Force a reactive update on the formSubmissionData
        this.$set(this, "formSubmissionData", this.formSubmissionData);
        console.log(
          "[AddOrEditEntry] Updated formSubmissionData:",
          this.formSubmissionData
        );

        // Get the current opportunity
        const selectedOpportunity = this.opportunities.find(
          (el) => el.id === this.opportunity
        );

        if (!selectedOpportunity) {
          throw new Error("Selected opportunity not found");
        }

        // Generate a unique ID for the entry if one doesn't exist
        const entryId = this.id || nanoid();
        console.log("[AddOrEditEntry] Using entry ID:", entryId);

        // Create the entry data with all necessary fields
        const now = new Date();
        const entryData = {
          id: entryId,
          offerId: this.currentOffer.id,
          opportunity: this.opportunity,
          member: this.member,
          formSubmissionData: this.formSubmissionData,
          formSubmittedAt: now,
          formPoints: this.formPoints || 0,
          company: this.company || "",
          points: this.points || 0,
          totalPoints: this.calculatedTotalPoints || 0,
          bonusPoints: this.bonusPoints || 0,
          history: this.history || [],
          submitted: DateUtils.toJsDate(this.submitted) || now,
          reviewed: this.reviewed ? DateUtils.toJsDate(this.reviewed) : null,
          status: this.status || "Pending",
          redeemable: selectedOpportunity.redeemable || false,
          createdAt: !this.id ? now : undefined,
          lastModifiedAt: now,
        };
        console.log("[AddOrEditEntry] Created entry data:", entryData);

        // Update the entries in the store
        const currentEntries = this.currentOffer.entries || [];
        let updatedEntries;

        if (!this.id) {
          // For new entries, add to the beginning of the array
          this.id = entryId; // Store the generated ID
          updatedEntries = [entryData, ...currentEntries];
          console.log("[AddOrEditEntry] Adding new entry to entries array");
        } else {
          // For existing entries, update the matching entry
          updatedEntries = currentEntries.map((e) =>
            e.id === entryData.id ? entryData : e
          );
          console.log(
            "[AddOrEditEntry] Updating existing entry in entries array"
          );
        }

        console.log(
          "[AddOrEditEntry] Calling patchCurrentOffer with entries:",
          updatedEntries
        );
        // Update the current offer with the new/updated entry
        // This only updates local state - changes will be saved to Firebase when the user clicks Save in Offer.vue
        this.$store
          .dispatch("patchCurrentOffer", {
            entries: updatedEntries,
          })
          .then(() => {
            console.log(
              "[AddOrEditEntry] patchCurrentOffer completed successfully"
            );
            // Check if the store was updated correctly
            const updatedStoreEntries =
              this.$store.state.offer.currentOffer.entries;
            console.log(
              "[AddOrEditEntry] Store entries after update:",
              updatedStoreEntries
            );
            const pendingUpdates = this.$store.state.offer.pendingOfferUpdates;
            console.log(
              "[AddOrEditEntry] Pending updates after patch:",
              pendingUpdates
            );
          })
          .catch((error) => {
            console.error(
              "[AddOrEditEntry] Error in patchCurrentOffer:",
              error
            );
          });

        this.showFormDialog = false;
        this.$store.commit(
          "setSnackbar",
          "Form data added to entry. Remember to save changes."
        );
      } catch (error) {
        console.error("[AddOrEditEntry] Error saving form:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to save form. Please try again."
        );
      }
    },

    handleFormCancel() {
      this.showFormDialog = false;
    },

    viewFormData() {
      // TODO: Implement form data viewing logic
      console.log("Viewing submitted form data");
    },

    getFieldValue(field) {
      if (!field.idx) return null;

      // First check local formSubmissionData
      if (this.formSubmissionData && this.formSubmissionData[field.idx]) {
        const fieldData = this.formSubmissionData[field.idx];
        return this.processFieldData(field, fieldData);
      }

      // Then check currentEntry formSubmissionData
      if (!this.entryId || !this.currentOffer?.entries) return null;

      const entry = this.currentOffer.entries.find(
        (e) => e.id === this.entryId
      );
      if (!entry?.formSubmissionData || !field.idx) return null;

      // Get the field data from the form submission data
      const fieldData = entry.formSubmissionData[field.idx];
      if (!fieldData) return null;

      return this.processFieldData(field, fieldData);
    },

    processFieldData(field, fieldData) {
      // Create a deep copy of the field data
      const processedData = JSON.parse(JSON.stringify(fieldData));

      // For radio fields, ensure allOptions reflects the selection state
      if (field.type === "radio" && processedData.allOptions) {
        processedData.allOptions.forEach((opt) => {
          opt.isSelected = opt.value === processedData.value;
        });
      }

      // For table fields, process the data to format dropdown values
      if (field.type === "table" && Array.isArray(processedData.value)) {
        processedData.value = this.processTableData(field, [
          ...processedData.value,
        ]);
      }

      return processedData.value;
    },

    // Process table data to format dropdown values
    processTableData(tableField, tableData) {
      if (!tableField.componentFields || !Array.isArray(tableData)) {
        return tableData;
      }

      // Get dropdown fields in the table
      const dropdownFields = tableField.componentFields.filter(
        (field) => field.type === "dropdown" && field.active
      );

      // If no dropdown fields, return original data
      if (dropdownFields.length === 0) {
        return tableData;
      }

      // Process each row
      return tableData.map((row) => {
        const processedRow = { ...row };

        // Process each dropdown field
        dropdownFields.forEach((field) => {
          const fieldId = field.idx || `field-${field.label}`;
          const value = row[fieldId];

          if (value !== undefined && value !== null && value !== "") {
            // For dynamic presets
            if (field.presets && field.presets.presetsType === "dynamic") {
              // Try to find the selected item in the cached dynamic options
              const selectedItem = this.findSelectedDynamicItem(field, value);
              if (selectedItem) {
                processedRow[`${fieldId}_display`] = this.generateDisplayText(
                  field,
                  selectedItem
                );
              }
            }
            // For static options
            else if (field.presets && field.presets.staticOptions) {
              const option = field.presets.staticOptions.find(
                (opt) => (typeof opt === "object" ? opt.value : opt) === value
              );
              if (option) {
                processedRow[`${fieldId}_display`] =
                  typeof option === "object" ? option.text : option;
              }
            }
          }
        });

        return processedRow;
      });
    },

    // Find selected dynamic item from cache
    findSelectedDynamicItem(field, value) {
      if (!field?.presets?.selectedPresetsGroup) return null;

      const groupId = field.presets.selectedPresetsGroup;
      const records = this.presetRecordsCache[groupId];

      if (records && Array.isArray(records)) {
        return records.find((item) => item.identifier === value);
      }

      return null;
    },

    // Generate display text for dynamic items
    generateDisplayText(field, item) {
      if (!item) return "-";

      if (!field.presets?.valuesToDisplay?.length) {
        return item.identifier || "-";
      }

      const displayValues = field.presets.valuesToDisplay;

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean);

      return displayParts.length > 0
        ? displayParts.join(" • ")
        : item.identifier || "-";
    },

    // Format form data for CSV
    formatFormDataForCsv() {
      if (!this.entryId || !this.currentOffer?.entries) return null;

      const entry = this.currentOffer.entries.find(
        (e) => e.id === this.entryId
      );
      if (!entry?.formSubmissionData) return null;

      // Get all fields from all pages
      const fields = this.formPages.reduce((acc, page) => {
        page.sections.forEach((section) => {
          section.columns.forEach((column) => {
            column.items.forEach((field) => {
              acc.push(field);
            });
          });
        });
        return acc;
      }, []);

      // Create CSV header row
      const headers = fields
        .map((field) => {
          if (field.type === "table") {
            // For table fields, create columns for each table field
            const tableHeaders = field.componentFields
              .filter((f) => f.active)
              .map((f) => `${field.label} - ${f.label}`);
            return tableHeaders;
          }
          return field.label;
        })
        .flat();

      // Create CSV data row
      const data = fields
        .map((field) => {
          const value = this.getFieldValue(field);

          if (field.type === "table" && Array.isArray(value)) {
            // For table fields, create a column for each table field
            const tableData = value.map((row) => {
              return field.componentFields
                .filter((f) => f.active)
                .map((f) => {
                  const fieldId = f.idx || `field-${f.label}`;
                  // Use display value for dropdowns if available
                  return row[`${fieldId}_display`] || row[fieldId] || "";
                });
            });

            // Join multiple rows with semicolons
            return tableData.map((row) => row.join(", ")).join("; ");
          }

          // Handle other field types
          if (typeof value === "object") {
            return JSON.stringify(value);
          }
          return value || "";
        })
        .flat();

      return {
        headers,
        data,
      };
    },

    // Download CSV file
    downloadCsv() {
      const formattedData = this.formatFormDataForCsv();
      if (!formattedData) return;

      const { headers, data } = formattedData;

      // Create CSV content
      const csvContent = [headers.join(","), data.join(",")].join("\n");

      // Create blob and download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `form_data_${this.entryId}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    openMemberDialog(memberId) {
      this.selectedMemberId = memberId;
      this.memberDialog = true;
    },

    closeMemberDialog() {
      this.selectedMemberId = "";
      this.memberDialog = false;
    },

    async validateFormFields() {
      const errors = [];

      // Get all fields from all pages
      const fields = this.formPages.reduce((acc, page) => {
        page.sections.forEach((section) => {
          section.columns.forEach((column) => {
            column.items.forEach((field) => {
              acc.push(field);
            });
          });
        });
        return acc;
      }, []);

      // Validate each field
      for (const field of fields) {
        // Check if field has dynamic validation
        if (
          field.validation?.validationFormDataType === "dynamic" &&
          field.validation?.selectedFormValidationDataSet
        ) {
          const fieldData = this.formSubmissionData[field.idx];
          if (!fieldData) continue;

          const value = fieldData.value;

          if (field.required && !value) {
            errors.push(`${field.label} is required`);
            continue;
          }

          if (value) {
            try {
              // Get the preset data set records
              const records = await this.$store.dispatch(
                "loadPresetRecords",
                field.validation.selectedFormValidationDataSet
              );

              // Check if the value matches any identifier in the preset data set
              const isValid = records.some(
                (record) => record.identifier === value
              );

              if (!isValid) {
                errors.push(`${field.label} is invalid`);
              }
            } catch (error) {
              console.error(`Error validating field ${field.label}:`, error);
              errors.push(`Error validating ${field.label}`);
            }
          }
        }
      }

      return errors;
    },
  },

  watch: {
    opportunity: function (newVal) {
      // Check if this entry has approved opportunity points in history
      const hasApprovedOpportunityPoints =
        this.history &&
        Array.isArray(this.history) &&
        this.history.some(
          (entry) =>
            entry.points > 0 &&
            (entry.outcome === "approved" ||
              (entry.total > 0 && entry.outcome !== "rejected"))
        );

      // Only set the default points when opportunity changes if no approved points in history
      if (newVal && !hasApprovedOpportunityPoints) {
        const selectedOpportunity = this.opportunities.find(
          (el) => el.id === newVal
        );
        this.points = selectedOpportunity ? selectedOpportunity.points : 0;
        this.redeemable = selectedOpportunity
          ? selectedOpportunity.redeemable
          : false;
      } else if (!newVal) {
        this.points = 0;
        this.redeemable = false;
      }
    },

    open: function (newVal) {
      if (newVal && !this.entryId) {
        // Set today's date when opening for new entry
        this.submitted = moment().format("YYYY-MM-DD");
        // Set initial status to Pending for new entries
        this.status = "Pending";
        // Reset to first tab
        this.activeTab = 0;
        // Reset bonus points
        this.bonusPoints = 0;

        // Set initial points if opportunity is already selected
        if (this.opportunity) {
          const selectedOpportunity = this.opportunities.find(
            (el) => el.id === this.opportunity
          );
          this.points = selectedOpportunity ? selectedOpportunity.points : 0;
        }
      } else if (newVal) {
        // Also reset to first tab when opening existing entries
        this.activeTab = 0;
        // Reset bonus points if not editing an existing entry
        if (!this.entryId) {
          this.bonusPoints = 0;
        }
      }
    },

    entryId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedEntry = this.entries.find((item) => item.id === newVal);
          if (!selectedEntry) return;

          const {
            history,
            company,
            member,
            points,
            opportunity,
            submitted,
            status,
            totalPoints,
            reviewed,
            formPoints,
            id,
            formSubmissionData,
            formSubmittedAt,
          } = selectedEntry;

          // Set opportunity first so the watcher can set default points if needed
          this.opportunity = opportunity;

          // Only override points if they were explicitly set in the entry
          if (points !== undefined && points !== null) {
            this.points = points;
          } else if (opportunity) {
            // Fallback to opportunity default points
            const selectedOpportunity = this.opportunities.find(
              (el) => el.id === opportunity
            );
            this.points = selectedOpportunity ? selectedOpportunity.points : 0;
          }

          // Always reset bonusPoints to 0 when opening an entry
          this.bonusPoints = 0;

          // Set history first so we can check for approved points
          this.history = history;

          // Check if opportunity points are already in history
          const opportunityPointsAlreadyApproved =
            this.history &&
            Array.isArray(this.history) &&
            this.history.some(
              (entry) =>
                entry.points > 0 &&
                (entry.outcome === "approved" ||
                  (entry.total > 0 && entry.outcome !== "rejected"))
            );

          // Check if form points are already in history
          const formPointsAlreadyApproved =
            this.history &&
            Array.isArray(this.history) &&
            this.history.some(
              (entry) =>
                entry.formPoints > 0 &&
                (entry.outcome === "approved" ||
                  (entry.total > 0 && entry.outcome !== "rejected"))
            );

          // If opportunity points have already been approved, set them to the default from the opportunity
          if (opportunityPointsAlreadyApproved) {
            // Reset opportunity points to 0 instead of the default value
            this.points = 0;
          }

          // Set formPoints from entry document or calculate from formSubmissionData ONLY if not already approved
          if (formPointsAlreadyApproved) {
            // If form points are already in history, set to 0
            this.formPoints = 0;
          } else if (formPoints !== undefined && formPoints !== null) {
            this.formPoints = formPoints;
          } else if (formSubmissionData?.formPoints !== undefined) {
            this.formPoints = formSubmissionData.formPoints;
          } else {
            // Calculate formPoints from formSubmissionData if available
            let calculatedFormPoints = 0;
            if (formSubmissionData) {
              Object.values(formSubmissionData).forEach((field) => {
                if (field && field.points) {
                  calculatedFormPoints += Number(field.points) || 0;
                }
              });
            }
            this.formPoints = calculatedFormPoints;
          }

          this.reviewed = reviewed;
          this.company = company;
          this.member = member;
          this.submitted = submitted;
          this.status = status;
          this.totalPoints = totalPoints;
          this.id = id;
          this.formSubmissionData = formSubmissionData;
          this.formSubmittedAt = formSubmittedAt;
        }
      },
    },
  },
};
</script>

<style scoped>
.form-results-container {
  max-height: calc(80vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}

.raw-data {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  white-space: pre-wrap;
  max-height: 300px;
  overflow-y: auto;
  font-size: 12px;
  font-family: monospace;
}

/* Remove shadow from stepper */
.v-stepper__header {
  box-shadow: none !important;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.clickable {
  cursor: pointer;
}

.points-chip {
  font-size: 16px;
}

.points-pulse {
  animation: pulse 1s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",style:('background-color: ' + this.webTheme.colors.canvas.hexa),attrs:{"fluid":"","tile":""}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":_vm.component.settings.cols}},[_c('v-card',{attrs:{"elevation":"0","height":_vm.height,"tile":"","color":_vm.component.settings.color.hexa,"img":_vm.component.settings.mode === 'image'
            ? _vm.component.background.image
            : ''}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":_vm.component.block.justify,"align":_vm.component.block.align}},[_c('v-col',{attrs:{"xs":"12","sm":"10","md":"8","lg":_vm.component.block.cols}},[_c('v-col',[_c('v-card',{class:_vm.classes,style:(_vm.styles + ';' + _vm.gradient),attrs:{"height":_vm.component.card.height,"elevation":_vm.component.card.elevation}},[_c('v-row',{attrs:{"no-gutters":""}},[(
                      _vm.component.logo.placement === 'left' &&
                      _vm.component.logo.image
                    )?_c('v-col',{attrs:{"cols":"auto"}},[_c('view-logo',{style:(_vm.logoMargin),attrs:{"settings":_vm.component.logo}})],1):_vm._e(),_c('v-col',[(
                        _vm.component.logo.placement === 'top' &&
                        _vm.component.logo.image
                      )?_c('view-logo',{style:(_vm.logoMargin),attrs:{"settings":_vm.component.logo}}):_vm._e(),_c('view-text',{attrs:{"settings":_vm.component.title}}),_c('view-text',{attrs:{"settings":_vm.component.subtitle}}),_c('v-text-field',{attrs:{"disabled":!_vm.signinOpen,"id":"email","name":"Email","label":"Email","outlined":true,"dense":true,"background-color":"white","color":"grey","filled":""}}),_c('v-text-field',{attrs:{"disabled":!_vm.signinOpen,"id":"password","Name":"Password","label":"Password","outlined":true,"dense":true,"background-color":"white","color":"grey"}}),_c('v-row',{attrs:{"no-gutters":"","justify":_vm.component.button.justify}},[_c('v-btn',{staticClass:"ma-0",attrs:{"disabled":!_vm.signinOpen,"color":_vm.component.button.color.hex,"block":_vm.component.button.block,"dark":!_vm.component.button.dark,"elevation":"0","width":_vm.component.button.width,"height":_vm.component.button.height}},[_vm._v(" "+_vm._s(_vm.signinOpen ? "Sign In" : "not available")+" ")])],1),(_vm.signinOpen)?_c('a',{on:{"click":function($event){return _vm.forgotPassword()}}},[_c('view-text',{attrs:{"settings":_vm.component.forgot,"value":_vm.component.value}})],1):_c('view-text',{attrs:{"settings":_vm.component.forgot,"value":_vm.component.value}}),(_vm.registrationOpen)?[_c('div',[_c('view-text',{attrs:{"settings":_vm.component.account,"value":_vm.component.value}}),_c('a',{on:{"click":function($event){return _vm.signUp()}}},[_c('view-text',{attrs:{"settings":_vm.component.signup,"value":_vm.component.value}})],1)],1)]:_vm._e(),_c('view-text',{attrs:{"settings":_vm.component.signing,"value":_vm.component.value}}),_c('a',[_c('view-text',{attrs:{"settings":_vm.component.terms,"value":_vm.component.value}})],1),_c('view-text',{staticClass:"mt-3",attrs:{"settings":_vm.component.viewour,"value":_vm.component.value}}),_c('a',[_c('view-text',{attrs:{"settings":_vm.component.privacy,"value":_vm.component.value}})],1),(
                        _vm.component.logo.placement === 'bottom' &&
                        _vm.component.logo.image
                      )?_c('view-logo',{style:(_vm.logoMargin),attrs:{"settings":_vm.component.logo}}):_vm._e()],2),(
                      _vm.component.logo.placement === 'right' &&
                      _vm.component.logo.image
                    )?_c('v-col',{attrs:{"cols":"auto"}},[_c('view-logo',{style:(_vm.logoMargin),attrs:{"settings":_vm.component.logo}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('v-toolbar',{staticClass:"mb-6",attrs:{"flat":"","absolute":"","bottom":"","color":"transparent"}},[_c('footnote')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
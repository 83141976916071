<template>
  <div>
    <v-toolbar dense elevation="0" color="transparent">
      <v-row no-gutters justify="start">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col>
              <v-tabs v-model="tab" class="mx-n4" transparent>
                <v-tab class="tab" @click="clearSearch()">Quizzes</v-tab>
                <v-tab class="tab" @click="clearSearch()">Categories</v-tab>
                <v-tab class="tab" @click="clearSearch()">Inactive</v-tab>
                <v-tab class="tab" @click="clearSearch()">Archive</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                class="mt-1 caption"
                v-model="search"
                solo-inverted
                dense
                flat
                hide-details
                prepend-inner-icon="search"
                clearable
                @click:clear="clearSearch()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-row no-gutters justify="start">
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <v-col cols="9" class="ml-n3 mt-n3">
              <quizzes-list
                :quizzes="filteredQuizzes"
                status="Active"
              ></quizzes-list>
            </v-col>
          </v-tab-item>
          <v-tab-item> <quiz-categories /> </v-tab-item>
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <v-col cols="9" class="ml-n3 mt-n3">
              <quizzes-list
                :quizzes="filteredQuizzes"
                status="Inactive"
              ></quizzes-list>
            </v-col>
          </v-tab-item>
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <v-col cols="9" class="ml-n3 mt-n3">
              <quizzes-list
                :quizzes="filteredQuizzes"
                status="Archive"
              ></quizzes-list>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- Loading Cards Table-->
  </div>
</template>
<script>
import QuizzesList from "./List.vue";
import QuizCategories from "./Category/QuizCategoriesTable.vue";
export default {
  components: {
    QuizzesList,
    QuizCategories,
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadQuizzes");
    this.$store.dispatch("loadQuizCategories");
    this.$store.dispatch("setNavMenu", true);
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadMemberTags");
  },
  data: () => ({
    search: null,
    tab: null,
  }),
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    quizzes() {
      return this.$store.state.quizzes.quizzes;
    },
    programId() {
      return this.$store.getters.programId;
    },
    cardCount() {
      return 6;
    },
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
    filteredQuizzes() {
      const array = this.quizzes;
      if (this.search !== null) {
        var input = this.search.toUpperCase();
        var filtered = array.filter((item) =>
          item.titleUppercase.includes(input)
        );
        return filtered;
      }
      return array;
    },
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
  },
};
</script>

<style scoped></style>

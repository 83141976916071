<template>
  <v-container v-if="loading" fluid fill-height>
    <v-row align="center" justify="center">
      <v-col align="center">
        <v-progress-circular
          :size="100"
          :width="8"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else fluid>
    <v-tabs v-model="tab" class="mt-n3 mx-n3">
      <v-tab class="tab"> Entries </v-tab>
      <v-tab class="tab"> Opportunities </v-tab>
      <v-tab class="tab" v-if="isIncentableAdmin"> Form </v-tab>

      <v-tab class="tab"> Resources </v-tab>
      <v-tab class="tab"> Titles </v-tab>
      <v-tab class="tab"> Tags </v-tab>
      <v-tab class="tab"> Other </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-entries />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-opportunities />
      </v-tab-item>

      <v-tab-item
        v-if="isIncentableAdmin"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <form-builder />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-resources />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-titles />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-tags />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-settings />
      </v-tab-item>
    </v-tabs-items>

    <div class="sticky-footer">
      <v-btn
        class="ml-6"
        color="primary"
        :disabled="shouldDisableSave"
        :loading="processingOffer"
        @click="handleSave"
        elevation="0"
      >
        save
      </v-btn>
      <span class="incentable-form-subheading ml-3">
        Last updated: {{ currentOffer.updated | date }}
      </span>
    </div>

    <offer-processing-dialog
      :openProcessingDialog="processingOffer && !openSyncDialog"
    ></offer-processing-dialog>

    <databucket-sync-dialog
      v-if="openSyncDialog"
      :logs="syncLogs"
      :syncSuccess="syncSuccess"
    />
    <div v-if="isIncentableAdmin" class="migration-tools mt-6 ml-3">
      <v-card flat class="px-4 rounded-lg" outlined>
        <v-card-title class="pl-0 pb-2">Data Migration Tools</v-card-title>
        <v-card-text class="pl-0 grey--text">
          These tools help migrate offer entries from the main document to
          subcollections. This option is Incentable Admin only. Not shown to
          clients
        </v-card-text>
        <v-card-actions class="pl-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                :loading="verifyingMigration"
                v-bind="attrs"
                v-on="on"
                @click="verifyMigrationStatus"
                class="mr-4"
              >
                <v-icon left>search</v-icon>
                Verify Migration Status
              </v-btn>
            </template>
            <span
              >Check which offers need to be migrated to the new entry
              format</span
            >
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="warning"
                :loading="migratingData"
                v-bind="attrs"
                v-on="on"
                @click="migrateFormData"
              >
                <v-icon left>transform</v-icon>
                Migrate Data
              </v-btn>
            </template>
            <span>Migrate entries from main document to subcollections</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import OfferTitles from "./Components/Titles.vue";
import OfferTags from "./Components/Tags.vue";
import OfferSettings from "./Components/Settings.vue";
import FormBuilder from "./ClaimFormBuilder.vue";
import OfferOpportunities from "./Components/Opportunities/OfferOpportunities.vue";
import OfferEntries from "./Components/Entries/OfferEntries.vue";
import OfferProcessingDialog from "./OfferProcessingDialog.vue";
import OfferResources from "./OfferResources.vue";
import DatabucketSyncDialog from "../Databucket/DatabucketSync.vue";

export default {
  components: {
    OfferTitles,
    OfferTags,
    OfferSettings,
    FormBuilder,
    OfferOpportunities,
    OfferEntries,
    OfferProcessingDialog,
    OfferResources,
    DatabucketSyncDialog,
  },
  created() {
    const offerId = this.$route.params.offerId;
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadDatabuckets");
    this.$store.dispatch("loadCurrentOffer", offerId);
    this.$store.dispatch("setProcessingOffer", false);
  },
  data: function () {
    return {
      tab: null,
      isProcessing: false,
      openSyncDialog: false,
      syncLogs: [],
      search: false,
      searchActive: false,
      syncSuccess: false,
      verifyingMigration: false,
      migratingData: false,
    };
  },
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },

    loading() {
      return this.$store.state.offer.loadingCurrentOffer;
    },

    processingOffer() {
      return this.$store.getters.processingOffer;
    },

    matchingData() {
      return this.$store.getters.offerMatchingData;
    },

    hasOfferPendingUpdates() {
      const hasPending = this.$store.getters.hasOfferPendingUpdates;
      return hasPending;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },

    shouldDisableSave() {
      const hasChanges = this.$store.getters.hasOfferPendingUpdates;
      const isProcessing = this.$store.getters.processingOffer;
      const isLoading = this.$store.getters.loading;
      const result = !hasChanges || isProcessing || isLoading;
      return result;
    },

    // hasOfferRawPendingUpdates() {
    //   return this.$store.getters.hasOfferRawPendingUpdates;
    // },
  },

  watch: {
    currentOffer: {
      handler(newVal) {
        if (newVal) {
          this.setTitle(newVal.title);
        }
      },
      immediate: true,
    },
  },

  methods: {
    setTitle(title) {
      if (title) {
        document.title = `${title} | Incentable`;
      }
    },
    async verifyMigrationStatus() {
      this.verifyingMigration = true;
      try {
        const status = await this.$store.dispatch("verifyMigrationStatus");
        console.log("[Offer] Migration status:", status);

        // Show detailed status in a snackbar or dialog
        this.$store.commit("setSnackbar", {
          text: `Migration Status: ${status.offersNeedingMigration.length} offers need migration`,
          color: "info",
          timeout: 5000,
        });
      } catch (error) {
        console.error("[Offer] Error verifying migration status:", error);
        this.$store.commit("setSnackbar", {
          text: "Failed to verify migration status",
          color: "error",
        });
      } finally {
        this.verifyingMigration = false;
      }
    },

    async migrateFormData() {
      this.migratingData = true;
      try {
        const result = await this.$store.dispatch("migrateAllOffers");
        console.log("[Offer] Migration result:", result);

        this.$store.commit("setSnackbar", {
          text: `Migration completed: ${result.migratedCount} migrated, ${result.skippedCount} skipped, ${result.errorCount} errors`,
          color: result.errorCount > 0 ? "warning" : "success",
          timeout: 6000,
        });
      } catch (error) {
        console.error("[Offer] Error during migration:", error);
        this.$store.commit("setSnackbar", {
          text: "Migration failed: " + error.message,
          color: "error",
        });
      } finally {
        this.migratingData = false;
      }
    },
    async handleSave() {
      console.log("[Offer] handleSave method called");
      try {
        // Get all pending updates
        const pendingUpdates = this.$store.state.offer.pendingOfferUpdates;
        const deletedEntries = this.$store.state.offer.deletedEntries || [];
        const deletedHistoryEntries =
          this.$store.state.offer.deletedHistoryEntries || [];

        console.log("[Offer] pendingUpdates:", pendingUpdates);
        console.log("[Offer] pendingUpdates entries:", pendingUpdates.entries);
        console.log("[Offer] deletedHistoryEntries:", deletedHistoryEntries);
        console.log("[Offer] deletedEntries:", deletedEntries);

        // Verify we have actual updates to save - check for valid entries object
        const hasEntries =
          pendingUpdates.entries && pendingUpdates.entries.length > 0;
        const hasOtherUpdates =
          Object.keys(pendingUpdates).filter(
            (key) => key !== "entries" && key !== "__ob__"
          ).length > 0;
        const hasDeletedEntries = deletedEntries.length > 0;

        if (
          !hasEntries &&
          !hasOtherUpdates &&
          !hasDeletedEntries &&
          deletedHistoryEntries.length === 0
        ) {
          console.log("[Offer] No pending updates to save, returning");
          return;
        }

        // Start processing
        this.$store.commit("setProcessingOffer", true);

        // Process deleted history entries first
        for (const deletedEntry of deletedHistoryEntries) {
          console.log(
            "[Offer] Processing deleted history entry:",
            deletedEntry
          );
          if (
            deletedEntry.redeemable &&
            deletedEntry.historyEntry.outcome === "approved" &&
            deletedEntry.historyEntry.total > 0
          ) {
            const opportunity = this.currentOffer.opportunities?.find(
              (opp) => opp.id === deletedEntry.opportunity
            );

            console.log("[Offer] Subtracting points for deleted entry:", {
              points: deletedEntry.historyEntry.total,
              memberId: deletedEntry.memberId,
              opportunity,
            });

            await this.$store.dispatch("subtractMemberPoints", {
              points: deletedEntry.historyEntry.total,
              type: "Award",
              description: "Claim Reversed",
              notes: opportunity?.description || "Points removed from claim",
              memberId: deletedEntry.memberId,
            });
          }
        }

        // If we have deleted entries, log them
        if (hasDeletedEntries) {
          console.log(
            `[Offer] Processing ${deletedEntries.length} deleted entries`
          );
          // The updateOffer action will handle actually deleting these entries
        }

        // If entries were updated, process points before saving
        if (hasEntries) {
          console.log("[Offer] Processing pending entry updates");
          // For entries that use subcollections, we need to rely on the entries in pendingUpdates
          // rather than currentOffer.entries, which might be outdated
          const updatedEntries = pendingUpdates.entries;

          console.log("[Offer] Updated entries count:", updatedEntries.length);

          // Create array to collect processed entries
          const processedEntries = [];

          // Process all entries in pendingUpdates.entries
          for (const updatedEntry of updatedEntries) {
            // For new entries or modified entries, we need to process them
            console.log("[Offer] Processing entry:", updatedEntry.id);

            // Process all unprocessed history entries
            const unprocessedHistory = updatedEntry.history
              ? updatedEntry.history.filter((h) => h.processed === false)
              : [];

            console.log(
              "[Offer] Unprocessed history items:",
              unprocessedHistory.length
            );

            // Process each unprocessed history entry
            for (const historyEntry of unprocessedHistory) {
              console.log("[Offer] Processing history entry:", historyEntry);

              if (
                updatedEntry.redeemable &&
                historyEntry.outcome === "approved" &&
                historyEntry.total > 0
              ) {
                const opportunity = this.currentOffer.opportunities?.find(
                  (opp) => opp.id === updatedEntry.opportunity
                );

                console.log("[Offer] Adding points for approved entry:", {
                  points: historyEntry.total,
                  memberId: updatedEntry.member,
                  opportunity,
                });

                await this.$store.dispatch("addMemberPoints", {
                  points: historyEntry.total,
                  type: "Award",
                  description: "Claim",
                  notes:
                    opportunity?.description || "Points awarded from claim",
                  memberId: updatedEntry.member,
                });

                // Mark the history entry as processed
                historyEntry.processed = true;
                console.log("[Offer] Marked history entry as processed");
              }
            }

            // Create a copy of the entry for processing to avoid reactive issues
            const processedEntry = JSON.parse(JSON.stringify(updatedEntry));

            // Update the entry with processed history if there were unprocessed entries
            if (unprocessedHistory.length > 0 && processedEntry.history) {
              processedEntry.history = processedEntry.history.map((h) => {
                const found = unprocessedHistory.find(
                  (uh) =>
                    uh.created === h.created &&
                    uh.outcome === h.outcome &&
                    uh.total === h.total
                );
                return found ? { ...h, processed: true } : h;
              });
              console.log("[Offer] Updated entry history with processed flags");
            }

            // Add to processed entries array
            processedEntries.push(processedEntry);
          }

          // Update entries with processed history - ensuring we handle subcollection entries properly
          pendingUpdates.entries = processedEntries;
          console.log(
            "[Offer] Updated pendingUpdates.entries with processed history",
            processedEntries.length
          );

          // Force reactivity for the entries array
          this.$set(pendingUpdates, "entries", [...pendingUpdates.entries]);
          console.log(
            "[Offer] Forced reactive update of pendingUpdates.entries"
          );
        }

        // Save to Firebase
        console.log("[Offer] Calling updateOffer to save to Firebase");
        console.log("[Offer] Final pendingUpdates:", pendingUpdates);

        // Make a final check to ensure we have entries to save
        if (pendingUpdates.entries) {
          console.log(
            "[Offer] Number of entries to be saved:",
            pendingUpdates.entries.length
          );
          for (const entry of pendingUpdates.entries) {
            console.log("[Offer] Entry being saved:", entry.id, entry);
          }
        }

        // Save to Firebase using updateOffer, which will process entries as subcollection
        await this.$store.dispatch("updateOffer");
        console.log("[Offer] Firebase save completed successfully");

        // Clear all pending updates after successful save
        this.$store.commit("clearPendingOfferUpdates");
        this.$store.commit("clearDeletedHistoryEntries");
        this.$store.commit("clearDeletedEntries");
        console.log(
          "[Offer] Cleared pending updates and deleted history entries"
        );

        this.$store.commit("setSnackbar", "Changes saved successfully");
      } catch (error) {
        console.error("[Offer] Error saving changes:", error);
        this.$store.commit(
          "setSnackbar",
          "Error saving changes. Please try again."
        );
      } finally {
        this.$store.commit("setProcessingOffer", false);
        console.log("[Offer] handleSave completed");
      }
    },
    async fetchOffer() {
      try {
        await this.$store.dispatch("fetchOffer", this.offerId);
      } catch (error) {
        console.error("[Offer] Error fetching offer:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to fetch offer. Please try again."
        );
      }
    },

    async saveOffer() {
      try {
        await this.$store.dispatch("saveOffer", this.offer);
        this.$store.commit("setSnackbar", "Offer saved successfully");
      } catch (error) {
        console.error("[Offer] Error saving offer:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to save offer. Please try again."
        );
      }
    },

    async deleteOffer() {
      try {
        await this.$store.dispatch("deleteOffer", this.offerId);
        this.$router.push("/offers");
        this.$store.commit("setSnackbar", "Offer deleted successfully");
      } catch (error) {
        console.error("[Offer] Error deleting offer:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to delete offer. Please try again."
        );
      }
    },

    async publishOffer() {
      try {
        await this.$store.dispatch("publishOffer", this.offerId);
        this.$store.commit("setSnackbar", "Offer published successfully");
        this.fetchOffer();
      } catch (error) {
        console.error("[Offer] Error publishing offer:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to publish offer. Please try again."
        );
      }
    },

    async unpublishOffer() {
      try {
        await this.$store.dispatch("unpublishOffer", this.offerId);
        this.$store.commit("setSnackbar", "Offer unpublished successfully");
        this.fetchOffer();
      } catch (error) {
        console.error("[Offer] Error unpublishing offer:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to unpublish offer. Please try again."
        );
      }
    },
  },
};
</script>

<style scoped>
.sticky-footer {
  position: fixed;
  bottom: 0px;
  margin-bottom: 20px;
  z-index: 1000 !important;
}

.migration-tools {
  margin-top: 20px;
  margin-left: 20px;
}
</style>

<template>
  <div>
    <v-dialog v-model="open" persistent max-width="60vw">
      <v-card elevation="0">
        <v-progress-linear
          :indeterminate="true"
          v-if="processing"
        ></v-progress-linear>

        <v-card-text class="pt-3 px-3">
          <!-- Stepper for multi-page forms -->
          <v-stepper v-if="showStepper" v-model="currentStep">
            <v-stepper-header>
              <!-- Render steps and dividers -->
              <template v-for="(page, index) in formPages">
                <v-stepper-step
                  :key="`step-${index}`"
                  :complete="currentStep > index + 1"
                  :step="index + 1"
                  color="primary"
                >
                  {{ page.title || `Page ${index + 1}` }}
                </v-stepper-step>
                <v-divider
                  v-if="index < formPages.length - 1"
                  :key="`divider-${index}`"
                ></v-divider>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                v-for="(page, pageIndex) in formPages"
                :key="`content-${pageIndex}`"
                :step="pageIndex + 1"
              >
                <v-card flat>
                  <preview-page
                    :page="page"
                    :pageIndex="pageIndex"
                    :formValues="formValues"
                    @update:form-value="updateFormValue"
                    @table-edit-state-change="handleTableEditStateChange"
                  />
                </v-card>

                <v-row class="mt-4" no-gutters>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="pageIndex > 0"
                    text
                    color="primary"
                    elevation="0"
                    @click="currentStep--"
                    :disabled="isTableBeingEdited"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    v-if="pageIndex < formPages.length - 1"
                    color="primary"
                    elevation="0"
                    @click="currentStep++"
                    :disabled="isTableBeingEdited"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-if="pageIndex === formPages.length - 1"
                    color="primary"
                    elevation="0"
                    @click="submitForm"
                    :loading="processing"
                    :disabled="isTableBeingEdited"
                  >
                    Submit Form From Stepper
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <!-- Single page display when no stepper -->
          <div v-if="!showStepper">
            <preview-page
              v-for="(page, pageIndex) in formPages"
              :key="`page-${pageIndex}`"
              :page="page"
              :pageIndex="pageIndex"
              :formValues="formValues"
              :isPreview="isPreview"
              @update:form-value="updateFormValue"
              @table-edit-state-change="handleTableEditStateChange"
            />

            <v-card-actions class="mt-4" no gutters>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="submitForm"
                :loading="processing"
                :disabled="isTableBeingEdited"
                elevation="0"
              >
                Submit Form
              </v-btn>
            </v-card-actions>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Form Results Dialog Component -->
    <form-results-dialog
      :open.sync="showResults"
      :structured-form-values="structuredFormValues"
      :form-pages="formPages"
    />
  </div>
</template>

<script>
import PreviewPage from "./PreviewPage.vue";
import FormResultsDialog from "./FormResultsDialog.vue";
import FormDataProcessor from "@/utils/FormDataProcessor";

export default {
  components: {
    PreviewPage,
    FormResultsDialog,
  },
  props: {
    open: Boolean,
    previewArr: Array,
    isPreview: Boolean,
  },
  data() {
    return {
      processing: false,
      currentStep: 1,
      formPages: [],
      formValues: {},
      structuredFormValues: {},
      fieldInfoMap: {}, // Maps field_x_y_z keys to field objects with extended properties
      dynamicOptionsCache: {}, // Cache of dynamic options for each field
      showResults: false,
      tablesBeingEdited: [], // Track which tables are being edited (changed from Set to array)
      formSubmissionMeta: {
        submittedAt: null,
        formId: null,
        formTitle: null,
        formVersion: "1.0",
      },
    };
  },
  computed: {
    showStepper() {
      // Check if stepper should be shown based on the form configuration
      return (
        this.formPages.length > 1 &&
        this.$store.state.offer?.currentOffer?.form?.stepper
      );
    },

    currentFormId() {
      return this.$store.state.offer?.currentOffer?.id || null;
    },

    currentFormTitle() {
      return this.$store.state.offer?.currentOffer?.name || null;
    },

    isTableBeingEdited() {
      const isEditing = this.tablesBeingEdited.length > 0;
      return isEditing;
    },
  },
  watch: {
    previewArr: {
      handler(newVal) {
        // Create a deep copy when the prop changes
        this.formPages = JSON.parse(JSON.stringify(newVal));
        // Initialize form values structure and build field map
        this.initializeFormValues();
      },
      immediate: true,
    },
  },
  created() {
    // Listen for dynamic option updates
    this.$root.$on("dynamic-options-loaded", this.handleDynamicOptionsLoaded);
  },
  beforeDestroy() {
    // Remove event listener
    this.$root.$off("dynamic-options-loaded", this.handleDynamicOptionsLoaded);
  },
  methods: {
    closeDialog() {
      this.$emit("onCancelPreviewForm");
      this.resetForm();
    },

    /**
     * Generate a unique ID for fields that need one
     */
    generateUniqueId() {
      return "field-" + Math.random().toString(36).substring(2, 10);
    },

    /**
     * Handle when dynamic options are loaded by any field in the form
     * @param {Object} data - Object containing fieldId and options array
     */
    handleDynamicOptionsLoaded(data) {
      if (data && data.fieldId && Array.isArray(data.options)) {
        // Cache the options for this field
        this.dynamicOptionsCache[data.fieldId] = data.options;

        // If we have a field mapping for this field, add dynamic options to it
        if (this.fieldInfoMap[data.fieldId]) {
          this.fieldInfoMap[data.fieldId].dynamicOptions = data.options;
        }
      }
    },

    initializeFormValues() {
      // Reset form values and field map
      this.formValues = {};
      this.fieldInfoMap = {};
      this.dynamicOptionsCache = {};

      // Create structure for storing form values and build field map
      this.formPages.forEach((page, pageIndex) => {
        page.sections.forEach((section, sectionIndex) => {
          section.columns.forEach((column, columnIndex) => {
            column.items.forEach((field, fieldIndex) => {
              const fieldId = `field_${pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}`;

              // Skip certain field types that don't need values
              if (["title", "subtitle", "note"].includes(field.type)) {
                return;
              }

              // Ensure field has an idx
              if (!field.idx) {
                field.idx = this.generateUniqueId();
              }

              // Process table component fields to ensure they all have idx
              if (
                field.type === "table" &&
                Array.isArray(field.componentFields)
              ) {
                field.componentFields = field.componentFields.map(
                  (compField) => {
                    // Ensure each component field has a unique idx
                    if (!compField.idx) {
                      compField.idx = `table-field-${this.generateUniqueId()}`;
                    }
                    return compField;
                  }
                );
              }

              // Store original field in the map with extended properties
              this.fieldInfoMap[fieldId] = {
                // Basic field identification
                fieldId: fieldId,
                idx: field.idx,

                // Field display properties
                label: field.label || `Field ${fieldIndex}`,
                type: field.type || "text",
                placeholder: field.placeholder,

                // Field constraints
                required: field.required || false,
                clearable: field.clearable,

                // Field validation
                validation: field.validation,
                entryLimits: field.entryLimits,

                // Field positioning information for reporting
                position: {
                  page: pageIndex,
                  section: sectionIndex,
                  column: columnIndex,
                  index: fieldIndex,
                },

                // Field options and presets
                presets: this.processFieldPresets(field),

                // Additional properties for specific field types
                min: field.min,
                max: field.max,
                multiple: field.multiple,
                prependIcon: field.prependIcon,
                thumbLabel: field.thumbLabel,

                // Component fields for table-type fields (with unique idx)
                componentFields: this.processComponentFields(
                  field,
                  pageIndex,
                  sectionIndex,
                  columnIndex
                ),

                // Styles for heading-type fields
                styles: field.styles,
              };

              // Set initial value based on field type
              if (field.type === "checkbox" || field.type === "checkboxes") {
                this.$set(this.formValues, fieldId, []);
              } else if (field.type === "switch") {
                this.$set(this.formValues, fieldId, false);
              } else if (field.type === "table") {
                this.$set(this.formValues, fieldId, [{}]);
              } else if (field.type === "radio") {
                this.$set(this.formValues, fieldId, "");
              } else if (field.type === "facebook") {
                this.$set(this.formValues, fieldId, {
                  url: "",
                  screenshot: null,
                });
              } else {
                this.$set(this.formValues, fieldId, "");
              }
            });
          });
        });
      });
    },

    /**
     * Process field presets to ensure they're fully captured
     */
    processFieldPresets(field) {
      if (!field.presets) return null;

      // Deep clone the presets object
      const presets = JSON.parse(JSON.stringify(field.presets));

      // For static presets, ensure options array is properly formed
      if (
        presets.presetsType === "static" &&
        Array.isArray(presets.staticOptions)
      ) {
        // Make sure each option has a consistent structure
        presets.staticOptions = presets.staticOptions.map((option) => {
          if (typeof option === "string") {
            return { value: option, text: option, identifier: option };
          } else if (typeof option === "object") {
            // Add identifier property if it's missing but needed
            const enhancedOption = {
              value: option.value || option.text || "",
              text: option.text || option.value || "",
              identifier:
                option.identifier || option.value || option.text || "",
              ...option,
            };
            return enhancedOption;
          }
          return option;
        });
      }

      return presets;
    },

    /**
     * Process component fields for complex field types like tables
     * Ensures each field has a unique idx
     */
    processComponentFields(field, pageIndex, sectionIndex, columnIndex) {
      if (!field.componentFields) return null;

      // Process each component field similarly to main fields
      return field.componentFields.map((componentField, fieldIndex) => {
        const componentFieldId = `field_${pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}_component`;

        // Ensure the component field has an idx
        const componentFieldIdx =
          componentField.idx || `table-field-${this.generateUniqueId()}`;

        // Special handling for dropdown fields in tables
        if (
          componentField.type === "dropdown" &&
          componentField.presets &&
          componentField.presets.presetsType === "static"
        ) {
          // Ensure static options have all necessary properties
          if (Array.isArray(componentField.presets.staticOptions)) {
            componentField.presets.staticOptions =
              componentField.presets.staticOptions.map((option) => {
                if (typeof option === "object") {
                  // Make sure all possible properties are present
                  return {
                    value: option.value || "",
                    text: option.value || "", // Use value as text if text is missing
                    title: option.value || "", // Add title property
                    identifier: option.value || "", // Add identifier property
                    ...option,
                  };
                }
                return option;
              });
          }
        }

        return {
          fieldId: componentFieldId,
          idx: componentFieldIdx,
          label: componentField.label || "",
          type: componentField.type || "text",
          placeholder: componentField.placeholder,
          required: componentField.required || false,
          clearable: componentField.clearable,
          presets: this.processFieldPresets(componentField),
          active:
            componentField.active !== undefined ? componentField.active : true,
          cols: componentField.cols || 3,
        };
      });
    },

    updateFormValue(fieldId, value) {
      // Get the field info
      const fieldInfo = this.fieldInfoMap[fieldId];

      // Update the form value
      this.$set(this.formValues, fieldId, value);

      // For radio fields, ensure the field info reflects the selection
      if (fieldInfo && fieldInfo.type === "radio" && fieldInfo.allOptions) {
        fieldInfo.allOptions.forEach((opt) => {
          opt.isSelected = opt.value === value;
        });
      }
    },

    /**
     * Build a form structure object that can be used by FormDataProcessor
     */
    buildFormStructure() {
      // Add any cached dynamic options to the field info
      Object.keys(this.dynamicOptionsCache).forEach((fieldId) => {
        if (this.fieldInfoMap[fieldId]) {
          this.fieldInfoMap[fieldId].dynamicOptions =
            this.dynamicOptionsCache[fieldId];
        }
      });

      // Convert the field map to an array of fields
      const fields = Object.values(this.fieldInfoMap);

      // Return a structure FormDataProcessor can use
      return {
        fields,
        formId: this.currentFormId,
        formTitle: this.currentFormTitle,
        formVersion: "1.0",
      };
    },

    /**
     * Transform form values to structured format
     */
    transformFormData() {
      // Set submission metadata
      this.formSubmissionMeta = {
        submittedAt: new Date().toISOString(),
        formId: this.currentFormId,
        formTitle: this.currentFormTitle,
        formVersion: "1.0",
      };

      // Build form structure
      const formStructure = this.buildFormStructure();

      // Transform to structured format with idx as keys
      this.structuredFormValues = FormDataProcessor.transformToStructuredData(
        this.formValues,
        formStructure
      );

      // Update allOptions for radio fields to reflect selection state
      Object.keys(this.structuredFormValues).forEach((key) => {
        const field = this.structuredFormValues[key];
        if (field.type === "radio" && field.allOptions) {
          field.allOptions.forEach((opt) => {
            opt.isSelected = opt.value === field.value;
          });
        }
      });
    },

    async submitForm() {
      console.log("PreviewForm: submitForm started");
      this.processing = true;

      try {
        // Validate all fields with dynamic validation first
        console.log("PreviewForm: Starting field validation");
        const validationErrors = await this.validateFormFields();
        if (validationErrors.length > 0) {
          console.log(
            "PreviewForm: Validation errors found:",
            validationErrors
          );
          this.$store.commit("setSnackbar", {
            text: "Please fix validation errors before submitting",
            color: "error",
          });
          return;
        }
        console.log("PreviewForm: Validation passed");

        console.log("PreviewForm: Starting form data transformation");
        this.transformFormData();
        console.log(
          "PreviewForm: Form data transformed:",
          this.structuredFormValues
        );

        // Emit the structured form data to parent
        console.log("PreviewForm: Emitting onSubmitForm event");
        this.$emit("onSubmitForm", this.structuredFormValues);
        console.log("PreviewForm: Event emitted");

        // Show results dialog
        this.showResults = true;
      } catch (error) {
        console.error("PreviewForm: Error submitting form:", error);
        this.$store.commit("setSnackbar", {
          text: "Error submitting form. Please try again.",
          color: "error",
        });
      } finally {
        this.processing = false;
      }
    },

    async validateFormFields() {
      const errors = [];

      // Iterate through all fields in the form
      for (const page of this.formPages) {
        for (const section of page.sections) {
          for (const column of section.columns) {
            for (const field of column.items) {
              // Check if field has dynamic validation
              if (
                field.validation?.validationFormDataType === "dynamic" &&
                field.validation?.selectedFormValidationDataSet
              ) {
                const fieldId = `field_${page.index}_${section.index}_${column.index}_${field.index}`;
                const value = this.formValues[fieldId];

                if (field.required && !value) {
                  errors.push(`${field.label} is required`);
                  continue;
                }

                if (value) {
                  try {
                    // Get the preset data set records
                    const records = await this.$store.dispatch(
                      "loadPresetRecords",
                      field.validation.selectedFormValidationDataSet
                    );

                    // Check if the value matches any identifier in the preset data set
                    const isValid = records.some(
                      (record) => record.identifier === value
                    );

                    if (!isValid) {
                      errors.push(`${field.label} is invalid`);
                    }
                  } catch (error) {
                    console.error(
                      `Error validating field ${field.label}:`,
                      error
                    );
                    errors.push(`Error validating ${field.label}`);
                  }
                }
              }
            }
          }
        }
      }

      return errors;
    },

    resetForm() {
      this.currentStep = 1;
      this.initializeFormValues();
      this.structuredFormValues = {};
      this.showResults = false;
    },

    handleTableEditStateChange({ fieldId, isEditing }) {
      if (isEditing) {
        if (!this.tablesBeingEdited.includes(fieldId)) {
          this.tablesBeingEdited.push(fieldId);
        }
      } else {
        const index = this.tablesBeingEdited.indexOf(fieldId);
        if (index > -1) {
          this.tablesBeingEdited.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
</style>

<template>
  <div>
    <v-dialog v-model="open" persistent max-width="60vw">
      <v-card elevation="0">
        <v-progress-linear
          :indeterminate="true"
          v-if="processing"
        ></v-progress-linear>

        Entries/LiveFormEntry.vue

        <v-card-text class="pt-3 px-3">
          <!-- Alerts section - moved from evaluation tab -->
          <v-alert v-if="hasError" type="error" class="mb-4">
            {{ errorMessage || "There was an error processing the form data." }}
          </v-alert>

          <v-alert v-if="isTableBeingEdited" type="warning" class="mb-4">
            Please save or cancel your current table edits before submitting the
            form.
          </v-alert>

          <!-- Stepper for multi-page forms -->
          <v-stepper v-if="showStepper" v-model="currentStep">
            <v-stepper-header>
              <!-- Render steps and dividers -->
              <div
                v-for="(page, index) in formPages"
                :key="`step-container-${index}`"
              >
                <v-stepper-step
                  :complete="currentStep > index + 1"
                  :step="index + 1"
                  color="primary"
                >
                  {{ page.title || `Page ${index + 1}` }}
                </v-stepper-step>
                <v-divider v-if="index < formPages.length - 1"></v-divider>
              </div>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                v-for="(page, pageIndex) in formPages"
                :key="`content-${pageIndex}`"
                :step="pageIndex + 1"
              >
                <v-card flat>
                  <preview-page
                    :page="page"
                    :pageIndex="pageIndex"
                    :formValues="formValues"
                    :validationErrors="validationErrors"
                    @update:form-value="updateFormValue"
                    @table-edit-state-change="handleTableEditStateChange"
                  />
                </v-card>

                <v-row class="mt-4" no-gutters>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="pageIndex > 0"
                    text
                    color="primary"
                    elevation="0"
                    @click="currentStep--"
                    :disabled="isTableBeingEdited"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    v-if="pageIndex < formPages.length - 1"
                    color="primary"
                    elevation="0"
                    @click="currentStep++"
                    :disabled="isTableBeingEdited"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-if="pageIndex === formPages.length - 1"
                    color="primary"
                    elevation="0"
                    @click="submitForm"
                    :loading="processing"
                    :disabled="isTableBeingEdited"
                  >
                    Submit Form
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <!-- Single page display when no stepper -->
          <div v-if="!showStepper">
            <preview-page
              v-for="(page, pageIndex) in formPages"
              :key="`page-${pageIndex}`"
              :page="page"
              :pageIndex="pageIndex"
              :formValues="formValues"
              :validationErrors="validationErrors"
              :isPreview="false"
              @update:form-value="updateFormValue"
              @table-edit-state-change="handleTableEditStateChange"
            />

            <v-card-actions class="mt-4" no gutters>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="submitForm"
                :loading="processing"
                :disabled="isTableBeingEdited"
                elevation="0"
              >
                Submit Form
              </v-btn>
            </v-card-actions>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PreviewPage from "../FormBuilder/Preview/PreviewPage.vue";
import FormDataProcessor from "@/utils/FormDataProcessor";
import DateUtils from "@/utils/DateUtils";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  components: {
    PreviewPage,
  },
  props: {
    open: Boolean,
    previewArr: Array,
    entryId: String, // Added entryId prop for entry identification
    memberId: String,
    opportunityId: String,
  },
  data() {
    return {
      processing: false,
      currentStep: 1,
      formPages: [],
      formValues: {},
      structuredFormValues: {},
      fieldInfoMap: {}, // Maps field_x_y_z keys to field objects with extended properties
      dynamicOptionsCache: {}, // Cache of dynamic options for each field
      tablesBeingEdited: [], // Track which tables are being edited
      formSubmissionMeta: {
        submittedAt: null,
        formId: null,
        formTitle: null,
        formVersion: "1.0",
      },
      presetRecordsCache: {}, // Add cache for preset records
      validationErrors: {}, // Add validationErrors data property
      errorMessage: "",
      hasError: false,
      formPoints: 0,
    };
  },
  computed: {
    showStepper() {
      return (
        this.formPages.length > 1 &&
        this.$store.state.offer &&
        this.$store.state.offer.currentOffer &&
        this.$store.state.offer.currentOffer.form &&
        this.$store.state.offer.currentOffer.form.stepper
      );
    },

    currentFormId() {
      return this.$store.state.offer && this.$store.state.offer.currentOffer
        ? this.$store.state.offer.currentOffer.id
        : null;
    },

    currentFormTitle() {
      return this.$store.state.offer && this.$store.state.offer.currentOffer
        ? this.$store.state.offer.currentOffer.name
        : null;
    },

    isTableBeingEdited() {
      return this.tablesBeingEdited.length > 0;
    },

    formTitle() {
      if (this.currentFormTitle) {
        return this.currentFormTitle;
      } else if (this.formPages.length > 0 && this.formPages[0].title) {
        return this.formPages[0].title;
      }
      return "Form Entry";
    },
  },
  watch: {
    previewArr: {
      handler(newVal) {
        // Create a deep copy when the prop changes
        this.formPages = JSON.parse(JSON.stringify(newVal));
        // Initialize form values structure and build field map
        this.initializeFormValues();
      },
      immediate: true,
    },
  },
  created() {
    // Listen for dynamic option updates
    this.$root.$on("dynamic-options-loaded", this.handleDynamicOptionsLoaded);
  },
  beforeDestroy() {
    // Remove event listener
    this.$root.$off("dynamic-options-loaded", this.handleDynamicOptionsLoaded);
  },
  methods: {
    closeDialog() {
      this.$emit("onCancel");
      this.resetForm();
      this.clearAlertData();
    },

    // Helper method to clear alert data
    clearAlertData() {
      this.hasError = false;
      this.errorMessage = "";
      this.formPoints = 0;
    },

    resetForm() {
      this.currentStep = 1;
      this.initializeFormValues();
      this.structuredFormValues = {};
      this.validationErrors = {};
    },

    generateUniqueId() {
      return "field-" + Math.random().toString(36).substring(2, 10);
    },

    handleDynamicOptionsLoaded(data) {
      if (data && data.fieldId && Array.isArray(data.options)) {
        this.dynamicOptionsCache[data.fieldId] = data.options;
        if (this.fieldInfoMap[data.fieldId]) {
          this.fieldInfoMap[data.fieldId].dynamicOptions = data.options;
        }
      }
    },

    initializeFormValues() {
      // Reset form values and field map
      this.formValues = {};
      this.fieldInfoMap = {};
      this.dynamicOptionsCache = {};

      // Create structure for storing form values and build field map
      this.formPages.forEach((page, pageIndex) => {
        page.sections.forEach((section, sectionIndex) => {
          section.columns.forEach((column, columnIndex) => {
            column.items.forEach((field, fieldIndex) => {
              const fieldId = `field_${pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}`;

              // Skip certain field types that don't need values
              if (["title", "subtitle", "note"].includes(field.type)) {
                return;
              }

              // Ensure field has an idx
              if (!field.idx) {
                field.idx = this.generateUniqueId();
              }

              // Process table component fields
              if (
                field.type === "table" &&
                Array.isArray(field.componentFields)
              ) {
                field.componentFields = field.componentFields.map(
                  (compField) => {
                    if (!compField.idx) {
                      compField.idx = `table-field-${this.generateUniqueId()}`;
                    }
                    return compField;
                  }
                );
              }

              // Store field info
              this.fieldInfoMap[fieldId] = {
                fieldId: fieldId,
                idx: field.idx,
                label: field.label || `Field ${fieldIndex}`,
                type: field.type || "text",
                placeholder: field.placeholder,
                required: field.required || false,
                clearable: field.clearable,
                validation: field.validation,
                entryLimits: field.entryLimits,
                position: {
                  page: pageIndex,
                  section: sectionIndex,
                  column: columnIndex,
                  index: fieldIndex,
                },
                presets: this.processFieldPresets(field),
                min: field.min,
                max: field.max,
                multiple: field.multiple,
                prependIcon: field.prependIcon,
                thumbLabel: field.thumbLabel,
                componentFields: this.processComponentFields(
                  field,
                  pageIndex,
                  sectionIndex,
                  columnIndex
                ),
                styles: field.styles,
              };

              // Set initial value based on field type
              if (field.type === "checkbox" || field.type === "checkboxes") {
                this.$set(this.formValues, fieldId, []);
              } else if (field.type === "switch") {
                this.$set(this.formValues, fieldId, false);
              } else if (field.type === "table") {
                this.$set(this.formValues, fieldId, [{}]);
              } else if (field.type === "radio") {
                this.$set(this.formValues, fieldId, "");
              } else if (field.type === "facebook") {
                this.$set(this.formValues, fieldId, {
                  url: "",
                  screenshot: null,
                });
              } else if (field.type === "dropzone") {
                this.$set(this.formValues, fieldId, []);
              } else {
                this.$set(this.formValues, fieldId, "");
              }
            });
          });
        });
      });
    },

    processFieldPresets(field) {
      if (!field.presets) return null;

      const presets = JSON.parse(JSON.stringify(field.presets));

      if (
        presets.presetsType === "static" &&
        Array.isArray(presets.staticOptions)
      ) {
        presets.staticOptions = presets.staticOptions.map((option) => {
          if (typeof option === "string") {
            return { value: option, text: option, identifier: option };
          } else if (typeof option === "object") {
            return {
              value: option.value || option.text || "",
              text: option.text || option.value || "",
              identifier:
                option.identifier || option.value || option.text || "",
              ...option,
            };
          }
          return option;
        });
      }

      return presets;
    },

    processComponentFields(field, pageIndex, sectionIndex, columnIndex) {
      if (!field.componentFields) return null;

      return field.componentFields.map((componentField, fieldIndex) => {
        const componentFieldId = `field_${pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}_component`;
        const componentFieldIdx =
          componentField.idx || `table-field-${this.generateUniqueId()}`;

        if (
          componentField.type === "dropdown" &&
          componentField.presets &&
          componentField.presets.presetsType === "static"
        ) {
          if (Array.isArray(componentField.presets.staticOptions)) {
            componentField.presets.staticOptions =
              componentField.presets.staticOptions.map((option) => {
                if (typeof option === "object") {
                  return {
                    value: option.value || "",
                    text: option.value || "",
                    title: option.value || "",
                    identifier: option.value || "",
                    ...option,
                  };
                }
                return option;
              });
          }
        }

        return {
          fieldId: componentFieldId,
          idx: componentFieldIdx,
          label: componentField.label || "",
          type: componentField.type || "text",
          placeholder: componentField.placeholder,
          required: componentField.required || false,
          clearable: componentField.clearable,
          presets: this.processFieldPresets(componentField),
          active:
            componentField.active !== undefined ? componentField.active : true,
          cols: componentField.cols || 3,
        };
      });
    },

    updateFormValue(fieldId, value) {
      const fieldInfo = this.fieldInfoMap[fieldId];
      this.$set(this.formValues, fieldId, value);

      // Clear validation error for this field
      if (this.validationErrors[fieldId]) {
        this.$delete(this.validationErrors, fieldId);
      }

      // Clear error alert when user fixes field values
      if (this.hasError && Object.keys(this.validationErrors).length === 0) {
        this.hasError = false;
        this.errorMessage = "";
      }

      if (fieldInfo && fieldInfo.type === "radio" && fieldInfo.allOptions) {
        fieldInfo.allOptions.forEach((opt) => {
          opt.isSelected = opt.value === value;
        });
      }
    },

    buildFormStructure() {
      Object.keys(this.dynamicOptionsCache).forEach((fieldId) => {
        if (this.fieldInfoMap[fieldId]) {
          this.fieldInfoMap[fieldId].dynamicOptions =
            this.dynamicOptionsCache[fieldId];
        }
      });

      const fields = Object.values(this.fieldInfoMap);

      return {
        fields,
        formId: this.currentFormId,
        formTitle: this.currentFormTitle,
        formVersion: "1.0",
      };
    },

    transformFormData() {
      this.formSubmissionMeta = {
        submittedAt: new Date().toISOString(),
        formId: this.currentFormId,
        formTitle: this.currentFormTitle,
        formVersion: "1.0",
      };

      const formStructure = this.buildFormStructure();
      this.structuredFormValues = FormDataProcessor.transformToStructuredData(
        this.formValues,
        formStructure
      );

      // Process file resources from field_x_y_z objects in the entry
      this.formPages.forEach((page, pageIndex) => {
        page.sections.forEach((section, sectionIndex) => {
          section.columns.forEach((column, columnIndex) => {
            column.items.forEach((field, fieldIndex) => {
              const fieldId = `field_${pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}`;

              // If this is a dropzone field, get the resources from the entry
              if (field.type === "dropzone" && this.entryId) {
                // Look for resources stored in the entry
                const entriesCollection =
                  this.$store.state.offer.currentOffer?.entries || [];
                const currentEntry = entriesCollection.find(
                  (entry) => entry.id === this.entryId
                );

                if (
                  currentEntry &&
                  currentEntry[fieldId] &&
                  currentEntry[fieldId].resources
                ) {
                  // Add the resources to the form values
                  this.$set(
                    this.formValues,
                    fieldId,
                    currentEntry[fieldId].resources
                  );

                  // Also update the structured values
                  if (field.idx && this.structuredFormValues[field.idx]) {
                    this.structuredFormValues[field.idx].value =
                      currentEntry[fieldId].resources;
                  }

                  console.log(
                    `[LiveFormEntry] Added file resources for field ${fieldId}:`,
                    currentEntry[fieldId].resources
                  );
                }
              }
            });
          });
        });
      });

      // Sanitize value helper function
      const sanitizeValue = (value) => {
        if (value === undefined) return null;
        if (Array.isArray(value)) {
          return value.map((v) => sanitizeValue(v));
        }
        if (value && typeof value === "object") {
          const sanitized = {};
          Object.entries(value).forEach(([key, val]) => {
            sanitized[key] = sanitizeValue(val);
          });
          return sanitized;
        }
        return value;
      };

      // Sanitize all values in the structured form data
      Object.keys(this.structuredFormValues).forEach((key) => {
        const field = this.structuredFormValues[key];
        if (field && typeof field === "object") {
          Object.keys(field).forEach((fieldKey) => {
            field[fieldKey] = sanitizeValue(field[fieldKey]);
          });
        }

        if (field.type === "radio" && field.allOptions) {
          field.allOptions = field.allOptions.map((opt) => ({
            ...opt,
            isSelected: opt.value === field.value,
          }));
        }
      });
    },

    async submitForm() {
      this.processing = true;
      this.hasError = false;
      this.errorMessage = "";

      let formSubmissionData = null;

      try {
        // Don't validate if any tables are currently being edited
        if (this.tablesBeingEdited.length > 0) {
          this.$store.commit(
            "setSnackbar",
            "Please save or cancel your current table edits before submitting the form."
          );
          this.processing = false;
          return;
        }

        // Validate all fields before submission
        let hasError = false;
        for (const page of this.formPages) {
          for (const section of page.sections) {
            for (const column of section.columns) {
              for (const field of column.items) {
                const fieldId = `field_${page.sections.indexOf(
                  section
                )}_${section.columns.indexOf(column)}_${column.items.indexOf(
                  field
                )}`;

                // Skip validation if this field is a table that's currently being edited
                if (this.tablesBeingEdited.includes(fieldId)) {
                  continue;
                }

                const value = this.formValues[fieldId];

                // Skip validation for non-input fields
                if (["title", "subtitle", "note"].includes(field.type)) {
                  continue;
                }

                // Required field validation
                if (field.required && (!value || value === "")) {
                  // Store error in validationErrors object
                  this.$set(
                    this.validationErrors,
                    fieldId,
                    `${field.label || "Field"} is required`
                  );

                  this.$store.commit(
                    "setSnackbar",
                    `${field.label || "Field"} is required`
                  );
                  hasError = true;
                  // Do not return immediately, collect all errors
                } else if (this.validationErrors[fieldId]) {
                  // Clear error if field is now valid
                  this.$delete(this.validationErrors, fieldId);
                }

                // Skip further validation if no value
                if (!value) continue;

                // Skip table validation if this is a table and tables are being edited
                if (
                  field.type === "table" &&
                  this.tablesBeingEdited.length > 0
                ) {
                  continue;
                }

                // Static validation
                if (
                  field.validation &&
                  field.validation.validationFormDataType === "static" &&
                  field.validation.staticValidationOptions &&
                  field.validation.staticValidationOptions.length > 0
                ) {
                  const isValid = field.validation.staticValidationOptions.some(
                    (option) => option.value === value
                  );

                  if (!isValid) {
                    // Store error in validationErrors object
                    this.$set(
                      this.validationErrors,
                      fieldId,
                      `${
                        field.label || "Field"
                      } must match one of the allowed options`
                    );

                    this.$store.commit(
                      "setSnackbar",
                      `${
                        field.label || "Field"
                      } must match one of the allowed options`
                    );
                    hasError = true;
                    // Do not return immediately, collect all errors
                  } else if (this.validationErrors[fieldId]) {
                    // Clear error if field is now valid
                    this.$delete(this.validationErrors, fieldId);
                  }
                }
                // Dynamic validation
                else if (
                  field.validation &&
                  field.validation.validationFormDataType === "dynamic" &&
                  field.validation.selectedFormValidationDataSet
                ) {
                  try {
                    const dataSetId =
                      field.validation.selectedFormValidationDataSet;
                    let records = this.presetRecordsCache[dataSetId];

                    // If records are not in cache, try to load them
                    if (!records || !Array.isArray(records)) {
                      records = await this.$store.dispatch(
                        "getPresetRecords",
                        dataSetId
                      );
                      if (records && Array.isArray(records)) {
                        this.$set(this.presetRecordsCache, dataSetId, records);
                      }
                    }

                    if (!records || !Array.isArray(records)) {
                      // Store error in validationErrors object
                      this.$set(
                        this.validationErrors,
                        fieldId,
                        `Error: No preset records available for ${
                          field.label || "Field"
                        }`
                      );

                      this.$store.commit(
                        "setSnackbar",
                        `Error: No preset records available for ${
                          field.label || "Field"
                        }`
                      );
                      hasError = true;
                      continue;
                    }

                    const isValid = records.some(
                      (record) => record.identifier === value
                    );
                    if (!isValid) {
                      // Store error in validationErrors object
                      this.$set(
                        this.validationErrors,
                        fieldId,
                        `${field.label || "Field"} is invalid`
                      );

                      this.$store.commit(
                        "setSnackbar",
                        `${field.label || "Field"} is invalid`
                      );
                      hasError = true;
                      // Do not return immediately, collect all errors
                    } else if (this.validationErrors[fieldId]) {
                      // Clear error if field is now valid
                      this.$delete(this.validationErrors, fieldId);
                    }
                  } catch (error) {
                    console.error(
                      `Error validating field ${field.label}:`,
                      error
                    );

                    // Store error in validationErrors object
                    this.$set(
                      this.validationErrors,
                      fieldId,
                      `Error validating ${field.label || "Field"}`
                    );

                    this.$store.commit(
                      "setSnackbar",
                      `Error validating ${field.label || "Field"}`
                    );
                    hasError = true;
                  }
                }
              }
            }
          }
        }

        // If any validation errors exist, stop submission process
        if (hasError) {
          this.hasError = true;
          this.errorMessage =
            "Please correct the validation errors before submitting.";
          this.processing = false;
          return;
        }

        this.transformFormData();

        // Calculate total points from all tables
        let totalFormPoints = 0;
        const tablePoints = [];

        this.formPages.forEach((page, pageIndex) => {
          page.sections.forEach((section, sectionIndex) => {
            section.columns.forEach((column, columnIndex) => {
              column.items.forEach((field, fieldIndex) => {
                if (field.type === "table" && this.hasPointsColumns(field)) {
                  const fieldId = `field_${pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}`;
                  const tableData = this.formValues[fieldId];
                  const points = this.calculateTotalPoints(field, tableData);

                  totalFormPoints += points;
                  tablePoints.push({
                    label: field.label || fieldId,
                    points: points,
                  });
                }
              });
            });
          });
        });

        // Update formPoints for display in alert
        this.formPoints = totalFormPoints;

        // Create form submission data structure with complete data
        const now = new Date();
        formSubmissionData = {
          ...this.structuredFormValues,
          formPoints: totalFormPoints,
          _meta: {
            submittedAt: now,
            createdAt: now,
            updatedAt: now,
            formId: this.currentFormId,
            formTitle: this.currentFormTitle,
            formVersion: "1.0",
            pointsBreakdown: tablePoints,
            entryId: this.entryId || null,
            status: "submitted",
          },
        };

        console.log(
          "[LiveFormEntry] Starting file resources processing for submission"
        );

        // Ensure file resources are included in the form submission
        const resourcePromises = [];

        this.formPages.forEach((page, pageIndex) => {
          page.sections.forEach((section, sectionIndex) => {
            section.columns.forEach((column, columnIndex) => {
              column.items.forEach((field, fieldIndex) => {
                const fieldId = `field_${pageIndex}_${sectionIndex}_${columnIndex}_${fieldIndex}`;

                // Handle files from dropzone fields
                if (field.type === "dropzone") {
                  // First check the current formValues for the latest values
                  // This is important as FileDropzone directly updates formValues through v-model
                  const currentResources = this.formValues[fieldId];

                  console.log(
                    `[LiveFormEntry] Processing dropzone field ${fieldId}:`,
                    currentResources && currentResources.length
                      ? `Found ${currentResources.length} resources in current values`
                      : "No resources in current values"
                  );

                  if (currentResources && currentResources.length > 0) {
                    // If we already have resources in formValues, use them directly
                    console.log(
                      `[LiveFormEntry] Using ${currentResources.length} resources from current form values`
                    );

                    // Update the formSubmissionData with the resources
                    if (field.idx && formSubmissionData[field.idx]) {
                      formSubmissionData[field.idx].value = currentResources;
                    }
                  } else {
                    // Add a promise to load resources for this field from the entry if not already in formValues
                    const resourcePromise = this.loadFieldResourcesFromEntry(
                      fieldId,
                      field.idx
                    ).then((resources) => {
                      if (resources && resources.length > 0) {
                        console.log(
                          `[LiveFormEntry] Found ${resources.length} resources for field ${fieldId} from entry`
                        );

                        // Also update the formValues for consistency
                        this.$set(this.formValues, fieldId, resources);

                        // Update the formSubmissionData with the resources
                        if (field.idx && formSubmissionData[field.idx]) {
                          formSubmissionData[field.idx].value = resources;
                        }
                      } else {
                        console.log(
                          `[LiveFormEntry] No resources found for field ${fieldId}`
                        );
                      }
                      return resources;
                    });

                    resourcePromises.push(resourcePromise);
                  }
                }
              });
            });
          });
        });

        // Wait for all resource promises to resolve
        if (resourcePromises.length > 0) {
          console.log(
            `[LiveFormEntry] Waiting for ${resourcePromises.length} resource loading promises to resolve`
          );
          await Promise.all(resourcePromises);
        } else {
          console.log(
            `[LiveFormEntry] No resource loading promises needed, all resources already loaded`
          );
        }

        // Final check of formSubmissionData to ensure all file resources are included
        let fileFieldsCount = 0;
        let fileResourcesCount = 0;

        Object.entries(formSubmissionData).forEach(([key, field]) => {
          if (field && field.type === "dropzone") {
            fileFieldsCount++;
            if (field.value && Array.isArray(field.value)) {
              fileResourcesCount += field.value.length;
              console.log(
                `[LiveFormEntry] Field ${key} has ${field.value.length} file resources`
              );
            } else {
              console.log(
                `[LiveFormEntry] Field ${key} has no file resources array`
              );
            }
          }
        });

        console.log(
          `[LiveFormEntry] Final submission contains ${fileFieldsCount} file fields with ${fileResourcesCount} total resources`
        );

        // Prepare dates for Firestore using DateUtils
        const preparedData = DateUtils.prepareForFirestore(formSubmissionData, [
          "submittedAt",
          "createdAt",
          "updatedAt",
        ]);

        // Show a success message
        this.$store.commit(
          "setSnackbar",
          "Form submitted. Don't forget to save changes."
        );

        // Emit the complete form data to the parent component (AddOrEditEntry)
        // The parent will handle updating the entry in the offer
        this.$emit("onSubmitForm", preparedData);

        // Close the form dialog
        this.closeDialog();
      } catch (error) {
        console.error("[LiveFormEntry] Error submitting form:", error);

        // Try to submit form data even if there was an error with resources
        try {
          if (formSubmissionData) {
            const preparedData = DateUtils.prepareForFirestore(
              formSubmissionData,
              ["submittedAt", "createdAt", "updatedAt"]
            );

            this.$store.commit(
              "setSnackbar",
              "Form submitted with possible missing files. Don't forget to save changes."
            );

            this.$emit("onSubmitForm", preparedData);
            this.closeDialog();
            return;
          }
        } catch (fallbackError) {
          console.error(
            "[LiveFormEntry] Error in fallback submission:",
            fallbackError
          );
        }

        this.$store.commit(
          "setSnackbar",
          "Failed to submit form. Please try again."
        );
        this.hasError = true;
        this.errorMessage =
          "Failed to submit form: " + (error.message || "Unknown error");
      } finally {
        this.processing = false;
      }
    },

    handleTableEditStateChange({ fieldId, isEditing }) {
      if (isEditing) {
        if (!this.tablesBeingEdited.includes(fieldId)) {
          this.tablesBeingEdited.push(fieldId);

          // Clear validation errors from the parent form when a table is being edited
          this.clearValidationErrors();
        }
      } else {
        const index = this.tablesBeingEdited.indexOf(fieldId);
        if (index > -1) {
          this.tablesBeingEdited.splice(index, 1);
        }
      }
    },

    // Helper method to clear validation errors
    clearValidationErrors() {
      // Clear all validation errors
      this.validationErrors = {};
    },

    // Add new methods for enhanced form display
    hasPointsColumns(field) {
      return (
        field.componentFields &&
        field.componentFields.some(
          (subField) =>
            subField.type === "dropdown" &&
            subField.presets &&
            subField.presets.valuesToDisplay &&
            subField.presets.valuesToDisplay.includes("points")
        )
      );
    },

    calculateTotalPoints(field, tableData) {
      if (!Array.isArray(tableData)) return 0;

      let total = 0;
      const pointsFields =
        (field.componentFields &&
          field.componentFields.filter(
            (subField) =>
              subField.type === "dropdown" &&
              subField.presets &&
              subField.presets.valuesToDisplay &&
              subField.presets.valuesToDisplay.includes("points")
          )) ||
        [];

      // Find quantity field
      const quantityField =
        field.componentFields &&
        field.componentFields.find(
          (subField) =>
            subField.type === "textfield" && subField.label === "Quantity"
        );

      tableData.forEach((row) => {
        pointsFields.forEach((pointsField) => {
          const fieldId = pointsField.idx || `field-${pointsField.label}`;
          const pointsKey = `${fieldId}_points`;
          if (row[pointsKey] !== undefined) {
            let points = parseFloat(row[pointsKey]) || 0;

            // If quantity field exists, multiply points by quantity
            if (quantityField) {
              const quantityId =
                quantityField.idx || `field-${quantityField.label}`;
              const quantity = parseFloat(row[quantityId]) || 1;
              points *= quantity;
            }

            total += points;
          }
        });
      });

      return total;
    },

    isPointsEnabledField(field) {
      return (
        field.type === "dropdown" &&
        field.presets &&
        field.presets.presetsType === "dynamic" &&
        field.presets.valuesToDisplay &&
        Array.isArray(field.presets.valuesToDisplay) &&
        field.presets.valuesToDisplay.includes("points")
      );
    },

    getPointsValue(row, field) {
      if (!field) return "0";

      const fieldId = field.idx || `field-${field.label}`;
      const pointsKey = `${fieldId}_points`;

      // Check for stored points value
      if (row[pointsKey] !== undefined) {
        let points = parseFloat(row[pointsKey]) || 0;

        // Find quantity field
        const quantityField =
          field.componentFields &&
          field.componentFields.find(
            (subField) =>
              subField.type === "textfield" && subField.label === "Quantity"
          );

        // If quantity field exists, multiply points by quantity
        if (quantityField) {
          const quantityId =
            quantityField.idx || `field-${quantityField.label}`;
          const quantity = parseFloat(row[quantityId]) || 1;
          points *= quantity;
        }

        return points.toString();
      }

      const value = row[fieldId];
      if (!value || !field.presets || field.presets.presetsType !== "dynamic") {
        return "0";
      }

      // Try to find points in dynamic options
      const selectedItem = this.findSelectedDynamicItem(field, value);
      if (selectedItem && selectedItem.points !== undefined) {
        let points = parseFloat(selectedItem.points) || 0;

        // Find quantity field
        const quantityField =
          field.componentFields &&
          field.componentFields.find(
            (subField) =>
              subField.type === "textfield" && subField.label === "Quantity"
          );

        // If quantity field exists, multiply points by quantity
        if (quantityField) {
          const quantityId =
            quantityField.idx || `field-${quantityField.label}`;
          const quantity = parseFloat(row[quantityId]) || 1;
          points *= quantity;
        }

        return points.toString();
      }

      return "0";
    },

    findSelectedDynamicItem(field, value) {
      if (!field || !field.presets || !field.presets.selectedPresetsGroup)
        return null;

      const groupId = field.presets.selectedPresetsGroup;
      const records = this.presetRecordsCache[groupId];

      if (records && Array.isArray(records)) {
        return records.find((item) => item.identifier === value);
      }

      return null;
    },

    // Update getFieldValue method to handle points
    getFieldValue(field) {
      if (!field.idx || !this.structuredFormValues[field.idx]) {
        return null;
      }

      // Create a deep copy of the field data
      const fieldData = JSON.parse(
        JSON.stringify(this.structuredFormValues[field.idx])
      );

      // For radio fields, ensure allOptions reflects selection state
      if (field.type === "radio" && fieldData.allOptions) {
        fieldData.allOptions.forEach((opt) => {
          opt.isSelected = opt.value === fieldData.value;
        });
      }

      // For table fields, process data and handle points
      if (field.type === "table" && Array.isArray(fieldData.value)) {
        fieldData.value = this.processTableData(field, [...fieldData.value]);

        // Calculate total points if table has points columns
        if (this.hasPointsColumns(field)) {
          fieldData.totalPoints = this.calculateTotalPoints(
            field,
            fieldData.value
          );
        }
      }

      // For dropzone fields, ensure we return file resources
      if (field.type === "dropzone") {
        // If fieldData has value array, use it
        if (Array.isArray(fieldData.value)) {
          return fieldData.value;
        }

        // Look for resources in the form values
        const fieldId = field.fieldId;
        if (fieldId) {
          // Check if this entry has resources for this field
          const entriesCollection =
            this.$store.state.offer.currentOffer?.entries || [];
          const currentEntry = entriesCollection.find(
            (entry) => entry.id === this.entryId
          );

          if (
            currentEntry &&
            currentEntry[fieldId] &&
            currentEntry[fieldId].resources
          ) {
            return currentEntry[fieldId].resources;
          }
        }

        // Return empty array as fallback
        return [];
      }

      return fieldData.value;
    },

    // Update processTableData method to handle points
    processTableData(tableField, tableData) {
      if (!tableField.componentFields || !Array.isArray(tableData)) {
        return tableData;
      }

      return tableData.map((row) => {
        const processedRow = { ...row };

        tableField.componentFields.forEach((field) => {
          if (field.type === "dropdown") {
            const fieldId = field.idx || `field-${field.label}`;
            const value = row[fieldId];
            const pointsKey = `${fieldId}_points`;

            // Process dropdown display value
            if (value) {
              processedRow[fieldId] = this.getDropdownDisplayValue(
                field,
                value
              );
            }

            // Include points data if available
            if (row[pointsKey] !== undefined) {
              processedRow[pointsKey] = row[pointsKey];
            }
          }
        });

        return processedRow;
      });
    },

    getDropdownDisplayValue(field, value) {
      if (value === null || value === undefined || value === "") {
        return "Not selected";
      }

      // For dynamic presets
      if (field.presets && field.presets.presetsType === "dynamic") {
        const selectedItem = this.findSelectedDynamicItem(field, value);
        if (selectedItem) {
          return this.generateDisplayText(field, selectedItem);
        } else {
          return value;
        }
      }

      // For static options
      if (field.presets && field.presets.staticOptions) {
        const option = field.presets.staticOptions.find(
          (opt) => (typeof opt === "object" ? opt.value : opt) === value
        );
        if (option) {
          return typeof option === "object" ? option.text : option;
        }
      }

      return value;
    },

    generateDisplayText(field, item) {
      if (!item) return "-";

      if (
        !field.presets ||
        !field.presets.valuesToDisplay ||
        !field.presets.valuesToDisplay.length
      ) {
        return item.identifier || "-";
      }

      const displayValues = field.presets.valuesToDisplay;

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean);

      return displayParts.length > 0
        ? displayParts.join(" • ")
        : item.identifier || "-";
    },

    loadFieldResourcesFromEntry(fieldId, idx) {
      return new Promise((resolve) => {
        // First check if we can find the resources in the local state
        if (this.entryId) {
          const entriesCollection =
            this.$store.state.offer.currentOffer?.entries || [];
          const currentEntry = entriesCollection.find(
            (entry) => entry.id === this.entryId
          );

          if (
            currentEntry &&
            currentEntry[fieldId] &&
            currentEntry[fieldId].resources
          ) {
            const resources = currentEntry[fieldId].resources;
            console.log(
              `[LiveFormEntry] Found ${resources.length} resources in local state for field ${fieldId}`
            );
            return resolve(resources);
          }

          // If not found in local state, try to load from Firestore
          if (this.$store.state.offer.currentOffer?.id) {
            const db = getFirestore();
            const entryRef = doc(
              db,
              "programs",
              this.$store.getters.programId,
              "offers",
              this.$store.state.offer.currentOffer.id,
              "entries",
              this.entryId
            );

            getDoc(entryRef)
              .then((snapshot) => {
                if (snapshot.exists()) {
                  const entryData = snapshot.data();
                  if (entryData[fieldId] && entryData[fieldId].resources) {
                    const resources = entryData[fieldId].resources;
                    console.log(
                      `[LiveFormEntry] Found ${resources.length} resources in Firestore for field ${fieldId}`
                    );
                    return resolve(resources);
                  }
                }
                // If we still haven't found resources, return an empty array
                console.log(
                  `[LiveFormEntry] No resources found for field ${fieldId} with idx ${idx}`
                );
                return resolve([]);
              })
              .catch((error) => {
                console.error(
                  `[LiveFormEntry] Error fetching entry from Firestore:`,
                  error
                );
                return resolve([]);
              });
          } else {
            // If no offer ID, return empty array
            return resolve([]);
          }
        } else {
          // If no entry ID, return empty array
          return resolve([]);
        }
      });
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
</style>

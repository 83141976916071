<template>
  <div class="pb-16">
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="9">
          <Pages :pages="pages"></Pages>
        </v-col>

        <v-col cols="3" class="available-group col-padding mt-n1">
          <v-row no-gutters class="page-subheading"
            ><v-btn color="primary" elevation="0" @click="previewForm"
              >Preview form</v-btn
            ></v-row
          >
          <v-row no-gutters class="page-subheading">Components</v-row>
          <draggable
            v-model="availableComponents"
            tag="div"
            :group="{
              name: 'form-fields',
              pull: 'clone',
              put: false,
            }"
            ghost-class="ghost"
            class="available-components-grid"
          >
            <v-card
              v-for="(field, index) in availableComponents"
              :key="index"
              class="available-component-card"
              elevation="0"
            >
              <v-row
                align="center"
                class="fill-height"
                no-gutters
                justify="center"
              >
                <v-col cols="auto">
                  <v-icon class="icon-available-components">{{
                    field.icon
                  }}</v-icon>
                </v-col>
                <v-col>
                  <span class="subheading-available-components">{{
                    field.label
                  }}</span>
                </v-col>
              </v-row>
            </v-card>
          </draggable>
          <v-row no-gutters class="page-subheading">Sections</v-row>
          <draggable
            v-model="availableSections"
            tag="div"
            :group="{
              name: 'form-fields',
              pull: 'clone',
              put: false,
            }"
            ghost-class="ghost"
            class="available-components-grid"
          >
            <v-card
              v-for="(section, index) in availableSections"
              :key="index"
              class="available-component-card"
              elevation="0"
            >
              <v-row
                align="center"
                class="fill-height"
                no-gutters
                justify="center"
              >
                <v-col cols="auto">
                  <v-icon class="icon-available-components">{{
                    section.icon
                  }}</v-icon>
                </v-col>
                <v-col>
                  <span class="subheading-available-components">{{
                    section.label
                  }}</span>
                </v-col>
              </v-row>
            </v-card>
          </draggable>

          <v-row no-gutters class="page-subheading">Templates</v-row>
          <draggable
            v-model="availableSpecial"
            tag="div"
            :group="{
              name: 'form-fields',
              pull: 'clone',
              put: false,
            }"
            ghost-class="ghost"
            class="available-components-grid"
          >
            <v-card
              v-for="(field, index) in availableSpecial"
              :key="index"
              class="available-component-card"
              elevation="0"
            >
              <v-row
                align="center"
                class="fill-height"
                no-gutters
                justify="center"
              >
                <v-col cols="auto">
                  <v-icon class="icon-available-components">{{
                    field.icon
                  }}</v-icon>
                </v-col>
                <v-col>
                  <span class="subheading-available-components">{{
                    field.label
                  }}</span>
                </v-col>
              </v-row>
            </v-card>
          </draggable>
          <v-row no-gutters class="page-subheading">Headings</v-row>
          <draggable
            v-model="availableHeadings"
            tag="div"
            :group="{
              name: 'form-fields',
              pull: 'clone',
              put: false,
            }"
            ghost-class="ghost"
            class="available-components-grid"
          >
            <v-card
              v-for="(field, index) in availableHeadings"
              :key="index"
              class="available-component-card"
              elevation="0"
            >
              <v-row
                align="center"
                class="fill-height"
                no-gutters
                justify="center"
              >
                <v-col cols="auto">
                  <v-icon class="icon-available-components">{{
                    field.icon
                  }}</v-icon>
                </v-col>
                <v-col>
                  <span class="subheading-available-components">{{
                    field.label
                  }}</span>
                </v-col>
              </v-row>
            </v-card>
          </draggable>
          <v-row no-gutters class="page-subheading">Settings</v-row>
          <v-switch v-model="stepper" label="Stepper"></v-switch>
        </v-col>
      </v-row>

      <PreviewForm
        :open="previewFormOpen"
        :previewArr="formPreviewArr"
        :isPreview="isPreview"
        @onCancelPreviewForm="cancelPreviewForm"
      ></PreviewForm>
    </v-container>
  </div>
</template>

<script>
import Pages from "./Pages.vue";
import PreviewForm from "../Preview/PreviewForm.vue";
export default {
  components: {
    Pages,
    PreviewForm,
  },
  data: () => ({
    search: "",
    editingItem: null,
    editingSection: null,
    editingItemTable: null,
    fieldText: null,
    editedIndex: -1,
    fieldDialogOpen: false,
    tableDialogOpen: false,
    dialogDeleteSection: false,
    menu: false,
    dates: null,
    previewFormOpen: false,
    formPreviewArr: [],
    isPreview: true,
    availableFields: [
      // textfield
      {
        type: "textfield",
        title: "Text Field",
        label: "Text Field",
        clearable: true,
        icon: "text_fields",
        required: false,
        group: "fields",
        placeholder: "",
        editor: "components",
        validation: {
          validationFormDataType: "none", // Validation mode Options: none = no validation, static = via staticValidationOptions,
          // dynamic = via selectedFormValidationDataSet from the presetDataSets collection.
          selectedFormValidationDataSet: null, // The document id of the form validation data set from the presetDataSets collection. records saved
          // in the presetDataSets doc in the data subcollection are used to validate the form field.
          selectedFormValidationField: null, // FUTURE FEATURE NOT IN USE: will be used to allow user to select which field from
          // the data set to validate against the form field.
          // from either identifier, title, description
          staticValidationOptions: [], // Arr of Obj [{ value: "ABC123" }, { value: "XYZ123" }] ... applies to static
        },
        entryLimits: {
          makeAvailable: true,
          maximumEntriesPerValue: null,
        },
      },
      // dropzone
      {
        type: "dropzone",
        title: "Dropzone",
        label: "Upload Files",
        icon: "cloud_upload",
        required: false,
        group: "fields",
        placeholder: "",
        editor: "components",
        maxFiles: 10,
        maxFileSize: 1024 * 1024 * 10, // 10MB
        permittedFileExtensions: [
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "csv",
        ],
      },
      // textarea
      {
        type: "textarea",
        title: "Text Area",
        label: "Text Area",
        icon: "notes",
        clearable: true,
        required: false,
        group: "fields",
        placeholder: "",
        editor: "components",
        entryLimits: {
          makeAvailable: true,
          maximumEntriesPerValue: null,
        },
      },
      // dropdown
      {
        type: "dropdown",
        label: "Dropdown",
        icon: "expand_more",
        group: "fields",
        clearable: true,
        required: false,
        placeholder: "",
        editor: "components",
        entryLimits: {
          maximumEntriesPerValue: null,
        },
        presets: {
          presetsType: "static", // selection data
          selectedPresetsGroup: null,
          valuesToDisplay: ["value"],
          staticOptions: [{ value: "Option 1" }, { value: "Option 2" }],
        },
      },
      // radio
      {
        type: "radio",
        label: "Single",
        presets: {
          presetsType: "static", // selection data
          selectedPresetsGroup: null,
          valuesToDisplay: ["title"],
          staticOptions: [{ value: "Option 1" }, { value: "Option 2" }],
        },
        icon: "radio_button_checked",
        group: "fields",
        required: false,
        editor: "components",
      },
      //checkboxes
      {
        type: "checkboxes",
        label: "Multiple",
        presets: {
          presetsType: "static", // selection data
          selectedPresetsGroup: null,
          valuesToDisplay: ["title"],
          staticOptions: [{ value: "Option 1" }, { value: "Option 2" }],
        },
        icon: "check_box",
        group: "fields",
        required: false,
        editor: "components",
      },
      // table // products,serial,price,quantity
      {
        type: "table",
        label: "Table",
        tags: ["Product", "Serial", "Price", "Quantity"],
        icon: "table_view",
        group: "fields",
        editor: "special",
        componentFields: [
          {
            active: true,
            label: "Products",
            required: true,
            clearable: true,
            type: "dropdown",
            placeholder: "",
            entryLimits: {
              maximumEntriesPerValue: null,
            },
            presets: {
              presetsType: "static", // selection data
              selectedPresetsGroup: null,
              valuesToDisplay: ["identifier"],
              staticOptions: [{ value: "Option 1" }, { value: "Option 2" }],
            },
            cols: "5",
          },
          {
            active: true,
            label: "Serial Number",
            required: true,
            clearable: true,
            type: "textfield",
            formOptions: [],
            validation: {
              validationFormDataType: "none", // Validation mode Options: none, static, dynamic
              selectedFormValidationDataSet: null, // The document id of the form validation data set from the presetDataSets collection. records saved
              // in the presetDataSets doc in the data subcollection are used to validate the form field.
              selectedFormValidationField: null, // FUTURE FEATURE NOT IN USE: will be used to allow user to select which field from the data set to validate against the form field.
              // from either identifier, title, description
              staticValidationOptions: [], // Arr of Obj [{ value: "ABC123" }, { value: "XYZ123" }] ... applies to static
            },
            cols: "3",
            placeholder: "",
          },
          {
            active: true,
            label: "Price Each",
            required: true,
            clearable: true,
            type: "textfield",
            formOptions: [],
            validation: {
              validationFormDataType: "none", // Validation mode Options: none, static, dynamic
              selectedFormValidationDataSet: null, // The document id of the form validation data set from the presetDataSets collection. records saved
              // in the presetDataSets doc in the data subcollection are used to validate the form field.
              selectedFormValidationField: null, // FUTURE FEATURE NOT IN USE: will be used to allow user to select which field from the data set to validate against the form field.
              // from either identifier, title, description
              staticValidationOptions: [], // Arr of Obj [{ value: "ABC123" }, { value: "XYZ123" }] ... applies to static
            },
            cols: "2",
            placeholder: "",
          },
          {
            active: true,
            label: "Quantity",
            required: true,
            clearable: true,
            type: "textfield",
            formOptions: [],
            validation: {
              validationFormDataType: "none", // Validation mode Options: none, static, dynamic
              selectedFormValidationDataSet: null, // The document id of the form validation data set from the presetDataSets collection. records saved
              // in the presetDataSets doc in the data subcollection are used to validate the form field.
              selectedFormValidationField: null, // FUTURE FEATURE NOT IN USE: will be used to allow user to select which field from the data set to validate against the form field.
              // from either identifier, title, description
              staticValidationOptions: [], // Arr of Obj [{ value: "ABC123" }, { value: "XYZ123" }] ... applies to static
            },
            cols: "2",
            placeholder: "",
          },
        ],
      },
      // Facebook post // url, screenshot
      {
        type: "facebook",
        label: "Facebook",
        tags: ["Facebook Post URL", "Screenshot"],
        icon: "facebook",
        group: "fields",
        editor: "special",
        componentFields: [
          {
            type: "subtitle",
            label: "Facebook Post Upload",
            icon: "text_fields",
            group: "fields",
            editor: "headings",
            styles: {
              align: "center",
              boxHeight: 25,
              color: "#000000FF",
              cols: 12,
              field: "title",
              floatText: "text-left",
              font: "Roboto, sans-serif",
              justify: "center",
              lineHeight: 1.5,
              size: 14,
              style: "normal",
              value: "Facebook",
              weight: "bold",
              marginLeft: 10,
              marginRight: 0,
            },
          },
          {
            active: true,
            label: "Facebook Post URL",
            required: true,
            clearable: true,
            type: "textfield",
            prependIcon: "link",
            validation: {
              validationFormDataType: "none", // Validation mode Options: none, static, dynamic
              selectedFormValidationDataSet: null, // Presets group id from Form Presets Library
              selectedFormValidationField: null, // Presets group id from Form Presets Library
              staticValidationOptions: [], // Arr of Obj [{ value: "ABC123" }, { value: "XYZ123" }] ... applies to static
            },
            cols: "12",
            placeholder: "https://facebook.com/...",
          },
          {
            type: "file-input",
            label: "Post Screenshot",
            clearable: false,
            icon: "photo_camera",
            group: "fields",
            editor: "components",
            placeholder: "Choose image file...",
            multiple: false,
            required: false,
          },
        ],
      },
      {
        type: "date-picker",
        label: "Date",
        clearable: false,
        required: false,
        icon: "calendar_month",
        group: "fields",
        placeholder: "",
        editor: "components",
      },
      {
        type: "time-picker",
        label: "Time",
        clearable: false,
        required: false,
        icon: "schedule",
        group: "fields",
        placeholder: "",
        editor: "components",
      },
      {
        type: "switch",
        label: "Switch",
        icon: "toggle_on",
        group: "fields",
        editor: "components",
      },

      {
        type: "slider",
        label: "Slider",
        min: 1,
        max: 10,
        thumbLabel: true,
        required: false,
        icon: "linear_scale",
        group: "fields",
        editor: "components",
      },
      {
        type: "section",
        label: "1 Column",
        columns: [
          {
            items: [],
          },
        ],
        icon: "table_chart",
        group: "sections",
        editor: "sections",
      },
      {
        type: "section",
        label: "2 Columns",
        columns: [
          {
            items: [],
          },
          {
            items: [],
          },
        ],
        icon: "table_chart",
        group: "sections",
        editor: "sections",
      },
      {
        type: "section",
        label: "3 Columns",
        columns: [
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
        ],
        icon: "table_chart",
        group: "sections",
        editor: "sections",
      },
      {
        type: "section",
        label: "4 Columns",
        columns: [
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
        ],
        icon: "table_chart",
        group: "sections",
        editor: "sections",
      },
      {
        type: "section",
        label: "5 Columns",
        columns: [
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
        ],
        icon: "table_chart",
        group: "sections",
        editor: "sections",
      },
      {
        type: "section",
        label: "6 Columns",
        columns: [
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
          {
            items: [],
          },
        ],
        icon: "table_chart",
        group: "sections",
        editor: "sections",
      },
      {
        type: "title",
        label: "Title",
        icon: "title",
        group: "fields",
        editor: "headings",
        styles: {
          align: "center",
          boxHeight: 10,
          color: "#000000FF",
          cols: 12,
          field: "title",
          floatText: "text-left",
          font: "Roboto, sans-serif",
          justify: "center",
          lineHeight: 1.2,
          size: 24,
          style: "normal",
          value: "Title",
          weight: "normal",
          marginLeft: 0,
          marginRight: 0,
        },
      },
      {
        type: "subtitle",
        label: "Subtitle",
        icon: "text_fields",
        group: "fields",
        editor: "headings",
        styles: {
          align: "center",
          boxHeight: 25,
          color: "#000000FF",
          cols: 12,
          field: "title",
          floatText: "text-left",
          font: "Roboto, sans-serif",
          justify: "center",
          lineHeight: 1.5,
          size: 14,
          style: "normal",
          value: "Subtitle",
          weight: "bold",
          marginLeft: 0,
          marginRight: 0,
        },
      },
      {
        type: "note",
        label: "Note",
        icon: "notes",
        group: "fields",
        editor: "headings",
        styles: {
          align: "center",
          boxHeight: 25,
          color: "#000000FF",
          cols: 12,
          field: "title",
          floatText: "text-left",
          font: "Roboto, sans-serif",
          justify: "center",
          lineHeight: 1.3,
          size: 12,
          style: "normal",
          value: "Note",
          weight: "normal",
          marginLeft: 0,
          marginRight: 0,
        },
      },
    ],
  }),
  created() {},
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    pages() {
      // Check if form and pages exist, otherwise handle legacy data
      if (this.currentOffer.form && this.currentOffer.form.pages) {
        return this.currentOffer.form.pages;
      } else {
        return [];
      }
    },

    loading() {
      return this.$store.getters.loading;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    quizChanged() {
      return this.$store.state.quiz.quizChanged;
    },
    offerUpdates() {
      return this.$store.state.offer.offerUpdates;
    },
    reordering() {
      return this.$store.state.quiz.reorderingQuiz;
    },
    availableComponents() {
      return this.availableFields
        .filter((el) => el.editor === "components")
        .map((field) => ({
          ...field,
          idx: undefined,
        }));
    },
    availableSections() {
      return this.availableFields
        .filter((el) => el.editor === "sections")
        .map((field) => ({
          ...field,
          idx: undefined,
        }));
    },
    availableHeadings() {
      return this.availableFields
        .filter((el) => el.editor === "headings")
        .map((field) => ({
          ...field,
          idx: undefined,
        }));
    },
    availableTables() {
      return this.availableFields
        .filter((el) => el.editor === "tables")
        .map((field) => ({
          ...field,
          idx: undefined,
        }));
    },
    availableSpecial() {
      return this.availableFields
        .filter((el) => el.editor === "special")
        .map((field) => ({
          ...field,
          idx: undefined,
        }));
    },
    activePageNumber() {
      if (this.tab !== null) {
        return this.tab + 1;
      }
      return "";
    },
    stepper: {
      get() {
        if (
          this.currentOffer &&
          this.currentOffer.form &&
          this.currentOffer.form.stepper
        ) {
          return this.currentOffer.form.stepper;
        }
        return false;
      },
      set(change) {
        this.currentOffer.form.stepper = change;
        this.patchCurrentOffer();
      },
    },
  },
  methods: {
    handleMove(event) {
      // Check if coming from editor group
      const fromGroup = event.from?.__vueParent__?.$attrs?.group?.name;
      if (fromGroup === "editor") {
        return true;
      }

      const itemMovedGroup = event.draggedContext?.element?.group;
      const destinationGroup =
        event.relatedContext?.component?.$attrs?.group?.name;

      // For non-editor moves, check if groups match
      if (itemMovedGroup === destinationGroup) {
        const update = {
          form: this.currentOffer.form,
          stepper: this.stepper,
        };
        this.$store.dispatch("patchCurrentOffer", update);
        return true;
      }

      return false;
    },
    handleNew() {
      this.editingItem = null;
      this.fieldDialogOpen = true;
    },
    handleNewTable() {
      this.editingItem = null;
      this.tableDialogOpen = true;
    },
    editItem(item) {
      this.editedIndex = this.sections.indexOf(item);
      this.editingItem = Object.assign({}, item);
      if (item.type === "table") {
        this.tableDialogOpen = true;
      } else {
        this.fieldDialogOpen = true;
      }
      if (item.presetsType === "dynamic") {
        this.$store.dispatch("loadPresetDataSets");
      }
    },
    closeDialog() {
      this.editingItem = null;
      this.fieldDialogOpen = false;
      this.tableDialogOpen = false;
      this.editedIndex = -1;
    },
    handleDeleteSectionConfirmation(item) {
      this.editedIndex = this.currentOffer.form.pages.indexOf(item);
      this.dialogDeleteSection = true;
    },
    confirmDeleteSection() {
      this.currentOffer.form.pages.splice(this.editedIndex, 1);
      const update = {
        ...this.currentOffer,
        ...this.offerUpdates,
      };
      this.$store.dispatch("patchCurrentOffer", update);
      this.dialogDeleteSection = false;
      this.editedIndex = -1;
    },
    cancelDeleteSection() {
      this.editedIndex = -1;
      this.dialogDeleteSection = false;
    },
    previewForm() {
      // Create a deep copy of the pages array
      this.formPreviewArr = JSON.parse(JSON.stringify(this.pages));
      this.previewFormOpen = true;
    },
    cancelPreviewForm() {
      this.previewFormOpen = false;
      this.formPreviewArr = [];
    },
  },
  watch: {},
};
</script>

<style scoped>
.handle {
  cursor: pointer;
}
.ghost {
  border: 1px dotted rgb(134, 134, 134) !important;
  margin-left: 14px;
  background-color: #ececec !important;
  margin-top: 14px;
}
.points-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 2px !important;
  font-size: 14px !important;
}

.type-subheading {
  font-weight: 300 !important;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #505050 !important;
  margin-left: 10px;
}
.w-100 {
  width: 100%;
}
.w-99 {
  width: 99px;
}
.movable {
  width: 100%;
}
.available-group {
  margin-top: -1px;
  margin-left: -1px;
}
.available-group .available-items-group {
  background-color: #ececec !important;
  margin-top: 1px;
  cursor: all-scroll;
}
.active-items {
  border: 1px solid #dddddd;
  color: #ececec !important;
  margin-top: 1px;
  margin-bottom: 7px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
  padding-left: 20px;
}
.icon {
  color: #505050 !important;
  margin-left: 14px;
}
.col-padding {
  padding-left: 22px !important;
}
.dragArea {
  min-height: 35px;
  display: block;
  padding-bottom: 50px;
}
.button-spacer {
  width: 5px !important;
}
.drag-ind-adjust {
  margin-left: -14px !important;
}
.icon-available-components {
  color: #505050 !important;
  font-size: 22px !important;
  margin-right: 6px;
  margin-left: 14px !important;
}
.subheading-available-components {
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  color: #505050 !important;
}
.available-components-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 4px;
}
.available-components-grid-2col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 4px;
}

.available-component-card {
  background-color: #ececec !important;
  height: 60px;
  cursor: all-scroll;
  width: 100%;
}

/* Update existing styles */
.icon-available-components {
  color: #505050 !important;
  font-size: 22px !important;
  margin-right: 6px;
  margin-left: 14px !important;
}

.subheading-available-components {
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  color: #505050 !important;
}
</style>

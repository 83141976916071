<template>
  <div class="preview-table">
    <!-- Form for adding new rows -->
    <v-sheet class="mb-0 pa-0" outlined rounded>
      <v-row align="center" no-gutters>
        <!-- Form fields container taking up most of the space -->
        <v-col>
          <v-row class="py-2" no-gutters>
            <v-col
              v-for="field in tableFields"
              :key="field.idx"
              :cols="field.cols || Math.floor(12 / tableFields.length)"
              class="pl-2"
            >
              <component-renderer
                :field="getClonedField(field)"
                :value="newRowFormValues[field.idx || `field-${field.label}`]"
                :show-validation="showValidation"
                :validation-error="
                  newRowValidationErrors[field.idx || `field-${field.label}`]
                "
                @input="
                  updateNewRowFormValue(
                    field.idx || `field-${field.label}`,
                    $event
                  )
                "
                @clear-validation="
                  clearNewRowValidationError(
                    field.idx || `field-${field.label}`
                  )
                "
              />
            </v-col>
          </v-row>
          <!-- Points display row -->
          <v-row v-if="pointsFields.length > 0" class="px-2 mt-2" no-gutters>
            <v-col>
              <div class="points-summary">
                <div
                  v-for="field in pointsFields"
                  :key="field.idx"
                  class="points-item d-inline-block"
                >
                  <v-chip
                    v-if="hasDropdownValue(field)"
                    color="primary"
                    class="mt-n3 mb-2"
                    small
                    icon
                  >
                    <v-icon left>stars</v-icon>
                    {{ getPointsValue(newRowFormValues, field) }} points
                  </v-chip>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <!-- Buttons in auto-width column -->
        <v-col cols="auto">
          <v-btn
            text
            color="grey darken-1"
            @click="resetNewRowForm"
            class="mr-2 mt-n8"
          >
            Clear
          </v-btn>
          <v-btn
            color="green"
            @click="addNewRow"
            :disabled="
              !isNewRowFormValid ||
              Object.keys(newRowValidationErrors).length > 0
            "
            elevation="0"
            :dark="
              isNewRowFormValid &&
              Object.keys(newRowValidationErrors).length === 0
            "
            :loading="showValidation"
            class="mt-n8"
            >Add
          </v-btn>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-data-table
        :headers="tableHeaders"
        :items="tableRows"
        :items-per-page="tableOptions.itemsPerPage"
        :page.sync="tableOptions.page"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, -1],
          'items-per-page-text': 'Rows per page:',
          'show-first-last-page': false,
          'show-current-page': true,
        }"
        :mobile-breakpoint="0"
      >
        <!-- Display data in read-only mode or edit mode -->
        <template v-slot:item="{ item, index }">
          <tr v-if="editingIndex !== index">
            <!-- Regular display mode -->
            <td v-for="header in displayHeaders" :key="header.value">
              {{
                header.value.endsWith("_points")
                  ? getPointsValue(item, getFieldFromPointsHeader(header))
                  : formatFieldValue(item, getFieldFromHeader(header))
              }}
            </td>
            <td class="text-center">
              <v-btn
                icon
                small
                color="amber"
                @click="editRow(index)"
                class="mx-1"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="red"
                @click="removeRow(index)"
                class="mx-1"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-else>
            <!-- Inline edit mode -->
            <td v-for="header in displayHeaders" :key="header.value">
              <template v-if="!header.value.endsWith('_points')">
                <component-renderer
                  :field="getClonedField(getFieldFromHeader(header))"
                  :value="editRowFormValues[header.value]"
                  @input="updateEditRowFormValue(header.value, $event)"
                  :show-validation="showValidation"
                  :validation-error="editRowValidationErrors[header.value]"
                  @clear-validation="clearEditRowValidationError(header.value)"
                />
              </template>
              <template v-else>
                {{
                  getPointsValue(
                    editRowFormValues,
                    getFieldFromPointsHeader(header)
                  )
                }}
              </template>
            </td>
            <td class="text-center">
              <v-btn
                icon
                small
                color="success"
                @click="saveEditedRow"
                class="mx-1"
                :disabled="
                  !isEditRowFormValid ||
                  Object.keys(editRowValidationErrors).length > 0
                "
                :loading="showValidation"
                x-small
              >
                <v-icon small>check</v-icon>
              </v-btn>
              <v-btn icon small color="grey" @click="cancelEdit" class="mx-1">
                <v-icon small>close</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <!-- No data message -->
        <template v-slot:no-data>
          <div class="text-center py-2">
            No data available. Use the form above to add data.
          </div>
        </template>
      </v-data-table>

      <!-- Total Points Display -->
      <v-chip v-if="hasPointsColumns" color="primary" class="mt-n16 ml-6 mb-0">
        <v-icon left>stars</v-icon>
        Total Points: {{ calculateTotalPoints() }}
      </v-chip>
    </v-sheet>
  </div>
</template>

<script>
import ComponentRenderer from "./ComponentRenderer.vue";

export default {
  components: {
    ComponentRenderer,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [{}],
    },
  },
  data() {
    return {
      tableOptions: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
      },
      editingIndex: -1, // -1 means not editing, >= 0 means editing existing row
      newRowFormValues: {}, // Values for the add new row form
      editRowFormValues: {}, // Values for the inline edit form
      presetRecordsCache: {}, // Cache for preset records by group ID
      showValidation: false,
      // Separate validation states for row editing and new row form
      editRowValidationErrors: {}, // Stores validation errors for the row being edited
      newRowValidationErrors: {}, // Stores validation errors for the new row form
    };
  },
  computed: {
    tableFields() {
      return (this.field.componentFields || []).filter((field) => field.active);
    },

    tableHeaders() {
      const headers = [];
      console.log("Building headers from fields:", this.tableFields);

      this.tableFields.forEach((field) => {
        const fieldId = field.idx || `field-${field.label}`;
        // Add the regular field header
        const header = {
          text: field.label || "Column",
          value: fieldId,
          sortable: true,
          align: "start",
          width: field.cols ? `${field.cols * 8}%` : "auto",
          fieldType: field.type, // Store field type for reference
          isPointsEnabled: this.isPointsEnabledField(field), // Store points capability
        };
        //console.log(`Creating header for field ${fieldId}:`, header);
        headers.push(header);

        // Check if this is a dropdown field with points capability
        if (this.isPointsEnabledField(field)) {
          const pointsHeader = {
            text: "Points",
            value: `${fieldId}_points`,
            sortable: false,
            align: "right",
            parentField: fieldId, // Store reference to parent field
            isPointsColumn: true,
          };
          //console.log(
          //  `Creating points header for field ${fieldId}:`,
          //  pointsHeader
          //);
          headers.push(pointsHeader);
        }
      });

      // Add actions column
      headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "120px",
      });

      return headers;
    },

    tableRows: {
      get() {
        // Only return non-empty rows or an empty array
        return this.value && this.value.length > 0
          ? this.value.filter((row) => {
              // Check if the row has any non-empty values
              return Object.values(row).some(
                (val) => val !== undefined && val !== null && val !== ""
              );
            })
          : [];
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    isNewRowFormValid() {
      return this.isFormValid(this.newRowFormValues);
    },

    isEditRowFormValid() {
      return this.isFormValid(this.editRowFormValues);
    },

    pointsFields() {
      return this.tableFields.filter(
        (field) =>
          field.type === "dropdown" &&
          field.presets &&
          field.presets.presetsType === "dynamic" &&
          field.presets.valuesToDisplay &&
          Array.isArray(field.presets.valuesToDisplay) &&
          field.presets.valuesToDisplay.includes("points")
      );
    },

    formFields() {
      const fields = [];
      this.tableFields.forEach((field) => {
        // Add the main field
        fields.push({
          id: field.idx || `field-${field.label}`,
          type: "field",
          data: field,
          cols: field.cols || Math.floor(12 / this.tableFields.length),
        });

        // Add points column if needed
        if (this.shouldShowPoints(field)) {
          fields.push({
            id: `${field.idx || `field-${field.label}`}-points`,
            type: "points",
            data: field,
            cols: "auto",
          });
        }
      });
      return fields;
    },

    displayHeaders() {
      return this.tableHeaders.filter((header) => header.value !== "actions");
    },

    hasPointsColumns() {
      return this.tableFields.some((field) => this.isPointsEnabledField(field));
    },
  },
  watch: {
    value: {
      handler(newValue) {
        // When the value prop changes from outside, reinitialize the table data
        if (newValue && Array.isArray(newValue)) {
          this.initializeTableData();
        }
      },
      deep: true,
    },
    newRowFormValues: {
      deep: true,
      handler() {},
    },
    editRowFormValues: {
      deep: true,
      handler() {},
    },
  },
  created() {
    this.resetNewRowForm();
    this.initializeTableData();
    this.loadAllPresetRecords();
  },
  methods: {
    // Initialize table data by removing any empty rows from the initial value
    initializeTableData() {
      if (this.value && this.value.length > 0) {
        // Filter out empty rows from the initial value
        const nonEmptyRows = this.value.filter((row) => {
          return Object.values(row).some(
            (val) => val !== undefined && val !== null && val !== ""
          );
        });

        // If the filtered array is different from the original, update it
        if (nonEmptyRows.length !== this.value.length) {
          this.$emit("input", nonEmptyRows);
        }
      }
    },

    // Generic method to check if a form is valid
    isFormValid(formValues) {
      // Get all required fields
      const requiredFields = this.tableFields.filter((field) => field.required);

      // If there are no required fields, consider the form valid
      if (requiredFields.length === 0) return true;

      // Check if all required fields have values
      return requiredFields.every((field) => {
        const fieldId = field.idx || `field-${field.label}`;
        const value = formValues[fieldId];
        return value !== undefined && value !== null && value !== "";
      });
    },

    // Initialize form with default values
    initializeFormValues() {
      const formValues = {};
      this.tableFields.forEach((field) => {
        const fieldId = field.idx || `field-${field.label}`;

        // Set appropriate default values based on field type
        let defaultValue = "";
        switch (field.type) {
          case "checkbox":
            defaultValue = false;
            break;
          case "number":
            defaultValue = null;
            break;
          case "date":
            defaultValue = "";
            break;
          default:
            defaultValue = "";
        }

        formValues[fieldId] = defaultValue;
      });
      return formValues;
    },

    resetNewRowForm() {
      // Initialize empty form with appropriate default values
      this.newRowFormValues = this.initializeFormValues();
      this.showValidation = false;
      this.newRowValidationErrors = {}; // Clear new row validation errors
    },

    resetEditRowForm() {
      this.editRowFormValues = {};
      this.editingIndex = -1;
      this.showValidation = false;
      this.editRowValidationErrors = {}; // Clear edit row validation errors
    },

    updateNewRowFormValue(fieldId, value) {
      // Clear validation error for this field when value changes
      if (this.newRowValidationErrors[fieldId]) {
        this.$delete(this.newRowValidationErrors, fieldId);
      }
      this.$set(this.newRowFormValues, fieldId, value);
    },

    updateEditRowFormValue(fieldId, value) {
      // Clear validation error for this field when value changes
      if (this.editRowValidationErrors[fieldId]) {
        this.$delete(this.editRowValidationErrors, fieldId);
      }
      this.$set(this.editRowFormValues, fieldId, value);
    },

    async addNewRow() {
      this.showValidation = true;
      this.newRowValidationErrors = {}; // Reset validation errors

      if (!this.isNewRowFormValid) {
        // If form isn't valid, just show validation and return
        return;
      }

      try {
        // Create a new row with the form values
        const newRow = { ...this.newRowFormValues };

        // Validate fields against preset data sets or static options
        for (const field of this.tableFields) {
          const fieldId = field.idx || `field-${field.label}`;
          const value = newRow[fieldId];

          // Required field validation
          if (field.required && !value && value !== false) {
            // Store the validation error
            this.$set(
              this.newRowValidationErrors,
              fieldId,
              `${field.label} is required`
            );
            this.$store.commit("setSnackbar", `${field.label} is required`);
            return;
          }

          if (value !== undefined && value !== null && value !== "") {
            // Handle static validation
            if (
              field.validation?.validationFormDataType === "static" &&
              field.validation?.staticValidationOptions?.length > 0
            ) {
              const isValid = field.validation.staticValidationOptions.some(
                (option) => option.value === value
              );

              if (!isValid) {
                // Store the validation error
                this.$set(
                  this.newRowValidationErrors,
                  fieldId,
                  `${field.label} must match one of the allowed options`
                );
                this.$store.commit(
                  "setSnackbar",
                  `${field.label} must match one of the allowed options`
                );
                return;
              }
            }
            // Handle dynamic validation
            else if (
              field.validation?.validationFormDataType === "dynamic" &&
              field.validation?.selectedFormValidationDataSet
            ) {
              try {
                const dataSetId =
                  field.validation.selectedFormValidationDataSet;
                let records = this.presetRecordsCache[dataSetId];

                // If records are not in cache, try to load them
                if (!records || !Array.isArray(records)) {
                  console.log(`Loading records for data set ${dataSetId}`);
                  records = await this.$store.dispatch(
                    "getPresetRecords",
                    dataSetId
                  );
                  if (records && Array.isArray(records)) {
                    this.$set(this.presetRecordsCache, dataSetId, records);
                  }
                }

                if (!records || !Array.isArray(records)) {
                  // Store the validation error
                  this.$set(
                    this.newRowValidationErrors,
                    fieldId,
                    `Error: No preset records available for ${field.label}`
                  );
                  this.$store.commit(
                    "setSnackbar",
                    `Error: No preset records available for ${field.label}`
                  );
                  return;
                }

                const isValid = records.some(
                  (record) => record.identifier === value
                );
                if (!isValid) {
                  // Store the validation error
                  this.$set(
                    this.newRowValidationErrors,
                    fieldId,
                    `${field.label} is invalid`
                  );
                  this.$store.commit(
                    "setSnackbar",
                    `${field.label} is invalid`
                  );
                  return;
                }
              } catch (error) {
                console.error(`Error validating field ${field.label}:`, error);
                // Store the validation error
                this.$set(
                  this.newRowValidationErrors,
                  fieldId,
                  `Error validating ${field.label}`
                );
                this.$store.commit(
                  "setSnackbar",
                  `Error validating ${field.label}`
                );
                return;
              }
            }
          }
        }

        // Add points data for dropdown fields that support points
        this.pointsFields.forEach((field) => {
          const fieldId = field.idx || `field-${field.label}`;
          const value = newRow[fieldId];
          const pointsKey = `${fieldId}_points`;

          if (value) {
            const selectedItem = this.findSelectedDynamicItem(field, value);
            if (selectedItem && selectedItem.points !== undefined) {
              newRow[pointsKey] = selectedItem.points;
              // Also store metadata about the points
              newRow[`${fieldId}_points_meta`] = {
                source: "dynamic_preset",
                originalValue: selectedItem.points,
                timestamp: new Date().toISOString(),
              };
            }
          }
        });

        // Add new row to table data
        const currentRows = [...this.tableRows, newRow];
        this.$emit("input", currentRows);
        this.resetNewRowForm();
      } catch (error) {
        console.error("Error adding new row:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to add row. Please try again."
        );
      } finally {
        this.showValidation = false;
      }
    },

    editRow(index) {
      // If already editing another row, confirm first
      if (this.editingIndex !== -1 && this.editingIndex !== index) {
        if (confirm("Save your current changes first?")) {
          this.saveEditedRow();
        } else {
          this.cancelEdit();
        }
      }

      // Load row data into edit form
      this.editingIndex = index;
      const row = this.tableRows[index];

      this.editRowFormValues = {};
      this.tableFields.forEach((field) => {
        const fieldId = field.idx || `field-${field.label}`;
        this.$set(this.editRowFormValues, fieldId, row[fieldId] || "");
      });

      // Also load points values
      this.pointsFields.forEach((field) => {
        const fieldId = field.idx || `field-${field.label}`;
        const pointsKey = `${fieldId}_points`;
        if (row[pointsKey] !== undefined) {
          this.$set(this.editRowFormValues, pointsKey, row[pointsKey]);
        }
      });

      // Emit event when editing starts
      console.log(
        "[PreviewTable] Starting edit mode, emitting edit-state-change true"
      );
      this.$emit("edit-state-change", true);
    },

    async saveEditedRow() {
      this.showValidation = true;
      this.editRowValidationErrors = {}; // Reset validation errors

      if (!this.isEditRowFormValid) {
        // If form isn't valid, just show validation and return
        return;
      }

      try {
        // Validate fields against preset data sets or static options
        for (const field of this.tableFields) {
          const fieldId = field.idx || `field-${field.label}`;
          const value = this.editRowFormValues[fieldId];

          // Required field validation
          if (field.required && !value && value !== false) {
            // Store validation error
            this.$set(
              this.editRowValidationErrors,
              fieldId,
              `${field.label} is required`
            );
            this.$store.commit("setSnackbar", `${field.label} is required`);
            return;
          }

          if (value !== undefined && value !== null && value !== "") {
            // Handle static validation
            if (
              field.validation?.validationFormDataType === "static" &&
              field.validation?.staticValidationOptions?.length > 0
            ) {
              const isValid = field.validation.staticValidationOptions.some(
                (option) => option.value === value
              );

              if (!isValid) {
                // Store validation error
                this.$set(
                  this.editRowValidationErrors,
                  fieldId,
                  `${field.label} must match one of the allowed options`
                );
                this.$store.commit(
                  "setSnackbar",
                  `${field.label} must match one of the allowed options`
                );
                return;
              }
            }
            // Handle dynamic validation
            else if (
              field.validation?.validationFormDataType === "dynamic" &&
              field.validation?.selectedFormValidationDataSet
            ) {
              try {
                const dataSetId =
                  field.validation.selectedFormValidationDataSet;
                let records = this.presetRecordsCache[dataSetId];

                // If records are not in cache, try to load them
                if (!records || !Array.isArray(records)) {
                  console.log(`Loading records for data set ${dataSetId}`);
                  records = await this.$store.dispatch(
                    "getPresetRecords",
                    dataSetId
                  );
                  if (records && Array.isArray(records)) {
                    this.$set(this.presetRecordsCache, dataSetId, records);
                  }
                }

                if (!records || !Array.isArray(records)) {
                  // Store validation error
                  this.$set(
                    this.editRowValidationErrors,
                    fieldId,
                    `Error: No preset records available for ${field.label}`
                  );
                  this.$store.commit(
                    "setSnackbar",
                    `Error: No preset records available for ${field.label}`
                  );
                  return;
                }

                const isValid = records.some(
                  (record) => record.identifier === value
                );
                if (!isValid) {
                  // Store validation error
                  this.$set(
                    this.editRowValidationErrors,
                    fieldId,
                    `${field.label} is invalid`
                  );
                  this.$store.commit(
                    "setSnackbar",
                    `${field.label} is invalid`
                  );
                  return;
                }
              } catch (error) {
                console.error(`Error validating field ${field.label}:`, error);
                // Store validation error
                this.$set(
                  this.editRowValidationErrors,
                  fieldId,
                  `Error validating ${field.label}`
                );
                this.$store.commit(
                  "setSnackbar",
                  `Error validating ${field.label}`
                );
                return;
              }
            }
          }
        }

        const updatedRows = [...this.tableRows];

        // Update points values before saving
        this.pointsFields.forEach((field) => {
          const fieldId = field.idx || `field-${field.label}`;
          const value = this.editRowFormValues[fieldId];
          const pointsKey = `${fieldId}_points`;

          if (value) {
            const selectedItem = this.findSelectedDynamicItem(field, value);
            if (selectedItem && selectedItem.points !== undefined) {
              this.editRowFormValues[pointsKey] = selectedItem.points;
              // Also store the points metadata for edited rows
              this.editRowFormValues[`${fieldId}_points_meta`] = {
                source: "dynamic_preset",
                originalValue: selectedItem.points,
                timestamp: new Date().toISOString(),
                lastEdited: new Date().toISOString(),
              };
            }
          }
        });

        // Update the row being edited
        updatedRows[this.editingIndex] = { ...this.editRowFormValues };

        this.$emit("input", updatedRows);
        this.resetEditRowForm();
        // Emit event when editing ends
        console.log(
          "[PreviewTable] Saving row, emitting edit-state-change false"
        );
        this.$emit("edit-state-change", false);
      } catch (error) {
        console.error("Error saving edited row:", error);
        this.$store.commit(
          "setSnackbar",
          "Failed to save changes. Please try again."
        );
      } finally {
        this.showValidation = false;
      }
    },

    cancelEdit() {
      this.resetEditRowForm();
      // Emit event when editing ends
      console.log(
        "[PreviewTable] Canceling edit, emitting edit-state-change false"
      );
      this.$emit("edit-state-change", false);
    },

    removeRow(index) {
      if (this.tableRows.length === 0) return;

      // Confirm deletion
      if (!confirm("Are you sure you want to delete this row?")) return;

      // Get current non-empty rows
      const currentRows = [...this.tableRows];

      // Remove the row at the specified index
      currentRows.splice(index, 1);

      this.$emit("input", currentRows);

      // If we were editing this row, cancel the edit
      if (this.editingIndex === index) {
        this.resetEditRowForm();
      } else if (this.editingIndex > index) {
        // Adjust editing index if we removed a row before the one being edited
        this.editingIndex--;
      }
    },

    formatFieldValue(row, field) {
      if (!field) {
        console.warn("Attempted to format value for undefined field");
        return "-";
      }

      const fieldId = field.idx || `field-${field.label}`;
      const value = row[fieldId];

      console.log("Formatting field value:", {
        fieldId,
        value,
        field: field,
        rowData: row,
      });

      if (value === undefined || value === null || value === "") {
        return "-";
      }

      // Format based on field type
      switch (field.type) {
        case "checkbox":
          return value ? "Yes" : "No";
        case "date":
          return this.formatDate(value);
        case "dropdown":
          return this.formatDropdownValue(field, value);
        default:
          return value;
      }
    },

    formatDate(dateString) {
      if (!dateString) return "-";
      try {
        const date = new Date(dateString);
        return date.toLocaleDateString();
      } catch (e) {
        return dateString;
      }
    },

    formatDropdownValue(field, value) {
      if (!value) return "-";

      // For dynamic presets with valuesToDisplay configuration
      if (field.presets && field.presets.presetsType === "dynamic") {
        // Try to find the selected item in the cached dynamic options
        const selectedItem = this.findSelectedDynamicItem(field, value);
        if (selectedItem) {
          // Ensure we're using the field's valuesToDisplay configuration
          return this.generateDisplayText(field, selectedItem);
        } else {
          // If item not found in cache, try to load it on demand
          this.loadPresetRecordsIfNeeded(field.presets.selectedPresetsGroup);
          // Return the raw value for now
          return value;
        }
      }

      // For static options
      if (field.presets && field.presets.staticOptions) {
        const option = field.presets.staticOptions.find(
          (opt) => (typeof opt === "object" ? opt.value : opt) === value
        );
        if (option) {
          return typeof option === "object" ? option.text : option;
        }
      }

      return value;
    },

    generateDisplayText(field, item) {
      if (!item) return "-";

      // If no valuesToDisplay configuration is provided, show identifier as fallback
      if (
        !field.presets ||
        !field.presets.valuesToDisplay ||
        field.presets.valuesToDisplay.length === 0
      ) {
        return item.identifier || "-";
      }

      const displayValues = field.presets.valuesToDisplay;

      const displayParts = displayValues
        .map((val) => {
          switch (val) {
            case "identifier":
              return item.identifier;
            case "title":
              return item.title;
            case "description":
              return item.description;
            case "points":
              return item.points ? `${item.points} points` : "";
            default:
              return "";
          }
        })
        .filter(Boolean); // Remove empty values

      return displayParts.length > 0
        ? displayParts.join(" • ")
        : item.identifier || "-";
    },

    findSelectedDynamicItem(field, value) {
      if (!field || !field.presets || !field.presets.selectedPresetsGroup)
        return null;

      const groupId = field.presets.selectedPresetsGroup;
      const records = this.presetRecordsCache[groupId];

      if (records && Array.isArray(records)) {
        return records.find((item) => item.identifier === value);
      }

      return null;
    },

    // Load all preset records needed for the table fields
    async loadAllPresetRecords() {
      if (!this.$store) return;

      // Find all unique preset groups used in the table
      const presetGroups = new Set();
      this.tableFields.forEach((field) => {
        // Check for validation data sets
        if (
          field.validation?.validationFormDataType === "dynamic" &&
          field.validation?.selectedFormValidationDataSet
        ) {
          presetGroups.add(field.validation.selectedFormValidationDataSet);
        }
        // Check for dropdown presets
        if (
          field.type === "dropdown" &&
          field.presets &&
          field.presets.presetsType === "dynamic" &&
          field.presets.selectedPresetsGroup
        ) {
          presetGroups.add(field.presets.selectedPresetsGroup);
        }
      });

      // Load records for each preset group
      for (const groupId of presetGroups) {
        await this.loadPresetRecords(groupId);
      }
    },

    // Load preset records for a specific group
    async loadPresetRecords(groupId) {
      if (!this.$store || !groupId) return;

      try {
        const records = await this.$store.dispatch("getPresetRecords", groupId);
        if (records && Array.isArray(records)) {
          // Store in cache
          this.$set(this.presetRecordsCache, groupId, records);
          console.log(`Loaded ${records.length} records for group ${groupId}`);
        } else {
          console.warn(`No records found for group ${groupId}`);
        }
      } catch (error) {
        console.error(
          `Error loading preset records for group ${groupId}:`,
          error
        );
      }
    },

    // Load preset records for a specific group if not already in cache
    loadPresetRecordsIfNeeded(groupId) {
      if (!this.$store || !groupId || this.presetRecordsCache[groupId]) return;

      this.loadPresetRecords(groupId);
    },

    shouldShowPoints(field) {
      return (
        field.type === "dropdown" &&
        field.presets &&
        field.presets.presetsType === "dynamic" &&
        field.presets.valuesToDisplay &&
        Array.isArray(field.presets.valuesToDisplay) &&
        field.presets.valuesToDisplay.includes("points")
      );
    },

    getPointsValue(item, field) {
      const fieldId = field.idx || `field-${field.label}`;
      const pointsKey = `${fieldId}_points`;

      // For table display, first check if we have a stored points value
      if (item[pointsKey] !== undefined) {
        return item[pointsKey];
      }

      const value = item[fieldId];
      if (!value || !field.presets || field.presets.presetsType !== "dynamic") {
        return "0";
      }

      // Try to find the selected item in the cached dynamic options
      const selectedItem = this.findSelectedDynamicItem(field, value);
      if (selectedItem && selectedItem.points !== undefined) {
        return `${selectedItem.points}`;
      }

      return "0";
    },

    hasDropdownValue(field) {
      const fieldId = field.idx || `field-${field.label}`;
      const value = this.newRowFormValues[fieldId];
      return value !== undefined && value !== null && value !== "";
    },

    getFieldFromHeader(header) {
      // First try by field ID
      let field = this.tableFields.find(
        (field) => (field.idx || `field-${field.label}`) === header.value
      );

      // If not found and this is a points column, try finding by parent field
      if (!field && header.parentField) {
        field = this.tableFields.find(
          (field) =>
            (field.idx || `field-${field.label}`) === header.parentField
        );
      }

      console.log(`Mapping header ${header.value} to field:`, field);
      return field;
    },

    getFieldFromPointsHeader(header) {
      const baseFieldId = header.value.replace("_points", "");
      const field = this.tableFields.find(
        (field) => (field.idx || `field-${field.label}`) === baseFieldId
      );
      console.log(`Mapping points header ${header.value} to field:`, field);
      return field;
    },

    isPointsEnabledField(field) {
      return (
        field.type === "dropdown" &&
        field.presets?.presetsType === "dynamic" &&
        field.presets?.valuesToDisplay &&
        Array.isArray(field.presets.valuesToDisplay) &&
        field.presets.valuesToDisplay.includes("points")
      );
    },

    calculateTotalPoints() {
      if (!this.tableRows || !Array.isArray(this.tableRows)) return 0;

      let total = 0;
      const pointsFields = this.tableFields.filter((field) =>
        this.isPointsEnabledField(field)
      );

      // Find quantity field - looking for textfield with label 'Quantity'
      const quantityField = this.tableFields.find(
        (field) => field.type === "textfield" && field.label === "Quantity"
      );

      this.tableRows.forEach((row) => {
        pointsFields.forEach((field) => {
          const fieldId = field.idx || `field-${field.label}`;
          const pointsKey = `${fieldId}_points`;
          if (row[pointsKey] !== undefined) {
            let points = parseFloat(row[pointsKey]) || 0;

            // If quantity field exists and has a value, multiply points by quantity
            if (quantityField) {
              const quantityId =
                quantityField.idx || `field-${quantityField.label}`;
              const quantityValue = row[quantityId];
              if (quantityValue) {
                points *= parseFloat(quantityValue) || 1;
              }
            }

            // Add the points to the total
            total += Math.round(points);
          }
        });
      });

      return Math.round(total);
    },

    getClonedField(field) {
      if (!field) return {};

      // Create a deep clone of the field
      const clonedField = JSON.parse(JSON.stringify(field));

      // Remove validation error property if it exists
      if (
        Object.prototype.hasOwnProperty.call(clonedField, "validationError")
      ) {
        delete clonedField.validationError;
      }

      return clonedField;
    },

    clearNewRowValidationError(fieldId) {
      if (this.newRowValidationErrors[fieldId]) {
        this.$delete(this.newRowValidationErrors, fieldId);
      }
    },

    clearEditRowValidationError(fieldId) {
      if (this.editRowValidationErrors[fieldId]) {
        this.$delete(this.editRowValidationErrors, fieldId);
      }
    },
  },
};
</script>

// src/utils/DateUtils.js

import moment from "moment";

/**
 * Centralized date utilities for consistent date handling
 * between client state and Firestore
 */
export const DateUtils = {
  /**
   * Converts any date format to JS Date object for client-side use
   * @param {Date|Timestamp|string} dateValue - Date in any format
   * @returns {Date|null} - JS Date object or null if invalid
   */
  toJsDate(dateValue) {
    if (!dateValue) return null;

    // Already a Date object
    if (dateValue instanceof Date) {
      return dateValue;
    }

    // Firestore Timestamp
    if (dateValue && typeof dateValue.toDate === "function") {
      return dateValue.toDate();
    }

    // Handle YYYY-MM-DD format from v-date-picker
    if (
      typeof dateValue === "string" &&
      dateValue.match(/^\d{4}-\d{2}-\d{2}$/)
    ) {
      // Use current time with the date to ensure consistent time part
      const now = new Date();
      const [year, month, day] = dateValue.split("-").map(Number);
      return new Date(
        year,
        month - 1,
        day,
        now.getHours(),
        now.getMinutes(),
        now.getSeconds()
      );
    }

    // Other string formats
    if (typeof dateValue === "string") {
      const parsed = new Date(dateValue);
      return isNaN(parsed.getTime()) ? null : parsed;
    }

    return null;
  },

  /**
   * Format date for display (e.g., "Jan 15, 2024")
   * @param {Date|Timestamp|string} dateValue - Date in any format
   * @returns {string} Formatted date or empty string if invalid
   */
  formatDisplay(dateValue) {
    const date = this.toJsDate(dateValue);
    return date ? moment(date).format("MMM DD, YYYY") : "";
  },

  /**
   * Format date to ISO format for input fields (e.g., "2024-01-15")
   * @param {Date|Timestamp|string} dateValue - Date in any format
   * @returns {string} ISO date string or empty string if invalid
   */
  formatISO(dateValue) {
    const date = this.toJsDate(dateValue);
    return date ? moment(date).format("YYYY-MM-DD") : "";
  },

  /**
   * Format time for display (e.g., "2:30 PM")
   * @param {Date|Timestamp|string} dateValue - Date in any format
   * @returns {string} Formatted time or empty string if invalid
   */
  formatTime(dateValue) {
    const date = this.toJsDate(dateValue);
    return date ? moment(date).format("hh:mm A") : "";
  },

  /**
   * Ensures consistent time zone handling for all dates
   * @param {Date|Timestamp|string} dateValue - Date in any format
   * @returns {Date} Date with consistent time zone
   */
  normalizeTimeZone(dateValue) {
    const date = this.toJsDate(dateValue);
    if (!date) return null;

    // Create new date with the same values but enforcing local time zone
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
  },

  /**
   * Ensures consistent date handling for Firestore operations
   * Prepares dates for saving to Firestore
   * @param {Object} data - Object containing dates to prepare
   * @param {Array} dateFields - Array of field names that should be treated as dates
   * @returns {Object} Object with properly formatted dates
   */
  prepareForFirestore(
    data,
    dateFields = [
      "submitted",
      "reviewed",
      "formSubmittedAt",
      "created",
      "updated",
      "createdAt",
      "lastModifiedAt",
    ]
  ) {
    const prepared = { ...data };

    dateFields.forEach((field) => {
      if (prepared[field] !== undefined) {
        // Convert to JS Date first to ensure consistency and normalize time zone
        const jsDate = this.normalizeTimeZone(prepared[field]);

        // Either keep as JS Date (Firestore will convert to Timestamp)
        // or set to null if invalid
        prepared[field] = jsDate;
      }
    });

    return prepared;
  },
};

export default DateUtils;

/* eslint-disable no-console */
import { db } from "@/firebase";

const state = {
  presetDataSets: [],
  loadingPresetDataSets: false,
};

const actions = {
  async loadPresetDataSets({ commit, getters }) {
    commit("setLoadingPresetDataSets", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("presetDataSets")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const presetDataSets = [];
    for (const doc of querySnapshot.docs) {
      const hasData = await checkForRecords(doc.id);

      presetDataSets.push({
        id: doc.id,
        hasData,
        ...doc.data(),
      });
    }

    async function checkForRecords(datasetId) {
      //console.log("checking for data preset records");
      const dataRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("presetDataSets")
        .doc(datasetId)
        .collection("data");

      const snapshot = await dataRef.limit(1).get();
      return snapshot.empty ? false : true;
    }

    commit("setPresetDataSets", presetDataSets);
    commit("setLoadingPresetDataSets", false);
  },

  async createPresetDataSet({ dispatch, commit, getters }, payload) {
    dispatch("setLoadingPresetDataSets", true);
    const titlesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("presetDataSets");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await titlesRef
        .where("dataSetTitleUppercase", "==", payload.dataSetTitleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking if the title has been used";
    }

    if (titleDupSnapshot.size > 0) {
      dispatch("setLoadingPresetDataSets", false);
      throw "Data Set is already registered";
    }

    const { id, ...presetDataSetInput } = payload; // eslint-disable-line no-unused-vars
    const title = {
      ...presetDataSetInput,
    };

    let newTitleRef;
    try {
      newTitleRef = await titlesRef.add(title);
    } catch (e) {
      dispatch("setLoadingPresetDataSets", false);
      throw "Error occured when creating a new Data Set";
    }

    commit("createPresetDataSet", {
      ...presetDataSetInput,
      id: newTitleRef.id,
    });
    dispatch("setLoadingPresetDataSets", false);
    dispatch("setSnackbar", "Data Set Created");
  },

  async updatePresetDataSet({ dispatch, commit, getters }, payload) {
    dispatch("setLoadingPresetDataSets", true);
    const presetDataSetRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("presetDataSets");

    let storedPresetDataSet;
    try {
      const presetDataSetDoc = await presetDataSetRef.doc(payload.id).get();
      storedPresetDataSet = presetDataSetDoc.data();
    } catch (e) {
      storedPresetDataSet = null;
    }

    if (!storedPresetDataSet) {
      dispatch("setLoadingPresetDataSets", false);
      throw "Error occured when fetching the Data Set";
    }

    let presetDataSetDupSnapshot;
    try {
      presetDataSetDupSnapshot = await presetDataSetRef
        .where("dataSetTitleUppercase", "==", payload.dataSetTitleUppercase)
        .get();
    } catch (e) {
      dispatch("setLoadingPresetDataSets", false);
      throw "Error occured when checking if the Data Set exists.";
    }

    if (presetDataSetDupSnapshot.size > 0) {
      let duplicated = false;
      presetDataSetDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        dispatch("setLoadingPresetDataSets", false);
        throw "Data set title is already registered.";
      }
    }

    const { id, ...presetDataSetInput } = payload;
    const dataset = {
      ...presetDataSetInput,
    };

    try {
      await presetDataSetRef.doc(id).update(dataset);
    } catch (e) {
      console.error(e);
      dispatch("setLoadingPresetDataSets", false);
      throw "Error occured when updating a Data Set";
    }

    commit("updatePresetDataSet", payload);
    dispatch("loadPresetDataSets");
    dispatch("setLoadingPresetDataSets", false);
    dispatch("setSnackbar", "Preset Data Set Updated.");
  },

  async deletePresetDataSet({ dispatch, commit, getters }, titleId) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("presetDataSets")
        .doc(titleId)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a title";
    }
    commit("deletePresetDataSet", titleId);
    dispatch("setSnackbar", "Data set deleted.");
  },

  setLoadingPresetDataSets({ commit }, payload) {
    commit("setLoadingPresetDataSets", payload);
  },
};

const mutations = {
  setPresetDataSets(state, payload) {
    state.presetDataSets = payload;
  },

  setLoadingPresetDataSets(state, payload) {
    state.loadingPresetDataSets = payload;
  },

  createPresetDataSet(state, payload) {
    state.presetDataSets = [...state.presetDataSets, payload];
  },

  updatePresetDataSet(state, payload) {
    state.presetDataSets = state.presetDataSets.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deletePresetDataSet(state, payload) {
    state.presetDataSets = state.presetDataSets.filter(
      (item) => item.id !== payload
    );
  },
};

const getters = {
  presetDataSets(state) {
    return state.presetDataSets;
  },
  loadingPresetDataSets(state) {
    return state.loadingPresetDataSets;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
